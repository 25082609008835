import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface DialogPreview {
  image: string;
}

@Component({
  selector: 'app-preview-label',
  templateUrl: './preview-label.component.html',
  styleUrls: ['./preview-label.component.css']
})
export class PreviewLabelComponent {

  constructor(
    public dialogRef: MatDialogRef<PreviewLabelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogPreview,
  ) {}
}
