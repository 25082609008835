export interface WorkflowOption {
  value: string,
  displayValue: string,
  conflicts?: string[],
  validIndexes?: number[],
};

export const MANUAL_ERASE_OPTIONS = [
  {
    value: 'print',
    displayValue: 'Print',
  },
  {
    value: 'off',
    displayValue: 'Power Off',
  },
];

export const MANUAL_RESTORE_OPTIONS = [
  {
    value: 'print',
    displayValue: 'Print',
  },
  {
    value: 'off',
    displayValue: 'Power Off',
  },
];

export const MANUAL_PRINT_OPTIONS = [
  {
    value: 'erase',
    displayValue: 'Erase',
    conflicts: ['restore'],
  },
  {
    value: 'restore',
    displayValue: 'Restore',
    conflicts: ['erase'],
  },
  {
    value: 'off',
    displayValue: 'Power Off',
  },
];

export const MANUAL_POWER_OFF_OPTIONS = [
  {
    value: 'print',
    displayValue: 'Print',
  },
];
