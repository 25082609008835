import { OnInit, OnDestroy, EventEmitter, Output, Input, Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  IMEI_CHECK_SETTING,
  APPLE_ACTION,
  SAMSUNG_ACTION,
  LG_ACTION,
  GOOGLE_ACTION,
  VERIZON_ACTION,
  TMOBILE_ACTION,
  ATT_ACTION,
  UNLOCKED_ACTION,
  NOT_SUPPORTED_OR_KNOWN_CARRIER_ACTION,
  IMEICheckConfiguration,
  IMEISettingConfiguration, TRACFONE_ACTION,
} from './imei-check-configuration.interface';

export const COST_EFFECTIVE_CONFIGURATION: IMEISettingConfiguration = {
  attAction: ATT_ACTION.CHECK_GLOBAL_BLACKLIST_ONLY,
  appleIPhoneAction: APPLE_ACTION.CHECK_CARRIER_IF_UNKNOWN_AND_CHECK_SIMLOCK,
  appleIPadAction: APPLE_ACTION.CHECK_CARRIER_IF_UNKNOWN_AND_CHECK_SIMLOCK,
  samsungAction: SAMSUNG_ACTION.CHECK_CARRIER_IF_UNKNOWN,
  tracfoneAction: TRACFONE_ACTION.CHECK_TRACFONE,
  tmobileAction: TMOBILE_ACTION.CHECK_TMOBILE,
  verizonAction: VERIZON_ACTION.CHECK_VERIZON,
  unlockedAction: UNLOCKED_ACTION.CHECK_GLOBAL_BLACKLIST_ONLY,
  notSupportedOrUnknownCarrierAction: NOT_SUPPORTED_OR_KNOWN_CARRIER_ACTION.CHECK_GLOBAL_BLACKLIST_ONLY,
};

export const MOST_ACCURATE_CONFIGURATION: IMEISettingConfiguration = {
  attAction: ATT_ACTION.CHECK_GLOBAL_BLACKLIST_ONLY,
  appleIPhoneAction: APPLE_ACTION.CHECK_CARRIER_IF_UNKNOWN_AND_CHECK_SIMLOCK,
  appleIPadAction: APPLE_ACTION.CHECK_CARRIER_IF_UNKNOWN_AND_CHECK_SIMLOCK,
  samsungAction: SAMSUNG_ACTION.CHECK_CARRIER_IF_UNKNOWN,
  tmobileAction: TMOBILE_ACTION.CHECK_TMOBILE,
  tracfoneAction: TRACFONE_ACTION.CHECK_TRACFONE,
  verizonAction: VERIZON_ACTION.CHECK_VERIZON,
  unlockedAction: UNLOCKED_ACTION.CHECK_ALL_POSIBLE_DATABASES,
  notSupportedOrUnknownCarrierAction: NOT_SUPPORTED_OR_KNOWN_CARRIER_ACTION.CHECK_ALL_POSIBLE_DATABASES,
};

export const CUSTOM_WORKFLOW_CONFIGURATION_US_FINANCIAL_CARRIER_DISABLE = {
  appleIPhoneAction: APPLE_ACTION.DO_NOT_CHECK,
  appleIPadAction: APPLE_ACTION.DO_NOT_CHECK,
  samsungAction: SAMSUNG_ACTION.DO_NOT_CHECK,
  notSupportedOrUnknownCarrierAction: NOT_SUPPORTED_OR_KNOWN_CARRIER_ACTION.CHECK_GLOBAL_BLACKLIST_ONLY,
  unlockedAction: UNLOCKED_ACTION.CHECK_GLOBAL_BLACKLIST_ONLY,
  verizonAction: VERIZON_ACTION.CHECK_GLOBAL_BLACKLIST_ONLY,
  tmobileAction: TMOBILE_ACTION.CHECK_GLOBAL_BLACKLIST_ONLY,
  tracfoneAction: TRACFONE_ACTION.CHECK_GLOBAL_BLACKLIST_ONLY,
  attAction: ATT_ACTION.CHECK_GLOBAL_BLACKLIST_ONLY,
}

// initial configuration
export const CUSTOM_WORKFLOW_CONFIGURATION: IMEISettingConfiguration = {
  appleIPhoneAction: APPLE_ACTION.CHECK_CARRIER_IF_UNKNOWN_AND_CHECK_SIMLOCK,
  appleIPadAction: APPLE_ACTION.CHECK_CARRIER_IF_UNKNOWN_AND_CHECK_SIMLOCK,
  samsungAction: SAMSUNG_ACTION.DO_NOT_CHECK,
  notSupportedOrUnknownCarrierAction: NOT_SUPPORTED_OR_KNOWN_CARRIER_ACTION.TAKE_NO_ACTION,
  unlockedAction: UNLOCKED_ACTION.TAKE_NO_ACTION,
  verizonAction: VERIZON_ACTION.TAKE_NO_ACTION,
  tmobileAction: TMOBILE_ACTION.TAKE_NO_ACTION,
  tracfoneAction: TRACFONE_ACTION.TAKE_NO_ACTION,
  attAction: ATT_ACTION.TAKE_NO_ACTION,
};

@Component({
  selector: 'app-imei-check',
  templateUrl: './imei-check.component.html',
  styleUrls: ['./imei-check.component.css']
})
export class ImeiCheckComponent implements OnInit, OnDestroy {
  @Input() state!: Partial<IMEICheckConfiguration>;
  @Output() changeEmitter = new EventEmitter<IMEICheckConfiguration>();

  automaticIMEICheck!: FormControl;
  imeiCheckForm!: FormGroup;
  configurationForm!: FormGroup;

  ngOnInit() {
    this.initializeFormGroups();
    this.initializeFormGroupSubscribers();
  }

  public isCustomSelected(): boolean {
    return this.imeiCheckForm.get('name')?.value === IMEI_CHECK_SETTING.CUSTOM_WORKFLOW;
  }

  public isAutomaticIMEICheckEnabled(): boolean {
    return this.automaticIMEICheck.value;
  }

  public getData(): IMEICheckConfiguration {
    const enabled = this.automaticIMEICheck.getRawValue();
    const data = this.imeiCheckForm.getRawValue() as IMEICheckConfiguration;

    switch (data.name) {
      case IMEI_CHECK_SETTING.CUSTOM_WORKFLOW:
        data.configuration = this.configurationForm.getRawValue() as any;
        break;
      case IMEI_CHECK_SETTING.COST_EFFECTIVE:
        data.configuration = COST_EFFECTIVE_CONFIGURATION;
        break;
      case IMEI_CHECK_SETTING.MOST_ACCURATE:
        data.configuration = MOST_ACCURATE_CONFIGURATION;
        break;
    }

    data.automaticIMEICheck = enabled;

    return data;
  }

  public notifyFormChange() {
    const data = this.getData();

    this.changeEmitter.emit(data);
  }

  initializeFormGroupSubscribers() {
    this.imeiCheckForm.valueChanges.subscribe(nameForm => {
      if (nameForm.name === 'Custom Workflow') {
        this.configurationForm.get('appleIPhoneAction')?.setValue('DO_NOT_CHECK');
        this.configurationForm.get('appleIPadAction')?.setValue('DO_NOT_CHECK');
        this.configurationForm.get('samsungAction')?.setValue('DO_NOT_CHECK');
      }
    });
    this.automaticIMEICheck.valueChanges.subscribe(enabled => {
      if (enabled) {
        this.imeiCheckForm.enable();
      } else {
        this.imeiCheckForm.get('usFinancialCarrierSetting')?.setValue(true);
        this.imeiCheckForm.get('name')?.setValue('Most Accurate');
        this.changeConfiguration(MOST_ACCURATE_CONFIGURATION);

        this.imeiCheckForm.disable();
        this.configurationForm.disable();
      }
    });

    this.imeiCheckForm.get('usFinancialCarrierSetting')?.valueChanges.subscribe(usFinancialCarrierCheckEnabled => {
      if (usFinancialCarrierCheckEnabled) {
        this.loadMostAccurate();
      } else {
        this.loadCustomWorkflow(true);
      }
    });

    this.imeiCheckForm.get('name')?.valueChanges.subscribe(name => {
      if (this.isCustomSelected()) {
        this.configurationForm.enable();
      } else {
        this.configurationForm.disable();
      }
    });
  }

  private loadMostAccurate(): void {
    this.imeiCheckForm.get('name')?.setValue(IMEI_CHECK_SETTING.MOST_ACCURATE, { emitEvent: false });
    this.changeConfiguration(MOST_ACCURATE_CONFIGURATION);
  }

  private loadCostEffective(): void {
    this.imeiCheckForm.get('name')?.setValue(IMEI_CHECK_SETTING.COST_EFFECTIVE, { emitEvent: false });
    this.changeConfiguration(COST_EFFECTIVE_CONFIGURATION);
  }

  private loadCustomWorkflow(usCarrierCheckDisabled: boolean): void {
    const conf = usCarrierCheckDisabled
      ? CUSTOM_WORKFLOW_CONFIGURATION_US_FINANCIAL_CARRIER_DISABLE
      : CUSTOM_WORKFLOW_CONFIGURATION;

    this.imeiCheckForm.get('name')?.setValue(IMEI_CHECK_SETTING.CUSTOM_WORKFLOW);
    this.changeConfiguration(conf);
  }

  isUSFinancialCarrierSettingEnable(): boolean {
    return this.imeiCheckForm.value.usFinancialCarrierSetting;
  }

  changeConfiguration(conf: IMEISettingConfiguration) {
    this.configurationForm.get('appleIPhoneAction')?.setValue(conf.appleIPhoneAction);
    this.configurationForm.get('appleIPadAction')?.setValue(conf.appleIPadAction);
    this.configurationForm.get('samsungAction')?.setValue(conf.samsungAction);
    this.configurationForm.get('notSupportedOrUnknownCarrierAction')?.setValue(conf.notSupportedOrUnknownCarrierAction);
    this.configurationForm.get('unlockedAction')?.setValue(conf.unlockedAction);
    this.configurationForm.get('verizonAction')?.setValue(conf.verizonAction);
    this.configurationForm.get('tmobileAction')?.setValue(conf.tmobileAction);
    this.configurationForm.get('tracfoneAction')?.setValue(conf.tracfoneAction);
    this.configurationForm.get('attAction')?.setValue(conf.attAction);
  }

  initializeFormGroups() {
    const workflowFormControl = new FormControl(this.state.name || IMEI_CHECK_SETTING.MOST_ACCURATE);

    workflowFormControl.valueChanges.subscribe(value => {
      if (value === IMEI_CHECK_SETTING.MOST_ACCURATE) {
        this.changeConfiguration(MOST_ACCURATE_CONFIGURATION)
      } else if (value === IMEI_CHECK_SETTING.COST_EFFECTIVE) {
        this.changeConfiguration(COST_EFFECTIVE_CONFIGURATION);
      } else if (value === IMEI_CHECK_SETTING.CUSTOM_WORKFLOW && !this.isUSFinancialCarrierSettingEnable()) {
        this.changeConfiguration(CUSTOM_WORKFLOW_CONFIGURATION_US_FINANCIAL_CARRIER_DISABLE);
      } else {
        this.changeConfiguration(CUSTOM_WORKFLOW_CONFIGURATION);
      }
    });
    this.automaticIMEICheck = new FormControl(this.state.automaticIMEICheck);
    this.imeiCheckForm = new FormGroup({
      usFinancialCarrierSetting: new FormControl(this.state.usFinancialCarrierSetting ?? true),
      name: workflowFormControl,
    });
    this.configurationForm = new FormGroup({
      appleIPhoneAction: new FormControl(this.state.configuration?.appleIPhoneAction || APPLE_ACTION.DO_NOT_CHECK),
      appleIPadAction: new FormControl(this.state.configuration?.appleIPadAction || APPLE_ACTION.DO_NOT_CHECK),
      samsungAction: new FormControl(this.state.configuration?.samsungAction || SAMSUNG_ACTION.DO_NOT_CHECK),
      notSupportedOrUnknownCarrierAction: new FormControl(this.state.configuration?.notSupportedOrUnknownCarrierAction || NOT_SUPPORTED_OR_KNOWN_CARRIER_ACTION.CHECK_GLOBAL_BLACKLIST_ONLY),
      unlockedAction: new FormControl(this.state.configuration?.unlockedAction || NOT_SUPPORTED_OR_KNOWN_CARRIER_ACTION.CHECK_GLOBAL_BLACKLIST_ONLY),
      verizonAction: new FormControl(this.state.configuration?.verizonAction || VERIZON_ACTION.CHECK_ALL_POSIBLE_DATABASES),
      tmobileAction: new FormControl(this.state.configuration?.tmobileAction || TMOBILE_ACTION.CHECK_ALL_POSIBLE_DATABASES),
      tracfoneAction: new FormControl(this.state.configuration?.tracfoneAction || TRACFONE_ACTION.TAKE_NO_ACTION),
      attAction: new FormControl(this.state.configuration?.attAction || ATT_ACTION.CHECK_ALL_POSIBLE_DATABASES)
    });

    if (!this.isCustomSelected()) {
      this.configurationForm.disable();
    }

    if (this.isStateEmpty()) {
      this.imeiCheckForm.enable();
    }
  }

  private isStateEmpty(): boolean {
    return !this.state || Object.keys(this.state).length === 0;
  }

  public initialize(conf: IMEICheckConfiguration): void {
    this.state = conf;

    this.initializeFormGroups();
    this.initializeFormGroupSubscribers();
  }

  public ngOnDestroy(): void {
    const data = this.getData();

    this.changeEmitter.emit(data);
  }
}
