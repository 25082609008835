<div class="container">
  <h1>Create filter</h1>
  <div mat-dialog-content>
    <mat-form-field> 
      <mat-label>Filter's name</mat-label>
      <input [formControl]="nameFormControl" matInput type="text">
    </mat-form-field> 
  </div>
  <div mat-dialog-actions align="center">
    <button mat-button (click)="onCancel()">Cancel</button>
    <button mat-button (click)="onSave()">Save</button>
  </div>
</div>
