import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-array-viewer',
  templateUrl: './array-viewer.component.html',
  styleUrls: ['./array-viewer.component.css']
})
export class ArrayViewerComponent {
  arrayDataParsed: { qId?: string, qType?: string, answer?: string, pTitle?: string,
    parentId?: string, question?: string, qCategory?: string, answer_status?: string  }[] = [];
  profile: string;
  routeName: string;
  isCosmetics: boolean = false;
  title: string = 'Grading Q/A';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { 
      arrayData: string,
      profile: string,
      routeName?: string,
      cosmetics?: string 
    },
  ) {
    const { arrayData, profile, routeName, cosmetics } = data;
    if(cosmetics){
      this.isCosmetics = true;
      this.title = 'Cosmetics Info';
    }
    this.arrayDataParsed = cosmetics ? arrayData : JSON.parse(arrayData);
    this.profile = profile;
    this.routeName = routeName || ''; // If routeName is not provided, set it to an empty string
  }
}
