<div class="main-component-container">
  <h1>Activity Report</h1>

  <mat-divider></mat-divider>

  <div class="filters-container">
    <mat-form-field>
      <mat-label>Range</mat-label>
      <mat-select [(ngModel)]="rangeType">
        <mat-option [value]="'daily'">Daily</mat-option>
        <!-- <mat-option></mat-option> -->
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Date range</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input
          matStartDate
          (dateChange)="onDateChange()"
          [(ngModel)]="startDate" placeholder="Start date">
        <input
          matEndDate
          (dateChange)="onDateChange()"
          [errorStateMatcher]="dailyErrorMatcher"
          [(ngModel)]="endDate" placeholder="End date">
      </mat-date-range-input>

      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
      <mat-error *ngIf="invalidDateRange">Invalid date range</mat-error>
    </mat-form-field>

    <!-- <mat-form-field> -->
    <!--   <mat-label>Start Date</mat-label> -->
    <!--   <input -->
    <!--     [(ngModel)]="startDate" -->
    <!--     matInput -->
    <!--     [matDatepicker]="startPicker" -->
    <!--     [matDatepickerFilter]="startDateFilter" -->
    <!--     (dateChange)="onDateInputChange($event)" -->
    <!--     > -->
    <!--   <mat-hint>DD/MM/YYYY</mat-hint> -->
    <!--   <mat-datepicker-toggle matIconSuffix [for]="startPicker"></mat-datepicker-toggle> -->
    <!--   <mat-datepicker #startPicker></mat-datepicker> -->
    <!-- </mat-form-field> -->

    <!-- <mat-form-field> -->
    <!--   <mat-label>End Date</mat-label> -->
    <!--   <input -->
    <!--     [(ngModel)]="endDate" -->
    <!--     matInput -->
    <!--     [matDatepickerFilter]="endDateFilter" -->
    <!--     [matDatepicker]="endPicker"> -->
    <!--   <mat-hint>DD/MM/YYYY</mat-hint> -->
    <!--   <mat-datepicker-toggle matIconSuffix [for]="endPicker"></mat-datepicker-toggle> -->
    <!--   <mat-datepicker #endPicker></mat-datepicker> -->
    <!-- </mat-form-field> -->

    <mat-form-field>
      <mat-label>By User/Station</mat-label>
      <mat-select [(ngModel)]="entityType">
        <mat-option [value]="'user'">By User</mat-option>
        <mat-option [value]="'station'">By Station</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Count Type</mat-label>
      <mat-select [(ngModel)]="countType">
        <mat-option [value]="'completion'">Completion</mat-option>
        <mat-option [value]="'connection'">Connection</mat-option>
        <!-- <mat-option></mat-option> -->
      </mat-select>
    </mat-form-field>

    <button 
      color="primary"
      class="phonecheck-button"
      [disabled]="dateRangeError"
      mat-raised-button
      (click)="onFilterUpdate()"
      >Update</button>
  </div>

  <mat-divider></mat-divider>

  <div class="color-coding-container">
    <h2>Color Coding</h2>
    <p>Target UPH and above will display green. Minimum value and below will display red.</p>

    <div class="uph-container">
      <mat-form-field>
        <mat-label>Target UPH</mat-label>
        <input matInput [(ngModel)]="targetUPH" placeholder="Optional" type="number">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Minimum UPH</mat-label>
        <input matInput [(ngModel)]="minimumUPH" placeholder="Optional" type="number">
      </mat-form-field>
      <button
        color="primary"
        class="phonecheck-button"
        mat-raised-button
        (click)="onUPHUpdate()"
        [disabled]="updatingUPH"
        >Update</button>
    </div>
  </div>

  <mat-divider></mat-divider>

  <h2>Summary</h2>
  <div *ngIf="errorLoadingData">
    <p class="error-message">There was an error while loading the data</p>
  </div>
  <div [hidden]="errorLoadingData" class="summary-container">
    <mat-progress-bar *ngIf="loadingSummary" mode="indeterminate"></mat-progress-bar>
    <mat-table class="datatable" [dataSource]="summaryList">
      <ng-container [matColumnDef]="'totalDevices'">
        <mat-header-cell *matHeaderCellDef>
          Total Devices
        </mat-header-cell>
        <mat-cell class="total-devices" *matCellDef="let summary">
          <ng-container>
            {{ getTableValue(summary.totalDevices) }}
          </ng-container>
        </mat-cell>
      </ng-container>

      <ng-container *ngFor="let column of TIME_COLUMNS" [matColumnDef]="column.field">
        <mat-header-cell *matHeaderCellDef>
          {{ column.displayName }}
        </mat-header-cell>

        <mat-cell *matCellDef="let summary">
          <ng-container>
            {{ getTableValue(summary[column.field]) }}
          </ng-container>
        </mat-cell>
      </ng-container>

      <mat-row *matRowDef="let skuFile; columns: summaryDisplayedColumns"></mat-row>
      <mat-header-row class="table-row" *matHeaderRowDef="summaryDisplayedColumns"></mat-header-row>
    </mat-table>
  </div>

  <mat-divider></mat-divider>

  <div [hidden]="errorLoadingData">
  <h2>Details</h2>
  <div class="download-buttons-container">
    <button mat-raised-button (click)="copyActivityReport()">Copy</button>
    <button mat-raised-button (click)="downloadActivityReportCSV()">CSV</button>
    <button mat-raised-button (click)="downloadActivityReportExcel()">Excel</button>
    <mat-form-field >
      <mat-label>Search</mat-label>
      <input matInput type="search" [formControl]="searchFormControl">
    </mat-form-field>
  </div>

  <div [hidden]="errorLoadingData" class="activity-container">
    <mat-progress-bar *ngIf="loadingActivity" mode="indeterminate"></mat-progress-bar>
    <mat-table *ngIf="!loadingActivity" class="datatable" [dataSource]="activityList">
      <mat-header-row  *matHeaderRowDef="activityDisplayedColumns"></mat-header-row>
      <mat-row *matRowDef="let activity; columns: activityDisplayedColumns"></mat-row>

      <ng-container *ngFor="let column of activityColumns" [matColumnDef]="column.field">
        <mat-header-cell *matHeaderCellDef>
          {{ column.displayName }}
        </mat-header-cell>
        <mat-cell *matCellDef="let activity" [ngClass]="getAverageClass(column.field, activity[column.field])">
          <ng-container>{{ getTableValue(activity[column.field]) }}</ng-container>
        </mat-cell>
      </ng-container>
      <tr class="mat-row empty-table" *matNoDataRow >
        <td class="mat-cell" *ngIf="!loadingActivity" [attr.colspan]="activityDisplayedColumns.length">
          No results matching the filter
        </td>
      </tr>
    </mat-table>
    <mat-paginator
      #paginator
      (page)="onPaginate($event)"
      [pageSize]="paginatorData.pageSize"
      [pageSizeOptions]="paginatorData.pageOptions"
      [length]="paginatorData.length" showFirstLastButtons></mat-paginator>
  </div>
  </div>
</div>
