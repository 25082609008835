import { Router } from "@angular/router";
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild, EventEmitter } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { AuthService } from "../services/auth.service";
import { LabelService } from "../services/label.service";
import { MatDialog } from '@angular/material/dialog';
import { PreviewLabelComponent } from "../dialogs/preview-label/preview-label.component";
import { DeleteLabelComponent } from "../dialogs/delete-label/delete-label.component";
import { CustomizationService } from "../services/customization.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { TestPlanComponent } from '../test-plan/test-plan.component';
import { ImeiCheckComponent, MOST_ACCURATE_CONFIGURATION } from '../imei-check/imei-check.component';
import { MatCheckboxChange } from "@angular/material/checkbox";
import { DeviceTestPlan, ProfileTestPlan, PartialTestPlan, WatchTestPlan, AirpodsTestPlan } from "../interfaces/deviceTest.interfaces";
import { MatSort } from "@angular/material/sort";
import { AssignChecklistComponent } from "./assign-checklist/assign-checklist.component";
import { Profile } from "../interfaces/customizationProfile.interface";
import { DeleteProfileComponent } from "../dialogs/delete-profile/delete-profile.component";
import { PasswordProtectProfileComponent } from "../dialogs/password-protect-profile/password-protect-profile.component";
import { PasswordRequiredComponent } from "../dialogs/password-required/password-required.component";
import { PasswordUpdateComponent } from "../dialogs/password-update/password-update.component";
import { pick, omit, isEqual, isEmpty } from 'lodash';
import { environment } from '../../environments/environment';
import {
  BehaviorSubject,
  firstValueFrom,
  Observable,
  Subject,
  takeUntil,
  debounceTime,
} from "rxjs";
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from '../services/notification.service';
import { PasswordProtectComponent } from "../password-protect/password-protect.component";
import { ToggleMenuService } from "../services/toggle-menu.service";
import { AssignmentPayload } from "../interfaces/assignment-payload.interface";
import { MasterService, RawWarehouse, RawStation } from "../services/master.service";
import { SavingWithoutAssignmentsDialogComponent } from "../dialogs/saving-without-assigments-dialog/saving-without-assignments-dialog.component";
import { AssignmentExpressComponent } from "../assignment-express/assignment-express.component";
import {
  MANUAL_ERASE_OPTIONS,
  MANUAL_RESTORE_OPTIONS,
  MANUAL_PRINT_OPTIONS,
  MANUAL_POWER_OFF_OPTIONS,
} from './workflow-options/customTriggerWorkflowData';
import { WorkflowBuilder } from '../common/workflow-builder';
import { AssignmentIdsPayload } from "../interfaces/assignment-ids-payload.interface";
import { IMEI_CHECK_SETTING } from "../imei-check/imei-check-configuration.interface";
import { ComponentCanDeactivate } from "../pending-changes.guard";
import { BackWithoutSavingComponent } from "../dialogs/back-without-saving/back-without-saving.component";
import { Device } from "../interfaces/device.interface";
import { AdvancedSettingsComponent } from "../advanced-settings/advanced-settings.component";
import { CustomTestComponent } from "../custom-test/custom-test.component";
import { AdminService } from "../services/admin.service";
import { PasswordOptions } from "../interfaces/passwordProtect.interface";

const CONNECTION_WORKFLOW_OPTIONS = [
  {
    value: 'dataVerification',
    displayValue: 'Data Verification',
    conflicts: [
      'erase',
      'install',
      'restore',
      'remove',
      'restoreJailbroken',
      'restoreIOSBeta',
      'eraseIOSMDM',
      'scanLPN',
      'scanCustom1',
    ],
    validIndexes: [0],
  },
  {
    value: 'erase',
    displayValue: 'Erase',
    conflicts: ['dataVerification'],
  },
  {
    value: 'install',
    displayValue: 'Setup & Install App',
    conflicts: ['dataVerification'],
  },
  {
    value: 'print',
    displayValue: 'Print',
  },
  {
    value: 'restore',
    displayValue: 'Restore',
    conflicts: ['dataVerification'],
  },
  {
    value: 'off',
    displayValue: 'Power Off',
  },
  {
    value: 'remove',
    displayValue: 'Remove App & Profiles',
    conflicts: ['dataVerification'],
  },
  {
    value: 'setup',
    displayValue: 'Setup',
    conflicts: ['dataVerification'],
  },
  {
    value: 'restoreJailbroken',
    displayValue: 'Restore if Jailbroken',
    conflicts: ['dataVerification'],
  },
  {
    value: 'restoreIOSBeta',
    displayValue: 'Restore if iOS Beta',
    conflicts: ['dataVerification'],
  },
  {
    value: 'eraseiOSMDM',
    displayValue: 'Erase if iOS MDM',
    conflicts: ['dataVerification'],
  },
  {
    value: 'scanLPN',
    displayValue: 'Scan LPN',
    conflicts: ['dataVerification'],
  },
  {
    value: 'scanCustom1',
    displayValue: 'Scan Custom1',
    conflicts: ['dataVerification'],
  },
  {
    value: 'androidUnlockWithCode',
    displayValue: 'Android Unlock with code',
    conflicts: ['dataVerification'],
  },
];
const CONNECTION_WORKFLOW_DEFAULT_SELECTIONS: string[] = [
  'restoreJailbroken',
  'restoreIOSBeta',
  'eraseiOSMDM',
  'install',
];
const TEST_RESULT_OPTIONS = [
  {
    value: 'erase',
    displayValue: 'Erase',
  },
  {
    value: 'print',
    displayValue: 'Print',
  },
  {
    value: 'off',
    displayValue: 'Power Off',
  },
  {
    value: 'remove',
    displayValue: 'Remove App & Profiles',
  },
  {
    value: 'androidUnlockWithCode',
    displayValue: 'Android Unlock with code',
  },
];

interface Page {
  label: string,
  name: string,
}

export interface Label {
  name: string;
  id: number;
  enabled: boolean;
  image: string;
  canvas_size?: string;
  created_at: string;
  updated_at: string;
}
export interface Customization {
  id: string,
  name: string;
  stations: number;
  warehouses: number;
  labels?: Label[];
  createdAt: string;
  updatedAt: string;
}
export interface BatteryHealthRule {
  code: string;
  minimumCapacity: number;
  maximumCycle: number;
}
export interface BatteryHealthCriteria {
  name: string;
  description: string;
  rules: BatteryHealthRule[];
  applyAllDevices: boolean;
  applyAndroidDevices: boolean;
  applyAppleIphoneDevices: boolean;
  applyAppleIpadDevices: boolean;
  specifyDevices: boolean;
  devices: Device[];
}

export interface FieldOption {
  name: string;
  renaming?: boolean;
}

enum ConnectionWorkflow {
  RESTORE_JAILBROKEN = 'restoreJailbroken',
  RESTORE_IOS_BETA = 'restoreIOSBeta',
  ERASE_IOS_MDM = 'eraseIOSMDM',
  INSTALL = 'install',
}

interface DeviceWorkflow {
  warningMessagesEnabled: boolean,
  skipAllSetupAssistant: boolean;
  skipSetupAssistant: any;
  warningMessages: {
    simCardIsDetected: boolean;
    sdCardIsDetected: boolean;
    failSimCardNotRemoved: boolean;
    failSDCardNotRemoved: boolean;
  },
  initialDefectsWorkflowEnabled: boolean;
  initialDefectsWorkflow: {
    IMEISerialValidation: boolean;
    noIMEI: boolean;
    noEID: boolean;
    appleIcloudGoogleLock: boolean;
    mdm: boolean;
    samsungKnox: boolean;
    jailbreakRoot: boolean;
    batteryHealth: boolean;
    betaOS: boolean;
    badESN?: boolean;
  };
  enabled: boolean;
  checkIMEI: boolean;
  connectionWorkflowEnabled: boolean;
  connectionWorkflow: string[];
  testResultWorkflowEnabled: boolean;
  separateFlow: boolean;
  testResultWorkflow: string[];
  failedWorkFlow: string[];

  customTriggerWorkflowEnabled: boolean;
  manualEraseSelectedActions: string[];
  initialDefectWorkflowsActions: string[];
  manualRestoreSelectedActions: string[];
  manualPrintSelectedActions: string[];
  manualPowerOffSelectedActions: string[];
}

@Component({
  selector: 'app-customizations',
  templateUrl: './customizations.component.html',
  styleUrls: ['./customizations.component.css']
})
export class CustomizationsComponent implements OnInit, AfterViewInit, ComponentCanDeactivate {
  @ViewChild('deviceTestPlan') testPlanComponent!: TestPlanComponent;
  @ViewChild('watchTestPlan') watchTestPlanComponent!: TestPlanComponent;
  @ViewChild('airpodsTestPlan') airpodsTestPlanComponent!: TestPlanComponent;
  // Saved test plans
  public selectedDeviceTests: DeviceTestPlan | undefined;
  public selectedWatchTests: WatchTestPlan | undefined;
  public selectedAirpodsTests: AirpodsTestPlan | undefined;
  // Edited Test Plan
  private currentTestPlan!: ProfileTestPlan;

  @ViewChild(AssignmentExpressComponent) assignmentExpressComponent!: AssignmentExpressComponent;
  @ViewChild(CustomTestComponent) customTestComponent!: CustomTestComponent;
  @ViewChild(ImeiCheckComponent) imeiCheckComponent!: ImeiCheckComponent;
  @ViewChild(AdvancedSettingsComponent) advancedSettingsComponent!: AdvancedSettingsComponent;
  @ViewChild(PasswordProtectComponent) passwordProtectComponent!: PasswordProtectComponent;
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  passwordOptions: Partial<PasswordOptions> = {
    newTransaction: false,
    updateVendorInfo: false,
    editTransaction: false,
  };
  @ViewChild('labelsTable', { read: MatSort, static: false }) set labelsMatSort(value: MatSort) {
    if (this.labelsDataSource && value) {
      this.labelsDataSource.sort = value;
    }
  }
  @ViewChild("nameInput") nameInput!: ElementRef;
  viewCarrierOptions = false;
  carrierInputFocus = false;
  carrierInputEmptyError = false;
  currentBatteryHealthCriteria: any;
  advancedSettings: any;
  creatingCustomTest = false;

  skipAssistantSteps = [
    { key: 'language', text: 'Language' },
    { key: 'region', text: 'Region' },
    { key: 'preferredLanguage', text: 'Preferred Language' },
    { key: 'keyboards', text: 'Keyboards' },
    { key: 'dictation', text: 'Dictation' },
    { key: 'setupCellular', text: 'Set Up Cellular' },
    { key: 'privacy', text: 'Privacy' },
    { key: 'passcode', text: 'Passcode' },
    { key: 'touchFaceId', text: 'Touch Id / Face Id' },
    { key: 'applePay', text: 'Apple Pay' },
    { key: 'appsData', text: 'Apps & Data' },
    { key: 'moveFromAndroid', text: 'Move from Android' },
    { key: 'appleID', text: 'Apple ID' },
    { key: 'intendedUser', text: 'Intended User' },
    { key: 'locationServices', text: 'Location Services' },
    { key: 'siri', text: 'Siri' },
    { key: 'screenTime', text: 'Screen Time' },
    { key: 'appAnalytics', text: 'App Analytics' },
    { key: 'keepDeviceUpToDate', text: 'Keep Your Device Up to Date' },
    { key: 'iMessageFaceTime', text: 'iMessage & FaceTime' },
    { key: 'displayZoom', text: 'Display Zoom (removed in iOS 17)' },
    { key: 'appStore', text: 'App Store' },
    { key: 'safety', text: 'Safety' },
    { key: 'multitasking', text: 'Multitasking' },
    { key: 'actionButton', text: 'Action Button' },
    { key: 'termsAddress', text: 'Terms Address' },
    { key: 'accessibilityAppearance', text: 'Accessibility Appearance' },
    { key: 'homeButton', text: 'Home Button' },
    { key: 'trueTone', text: 'True Tone' },
    { key: 'appearance', text: 'Appearance' },
    { key: 'iMessage', text: 'iMessage' },
    { key: 'watchMigration', text: 'Watch Migration' },
    { key: 'newFeatureHighlights', text: 'New Feature Highlights' },
    { key: 'welcome', text: 'Welcome' },
    { key: 'restoredCompleted', text: 'Restored Completed' },
    { key: 'updateCompleted', text: 'Update Completed' },
  ];

  advancedSettingsState: any = {
    "sourceURL": null,
    "sourceAPIKey": null,
    "sourceEnabled": false,
    "labelURL": null,
    "labelAPIKey": null,
    "labelEnabled": false,
    "resultsURL": null,
    "resultsAPIKey": null,
    "resultsEnabled": false,
    "shopfloorEnabled": false,
    "apiDataSharingEnabled": false
  };
  currentAdvancedSettings = {};
  customTest: any[] = [];
  customTestState: any = [];

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if (this.selectedIndex != 2) {
      return true;
    }
    this.unChangedProfile.labels = JSON.parse(`[${this.unChangedProfile.labels}]`)
    this.unChangedProfile.password = this.unChangedProfile.password ?? null;
    const latest = omit(this.getCurrentProfile(), 'profileId');
    const old = pick(this.unChangedProfile, Object.keys(latest));

    Object.keys(latest['testPlan']).forEach(k => {
      if (k == 'android' || k == 'apple') {
        this.unChangedProfile.testPlan.android ||= undefined;
        this.unChangedProfile.testPlan.apple ||= undefined;
      }
    });
    return isEqual(old, latest);
  }

  profileSubject: BehaviorSubject<Profile[]> = new BehaviorSubject<Profile[]>([]);
  profileObserver: Observable<Profile[]> = new Observable<Profile[]>();

  profiles: Profile[] = [];
  profilesToShow: Profile[] = [];
  loadingProfiles: boolean = true;
  imeiCheckState = {};
  protectionEnabled = false;

  nameFormControl = new FormControl('', []);
  searchProfileFormControl = new FormControl<string>('', []);
  profilesNotInUseFormControl = new FormControl(true);
  labelFilterFormControl: FormControl<string | number | null> = new FormControl('', []);
  displayedColumns: string[] = ['name', 'createdAt', 'updatedAt', 'stations', 'warehouses', 'buttons'];
  labelsColumns: string[] = ['enabled', 'name', 'canvas_size', 'updated_at', 'buttons'];
  profilesDataSource = new MatTableDataSource(this.profilesToShow);
  labels: Label[] = []
  labelsDataSource = new MatTableDataSource(this.labels);
  labelsDataSourceFiltered = new MatTableDataSource(this.labels);
  previewImage = '';
  /*
   * 0 profile list
   * 1 assignments
   * 2 create profile
   */
  selectedIndex = 0;
  currentPage: string = 'general';
  currentPageLabel = 'General';
  currentFieldsView = 'main';

  defaultPasswordOptions: PasswordOptions = {
    newTransaction: false,
    updateVendorInfo: false,
    editTransaction: false,
  };

  defaultCarrierOptions = [
    {
      name: 'Unlocked',
    },
    {
      name: 'Wifi Only',
    },
    {
      name: 'AT&T',
    },
    {
      name: 'Verizon',
    },
    {
      name: 'Sprint',
    },
    {
      name: 'T-Mobile',
    },
    {
      name: 'Rogers',
    },
  ];
  carrierOptions: FieldOption[] = this.defaultCarrierOptions;

  gradingOptions: FieldOption[] = [
    { name: 'A' },
    { name: 'B' },
    { name: 'C' },
    { name: 'D' },
  ];

  colorOptions: FieldOption[] = [
    { name: 'Black' },
    { name: 'Gold' },
    { name: 'Silver' },
    { name: 'Blue' },
    { name: 'Red' },
    { name: 'Green' },
    { name: 'Pink' },
    { name: 'Brown' },
    { name: 'Yellow' },
    { name: 'White' },
    { name: 'No color' },
  ];

  defaultGradingOption: string = '';
  defaultCarrierOption = '';
  gradeInputFocus = false;

  colorInputFocus = false;

  carrierRename = new FormControl('', {
    validators: [Validators.required],
    updateOn: "blur",
  });
  gradeRename = new FormControl('', {
    validators: [Validators.required],
    updateOn: "blur",
  });
  colorRename = new FormControl('', {
    validators: [Validators.required],
    updateOn: "blur",
  });
  printerSettings = {
    onErasureSuccess: false,
    powerOffDeviceAfterPrinter: false,
    onTestResults: false,
    onAppResultsFullyFunctional: false,
  };
  requiredFields = {
    enabled: false,
    LPN: false,
    custom: false,
    color: false,
    carrier: false,
    grade: false,
    ESN: false,
    unlockStatus: false,
    cosmetics: false,
  };
  restrictiveActions = {
    erase: false,
    print: false,
  };
  fields = {
    allowUserModifyColorField: true,
  };
  gradeInputEmptyError = false;
  wifiSettings = {
    name: '',
    password: '',
    disconnect: false,
  }

  pages: Page[] = [
    {
      label: 'General',
      name: 'general',
    },
    {
      label: 'Device Workflow',
      name: 'workflow',
    },
    {
      label: 'IMEI/ESN',
      name: 'esn_imei_health',
    },
    {
      label: 'Wifi',
      name: 'wifi',
    },
    {
      label: 'Test Plan',
      name: 'test_plan',
    },
    {
      label: 'Custom Tests',
      name: 'custom_test',
    },
    {
      label: 'Labels',
      name: 'labels',
    },
    {
      label: 'Battery',
      name: 'battery',
    },
    {
      label: 'Fields',
      name: 'fields',
    },
    {
      label: 'Cosmetics',
      name: 'cosmetics',
    },
    {
      label: 'Assignments',
      name: 'assignments',
    },
    {
      label: 'Advanced',
      name: 'advanced_settings'
    }
  ];
  pagesByName: Record<string, Page> = this.pages.reduce((acc: Record<string, Page>, p) => { acc[p.name] = p; return acc }, {});

  hidePassword = true;
  batteryHealthCriteriaSelected = 0;
  batterySettings = {
    customizeBattery: false,
    appleFailBatteryWarningMessage: true,
    healthCriteria: <any>[],
    drain: false,
    drainAlways: true,
    drainTestAfterTestResults: true,
    drainDuration: 5,
    allowDrainDurationChange: false,
    failDrainIfDischarge: false,
    failDrainIfDischargePercentage: 3,
  };
  eraseSettings = {
    automated: false,
    iosDoNotPEO: true,
    iosMDM: false,
    showEraseWarning: false,
    reconnectMessage: false,
    reconnectMinutes: 4,
    oneAutoErasePerTransactions: false,
    autoEraseEveryDeviceOnConnection: false,
    eraseAfterTestResult: false,
    eraseAfterOnlySuccessful: false,
  };
  cosmeticSettings = {
    enableCosmetics: true,
    cosmeticType: 'multi',
    saveCosmetics: 'cosmeticOnly',
    preferredFormat: 'name',
    cosmetics: [
      'Broken Screen',
      'Cracked Screen',
      'Pitted Screen'
    ],
    cosmeticQuestions: [{
      os: 'both',
      name: '',
      question: '',
      responses: [
        {
          response: '',
          criteria: true
        }
      ]
    }]
  };
  newCosmeticName = '';
  currentId = '';
  previousTabIndex = 0;
  hasPasswordProtectionObservable = new BehaviorSubject<boolean>(false);
  addRuleButtonDisabled = true;

  dataVerificationEnabled: boolean = false;
  workflow: DeviceWorkflow = this.getDefaultDeviceWorkflow();
  mouseOverAddActionBox = false;
  mouseOverTestAddActionBox = false;
  mouseOverFailedTestAddActionBox = false;
  customizationsUnsubscriber = new Subject<void>();
  editMode = false;
  embedMode = false;
  passwordProfile: string | null = null;
  private passwordSet: boolean = false;
  private currentCustomTest = {};
  private currentCheckIMEI = {}

  stations: RawStation[] = [];
  warehouses: RawWarehouse[] = [];
  assignmentPayload: AssignmentPayload = {
    stations: [],
    warehouses: [],
  };
  connectionWorkflowBuilder;
  testResultWorkflowBuilder;
  failedTestResultWorkflowBuilder;
  initialDefectsWorkflowBuilder;
  manualErase;
  manualRestore;
  manualPrint;
  manualPowerOff;
  unChangedProfile: any;

  searchBatteryCriteriaDevicesSubject: Subject<string> = new Subject<string>();

  resetWorkflows() {
    // Reset all workflows except connection workflow.
    // This is to be used when data verification is selected in connection workflow
    // in which case all other must be disabled.
    // They are not only disabled but also reset.
    this.testResultWorkflowBuilder = new WorkflowBuilder(TEST_RESULT_OPTIONS);
    this.failedTestResultWorkflowBuilder = new WorkflowBuilder(TEST_RESULT_OPTIONS);
    this.initialDefectsWorkflowBuilder = new WorkflowBuilder(TEST_RESULT_OPTIONS);
    this.manualErase = new WorkflowBuilder(MANUAL_ERASE_OPTIONS);
    this.manualRestore = new WorkflowBuilder(MANUAL_RESTORE_OPTIONS);
    this.manualPrint = new WorkflowBuilder(MANUAL_PRINT_OPTIONS);
    this.manualPowerOff = new WorkflowBuilder(MANUAL_POWER_OFF_OPTIONS);
  }

  constructor(
    public dialog: MatDialog,
    private authService: AuthService,
    private labelService: LabelService,
    private customizationService: CustomizationService,
    private changeDetector: ChangeDetectorRef,
    private route: ActivatedRoute,
    private notify: NotificationService,
    private toggleMenuService: ToggleMenuService,
    private masterService: MasterService,
    private adminService: AdminService,
    private router: Router,
  ) {
    this.testResultWorkflowBuilder = new WorkflowBuilder(TEST_RESULT_OPTIONS);
    this.failedTestResultWorkflowBuilder = new WorkflowBuilder(TEST_RESULT_OPTIONS);
    this.initialDefectsWorkflowBuilder = new WorkflowBuilder(TEST_RESULT_OPTIONS);
    this.manualErase = new WorkflowBuilder(MANUAL_ERASE_OPTIONS);
    this.manualRestore = new WorkflowBuilder(MANUAL_RESTORE_OPTIONS);
    this.manualPrint = new WorkflowBuilder(MANUAL_PRINT_OPTIONS);
    this.manualPowerOff = new WorkflowBuilder(MANUAL_POWER_OFF_OPTIONS);

    this.connectionChangeEmitter.subscribe((options: string) => {
      this.dataVerificationEnabled = options.includes('dataVerification');
      this.workflow.initialDefectsWorkflow = this.getDefaultInitialDefetcsWorkflow();;

      if (this.dataVerificationEnabled) {
        this.workflow.initialDefectsWorkflowEnabled = false;
        this.workflow.testResultWorkflowEnabled = false;
        this.workflow.customTriggerWorkflowEnabled = false;
        this.workflow.separateFlow = false;
        this.resetWorkflows();
      }
    });

    this.connectionWorkflowBuilder = new WorkflowBuilder(CONNECTION_WORKFLOW_OPTIONS, CONNECTION_WORKFLOW_DEFAULT_SELECTIONS, this.connectionChangeEmitter);

    this.resetWorkflows();

    this.toggleMenuService.allowUseAllMasterData(false);
    this.profileObserver = this.profileSubject.asObservable();

    if (this.authService.isMasterContext() || this.authService.isUserContext() || this.authService.getSelectedMaster()) {
      this.initStations();
      this.initWarehouses();
      this.getCustomizations()
      this.getLabels();
      this.initTests();
      this.searchBatteryCriteriaDevicesSubject.asObservable().pipe(debounceTime(200)).subscribe(search => {
        this.customizationService.getBuiltInBatteryDevices(search).then(devices => {
          this.selectableDevices = devices || [];
        });
      });
      this.initBuiltInBatteryDevices();

    } else {
      this.notify.warn('Select an account to view the customizations profiles');
      this.router.navigate(['/dashboard']);
    }

    this.authService.masterSelectedEmitter.asObservable().subscribe(master => {
      this.refresh();
    });

    this.authService.warehouseSelectedEmitter.asObservable().subscribe(warehouse => {
      this.refresh();
    })
  }

  private refresh() {
    this.initStations();
    this.initWarehouses();
    this.getCustomizations()
    this.getLabels();
    this.setEdit();
  }

  ngAfterViewInit() {
    if (this.authService.isMasterContext() || this.authService.isUserContext()) {
      this.setEdit();
    }
  }

  private initBuiltInBatteryDevices() {
    this.customizationService.getBuiltInBatteryDevices('').then(devices => {
      this.selectableDevices = devices || [];
    });
  }

  private async initStations() {
    return this.masterService.getStations().then(stations => {
      this.stations = stations || [];
    });
  }
  private initWarehouses() {
    this.masterService.getWarehouses().then(warehouses => {
      this.warehouses = warehouses || [];
    });
  }

  public cancelPreviousGetCustomizations(): void {
    this.customizationsUnsubscriber.next();
  }

  private getSearchTerm(): string | undefined {
    return this.searchProfileFormControl.getRawValue()?.trim().toLowerCase();
  }

  private filterProfileList(profiles: Profile[]): Profile[] {
    const hide = this.profilesNotInUseFormControl.getRawValue();
    const term = this.getSearchTerm();

    if (hide) {
      profiles = profiles.filter(profile => {
        return profile.warehouses > 0 || profile.stations > 0;
      });
    }

    if (term) {
      profiles = profiles.filter(profile => {
        const profileName = profile.name.toLowerCase();

        return profileName.includes(term);
      });
    }

    return profiles;
  }

  async ngOnInit() {
    this.profilesNotInUseFormControl.statusChanges.subscribe(() => {
      this.profilesToShow = this.filterProfileList(this.profiles);
      this.profilesDataSource.data = this.profilesToShow;
      this.profilesDataSource.sort = this.sort;
    });

    this.searchProfileFormControl.valueChanges.subscribe(() => {
      this.profilesToShow = this.filterProfileList(this.profiles);
      this.profilesDataSource.data = this.profilesToShow;
      this.profilesDataSource.sort = this.sort;
    });
  }

  openPreviewDialog(image: string): void {
    this.dialog.open(PreviewLabelComponent, {
      data: { image },
    });
  }

  private updatePasswordProtectedAccount() {
    this.protectionEnabled = true;
  }

  async openPasswordProtectProfile(element: any): Promise<void> {
    if (!this.protectionEnabled || this.authService.isAdminContext()) {
      const dialogPassword = this.dialog.open(PasswordProtectProfileComponent, {
        data: {
          name: element.name,
          id: element.id,
        },
      });

      const passwordSaved = await firstValueFrom(dialogPassword.afterClosed());
      if (passwordSaved) {
        this.updatePasswordProtectedAccount();
        element.protectionEnabled = true;
      }
    } else {
      await this.passwordUpdateProcessor(element, false);
    }

  }

  deleteLabel(label: any) {
    const dialogRef = this.dialog.open(DeleteLabelComponent, {
      data: { id: label.id, name: label.name, image: label.image },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getLabels();
    });
  }

  async getLabels(): Promise<void> {
    const labelsEnabled = this.labelsDataSource.data.filter(label => label.enabled).map(label => label.id);
    this.labelsDataSource.data = [];
    const labels = await this.labelService.getAllLabels();
    this.labelsDataSource.data = labels.data;
    this.labelsDataSourceFiltered.data = labels.data;
    this.reSelectLabels(labelsEnabled);
  }

  private reSelectLabels(labelsEnabled: number[]) {
    this.labelsDataSource.data.forEach(label => {
      const found = labelsEnabled.find(l => label.id === l);
      if (found) {
        label.enabled = true;
      }
    });
  }

  async getCustomizations(): Promise<void> {
    this.loadingProfiles = true;

    this.cancelPreviousGetCustomizations();

    const sort = this.sort?.active;
    const direction = this.sort?.direction;
    const profilesResponse: Observable<any> | undefined = this.customizationService.getProfiles(
      sort,
      direction,
    );

    profilesResponse?.pipe(takeUntil(this.customizationsUnsubscriber)).subscribe({
      next: (profiles) => {
        this.profiles = profiles;
        this.profilesToShow = this.filterProfileList(profiles);
        this.profilesDataSource.data = this.profilesToShow;
        this.profilesDataSource.sort = this.sort;
        this.profileSubject.next(profiles);

        this.loadingProfiles = false;
      },
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();

    if (filterValue === '') {
      // If the filter value is empty, reset to the original data
      this.labelsDataSource.data = this.labelsDataSourceFiltered.data;
    } else {

      const filteredItems: Label[] = this.labelsDataSourceFiltered.data.filter((item: Label) =>
        item.name.toLowerCase().includes(filterValue) ||
        item.canvas_size?.toLowerCase().includes(filterValue)
      );
      // Update the data source with the matching items
      this.labelsDataSource.data = filteredItems;
    }
  }

  clearFilter(): void {
    this.labelsDataSource.data = this.labelsDataSourceFiltered.data;
  }

  createLabel(): void {
    const masterId = this.authService.getCurrentMasterId();
    const token = this.authService.token;

    let url = `${environment.labelMakerUrl}/createLabel?token=${token}&usertype=master&master_Id=${masterId}&customizationVersion=v3`;
    if (this.authService.userType === 'cloudAdmin') {
      url = `${environment.labelMakerUrl}/createLabel?token=${token}&usertype=admin&master_Id=${masterId}&customizationVersion=v3`;
    } else if (this.authService.userType === 'user') {
      url = `${environment.labelMakerUrl}/createLabel?token=${token}&usertype=user&customizationVersion=v3`;
    }
    // add current language param in url
    url = this.urlLangParam(url)
    window.open(url, "_blank");
  }

  editLabel(element: Label) {
    const { id } = element;
    const masterId = this.authService.getCurrentMasterId();
    const token = this.authService.token;
    let url = `${environment.labelMakerUrl}/updateLabel?token=${token}&usertype=master&master_Id=${masterId}&id=${id}&customizationVersion=v3`;
    if (this.authService.userType === 'cloudAdmin') {
      url = `${environment.labelMakerUrl}/updateLabel?token=${token}&usertype=admin&master_Id=${masterId}&id=${id}&customizationVersion=v3`;
    } else if (this.authService.userType === 'user') {
      url = `${environment.labelMakerUrl}/updateLabel?token=${token}&usertype=user&id=${id}&customizationVersion=v3`;
    }
    // add current language param in url
    url = this.urlLangParam(url)
    window.open(url, "_blank");
  }

  async saveCustomization() {

    this.verifyPassword();

    if (!this.mustCreateAssigments()) {
      const redirectToAssigmentsTab = await this.openSavingWitoutAssignmentsDialog();
      if (redirectToAssigmentsTab) {
        this.changePage('assignments');
        return;
      }
    }

    this.changePage('general', 'General');

    if (this.protectionEnabled && !this.currentId && !this.passwordSet) {
      const msg = 'You must set a password if Password Protection is enabled'
      this.notify.error(msg);
      throw new Error(msg)
    }

    const customization = this.getCurrentProfile();
    const result = await this.customizationService.save(customization);

    if (result) {
      const { id } = result;

      if (id) {
        await this.customizationService.updateAssignments(
          id,
          this.getAssignmentIdsPayload(this.assignmentPayload),
        );
      }
    }

    if (result && !customization.id) {
      this.getCustomizations();
    }

    if (result && customization.id) {
      this.getCustomizations();
      this.currentId = "";
    }

    if (this.embedMode) {
      this.embedMode = false;
      window.close();
    }

    this.reset();
    this.selectedIndex = 0;
    this.toggleMenuService.showMenu(true);
  }

  updateIMEICheckBasedOnWorkflow(workflow: any, imeiCheck: any) {
    if (workflow.checkIMEI === true) {
      imeiCheck.automaticIMEICheck = true;
    } else {
      imeiCheck.automaticIMEICheck = false;
    }
  }

  reset() {
    this.currentId = '';
    this.changePage('general');
    this.nameFormControl.setValue('');

    this.labelsDataSource.data.forEach(label => {
      label.enabled = false;
    });
    /*  Reset Printer Settings*/
    this.printerSettings = {
      onErasureSuccess: false,
      powerOffDeviceAfterPrinter: false,
      onTestResults: false,
      onAppResultsFullyFunctional: false,
    };

    this.passwordProfile = null;
    this.protectionEnabled = false;
    this.clearAssignments();
    this.workflow = this.getDefaultDeviceWorkflow();
    this.manualErase = new WorkflowBuilder(MANUAL_ERASE_OPTIONS);
    this.manualRestore = new WorkflowBuilder(MANUAL_RESTORE_OPTIONS);
    this.manualPrint = new WorkflowBuilder(MANUAL_PRINT_OPTIONS);
    this.manualPowerOff = new WorkflowBuilder(MANUAL_POWER_OFF_OPTIONS);
    this.connectionWorkflowBuilder = new WorkflowBuilder(CONNECTION_WORKFLOW_OPTIONS, CONNECTION_WORKFLOW_DEFAULT_SELECTIONS, this.connectionChangeEmitter);
    this.testResultWorkflowBuilder = new WorkflowBuilder(TEST_RESULT_OPTIONS);
    this.failedTestResultWorkflowBuilder = new WorkflowBuilder(TEST_RESULT_OPTIONS);
    this.setDefaultIMEICheck();
    this.setCertifiedTestSuite();

    this.printerSettings = {
      onErasureSuccess: false,
      powerOffDeviceAfterPrinter: false,
      onTestResults: false,
      onAppResultsFullyFunctional: false,
    };

    this.requiredFields = {
      enabled: false,
      LPN: false,
      custom: false,
      color: false,
      carrier: false,
      grade: false,
      ESN: false,
      unlockStatus: false,
      cosmetics: false,
    };

    this.fields = {
      allowUserModifyColorField: true,
    };

    this.restrictiveActions = {
      erase: false,
      print: false,
    };

    this.gradeInputEmptyError = false;

    this.wifiSettings = {
      name: '',
      password: '',
      disconnect: false,
    }

    this.hidePassword = true;
    this.batteryHealthCriteriaSelected = 0;
    this.batterySettings = {
      customizeBattery: false,
      appleFailBatteryWarningMessage: true,
      healthCriteria: <any>[],
      drain: false,
      drainAlways: true,
      drainTestAfterTestResults: true,
      drainDuration: 5,
      allowDrainDurationChange: false,
      failDrainIfDischarge: false,
      failDrainIfDischargePercentage: 3,
    };
    this.colorOptions = [
      { name: 'Black' },
      { name: 'Gold' },
      { name: 'Silver' },
      { name: 'Blue' },
      { name: 'Red' },
      { name: 'Green' },
      { name: 'Pink' },
      { name: 'Brown' },
      { name: 'Yellow' },
      { name: 'White' },
      { name: 'No color' },
    ];
    this.eraseSettings = {
      automated: false,
      iosDoNotPEO: true,
      iosMDM: false,
      showEraseWarning: false,
      reconnectMessage: false,
      reconnectMinutes: 4,
      oneAutoErasePerTransactions: false,
      autoEraseEveryDeviceOnConnection: false,
      eraseAfterTestResult: false,
      eraseAfterOnlySuccessful: false,
    };
    this.cosmeticSettings = {
      enableCosmetics: true,
      cosmeticType: 'multi',
      saveCosmetics: 'cosmeticOnly',
      preferredFormat: 'name',
      cosmetics: [
        'Broken Screen',
        'Cracked Screen',
        'Pitted Screen'
      ],
      cosmeticQuestions: [{
        os: 'both',
        name: '',
        question: '',
        responses: [
          {
            response: '',
            criteria: true
          }
        ]
      }]
    };
    this.newCosmeticName = '';
    this.hasPasswordProtectionObservable = new BehaviorSubject<boolean>(false);
    this.addRuleButtonDisabled = true;

    this.gradingOptions = [
      { name: 'A' },
      { name: 'B' },
      { name: 'C' },
      { name: 'D' },
    ];
  }

  public addCarrier() {
    this.carrierOptions.push({ name: '', renaming: true });
    this.carrierInputFocus = true;
  }

  public addGrade() {
    this.gradingOptions.push({ name: '', renaming: true });
    this.gradeInputFocus = true;
  }

  public addColor() {
    this.colorOptions.push({ name: '', renaming: true });
    this.colorInputFocus = true;
  }

  public deleteCarrier(index: number) {
    this.carrierOptions.splice(index, 1);
    this.carrierRename.reset();
  }

  public deleteGrade(index: number) {
    this.gradingOptions.splice(index, 1);
    this.gradeRename.reset();
  }

  public deleteColor(index: number) {
    this.colorOptions.splice(index, 1);
    this.colorRename.reset();
  }

  public deleteBatteryCriteria(index: number) {
    const codes = ['BH-A', 'BH-B', 'BH-C', 'BH-D'];
    this.selectedCriteria().rules.splice(index, 1);
    this.selectedCriteria().rules.forEach((rule: any, index: any) => {
      rule.code = codes[index];
    });
    this.addRuleButtonDisabled = false;
  }

  public blurCarrier(element: FieldOption) {
    if (this.carrierRename.value) {
      element.renaming = false;
      this.carrierInputFocus = false;
    }
  }

  public blurGrade(element: FieldOption) {
    if (this.gradeRename.value) {
      element.renaming = false;
      this.gradeInputFocus = false;
    }
  }

  public blurColor(element: FieldOption) {
    if (this.colorRename.value) {
      element.renaming = false;
      this.colorInputFocus = false;
    }
  }

  public renameCarrier(element: FieldOption) {
    element.renaming = true;
    this.carrierInputFocus = true;
  }

  public clearDefaultCarrier() {
    this.defaultCarrierOption = '';
  }

  public closeCarrierOption() {
    if (this.carrierRename.untouched || this.carrierRename.valid) {
      this.carrierInputEmptyError = false;
      this.viewCarrierOptions = false
    } else {
      this.carrierInputFocus = true;
      this.carrierInputEmptyError = true;
    }
  }

  public dropCarrier(event: CdkDragDrop<FieldOption[]>) {
    moveItemInArray(this.carrierOptions, event.previousIndex, event.currentIndex);
  }

  public renameGrade(element: FieldOption) {
    element.renaming = true;
    this.gradeInputFocus = true;
  }

  public renameColor(element: FieldOption) {
    element.renaming = true;
    this.gradeInputFocus = true;
  }

  public clearDefaultGrade() {
    this.defaultGradingOption = '';
  }

  public dropGrade(event: CdkDragDrop<FieldOption[]>) {
    moveItemInArray(this.gradingOptions, event.previousIndex, event.currentIndex);
  }

  public dropColor(event: CdkDragDrop<FieldOption[]>) {
    moveItemInArray(this.colorOptions, event.previousIndex, event.currentIndex);
  }

  public setIMEICheckState(state: any) {
    this.imeiCheckState = state;
  }

  public setAdvancedSettingsState(state: any) {
    this.advancedSettingsState = state;
  }

  public addBatteryHealthCriteriaRule() {
    const names = ['BH-A', 'BH-B', 'BH-C', 'BH-D'];
    if (this.selectedCriteria().rules.length < 4) {
      const code: string = names[this.selectedCriteria().rules.length];

      // @ts-ignore
      this.selectedCriteria().rules.push({ code, minimumCapacity: 0, maximumCycle: 0 });
      if (this.selectedCriteria().rules.length === 4) {
        this.addRuleButtonDisabled = true;
      }
    }
  }

  public toggleSpecifyDevices(event: MatCheckboxChange) {
    this.selectedCriteria().applyAllDevices = false;
    this.selectedCriteria().applyAndroidDevices = false;
    this.selectedCriteria().applyAppleIphoneDevices = false;
    this.selectedCriteria().applyAppleIpadDevices = false;
    this.selectedCriteria().specifyDevices = event.checked;
  }

  public addDevices() {
    this.devicesToAddToCriteria.forEach(device => {
      const index = this.selectableDevices.indexOf(device);
      const criteria = this.selectedCriteria();

      this.selectableDevices.splice(index, 1);

      if (!this.containsDevice(criteria.devices, device)) {
        criteria.devices.push(device);
      }
    });

    this.devicesToAddToCriteria = [];
  }

  public removeDevices() {
    this.devicesToRemoveFromCriteria.forEach((device) => {
      const index = this.selectedCriteria().devices.indexOf(device);

      this.selectedCriteria().devices.splice(index, 1);
      this.devicesToRemoveFromCriteria = [];
    });
  }

  private defaultBatteryCriteria() {
    return {
      name: '',
      description: '',
      applyAllDevices: true,
      applyAndroidDevices: false,
      applyAppleIphoneDevices: false,
      applyAppleIpadDevices: false,
      specifyDevices: false,
      devices: [],
      rules: [],
    };
  }

  public addBatteryHealthCriteria() {
    this.devicesToRemoveFromCriteria = [];
    this.devicesToAddToCriteria = [];
    this.batterySettings.healthCriteria.push(this.defaultBatteryCriteria());
    this.batteryHealthCriteriaSelected = this.batterySettings.healthCriteria.length - 1;
    this.currentPage = 'new-battery';
  }

  public deleteBatteryHealthCriteria(index: number) {

    this.batterySettings.healthCriteria.splice(index, 1);

  }

  public editBatteryHealthCriteria(index: number) {
    this.batteryHealthCriteriaSelected = index;
    this.devicesToAddToCriteria = [];
    this.devicesToRemoveFromCriteria = [];
    this.changeDetector.detectChanges();
    this.currentBatteryHealthCriteria = structuredClone(this.selectedCriteria());
    this.currentPage = 'new-battery';
  }

  public addCosmetic() {
    this.cosmeticSettings.cosmetics.push(this.newCosmeticName);
    this.newCosmeticName = '';
  }

  public deleteCosmetic(index: number) {
    this.cosmeticSettings.cosmetics.splice(index, 1);
  }

  public addQuestion() {
    this.cosmeticSettings.cosmeticQuestions.push({
      os: 'both',
      name: '',
      question: '',
      responses: [
        {
          response: '',
          criteria: true
        }
      ]
    });
  }

  public deleteQuestion(index: number) {
    this.cosmeticSettings.cosmeticQuestions.splice(index, 1);
  }

  public dropCosmetic(event: CdkDragDrop<FieldOption[]>) {
    moveItemInArray(this.cosmeticSettings.cosmetics, event.previousIndex, event.currentIndex);
  }

  public dropQuestion(event: CdkDragDrop<FieldOption[]>) {
    moveItemInArray(this.cosmeticSettings.cosmeticQuestions, event.previousIndex, event.currentIndex);
  }

  public addResponse(question: any, i: number) {
    question.responses.splice(i += 1, 0, { criteria: true, response: '' });
  }

  public moveUp(index: any) {
    if (index > 0) {
      [this.cosmeticSettings.cosmeticQuestions[index - 1], this.cosmeticSettings.cosmeticQuestions[index]] = [this.cosmeticSettings.cosmeticQuestions[index], this.cosmeticSettings.cosmeticQuestions[index - 1]];
    }
  }

  public moveDown(index: any) {
    if (index < this.cosmeticSettings.cosmeticQuestions.length) {
      [this.cosmeticSettings.cosmeticQuestions[index + 1], this.cosmeticSettings.cosmeticQuestions[index]] = [this.cosmeticSettings.cosmeticQuestions[index], this.cosmeticSettings.cosmeticQuestions[index + 1]];
    }
  }

  private async checkPassword(profile: any) {
    if (profile.protectionEnabled && !this.authService.isAdminContext()) {
      const dialogRef = this.dialog.open(PasswordRequiredComponent,
        {
          data: {
            name: profile.name,
            id: profile.id
          },
        }
      );
      dialogRef.afterClosed().subscribe(result => {
        this.updatePassword(result)
      });

      return firstValueFrom(dialogRef.afterClosed());
    }
    return new Promise((resolve) => resolve(true));
  }

  public async unlockProfile(profile: any) {
    if (profile.protectionEnabled) {
      const result: boolean = await this.checkPassword(profile);
      if (result) {
        await this.passwordUpdateProcessor(profile);
      }
    }
  }

  public async passwordUpdateProcessor(profile: any, enableProtection?: boolean) {
    const passwordDialog = this.dialog.open(PasswordUpdateComponent, {
      data: {
        name: profile.name,
        id: profile.id,
        enableProtection: enableProtection !== undefined ? enableProtection : true,
      }
    });

    const edit = await firstValueFrom(passwordDialog.afterClosed());

    if (edit.changeProtection !== undefined) {
      profile.protectionEnabled = edit.changeProtection;
    }

    if (edit?.editProfile) {
      this.editProfile(profile);
    }
  }

  public async editProfile(profile: any) {
    this.unChangedProfile = structuredClone(profile);
    this.editMode = true;
    const passwordAccepted = await this.checkPassword(profile);

    if (passwordAccepted) {
      this.protectionEnabled = true;
      this.changePage('general');

      if (!passwordAccepted) {
        this.notify.warn('Password not accepted!');

        return;
      }

      this.changePage('general', 'General');

      // Get the current assignments of the profile to edit
      const stationsP = this.customizationService.getStations(profile.id);
      const warehousesP = this.customizationService.getWarehouses(profile.id);
      const [stations, warehouses] = await Promise.all([stationsP, warehousesP]);
      this.assignmentPayload.stations = stations;
      this.assignmentPayload.warehouses = warehouses;

      if (profile.id === 'default') {
        this.nameFormControl.setValue('');
        this.showProfileCreation();
      } else {

        const url = new URL(window.location.href);
        const assignedId = url.searchParams.get('station');
        if (assignedId) {
          // stationIds.push(Number(assignedId));
        }

        this.setProfile(profile);

        this.toggleMenuService.showMenu(false);
      }
    }
  }

  private setProfile(profile: any) {
    this.currentId = profile.id;
    this.nameFormControl.setValue(profile.name);
    this.gradingOptions = [];
    this.colorOptions = [];
    profile.colors?.forEach((color: any) => this.colorOptions.push({ name: color }));
    profile.grading?.forEach((grade: any) => this.gradingOptions.push({ name: grade }));
    this.carrierOptions = !profile.carriers ? this.defaultCarrierOptions : (profile.carriers || []).map((carrier: string) => ({ name: carrier }))
    this.defaultGradingOption = profile.defaultGrade;
    this.defaultCarrierOption = profile.defaultCarrier;
    this.requiredFields = profile.requiredFields;
    this.fields = profile.fields || { allowUserModifyColorField: true };
    this.restrictiveActions = profile.restrictiveActions;
    this.wifiSettings = profile.wifiSettings;
    this.batterySettings = profile.batterySettings;
    this.printerSettings = profile.printerSettings;
    this.eraseSettings = profile.eraseSettings;
    this.selectedIndex = 2;
    this.workflow = this.getDeviceWorkflow(profile.workflow);
    this.cosmeticSettings = profile.cosmeticSettings;
    this.protectionEnabled = profile.protectionEnabled;
    this.passwordOptions = profile.passwordOptions || this.defaultPasswordOptions;

    const labelsEnabled: number[] = JSON.parse(`[${profile.labels}]`);

    this.reSelectLabels(labelsEnabled);

    if (profile.testPlan && Object.keys(profile.testPlan).length > 0) {
      this.initializeTestPlan(profile.testPlan);
    } else {
      this.setCertifiedTestSuite();
    }

    if (profile.customTest?.length) {
      this.customTest = profile.customTest || [];
    }

    if (profile.advancedSettings && Object.keys(profile.advancedSettings).length > 0) {
      this.currentAdvancedSettings = profile.advancedSettings;
      this.advancedSettingsState = profile.advancedSettings;
    } else {
      this.setDefaultAdvancedSettings();
    }

    if (profile.imeiCheck && Object.keys(profile.imeiCheck).length > 0) {
      this.currentCheckIMEI = profile.imeiCheck;
      this.imeiCheckState = profile.imeiCheck;
      // this.imeiCheckComponent.initialize(profile.imeiCheck);
    } else {
      console.error(`empty check IMEI from edit profile!`);
      this.setDefaultIMEICheck();
    }
  }


  public async openDialog(type: 'WAREHOUSE' | 'STATION', profile: Customization) {
    const dialogRef = this.dialog.open(AssignChecklistComponent, {
      height: 'fit-content',
      data: {
        type,
        profile,
      }
    });

    dialogRef.backdropClick().subscribe(() => {
      dialogRef.close({ updated: false });
    })
    dialogRef.afterClosed().subscribe(result => {
      const { updated, numberOfEntitiesSelected } = result;

      if (updated) {
        this.getCustomizations();
      }
    });
  }

  public changeTab(index: number) {
    if (index !== 2) {
      this.leaveEditMode();
      this.resetFields();
    }

    this.previousTabIndex = index;
  }

  public resetFields() {
    this.currentId = ''
    this.nameFormControl.setValue('');
    this.gradingOptions = [
      { name: 'A' },
      { name: 'B' },
      { name: 'C' },
      { name: 'D' },
    ];
    this.carrierOptions = this.defaultCarrierOptions;
    this.defaultGradingOption = '';
    this.requiredFields = {
      enabled: false,
      LPN: false,
      custom: false,
      color: false,
      carrier: false,
      grade: false,
      ESN: false,
      unlockStatus: false,
      cosmetics: false,
    };
    this.fields = {
      allowUserModifyColorField: true,
    };

    this.restrictiveActions = {
      erase: false,
      print: false,
    };
    this.passwordOptions = {
      editTransaction: false,
      updateVendorInfo: false,
      newTransaction: false,
    };
    this.wifiSettings = {
      name: '',
      password: '',
      disconnect: false,
    }
    this.printerSettings = {
      onErasureSuccess: false,
      powerOffDeviceAfterPrinter: false,
      onTestResults: false,
      onAppResultsFullyFunctional: false,
    };

    this.eraseSettings = {
      automated: false,
      iosDoNotPEO: true,
      iosMDM: false,
      showEraseWarning: false,
      reconnectMessage: false,
      reconnectMinutes: 4,
      oneAutoErasePerTransactions: false,
      autoEraseEveryDeviceOnConnection: false,
      eraseAfterTestResult: false,
      eraseAfterOnlySuccessful: false,
    }
  }

  public async deleteProfile(element: any) {
    const passwordAccepted = await this.checkPassword(element);
    if (passwordAccepted) {
      const dialogRef = this.dialog.open(DeleteProfileComponent, {
        data: element,
      });

      dialogRef.afterClosed().subscribe(_ => {
        this.getCustomizations();
      });
    }
  }

  public async duplicateProfile(profile: any) {
    const passwordAccepted = await this.checkPassword(profile);

    if (passwordAccepted) {
      this.setProfile(profile);
      this.currentId = '';
      if (this.authService.isAdminContext()) {
        this.protectionEnabled = false;
      }
      this.nameFormControl.setValue(`${profile.name} copy`);
      this.clearAssignments();
      this.toggleMenuService.hideMenu();

      this.changePage('general');
    } else {
      this.notify.error('Password incorrect');
    }
  }

  public selectedCriteria(): BatteryHealthCriteria {
    return this.batterySettings.healthCriteria[this.batteryHealthCriteriaSelected];
  }

  public async setEdit() {
    const url = new URL(window.location.href);

    const profileId = this.route.snapshot.queryParamMap.get('edit');
    const assignedId = url.searchParams.get('station');
    const embed = url.searchParams.get('embed');

    if (embed) {
      this.embedMode = true;
    }
    if (profileId) {
      const profile = await this.customizationService.getProfile(profileId);

      if (!profile || profile.error) {
        this.notify.error('Failed to retrieve profile');
        console.error(`Couldn't get profile: ${profile.error}`);
      } else {
        await this.getLabels();
        this.selectedIndex = 2;
        await this.editProfile(profile);
      }
    }
    if (assignedId) {
      const profile = await this.customizationService.getAssignedProfile(assignedId);

      if (!profile || profile.error) {
        this.notify.error('Failed to retrieve profile');
        console.error(`Couldn't get profile: ${profile.error}`);
      } else {
        await this.getLabels();
        this.selectedIndex = 2;
        await this.editProfile(profile);
      }
    }
  }

  public cancelCustomization() {
    this.reset();
    this.unChangedProfile = this.getCurrentProfile();
    this.backToMainPage();
  }

  public backToMainPage() {
    const canGoToMainPage = this.canDeactivate();
    const changeToMainPage = () => {
      this.toggleMenuService.showMenu(true);
      this.selectedIndex = 0;
      this.currentId = '';
      this.changeDetector.detectChanges();
      this.profilesDataSource.sort = this.sort;
    }
    if (!canGoToMainPage) {
      this.backWithoutSavingDialog().then(response => {
        if (response) {
          changeToMainPage();
        }
      });
    } else {
      changeToMainPage();
    }
  }

  public showProfileCreation() {
    this.currentPage = 'general';
    this.toggleMenuService.showMenu(false);
    this.selectedIndex = 2;
    this.reset();
    this.unChangedProfile = structuredClone(this.getCurrentProfile());
  }

  deleteCosmeticResponse(question: any, responseIndex: number) {
    question.responses.splice(responseIndex, 1)
  }

  sortResponse(question: any, $event: CdkDragDrop<any, any>) {
    moveItemInArray(question.responses, $event.previousIndex, $event.currentIndex);
  }

  updatePasswordOptions(passwordOptions: Partial<PasswordOptions>) {
    this.passwordOptions = passwordOptions;
  }

  updatePassword(password: string) {
    this.passwordProfile = password;
  }

  setProtection(enabled: boolean) {
    this.passwordSet = true;
    this.protectionEnabled = enabled;
  }

  changeEnableFields(enabled: boolean) {
    if (!enabled) {
      this.requiredFields = {
        enabled: false,
        LPN: false,
        custom: false,
        color: false,
        carrier: false,
        grade: false,
        ESN: false,
        unlockStatus: false,
        cosmetics: false,
      };
      this.restrictiveActions = {
        erase: false,
        print: false,
      };
    }
  }

  changeEnableCosmetics(enabled: boolean) {
    if (!enabled) {
      this.cosmeticSettings = {
        enableCosmetics: false,
        cosmeticType: 'multi',
        saveCosmetics: 'cosmeticOnly',
        preferredFormat: 'name',
        cosmetics: [
          'Broken Screen',
          'Cracked Screen',
          'Pitted Screen'
        ],
        cosmeticQuestions: [{
          os: 'both',
          name: '',
          question: '',
          responses: [
            {
              response: '',
              criteria: true
            }
          ]
        }]
      };
    }
  }

  changeEnableCustomizeBattery(enabled: boolean) {
    if (!enabled) {
      this.batterySettings = {
        customizeBattery: false,
        appleFailBatteryWarningMessage: true,
        healthCriteria: [],
        drain: false,
        drainAlways: true,
        drainTestAfterTestResults: true,
        drainDuration: 5,
        allowDrainDurationChange: false,
        failDrainIfDischarge: false,
        failDrainIfDischargePercentage: 3,
      };
      this.devicesToRemoveFromCriteria = [];
      this.devicesToAddToCriteria = [];
    }
  }

  changeEnableBatteryDrainSettings(enabled: boolean) {

    if (!enabled) {
      this.batterySettings.drain = false;
      this.batterySettings.drainAlways = true;
      this.batterySettings.drainTestAfterTestResults = true;
      this.batterySettings.drainDuration = 5;
      this.batterySettings.allowDrainDurationChange = false;
      this.batterySettings.failDrainIfDischarge = false;
      this.batterySettings.failDrainIfDischargePercentage = 3;
    }
  }

  private getLatestIMEICheckData() {
    if (this.imeiCheckComponent) {
      this.imeiCheckState = this.imeiCheckComponent.getData();
    }

    return this.imeiCheckState;
  }


  private getLatestAdvancedSettingsData() {
    if (this.advancedSettingsComponent) {
      this.advancedSettingsState = this.advancedSettingsComponent.getData();
    }
    return this.advancedSettingsState;
  }

  private getLatestCustomTestData() {
    if (this.customTestComponent) {
      this.customTestState = this.customTestComponent.getData();
    }
  }

  changePage(name: string, label: string = '') {
    const page = this.pagesByName[name];
    const pageTitle = page?.label || label;
    const currentCheckIMEI = this.imeiCheckComponent?.getData();
    const currentTestPlan = this.getLatestTestPlanData();
    const currentAdvancedSettings = this.advancedSettingsComponent?.getData();
    const currentCustomTest = this.customTestComponent?.getData();

    if (!page) {
      throw new Error('Trying to change to an undefined page');
    }

    if (currentCheckIMEI) {
      this.currentCheckIMEI = currentCheckIMEI;
    }

    if (!isEmpty(currentTestPlan)) {
      this.currentTestPlan = currentTestPlan;
    }

    if (currentCustomTest) {
      this.currentCustomTest = currentCustomTest;
    }

    if (currentAdvancedSettings) {
      this.currentAdvancedSettings = currentAdvancedSettings;
    }

    this.currentPage = name;
    this.currentPageLabel = pageTitle;
  }

  public saveAssignments(event: AssignmentPayload) {
    this.assignmentPayload = event;
  }

  mustCreateAssigments(): boolean {
    let payload = this.assignmentPayload;

    if (this.assignmentExpressComponent) {
      // payload = this.assignmentExpressComponent.getData();
    }

    if (payload) {
      const numOfStationsToAssign = payload.stations?.filter(s => s.assigned).length || 0;
      const numOfWarehousesToAssign = payload.warehouses?.filter(s => s.assigned).length || 0;

      return numOfStationsToAssign > 0 || numOfWarehousesToAssign > 0;
    } else {
      return false;
    }
  }

  private leaveEditMode(): void {
    this.editMode = false;
  }

  private editModeEnabled(): boolean {
    return this.editMode;
  }

  backToBatteryPage() {
    this.verifyBatteryHealthCriteria();
    this.currentPage = 'battery';
    this.currentBatteryHealthCriteria = null;
    this.batteryHealthCriteriaSelected = this.batterySettings.healthCriteria.length - 1;
  }

  verifyPassword() {
    if (this.protectionEnabled && !this.passwordProfile && !this.currentId) {
      this.notify.error('No password');
      throw new Error(`No password`);
    }
  }

  verifyBatteryHealthCriteria() {

    try {
      for (let index = 0; index < this.batterySettings.healthCriteria.length; index++) {
        const criteria = this.batterySettings.healthCriteria[index];
        const deleteRules: string[] = [];

        criteria.rules = this.validateBatteryCriteriaRules(criteria.rules);
        deleteRules.forEach(ruleCode => {
          const i = criteria.rules.findIndex((rule: any) => rule.code === ruleCode);
          criteria.rules.splice(i, 1);
        });

        if (criteria.name === '') {
          const msg = `Battery Health Criteria can not be saved without a name`;
          this.backWithoutSavingHealthCriteria(msg, index);
        }

        if (!criteria.rules.length) {
          const msg = `Battery Health Criteria can not be empty`;
          this.backWithoutSavingHealthCriteria(msg, index);
        }
      };
    } catch (error) {
      console.error(`verifyBatteryHealthCriteria error`);
      console.error(error);
    }
  }

  private validateBatteryCriteriaRules(rules: { minimumCapacity: number | null, maximumCycle: number | null, code: string | null }[]) {
    // delete criteria without names and criteria rules without values
    const codes = ['BH-A', 'BH-B', 'BH-C', 'BH-D'];
    return rules.map((rule, ruleIndex) => {
      rule.code = codes[ruleIndex];
      if (rule.minimumCapacity === null) {
        rule.minimumCapacity = 0;
      }
      if (rule.maximumCycle === null) {
        rule.maximumCycle = 0;
      }
      return rule;
    });
  }


  private backWithoutSavingHealthCriteria(msg: string, index: number) {
    const defaultCriteria = this.defaultBatteryCriteria();
    defaultCriteria.rules = this.validateBatteryCriteriaRules(defaultCriteria.rules) as any;
    if (isEqual(defaultCriteria, this.batterySettings.healthCriteria[index])) {
      this.batterySettings.healthCriteria.splice(index, 1);
      return;
    }
    this.notify.error(msg);
    throw new Error(`Failed to verify battery health criteria: ${msg}`);
  }


  openSavingWitoutAssignmentsDialog() {
    const dialog = this.dialog.open(SavingWithoutAssignmentsDialogComponent, {});

    return dialog.afterClosed().toPromise();
  }

  private clearAssignments(): void {
    this.assignmentPayload = {
      stations: this.stations,
      warehouses: this.warehouses,
    }
  }

  private cleanCustomTriggerWorkflowData(workflow: DeviceWorkflow): DeviceWorkflow {
    if (workflow.customTriggerWorkflowEnabled) {
      workflow.manualEraseSelectedActions = this.manualErase.getSelections();
      workflow.manualRestoreSelectedActions = this.manualRestore.getSelections();
      workflow.manualPrintSelectedActions = this.manualPrint.getSelections();
      workflow.manualPowerOffSelectedActions = this.manualPowerOff.getSelections();
    } else {
      workflow.manualEraseSelectedActions = [];
      workflow.manualRestoreSelectedActions = [];
      workflow.manualPrintSelectedActions = [];
      workflow.manualPowerOffSelectedActions = [];
    }

    if (workflow.initialDefectsWorkflowEnabled) {
      workflow.initialDefectWorkflowsActions = this.initialDefectsWorkflowBuilder.getSelections();
    } else {
      workflow.initialDefectWorkflowsActions = [];
    }

    if (workflow.connectionWorkflowEnabled) {
      workflow.connectionWorkflow = this.connectionWorkflowBuilder.getSelections();
    } else {
      workflow.connectionWorkflow = [];
    }

    if (workflow.testResultWorkflow) {
      workflow.testResultWorkflow = this.testResultWorkflowBuilder.getSelections();

      if (workflow.separateFlow) {
        workflow.failedWorkFlow = this.failedTestResultWorkflowBuilder.getSelections();
      } else {
        workflow.failedWorkFlow = [];
      }
    }

    return workflow;
  }

  private getAssignmentIdsPayload(payload: AssignmentPayload): AssignmentIdsPayload {
    const stationIds = payload.stations?.filter(s => s.assigned).map(s => s.id);
    const warehouseIds = payload.warehouses?.filter(s => s.assigned).map(s => s.id);

    return { stationIds, warehouseIds };
  }

  getDefaultInitialDefetcsWorkflow() {
    return {
      IMEISerialValidation: false,
      noIMEI: false,
      noEID: false,
      appleIcloudGoogleLock: false,
      mdm: false,
      samsungKnox: false,
      jailbreakRoot: false,
      batteryHealth: false,
      betaOS: false,
      badESN: false,
    };
  }

  private getDefaultSkipSetupAssistant() {
    const skipAssistantSteps: any = {};
    this.skipAssistantSteps.forEach(sas => {
      skipAssistantSteps[sas.key] = true;
    });

    return skipAssistantSteps;
  }

  getDefaultDeviceWorkflow(): DeviceWorkflow {
    return {
      // Since CLD-6262, "enabled" should always be set to "true". Keeping it for compatibility
      enabled: true,
      skipAllSetupAssistant: true,
      skipSetupAssistant: this.getDefaultSkipSetupAssistant(),
      warningMessagesEnabled: false,
      warningMessages: {
        simCardIsDetected: false,
        sdCardIsDetected: false,
        failSDCardNotRemoved: false,
        failSimCardNotRemoved: false,
      },

      checkIMEI: false,
      initialDefectsWorkflowEnabled: false,
      initialDefectsWorkflow: this.getDefaultInitialDefetcsWorkflow(),
      initialDefectWorkflowsActions: <string[]>[],
      connectionWorkflowEnabled: true,
      connectionWorkflow: ['restoreJailbroken', 'restoreIOSBeta', 'EraseiOSMDM', 'install'],
      testResultWorkflowEnabled: false,
      separateFlow: false,
      testResultWorkflow: <string[]>[],
      failedWorkFlow: <string[]>[],

      customTriggerWorkflowEnabled: false,
      manualEraseSelectedActions: <string[]>[],
      manualRestoreSelectedActions: <string[]>[],
      manualPrintSelectedActions: <string[]>[],
      manualPowerOffSelectedActions: <string[]>[],
    };
  }

  getDeviceWorkflow(workflow: Partial<DeviceWorkflow>): DeviceWorkflow {
    const defaultWorkflow = this.getDefaultDeviceWorkflow();
    const profileWorkflow = Object.assign(defaultWorkflow, workflow);

    this.manualErase = new WorkflowBuilder(
      MANUAL_ERASE_OPTIONS,
      profileWorkflow.manualEraseSelectedActions,
    );
    this.manualRestore = new WorkflowBuilder(
      MANUAL_RESTORE_OPTIONS,
      profileWorkflow.manualRestoreSelectedActions,
    );
    this.manualPrint = new WorkflowBuilder(
      MANUAL_PRINT_OPTIONS,
      profileWorkflow.manualPrintSelectedActions,
    );
    this.manualPowerOff = new WorkflowBuilder(
      MANUAL_POWER_OFF_OPTIONS,
      profileWorkflow.manualPowerOffSelectedActions,
    );
    this.connectionWorkflowBuilder = new WorkflowBuilder(
      CONNECTION_WORKFLOW_OPTIONS,
      profileWorkflow.connectionWorkflow,
      this.connectionChangeEmitter,
    );
    this.testResultWorkflowBuilder = new WorkflowBuilder(
      TEST_RESULT_OPTIONS,
      profileWorkflow.testResultWorkflow,
    );
    this.failedTestResultWorkflowBuilder = new WorkflowBuilder(
      TEST_RESULT_OPTIONS,
      profileWorkflow.failedWorkFlow,
    );

    this.initialDefectsWorkflowBuilder = new WorkflowBuilder(
      TEST_RESULT_OPTIONS,
      profileWorkflow.initialDefectWorkflowsActions,
    );

    return profileWorkflow;
  }

  private getCurrentProfile() {
    const grading: string[] = [];
    const carriers: string[] = [];
    const colors: string[] = [];
    const labels: number[] = [];
    const currentCheckIMEI = this.getLatestIMEICheckData();
    const currentAdvancedSettings = this.getLatestAdvancedSettingsData();
    const currentTestPlan = this.getLatestTestPlanData();
    const currentCustomTest = this.customTestComponent?.getData();

    if (currentCheckIMEI) {
      this.currentCheckIMEI = currentCheckIMEI;
    }

    if (currentTestPlan) {
      this.currentTestPlan = currentTestPlan;
    }

    if (currentAdvancedSettings) {
      this.currentAdvancedSettings = currentAdvancedSettings;
    }

    if (currentCustomTest) {
      this.currentCustomTest = currentCustomTest;
    }

    this.verifyBatteryHealthCriteria();
    this.gradingOptions?.forEach(grade => grading.push(grade.name));
    this.carrierOptions?.forEach(carrier => carriers.push(carrier.name));
    this.colorOptions?.forEach(color => colors.push(color.name));
    this.labelsDataSourceFiltered.data.forEach(label => {
      if (label.enabled) {
        labels.push(label.id);
      }
    });

    const customization = {
      profileId: this.currentId,
      name: this.nameFormControl.value,
      labels,
      grading,
      carriers,
      colors,
      testPlan: this.currentTestPlan,
      customTest: this.customTest,
      imeiCheck: this.currentCheckIMEI,
      defaultGrade: this.defaultGradingOption,
      defaultCarrier: this.defaultCarrierOption,
      restrictiveActions: this.restrictiveActions,
      fields: this.fields,
      requiredFields: this.requiredFields,
      wifiSettings: this.wifiSettings,
      printerSettings: this.printerSettings,
      eraseSettings: this.eraseSettings,
      cosmeticSettings: this.cosmeticSettings,
      batterySettings: this.batterySettings,
      workflow: this.cleanCustomTriggerWorkflowData(this.workflow),
      password: this.passwordProfile,
      protectionEnabled: this.protectionEnabled,
      passwordOptions: this.passwordOptions,
      advancedSettings: this.currentAdvancedSettings,
    } as any;

    return customization;

  }

  private async backWithoutSavingDialog(): Promise<boolean> {
    const dialogBackWithoutSaving = this.dialog.open(BackWithoutSavingComponent);
    return await firstValueFrom(dialogBackWithoutSaving.afterClosed());
  }

  public checkValidNumber(event: KeyboardEvent) {
    if (!Number.isInteger(+event.key) && ![46, 8, 13, 9].includes(event.keyCode)) {
      event.preventDefault();
    }
  }

  initialDefectsWorkflowResetOptions() {
    if (!this.workflow.initialDefectsWorkflowEnabled) {
      this.workflow.initialDefectsWorkflow = this.getDefaultDeviceWorkflow().initialDefectsWorkflow;
    }
  }
  public disabledProfileCreation(profileName: string | null): boolean {
    const regex = new RegExp(/\w/gim);
    if (!profileName?.trim() || !regex.test(profileName.trim())) {
      return true;
    }
    return false;
  }

  private setDefaultIMEICheck(): void {
    const imeiCheckDefault = {
      automaticIMEICheck: false,
      usFinancialCarrierSetting: true,
      name: IMEI_CHECK_SETTING.MOST_ACCURATE,
      configuration: MOST_ACCURATE_CONFIGURATION,
    };

    this.imeiCheckState = imeiCheckDefault;
  }

  urlLangParam(url: string) {
    const currentLang = localStorage.getItem('wglang') || 'en';
    const labelEditorSupportedLanguages = ['en', 'ja', 'es'];
    const lang = labelEditorSupportedLanguages.includes(currentLang) ? currentLang : 'en';

    return `${url}&lang=${lang}`;
  }

  private setDefaultAdvancedSettings(): void {
    this.advancedSettingsState = {
      sourceURL: null,
      sourceAPIKey: null,
      sourceEnabled: false,
      labelURL: null,
      labelAPIKey: null,
      labelEnabled: false,
      resultsURL: null,
      resultsAPIKey: null,
      resultsEnabled: false,
      shopfloorEnabled: false,
      apiDataSharingEnabled: false,
    };
  }

  public searchDevices(): void {
    this.searchBatteryCriteriaDevicesSubject.next(this.deviceSearchTerm);
  }

  private deviceEquals(a: Device, b: Device): boolean {
    return a.modelName === b.modelName;
  }

  public setCustomTests(value: any) {
    this.customTest = value;
  }

  private containsDevice(devices: Device[], d: Device): boolean {
    return !!devices.find(d2 => d2.modelName === d.modelName);
  }

  public resetCustomTest() {
    this.customTestComponent.resetCustomTest();
  }
  public saveCustomTest() {
    this.customTestComponent.saveTest();
  }

  public resetFailSDCard() {
    if (!this.workflow.warningMessages.sdCardIsDetected) {
      this.workflow.warningMessages.failSDCardNotRemoved = false;
    }
  }
  public resetFailSimCard() {
    if (!this.workflow.warningMessages.simCardIsDetected) {
      this.workflow.warningMessages.failSimCardNotRemoved = false;
    }
  }

  deviceSearchTerm: string = '';
  selectableDevices: Device[] = [];
  devicesToAddToCriteria: Device[] = [];
  devicesToRemoveFromCriteria: Device[] = [];

  updateCardWarnings() {
    this.workflow.warningMessages.simCardIsDetected
      &&= this.workflow.warningMessagesEnabled;
    this.workflow.warningMessages.sdCardIsDetected
      &&= this.workflow.warningMessagesEnabled;
  }

  updateSkipAssistant() {
    this.workflow.skipSetupAssistant = { ...this.getDefaultSkipSetupAssistant() };
  }

  async resetPassword(profile: any) {
    await this.openPasswordProtectProfile(profile);
  }

  connectionChangeEmitter = new EventEmitter<string[]>();

  ////////////////////////////////////////////////////////
  // Init Test plan related
  ////////////////////////////////////////////////////////
  public skipUncheckingDefaultTestDialog: boolean = false;

  public setSkipUncheckingDefaultTestDialog(v: boolean) {
    this.skipUncheckingDefaultTestDialog = v;
  }

  public setSelectedDeviceTests(selectedTests: DeviceTestPlan | undefined): void {
    this.selectedDeviceTests = selectedTests;
  }

  public setSelectedWatchTests(selectedTests: WatchTestPlan | undefined): void {
    this.selectedWatchTests = selectedTests;
  }

  public setSelectedAirpodsTests(selectedTests: AirpodsTestPlan | undefined): void {
    this.selectedAirpodsTests = selectedTests;
  }

  private initTests() {
    this.customizationService.getTestPlanSkeleton().then(result => {
      this.setCertifiedTestSuite();
    });
  }

  private getLatestTestPlanData() {
    let testPlan = this.currentTestPlan;

    if (this.testPlanComponent) {
      const deviceTestPlan = this.testPlanComponent.getData();

      testPlan = { ...testPlan, ...deviceTestPlan };
    } else {
      testPlan = { ...testPlan, ...this.selectedDeviceTests };
    }

    if (this.watchTestPlanComponent) {
      const watchTestPlan = this.watchTestPlanComponent.getData();

      testPlan = { ...testPlan, ...watchTestPlan };
    } else {
      testPlan = { ...testPlan, ...this.selectedWatchTests };
    }

    if (this.airpodsTestPlanComponent) {
      const airpodsTestPlan = this.airpodsTestPlanComponent.getData();

      testPlan = { ...testPlan, ...airpodsTestPlan };
    } else {
      testPlan = { ...testPlan, ...this.selectedAirpodsTests }
    }

    this.currentTestPlan = testPlan;

    return testPlan;
  }

  private setCertifiedTestSuite(): void {
    this.selectedDeviceTests = this.customizationService.getCertifiedTestSuiteForBothPlatforms();
    this.selectedWatchTests = this.customizationService.getCertifiedTestSuiteWatch();
    this.selectedAirpodsTests = this.customizationService.getCertifiedTestSuiteAirpods();
    this.currentTestPlan = {
      ...this.selectedDeviceTests,
      ...this.selectedWatchTests,
      ...this.selectedAirpodsTests,
    }
  }

  private initializeTestPlan(testPlan: ProfileTestPlan) {
    this.currentTestPlan = testPlan;

    const {
      planType, both, android, apple,
      watchPlanType, watchTests,
      airpodsPlanType, airpodsTests
    } = testPlan;

    this.selectedDeviceTests = {
      planType, both, apple, android,
    };
    this.selectedWatchTests = {
      watchPlanType, watchTests,
    };
    this.selectedAirpodsTests = {
      airpodsPlanType, airpodsTests,
    }
  }
  ////////////////////////////////////////////////////////
  // End Test plan related
  ////////////////////////////////////////////////////////
}
