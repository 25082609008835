<div class="container">
  <div class="header">
    IMEI Checker
  </div>
  <mat-divider></mat-divider>
  <div class="imei-container">
    <mat-tab-group class="tab-group" (selectedTabChange)="tabChange($event)" [(selectedIndex)]="tabIndex">
      <mat-tab label="Single">  </mat-tab>
      <mat-tab label="Bulk">  </mat-tab>
    </mat-tab-group>
    <div class="imei-form-container">
      <div *ngIf="!showResults">
        <div class="imei-header">
          <mat-radio-group aria-label="Select an option" [(ngModel)]="searchType" (ngModelChange)="updateSearchType()">
            <mat-radio-button value="imei">IMEI</mat-radio-button>
            <mat-radio-button value="serial">Serial Number</mat-radio-button>
          </mat-radio-group>
        </div>
        <mat-form-field appearance="outline" class="imei-form" >
          <input matInput [(ngModel)]="settings.imei" (ngModelChange)="imeiFormChanged()"/>
          <button *ngIf="settings.imei" matSuffix mat-icon-button aria-label="Clear"
                  (click)="imeiClearField()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <div class="invalid-code" *ngIf="inputDeviceCodeInvalid">Invalid code</div>

        <mat-card *ngIf="error" class="error-card">
          <mat-card-content class="warning-card">
            <mat-icon>warning</mat-icon>&nbsp;Invalid&nbsp;
            <span *ngIf="searchType === 'imei'">IMEI</span>
            <span *ngIf="searchType === 'serial'">Serial Number</span>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="reset-container" *ngIf="showResults">
        <button mat-button (click)="reset()">Check new <span *ngIf="searchType === 'imei'">IMEI</span>
          <span *ngIf="searchType === 'serial'">serial</span></button>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="lower-container" *ngIf="!showResults">
    <div class="lower-header-container">
      <div class="tac-container" *ngIf="tac.make">
        <div class="tac-header">{{tac.make}} {{tac.model}}</div>
        <div class="tac-imei">{{settings.imei}}</div>
      </div>
    </div>
    <div class="comprehensive-container">
      <mat-checkbox [(ngModel)]="settings.comprehensive.checked"
                    [disabled]="settings.comprehensive.disabled"
      (ngModelChange)="toggleComprehensive()">Most comprehensive (DHR)</mat-checkbox>
      <button mat-button (click)="checkAll()" *ngIf="searchType === 'imei'">Select All</button>
      <button mat-button (click)="uncheckAll()" *ngIf="searchType === 'imei'">Deselect All</button>
    </div>
    <div class="three-box-container">
      <div class="device-box">
        <b>All Devices</b>
        <div class="checkbox-container">
          <mat-checkbox [(ngModel)]="settings.blacklist.checked"
                        [disabled]="settings.blacklist.disabled"
                        (ngModelChange)="disableMostComprehensive()">
            GSMA Blacklist
          </mat-checkbox>
          <mat-icon>info_outline</mat-icon>
        </div>
        <div class="checkbox-container">
          <mat-checkbox [(ngModel)]="settings.usBlackListInsurance.checked"
                        [disabled]="settings.usBlackListInsurance.disabled"
                        (ngModelChange)="disableMostComprehensive()">
            Us Insurance Blacklist
          </mat-checkbox>
          <mat-icon>info_outline</mat-icon>
        </div>
        <div class="checkbox-container">
          <mat-checkbox [(ngModel)]="settings.japanese.checked"
                        [disabled]="settings.japanese.disabled"
                        (ngModelChange)="disableMostComprehensive()">
            Japanese Network Lock
          </mat-checkbox>
          <mat-icon>info_outline</mat-icon>
        </div>
        <div class="checkbox-container">
          <mat-checkbox [(ngModel)]="settings.tMobile.checked"
                        [disabled]="settings.tMobile.disabled"
                        (ngModelChange)="disableMostComprehensive()">
            T-Mobile Carrier Financial Eligibility
          </mat-checkbox>
          <mat-icon>info_outline</mat-icon>
        </div>
        <div class="checkbox-container">
          <mat-checkbox [(ngModel)]="settings.verizon.checked"
                        [disabled]="settings.verizon.disabled"
                        (ngModelChange)="disableMostComprehensive()">
            Verizon Carrier Financial Eligibility
          </mat-checkbox>
          <mat-icon>info_outline</mat-icon>
        </div>
      </div>
      <div class="apple-box">
        <b>Apple Device Options</b>
        <div class="checkbox-container">
          <mat-checkbox [(ngModel)]="settings.appleInfo.checked"
                        [disabled]="settings.appleInfo.disabled"
                        (ngModelChange)="disableMostComprehensive(); updateDeviceCheckboxes();">
            Device & Carrier Info
          </mat-checkbox>
          <mat-icon>info_outline</mat-icon>
        </div>
        <div class="checkbox-container">
          <mat-checkbox [(ngModel)]="settings.iCloud.checked"
                        [disabled]="settings.iCloud.disabled"
                        (ngModelChange)="disableMostComprehensive()">
            iCloud Lock
          </mat-checkbox>
          <mat-icon>info_outline</mat-icon>
        </div>
        <div class="checkbox-container">
          <mat-checkbox [(ngModel)]="settings.simlock.checked"
                        [disabled]="settings.simlock.disabled"
                        (ngModelChange)="disableMostComprehensive()">Simlock
          </mat-checkbox>
          <mat-icon>info_outline</mat-icon>
        </div>
      </div>
      <div class="android-box">
        <b>Android Device Options</b>
        <div class="checkbox-container">
          <mat-checkbox [(ngModel)]="settings.samsung.checked"
                        [disabled]="settings.samsung.disabled"
                        (ngModelChange)="disableMostComprehensive()">
            Samsung Device Info
          </mat-checkbox>
          <mat-icon>info_outline</mat-icon>
        </div>
        <div class="checkbox-container">
          <mat-checkbox [(ngModel)]="settings.google.checked"
                        [disabled]="settings.google.disabled"
                        (ngModelChange)="disableMostComprehensive()">
            Google Device Info
          </mat-checkbox>
          <mat-icon>info_outline</mat-icon>
        </div>
        <div class="checkbox-container">
          <mat-checkbox [(ngModel)]="settings.LG.checked"
                        [disabled]="settings.LG.disabled"
                        (ngModelChange)="disableMostComprehensive()"
          >
            LG Device Info
          </mat-checkbox>
        </div>
      </div>
    </div>
    <div class="begin-button-container">
      <div class="begin-button" (click)="begin()" *ngIf="tac.make || searchType === 'serial' && settings.imei.length">
        <mat-icon *ngIf="!loading">play_arrow</mat-icon>
        <div class="uploader-status">
          <mat-spinner diameter="20" class="mat-spinner-color" *ngIf="loading"></mat-spinner>
        </div>
        <div class="begin-button-text" *ngIf="!loading">Begin Check</div>
        <div class="begin-button-text"  *ngIf="loading">Loading Results</div>
        <div class="spacer"></div>
      </div>
    </div>
  </div>
  <div class="results-container" *ngIf="showResults">
    <div class="results-title">Results</div>
    <div class="lower-header-container">
      <div class="results-tac-container">
        <div class="results-tac">{{tac.make}} {{tac.model}}</div>
        <div class="results-tac-imei">{{settings.imei}}</div>
      </div>
      <div class="history-report-link-container" *ngIf="historyReportId">
        <button mat-button (click)="openHistoryReport()"><mat-icon>description</mat-icon>View Device History Report</button>
      </div>
    </div>
    <div class="results-overall-container good" *ngIf="!overallBad">
      <div class="results-status-title">Overall Good</div>
      <div class="results-status-subtitle">Response Positive!</div>
    </div>
    <div class="results-overall-container bad" *ngIf="overallBad">
      <div class="results-status-title">Overall Bad</div>
    </div>
    <div class="result-grid-container">
      <div class="result" *ngFor="let result of results">

        <div class="result-title-container">
          <div class="result-title">{{result.service.title}} Response</div>
          <div class="result-status-chip good" *ngIf="!result.warn">Good</div>
          <div class="result-status-chip bad" *ngIf="result.warn">Bad</div>
        </div>
        <div class="result-data" *ngIf="!result.viewRaw">
          <div class="result-key" *ngFor="let prop of $any(result.mapped) | keyvalue">
            <div *ngIf="$any(prop.value).value">{{$any(prop.value).displayName}}:
              <span [ngClass]="{ 'bad-text': ['warn'].includes($any(prop.value).status) }">{{$any(prop.value).value}}</span>
            </div>
          </div>
        </div>
        <div class="result-raw-data" *ngIf="result.viewRaw">
          <div *ngFor="let item of $any(result.result) | keyvalue">
            {{item.key}}: {{item.value}}
          </div>
<!--          {{result.result | json}}-->
        </div>
        <button mat-button *ngIf="!result.viewRaw" (click)="result.viewRaw = true">view raw</button>
        <button mat-button *ngIf="result.viewRaw" (click)="result.viewRaw = false">view keys</button>
      </div>
    </div>
  </div>
</div>
