<div class="main-container">
  <h2>Email History</h2>
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <mat-table [dataSource]="history">
    <ng-container matColumnDef="userNames">
      <mat-header-cell *matHeaderCellDef>
        Users
      </mat-header-cell>
      <mat-cell *matCellDef="let emailHistory">
        {{ emailHistory.userNames }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef>
        Status
      </mat-header-cell>
      <mat-cell *matCellDef="let emailHistory">
        {{ emailHistory.status | titlecase }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="exports">
      <mat-header-cell *matHeaderCellDef>
        Filename
      </mat-header-cell>
      <mat-cell *matCellDef="let emailHistory">
        {{ getExports(emailHistory.mac, emailHistory.device) }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="filename">
      <mat-header-cell *matHeaderCellDef>
        Filename
      </mat-header-cell>
      <mat-cell *matCellDef="let emailHistory">
        {{ emailHistory.filename }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef>
        Filename
      </mat-header-cell>
      <mat-cell *matCellDef="let emailHistory">
        {{ emailHistory.date | date: 'mediumDate' }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="time">
      <mat-header-cell *matHeaderCellDef>
        Time
      </mat-header-cell>
      <mat-cell *matCellDef="let emailHistory">
        {{ emailHistory.date | date: 'longTime' }}
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let emailHistory; columns: displayedColumns"></mat-row>
    <tr *matNoDataRow>
      <td id="empty-table-td">
        Your email history is empty.
      </td>
    </tr>
  </mat-table>
  <mat-paginator
    showFirstLastButtons
    (page)="onPaginate($event)"
    [pageSize]="paginatorData.pageSize"
    [pageSizeOptions]="paginatorData.pageOptions"
    [length]="paginatorData.length"
    ></mat-paginator>
</div>
