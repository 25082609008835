<div class="dialog">
  <div class="content">
    <mat-card class="file-header-import">
      <mat-card-content>
        <div *ngIf="hasOptions">
          <h2>Please, confirm which column to use to search</h2>
          <div class="search-option-container">
            Search:
            <mat-button-toggle-group [formControl]="lookupOptionFormControl">
              <mat-button-toggle *ngFor="let option of availableDeviceCodeOptions"
               [value]="option.option">{{option.header}}
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div *ngIf="!hasOptions">
          <h2>No available options for the type of the device selected</h2>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-flat-button class="cancelBtn" (click)="onCancel()">{{ hasOptions ? 'Cancel' : 'Return' }}</button>
        <button *ngIf="hasOptions" mat-raised-button color="primary" class="mat-button-blue"
          (click)="submit()">Next</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
