<div [hidden]="automaticEmailSettingsOpen" class="main-component-container">
  <h1>Custom Reports</h1>

  <mat-divider></mat-divider>

  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
    <mat-tab label="Device Info">
      <app-custom-reports-tab
        [openSettingsEmitter]="openSettingsEmitter"
        [deviceInfoService]="deviceInfoService"
        ></app-custom-reports-tab>
    </mat-tab>
    <mat-tab label="Mac Devices">
      <app-custom-reports-tab
        [openSettingsEmitter]="openSettingsEmitter"
        [deviceInfoService]="macInfoService"
        ></app-custom-reports-tab>
    </mat-tab>
    <mat-tab label="Email History">
      <app-email-history></app-email-history>
    </mat-tab>
  </mat-tab-group>
</div>
<div *ngIf="automaticEmailSettingsOpen">
  <app-automatic-email-settings-list
    [automaticSettingsOpenCloseStatusEmitter]="openSettingsEmitter"
    ></app-automatic-email-settings-list>
</div>
