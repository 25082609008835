<div id="testPlanFormContainer" fxLayout="column">
  <form>
    <mat-form-field id="testSearchInput">
      <mat-label>Test search</mat-label>
      <input matInput class="search-input" [formControl]="searchInput" type="search" autocomplete="one-time-code">
    </mat-form-field>
  </form>

  <ng-container *ngIf="isSectionModeActive(); else listModeView">
    <div fxLayout="row">
      <mat-button-toggle-group vertical name="sections" [value]="getDefaultSection()">
        <mat-button-toggle *ngFor="let category of getTestCategories()" [value]="category"
          (click)="onSectionClick(category)">
          {{ category.displayName }}
        </mat-button-toggle>
      </mat-button-toggle-group>

      <div class="category-table">
        <p class="empty-parent-tests" *ngIf="getActiveSectionTests().length === 0">No tests in this section</p>
        <ul *ngIf="getActiveSectionTests().length > 0">
          <li *ngFor="let masterTest of getActiveSectionTests()">
            <div fxLayout="row">
              <mat-checkbox [formControl]="getMasterTestFormControl(masterTest.id)"
                [indeterminate]="masterTestIndeterminate(masterTest)"
                (change)="setAll(masterTest, $event.checked)"></mat-checkbox>
              <mat-expansion-panel [expanded]="openExpandable(masterTest)">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <span class="device-test">{{ masterTest.displayName }}</span>
                    <span *ngIf="isMasterTestDefault(masterTest)" class="badge">Default</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <table [hidden]="!masterTest.tests || masterTest.tests.length === 0">
                  <tr *ngFor="let test of masterTest.tests" matTooltip="{{ test.description }}"
                    [matTooltipShowDelay]="250" [matTooltipHideDelay]="250">
                    <td class="device-test">
                      <mat-checkbox [formControl]="getTestFormControl(test.id)">
                        {{ test.displayName }}
                      </mat-checkbox>
                    <td>
                      <div *ngIf="test.requiresParameter">
                        <ng-container *ngIf="displayTextInput(test); else selectTemplate">
                          <mat-form-field>
                            <mat-label>Value</mat-label>
                            <input class="parameter-input" matInput [formControl]="getTestParameterFormControl(test.id)"
                              type="text">
                          </mat-form-field>
                        </ng-container>
                        <ng-template #selectTemplate>
                          <mat-select class="parameter-input" [formControl]="getTestParameterFormControl(test.id)">
                            <mat-option *ngFor="let value of test.values" value="{{value.value}}">{{ value.displayValue
                              }}</mat-option>
                          </mat-select>
                        </ng-template>
                      </div>
                    </td>
                  </tr>
                </table>
              </mat-expansion-panel>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </ng-container>
  <ng-template #listModeView>
    <ul id="testListContainer">
      <li *ngFor="let masterTest of getDisplayedMasterTests()">
        <div fxLayout="row">
          <mat-checkbox [formControl]="getMasterTestFormControl(masterTest.id)"></mat-checkbox>
          <mat-expansion-panel [expanded]="openExpandable(masterTest)">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="device-test">{{ masterTest.displayName }}</span>
                <span *ngIf="isMasterTestDefault(masterTest)" class="badge">Default</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <table [hidden]="!masterTest.tests || masterTest.tests.length === 0">
              <tr *ngFor="let test of masterTest.tests" matTooltip="{{ test.description }}" [matTooltipShowDelay]="250"
                [matTooltipHideDelay]="250">
                <td class="device-test">
                  <mat-checkbox [formControl]="getTestFormControl(test.id)">
                    {{ test.displayName }}
                  </mat-checkbox>
                <td>
                  <div *ngIf="test.requiresParameter">
                    <ng-container *ngIf="displayTextInput(test); else selectTemplate">
                      <mat-form-field>
                        <mat-label>Value</mat-label>
                        <input class="parameter-input" matInput [formControl]="getTestParameterFormControl(test.id)"
                          type="text">
                      </mat-form-field>
                    </ng-container>
                    <ng-template #selectTemplate>
                      <mat-select class="parameter-input" [formControl]="getTestParameterFormControl(test.id)">
                        <mat-option *ngFor="let value of test.values" value="{{value.value}}">{{ value.displayValue
                          }}</mat-option>
                      </mat-select>
                    </ng-template>
                  </div>
                </td>
              </tr>
            </table>
          </mat-expansion-panel>
        </div>
      </li>
    </ul>
  </ng-template>
</div>
