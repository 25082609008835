<mat-progress-spinner *ngIf="loadingWarehouses"></mat-progress-spinner>
<div *ngIf="!loadingWarehouses">
  <table class="filter-group">
    <tr>
      <td>
        <div style="display: flex;">
          <button mat-list-item (click)="backToMainPage()" style="flex: 0 1 5em; border: none; cursor: pointer;">
            <mat-icon>arrow_back</mat-icon>
          </button>
          <mat-form-field subscriptSizing="dynamic" class="filter-input" style="flex: 1 1 5em;">
            <mat-label>Warehouse</mat-label>
            <mat-select (selectionChange)="onWarehouseChange($event)" value="all" placeholder="All warehouses">
              <mat-option value='all'>All warehouses</mat-option>
              <mat-option [value]="warehouse.id" *ngFor="let warehouse of warehouses">
                {{ warehouse.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </td>
      <td class="bulk-profile-container end-aligned-container">
        <mat-form-field class="filter-input" subscriptSizing="dynamic">
          <mat-label>Search stations</mat-label>
          <input matInput [formControl]="searchStationFormControl">
        </mat-form-field>
        <mat-form-field class="filter-input" subscriptSizing="dynamic">
          <mat-label>Assigned selected</mat-label>
          <mat-select [formControl]="bulkProfileFormControl">
            <mat-option *ngFor="let profile of profiles" [value]="profile.id">{{ profile.name }}</mat-option>
          </mat-select>
        </mat-form-field>
        <button class="filter-input" (click)="onSave()" [disabled]="saving || !showAssignedSelected()" color="primary"
          mat-button mat-raised-button>Save</button>
      </td>
    </tr>
  </table>
  <div class="card">
    <table class="assigment-table">
      <tr class="table-header">
        <td><mat-checkbox (change)="checkAllStations($event.checked)"></mat-checkbox></td>
        <td>Station</td>
        <td>Warehouse</td>
        <td>Profile Assignment</td>
      </tr>
      <tr *ngFor="let station of stationsToShow" class="station-assigment">
        <td><mat-checkbox [formControl]="stationsCheckFormControls[station.id]"></mat-checkbox></td>
        <td>{{ station.name }}</td>
        <td>{{ station.warehouse.name }}</td>
        <td>
          <mat-form-field class="station-profile-input" subscriptSizing="dynamic">
            <mat-select [formControl]="stationsProfileFormControls[station.id]" placeholder="Select a profile">
              <mat-option *ngFor="let profile of profiles" [value]="profile.id">{{ profile.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </tr>
    </table>
  </div>
</div>
