import { OnInit, OnDestroy, Output, EventEmitter, Input, ViewChild, Component, ViewChildren } from '@angular/core';
import { CustomizationService } from '../services/customization.service';
import { FormControl } from '@angular/forms';
import { TestPlanFormComponent } from '../test-plan-form/test-plan-form.component';
import { DeviceTestCategory, SelectedDeviceTest, DeviceTestPlan, DeviceTest, ProfileTestPlan, WatchTestPlan, AirpodsTestPlan, PartialTestPlan } from '../interfaces/deviceTest.interfaces';

@Component({
  selector: 'app-test-plan',
  templateUrl: './test-plan.component.html',
  styleUrls: ['./test-plan.component.css']
})
export class TestPlanComponent implements OnInit {
  @Input() selectedDeviceTests!: Partial<ProfileTestPlan> | undefined;
  @Input() deviceType: 'device' | 'airpods' | 'watch' = 'device';
  @Input() skipUncheckingDefaultTestDialog: boolean = false;
  @Output() updateDeviceEmitter = new EventEmitter<DeviceTestPlan>();
  @Output() updateWatchEmitter = new EventEmitter<WatchTestPlan>();
  @Output() updateAirpodsEmitter = new EventEmitter<AirpodsTestPlan>();
  @Output() onSkipDefaultTestDialog = new EventEmitter<boolean>();

  @ViewChild('global') globalPlanComponent!: TestPlanFormComponent;
  @ViewChild('apple')  applePlanComponent!: TestPlanFormComponent;
  @ViewChild('android') androidPlanComponent!: TestPlanFormComponent;

  selectedGlobalDeviceTests!: SelectedDeviceTest[] | undefined;
  selectedAppleDeviceTests!: SelectedDeviceTest[] | undefined;
  selectedAndroidDeviceTests!: SelectedDeviceTest[] | undefined;

  public tests!: Record<string, DeviceTestCategory>;
  private certifiedTestSuite!: SelectedDeviceTest[];
  public planSelected = new FormControl('certified-test-suite');
  public globalPlanEnabled = new FormControl(true);

  constructor(
    private customizationService: CustomizationService,
  ) {}

  public ngOnInit() {
    this.customizationService.getTestPlanSkeleton().then((skeleton: DeviceTestCategory[] | undefined) => {
      if (skeleton) {
        this.tests = this.testListToObject(skeleton);
        this.certifiedTestSuite = this.getCertifiedTestSuite(skeleton);
      }
    });
    this.initialize(this.selectedDeviceTests);
  }

  public initialize(selectedDeviceTests: Partial<ProfileTestPlan> | undefined) {
    this.selectedDeviceTests = selectedDeviceTests;

    if (this.selectedDeviceTests !== undefined) {
      let {
        planType, both, apple, android,
        watchPlanType, watchTests,
        airpodsPlanType, airpodsTests,
      } = this.selectedDeviceTests;

      let pType = planType;

      switch (this.deviceType) {
        case 'watch':
          pType = watchPlanType;
          this.selectedGlobalDeviceTests = watchTests;
          break;
        case 'airpods':
          pType = airpodsPlanType;
          this.selectedGlobalDeviceTests = airpodsTests;
          break;
        default:
          pType = planType;
          this.selectedGlobalDeviceTests = both;
          this.selectedAppleDeviceTests = apple;
          this.selectedAndroidDeviceTests = android;
      }

      this.planSelected.setValue(pType || null, { emitEvent: false });
      this.globalPlanEnabled.setValue(!!both || this.deviceType !== 'device', { emitEvent: false });
    }
  }

  public ngOnDestroy() {
    switch(this.deviceType) {
      case 'watch':
        this.updateWatchEmitter.emit(this.getData() as WatchTestPlan);
        break;
      case 'airpods':
        this.updateAirpodsEmitter.emit(this.getData() as AirpodsTestPlan);
        break;
      case 'device':
        this.updateDeviceEmitter.emit(this.getData() as DeviceTestPlan);
    }
  }

  private testListToObject(testSkeleton: DeviceTestCategory[]): Record<string, DeviceTestCategory> {
    const obj: Record<string, DeviceTestCategory> = {};

    testSkeleton.forEach(t => {
      obj[t.id] = t;
    });

    return obj;
  }

  private getCertifiedTestSuite(testSections: DeviceTestCategory[]): SelectedDeviceTest[] {
    const certifiedTestSuite: SelectedDeviceTest[] = [];
    const getDefaultValue = (test: DeviceTest): string | undefined => {
      const defaultValue = test.values.find(v => {
        return v.isDefault;
      });

      if (defaultValue) {
        return defaultValue.value;
      }

      if (test.values.length > 0) {
        return test.values[0].value;
      }

      return undefined;
    }

    testSections.forEach(section => {
      section.masterTests.forEach(masterTest => {
        if (masterTest.isDefault) {
          masterTest.tests.filter(test => this.testFilter(test)).forEach(t => {
            const selectedTest = {
              id: t.id,
              key: t.key,
              displayName: t.displayName,
              requiresParameter: t.requiresParameter,
              description: t.description,
              platform: t.platform,
              inputValue: t.requiresParameter ? getDefaultValue(t) : undefined,
            };

            certifiedTestSuite.push(selectedTest);
          });
        }
      });
    });

    return certifiedTestSuite;
  }

  public isGlobalPlanEnabled(): boolean {
    return this.deviceType !== 'device' || !!this.globalPlanEnabled.getRawValue();
  }

  public isReady(): boolean {
    return !!this.tests;
  }

  public isCustomTestPlanSelected(): boolean {
    return this.planSelected.getRawValue() === 'custom-test-plan';
  }

  public getData(): DeviceTestPlan | WatchTestPlan | AirpodsTestPlan {
    const CERTIFIED_TEST_SUITE = 'certified-test-suite';
    const CUSTOM_TEST_PLAN = 'custom-test-plan';
    const certifiedTestSuite = !this.isCustomTestPlanSelected();
    const watchCertified: WatchTestPlan = {
      watchPlanType: CERTIFIED_TEST_SUITE,
      watchTests: this.certifiedTestSuite,
    };
    const airpodsCertified: AirpodsTestPlan = {
      airpodsPlanType: CERTIFIED_TEST_SUITE,
      airpodsTests: this.certifiedTestSuite,
    };
    const deviceCertified: DeviceTestPlan = {
      planType: CERTIFIED_TEST_SUITE,
      both: this.certifiedTestSuite,
    };

    if (certifiedTestSuite && this.deviceType === 'device') {
      return deviceCertified;
    }

    if (certifiedTestSuite && this.deviceType === 'watch') {
      return watchCertified;
    }

    if (certifiedTestSuite && this.deviceType === 'airpods') {
      return airpodsCertified;
    }

    if (this.deviceType === 'watch') {
      return {
        watchPlanType: CUSTOM_TEST_PLAN,
        watchTests: this.globalPlanComponent.getData()
      }
    }

    if (this.deviceType === 'airpods') {
      return {
        airpodsPlanType: CUSTOM_TEST_PLAN,
        airpodsTests: this.globalPlanComponent.getData()
      }
    }

    if (this.isGlobalPlanEnabled()) {
      return {
        planType: CUSTOM_TEST_PLAN,
        both: this.globalPlanComponent.getData(),
        android: undefined, // This undefined is important, so it deletes a possible previous android test plan
        apple: undefined, // Same as above
      }
    }

    return {
      planType: CUSTOM_TEST_PLAN,
      android: this.androidPlanComponent.getData(),
      apple: this.applePlanComponent.getData(),
      both: undefined, // Important. Same as previous comments
    }
  }

  public setGlobalSelectedDeviceTests(selectedTests: any): void {
    this.selectedGlobalDeviceTests = selectedTests;
  }

  public setAppleSelectedDeviceTests(selectedTests: any): void {
    this.selectedAppleDeviceTests = selectedTests;
  }

  public setAndroidSelectedDeviceTests(selectedTests: any): void {
    this.selectedAndroidDeviceTests = selectedTests;
  }

  public deviceFilter(test: DeviceTest): boolean {
    const testPlatform = test.platform.toLowerCase();

    return testPlatform === 'both' || testPlatform === 'android'|| testPlatform === 'ios';
  }

  public watchFilter(test: DeviceTest): boolean {
    const testPlatform = test.platform.toLowerCase();

    return testPlatform === 'watch';
  }

  public airpodsFilter(test: DeviceTest): boolean {
    const testPlatform = test.platform.toLowerCase();

    return testPlatform === 'airpods';
  }

  public androidFilter(test: DeviceTest): boolean {
    const testPlatform = test.platform.toLowerCase();

    return testPlatform === 'android' || testPlatform === 'both';
  }

  public appleFilter(test: DeviceTest): boolean {
    const testPlatform = test.platform.toLowerCase();

    return testPlatform === 'ios' || testPlatform === 'both';
  }

  public testFilter(test: DeviceTest): boolean {
    switch(this.deviceType) {
      case 'device' : return this.deviceFilter(test);
      case 'watch' : return this.watchFilter(test);
      case 'airpods' : return this.airpodsFilter(test);
    }

    return this.deviceFilter(test);
  }

  public getPlatform() {
    switch(this.deviceType) {
      case 'device' : return 'both';
      case 'watch'  : return 'watch';
      case 'airpods': return 'airpods';
    }

    return 'both';
  }

  public setUncheckingDefaultTestDialog(v: boolean): void {
    this.skipUncheckingDefaultTestDialog = v;
    this.onSkipDefaultTestDialog.emit(v);
  }
}
