import * as XLSX from 'xlsx';
import { Papa } from 'ngx-papaparse';
import { debounceTime } from 'rxjs';
import { OnDestroy, ViewChild, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CdkDragDrop, CdkDragStart, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { DeviceInfoService } from '../services/deviceInfo.service';
import { Page } from '../interfaces/page.interface';
import { DateOption, toDateString, DateRange } from '../common/date';
import { MatDialog } from '@angular/material/dialog';
import { CreateDeviceInfoFilterComponent } from '../dialogs/create-device-info-filter/create-device-info-filter.component';
import { DeviceInfoColumnConfigurationComponent } from '../dialogs/device-info-column-configuration/device-info-column-configuration.component';
import { ColumnConfiguration, ColumnSettings } from '../interfaces/columnSettings.interface';
import { NotificationService } from '../services/notification.service';
import { MatPaginator } from '@angular/material/paginator';
import { DEVICE_INFO_COLUMNS } from '../device-info/columns';
import { JSONViewerComponent } from '../dialogs/json-viewer/json-viewer.component';
import { PaginatorData } from '../interfaces/paginatorData.interface';
import { DeviceInfo } from '../interfaces/deviceInfo.interface';
import { DeviceLookupQueryParams } from '../interfaces/deviceLookupQueryParams.interface';
import { DeviceLookupService } from '../services/deviceLookup.service';
import { LookupOption, LookupOptionHeader } from '../common/lookup-options.enum';
import moment, { Moment } from 'moment-timezone';
import { DeviceLookupContext } from '../interfaces/deviceLookup.interface';
import { ToggleMenuService } from '../services/toggle-menu.service';
import { AuthService } from '../services/auth.service';
import { DeviceInfoDatatableComponent } from '../device-info-datatable/device-info-datatable.component';
import { MacDeviceInfoService } from '../services/macDeviceInfo.service';
import { MacDeviceInfoDatatableComponent } from '../mac-device-info-datatable/mac-device-info-datatable.component';
import { DownloadFormat } from '../common/download';
import { DeviceCodeSelectorComponent } from "../dialogs/device-code-selector/device-code-selector.component";
import { getJsonFromXlsxAsync } from "../common/excel";
import { TemplateService } from "../services/template.service";
import { MAC_DEVICE_INFO_COLUMNS } from "../common/mac/columns";
import { MasterService } from '../services/master.service';

export interface BulkFile {
  name: string;
  extension: string;
  file: File;
}

enum SingleDeviceConf {
  LATEST = 'latest',
  ALL = 'all'
}
@Component({
  selector: 'app-device-lookup',
  templateUrl: './device-lookup.component.html',
  styleUrls: ['./device-lookup.component.css']
})
export class DeviceLookupComponent implements OnDestroy {
  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild(DeviceInfoDatatableComponent) table!: DeviceInfoDatatableComponent;
  @ViewChild(MacDeviceInfoDatatableComponent) macTable!: MacDeviceInfoDatatableComponent;

  public showLatest: boolean = true;
  public csvData: string = '';
  private readonly INPUT_FIELD_DEBOUNCE_TIME = 300;
  DISABLED_COLUMN_FILTERS = [
    'links',
  ];
  recordsToShow: FormControl<SingleDeviceConf | null> = new FormControl<SingleDeviceConf>(SingleDeviceConf.LATEST);
  isMacSelected: boolean = false;
  deviceTypeFormControl: FormControl<'mac' | 'deviceInfo' | null> = new FormControl<'mac' | 'deviceInfo'>('deviceInfo');
  lookupPerformed: boolean = false;
  copyingToClipboard: boolean = false;
  allColumns: ColumnConfiguration[] = DEVICE_INFO_COLUMNS;
  macAllColumns: ColumnConfiguration[] = MAC_DEVICE_INFO_COLUMNS;

  keywordFormControl: FormControl<string | null>;
  lookupOptionFormControl: FormControl<LookupOption | null>;

  selectedMacFilter: FormControl<string | null> = new FormControl<string>('default');
  selectedDeviceFilter: FormControl<string | null> = new FormControl<string>('default');

  constructor(
    private deviceLookupService: DeviceLookupService,
    private dialog: MatDialog,
    private notify: NotificationService,
    private headerMenuService: ToggleMenuService,
    private authService: AuthService,
    private macDeviceInfoService: MacDeviceInfoService,
    private csvParser: Papa,
    private deviceInfoService: DeviceInfoService,
    private templateService: TemplateService,
    private masterService: MasterService,
  ) {
    this.keywordFormControl = new FormControl(null);
    this.lookupOptionFormControl = new FormControl<LookupOption>(LookupOption.IMEI);
    this.authService.allMasterContextEmitter.asObservable().subscribe(allMasterContext => {
      this.refresh();
    });
    this.authService.masterSelectedEmitter.asObservable().subscribe(master => {
      this.refresh();
    });

    this.authService.warehouseSelectedEmitter.asObservable().subscribe(warehouse => {
      this.refresh();
    });
    this.deviceTypeFormControl.valueChanges.subscribe(value => {
      const macSelected = value === 'mac';
      const option = macSelected
        ? LookupOption.SERIAL
        : LookupOption.IMEI;

      this.isMacSelected = macSelected;
      this.lookupOptionFormControl.setValue(option, { emitEvent: false });
    });

    this.selectedDeviceFilter.valueChanges.subscribe(filterId => {
      const filter = this.deviceFilters.find(f => f.id === filterId);

      if (filter) {
        this.table.setAllColumns(filter.id, filter.visible);
      }
    });

    this.selectedMacFilter.valueChanges.subscribe(filterId => {
      const filter = this.macFilters.find(f => f.id === filterId);

      if (filter) {
        this.macTable.setAllColumns(filter.id, filter.visible);
      }
    });
  }

  private refresh(): void {
    this.headerMenuService.disableSeeAllMasterDataCheckbox();

    this.lookupPerformed = false;
    this.table.clear();
    this.macTable.clear();
  }

  private getSearchTerm(): string | null {
    return this.keywordFormControl.getRawValue();
  }

  public gradingDataExport: boolean = false;

  public ngOnInit(): void {
    this.getDeviceInfoColumns();
    this.getFilters();
    this.headerMenuService.allowUseAllMasterData(true);

    if (this.authService.isMasterSelected()) {
      this.masterService.getMasterSettings()
        .then(settings => {
          this.gradingDataExport = !!settings?.gradingDataExport;
        });
    }
  }

  private async getDeviceInfo(
    option: LookupOption,
    codes: string[],
  ) {
    try {
      const option = this.lookupOptionFormControl.getRawValue() || LookupOption.IMEI;
      const latest = this.showLatest;

      if (codes.length === 0) {
        this.notify.warn("The list of codes is empty");

        return;
      }

      this.lookupPerformed = true;

      if (this.isMacSelected) {
        this.macTable.onParamsChange({ codes: codes, option, latest: this.showLatest });
      } else {
        this.table.getBulkDeviceInfo(option, codes, this.showLatest);
      }

    } catch (err) {
      console.error(err);
    }
  }

  public openJSONViewer(field: string, data: object) {
    const jsonViewer = this.dialog.open(JSONViewerComponent, {
      data: {
        json: data,
        field,
      },
      width: '33vw',
      maxHeight: '90vh',
    });
  }

  public isObject(o: any) {
    return o !== null && typeof o === 'object';
  }

  macFilters: any[] = [];
  deviceFilters: any[] = [];

  private async getFilters() {
    const deviceFiltersPromise = this.deviceInfoService.getDeviceInfoColumnSettings();
    const macFiltersPromise = this.macDeviceInfoService.getDeviceInfoColumnSettings();

    const [deviceFilters, macFilters] = await Promise.all([deviceFiltersPromise, macFiltersPromise]);

    this.deviceFilters = deviceFilters;
    this.macFilters = macFilters;

  }

  private async getDeviceInfoColumns() {
    const devicePromise = this.deviceLookupService.getDeviceLookupColumns();
    const macPromise = this.macDeviceInfoService.getMacDeviceDefaultColumns();

    const [columns, macColumns] = await Promise.all([devicePromise, macPromise]);

    this.macAllColumns = macColumns;
    this.allColumns = columns;

    this.table.setAllColumns('default', columns);
    this.macTable.setAllColumns('default', macColumns);
  }

  public disabledColumnFilter(field: string): boolean {
    return this.DISABLED_COLUMN_FILTERS.includes(field);
  }

  public isLinksValue(value: any): boolean {
    return !!(value?.dhrLink);
  }

  public hasDHRLink(value: any) {
    return !!(value?.dhrLink);
  }

  public hasA4ReportLink(value: any) {
    return !!(value?.a4ReportLink);
  }

  public hasErasureReportLink(value: any) {
    return !!(value?.erasureReportLink);
  }

  public hasSCRReportLink(value: any) {
    return !!(value?.scrReportLink);
  }

  public openLink(url: string): void {
    window.open(url, '_blank');
  }

  public clear() {
    this.table.clear();
    this.macTable.clear();
  }

  public onCopyToClipboard(): void {
    this.copyingToClipboard = true;

    try {
      const context = this.table.getContext();
      this.deviceLookupService.copyToClipboard(context);
    } catch (err) {
      console.warn(err);
    }
    this.copyingToClipboard = false;
  }

  public onGradingDataExport() {
    return this.onDownload(DownloadFormat.EXCEL, true);
  }

  private onDownload(format: DownloadFormat, gradingDataExportOnly: boolean = false) {
    try {
      if (this.isMacSelected) {
        this.macTable.downloadBulkLookup(format, 'default', gradingDataExportOnly);
      } else {
        const context = this.table.getContext();

        this.deviceLookupService.downloadBulkLookup(context, format, gradingDataExportOnly);
      }
    } catch (err) {
      console.warn(err);
    }
  }

  public onDownloadExcel(): void {
    this.onDownload(DownloadFormat.EXCEL);
  }

  public onDownloadCSV(): void {
    this.onDownload(DownloadFormat.CSV);
  }

  public ngOnDestroy(): void {
    this.headerMenuService.allowUseAllMasterData(false);
  }

  public getCode(): string | null {
    return this.keywordFormControl.getRawValue();
  }

  public getOption(): LookupOption | null {
    return this.lookupOptionFormControl.getRawValue();
  }

  public readCsvFile(filename: string, file: Blob): void {
    this.csvParser.parse(file, {
      header: true,
      complete: (async results => {
        const { data, errors, meta } = results;

        if (errors.length) {
        }

        const {
          aborted: _aborted,
          delimiter: _delimiter,
          fields,
          linebreak: _linebreak,
          truncated: _truncated,
        } = meta;
        const codeData = await this.getData(filename, fields, data);

        if (codeData) {
          this.getDeviceInfo(codeData.option, codeData.codes);
        }
      }),
    });
  }

  private getLookupOption(header: string): LookupOption | undefined {
    if (/^imei$/i.test(header)) return LookupOption.IMEI;
    if (/^imei2$/i.test(header)) return LookupOption.IMEI2;
    if (/^lpn$/i.test(header)) return LookupOption.LPN;
    if (/^serial$/i.test(header)) return LookupOption.SERIAL;
    if (/^custom\s*1$/i.test(header)) return LookupOption.CUSTOM1;

    return undefined;
  }


  public getLookupOptionsFromHeaders(headers: string[]): LookupOptionHeader[] {
    const available: LookupOptionHeader[] = [];
    const isMacSupported = (option: LookupOption) => {
      return [LookupOption.SERIAL, LookupOption.LPN].includes(option);
    };

    headers.forEach((header: string, i: number) => {
      const option = this.getLookupOption(header);

      if (option) available.push({ option, index: i, header, macSupported: isMacSupported(option)});
    });

    return available;
  }
  public async confirmColumn(filename: string, available: LookupOptionHeader[]): Promise<LookupOptionHeader | undefined> {
    available = available.filter(a => !this.isMacSelected || a.macSupported);

    return this.dialog.open(DeviceCodeSelectorComponent, {
      data: {
        options: available,
        filename,
      },
    }).afterClosed().toPromise();
  }

  private async getData(filename: string, headers: string[], fileData: any): Promise<{option: LookupOption, codes: string[]} | undefined> {
    const available = this.getLookupOptionsFromHeaders(headers);

    let selected: LookupOptionHeader | undefined;

    if (available.length > 1) {
      selected = await this.confirmColumn(filename, available);
    } else if (available.length === 1) {
      selected = available[0];
    } else {
      this.notify.warn('No column with device codes');
    }

    if (selected) {
      const option = selected!.option;
      const codes = fileData
        .map((a: any) => String(a[selected!.header]))
        .map((a: string) => a.trim())
        .filter((a: string) => a.length > 0);

      return { option, codes };
    } else {
      return Promise.resolve(undefined);
    }
  }

  public async readExcelFile(filaname:string, file: Blob): Promise<void> {
    let fileData;

    try {
      fileData = await getJsonFromXlsxAsync(file);
    } catch (err) {
      this.notify.warn('The file is empty');

      return;
    }

    const [firstData] = fileData as Array<object>;

    if (!firstData) {
      this.notify.warn('The file is empty');

      return;
    }
    const keys = Object.keys(firstData);
    const data = await this.getData(filaname, keys, fileData);

    if (data) {
      this.getDeviceInfo(data.option, data.codes);
    }
  }

  public onFileSelected(event: any) {
    const VALID_TYPES = [
      'text/csv',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];
    const [file] = event.target.files;

    if (!file || !(VALID_TYPES.includes(file.type))) {
      this.notify.warn('Invalid file type.');

      return;
    }

    const extension: string = file.name.substr(file.name.lastIndexOf('.') + 1);
    const filename = file.name;

    if (extension === 'csv') {
      this.readCsvFile(filename, file);
    }
    else {
      this.readExcelFile(filename, file);
    }
  }

  private getGenericFilename(): string {
    return 'device_codes';
  }

  public search() {
    this.onSubmitTextArea();
  }

  private getDefaultLookupOption(): LookupOption {
    return this.isMacSelected ? LookupOption.SERIAL : LookupOption.IMEI;
  }

  public onSubmitTextArea() {
    const option = this.lookupOptionFormControl.getRawValue() || this.getDefaultLookupOption();
    const codes = this.getCodesFromText();

    if (codes.length === 0) {
      this.notify.warn("The list of codes is empty");

      return;
    }

    this.lookupPerformed = true;

    if (this.isMacSelected) {
      this.macTable.getBulkDeviceInfo(option, codes, this.showLatest);
    } else {
      this.table.getBulkDeviceInfo(option, codes, this.showLatest);
    }
    this.lookupOptionFormControl.setValue(this.getDefaultLookupOption(), { emitEvent: false });
    this.csvData = '';
  }

  public getCodesFromText() {
    return this.csvData.split(/[\s,]+/)
      .map(a => a.trim())
      .filter(a => !!a);
  }

  public downloadTemplate() {
    this.templateService.downloadTemplate();
  }
}


