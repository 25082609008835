import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatArray'
})
export class FormatArrayPipe implements PipeTransform {
  transform(value: any, column: any): string {
    const GRADING_QUESTIONS_COLUMNS = ['questionAnswer', 'gradingQuestionAnswer'];

    if (Array.isArray(value)) {
      return this.capitalizeWords(value.join(', '));
    } else if (typeof value === 'string') {
      try {
        const arrayValue = JSON.parse(value);
        if (Array.isArray(arrayValue)) {
          if (GRADING_QUESTIONS_COLUMNS.includes(column)) {
            return 'View Grading Questions'
          }
          return this.capitalizeWords(arrayValue.join(', '));
        }
      } catch (error) {
        console.error('FormatArrayPipe: Error parsing string as JSON array:', value);
      }

      // If it's a string enclosed in brackets, remove brackets and return
      const trimmedValue = value.trim();
      if (trimmedValue.startsWith('[') && trimmedValue.endsWith(']')) {
        return this.capitalizeWords(trimmedValue.slice(1, -1));
      }

      // If it's a string, remove double quotes and return
      return this.capitalizeWords(trimmedValue.replace(/"/g, ''));
    } else if (typeof value === 'object'){
      if(column === 'cosmeticsInfo'){
        return 'View Cosmetics';
      }
      return value ? value.status : '';
    }

    // If value is of type 'never', return an empty string
    return '';
  }

  private capitalizeWords(value: string): string {
    return value.replace(/\b\w/g, match => match.toUpperCase());
  }
}
