import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NotificationService} from './notification.service';
import { environment } from '../../environments/environment';
import { Observable, Subscribable } from 'rxjs';

export interface DashData {
  currentRunningTotal: number,
  deviceResults: any,
  licenses: any,
  licenseCounts: number,
  working: number,
  failed: number,
  failedPercentage: number,
  deviceCount: number,
  deviceBreakdown: any,
  chartLicenses: number[],
  chartDevices: number[],
  labels: string[],
}

@Injectable({
  providedIn: 'root'
})
export class DashService {

  constructor(
    private http: HttpClient,
    private notify: NotificationService
  ) {}

  getDashData(dateStart: string, dateEnd: string) {

      const url = `${environment.apiUrl}/dash`;

      return this.http.get(url, {params: {dateStart, dateEnd}});
  }
}
