<div id="cloud-view-container" fxLayout="column">
  <div id="filter-container" fxLayout="row">
      <div id="left">
        <mat-form-field>
          <mat-label>Date</mat-label>
          <mat-select [formControl]="dateOptionFormControl">
            <mat-option value="TODAY" selected>Today</mat-option>
            <mat-option value="YESTERDAY" >Yesterday</mat-option>
            <mat-option value="LAST_7_DAYS">Last 7 days</mat-option>
            <mat-option value="LAST_30_DAYS">Last 30 days</mat-option>
            <mat-option value="THIS_MONTH">This Month</mat-option>
            <mat-option value="LAST_MONTH">Last Month</mat-option>
            <mat-option value="CUSTOM_DATE_RANGE">Custom Date Range</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field >
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [disabled]="!datePickerEnabled()" [rangePicker]="picker">
            <input matStartDate (blur)="onDateRangeChanged()" [(ngModel)]="startDate" placeholder="Start">
            <input matEndDate (blur)="onDateRangeChanged()" [(ngModel)]="endDate" placeholder="End">
          </mat-date-range-input>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker (closed)="onDateRangeChanged()"></mat-date-range-picker>
        </mat-form-field>
        <mat-form-field class="filter-input">
          <mat-label>Search devices</mat-label>
          <input matInput [formControl]="keywordFormControl" type="search">
        </mat-form-field>
        <button mat-icon-button (click)="onClearFilter()" title="Clear filter"><mat-icon>refresh</mat-icon></button>
      </div>
      <div id="right" fxLayout="row" fxLayoutAlign="end">
        <mat-form-field>
          <mat-label>Select view filter</mat-label>
          <mat-select [formControl]="selectedFilterFormControl">
            <mat-option *ngFor="let filter of filters" value="{{ filter.id }}">{{ filter.name }}</mat-option>
          </mat-select>
        </mat-form-field>

        <button mat-icon-button (click)="saveFilter()" title="Save filter"><mat-icon>save</mat-icon></button>
        <button mat-icon-button (click)="configureColumns()" title="Column settings"><mat-icon>settings</mat-icon></button>
      </div>
  </div>
  <div class="button-row" fxLayout="column">
    <mat-progress-bar mode="indeterminate" *ngIf="filesLoading"></mat-progress-bar>
    <div fxLayout="row">
      <button mat-raised-button (click)="onDownloadExcel()" title="Download Excel">Excel</button>
      <button mat-raised-button (click)="onDownloadCSV()" title="Download CSV">CSV</button>
      <button mat-raised-button (click)="onCopy()" [disabled]="copyingToClipboard" title="Copy">Copy</button>
      <button mat-raised-button *ngIf="accountSettings?.gradingDataExport" (click)="onGradingDataExport()" [disabled]="copyingToClipboard" title="Grading Data Export">Grading Data Export</button>
    </div>
  </div>
  <div id="table-container" class="card">
    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    <mat-table
      id="cloud-table"
      cdkDropListGroup
      cdkDropList
      cdkDropListOrientation="horizontal"
      (cdkDropListDropped)="dropListDropped($event)"
      [dataSource]="deviceInfoList"
      matSort
      (matSortChange)="onSortChange($event)"
      matSortActive="updatedDate"
      matSortDirection="desc"
      >
      <ng-container *ngFor="let column of columns; let i = index" [matColumnDef]="column.field">
        <mat-header-cell
          mat-sort-header
          *matHeaderCellDef
          cdkDrag
          (cdkDragStarted)="dragStarted($event, i)"
          [cdkDragData]="{ name: column.field, columnIndex: i }"
          cdkDragBoundary="#drop-area"
          class="drag-header"
          >
          <div fxLayout="column">
            <span class="column-header">{{ column.displayName }}</span>
          <input class="column-search" [formControl]="getColumnFilterFormControl(column.field)" type="search" (click)="handleInputClick($event)">
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let device">
          <ng-container *ngIf="(isObject(device[column.field]) && !isEmptyObject(device[column.field])) || isStringArray(device[column.field]); else nativeView">
            <div class="json-container" *ngIf="isObject(device[column.field]) && !gradingQuestionAnswerField(column.field)" (click)="openJSONViewer(column.displayName, device[column.field])">
              {{ device[column.field] | dataformat: column }}
            </div>
            <div class="json-container" *ngIf="gradingQuestionAnswerField(column.field)" (click)="openGradingViewer(device[column.field].gradingProfileName, device[column.field], device[column.field].gradingRouteId)">
              {{ device[column.field] | dataformat: column }}
            </div>
          </ng-container>
          <ng-template #nativeView>
            <ng-container *ngIf="column.field === 'reports'; else otherContent">
              <img src="./assets/er.svg" [ngClass]="{ 'report-img': true, 'disabled': !isErased(device) }"
              (click)="isErased(device) ? openErasureReport(device[column.field]) : null">
              <img src="./assets/dhr.svg" [ngClass]="{ 'report-img': true, 'disabled': (!device['guid']) }"
              (click)="hasDHRLink(device) ? openDHR(device['guid']) : null">
            </ng-container>
            <ng-template #otherContent>
              {{ getPublicValue(device, column.field) | dataformat: column }}
            </ng-template>
          </ng-template>
        </mat-cell>
      </ng-container>
      <mat-header-row class="column-list" id="drop-area" *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let device; columns: displayedColumns"></mat-row>
      <tr class="mat-row empty-table" *matNoDataRow >
        <td class="mat-cell" *ngIf="!loading" [attr.colspan]="displayedColumns.length">
          No results matching the filter
        </td>
      </tr>
    </mat-table>
    <mat-paginator #paginator class="mat-paginator-sticky" (page)="onPaginate($event)" [pageSize]="paginatorData.pageSize" [pageSizeOptions]="paginatorData.pageOptions" [length]="paginatorData.length" showFirstLastButtons></mat-paginator>
  </div>
</div>
