import { Component, } from '@angular/core';
import { BuildService } from 'src/app/services/build.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AssignmentBuild } from "./../../interfaces/build.interface";
import { NotificationService } from './../../services/notification.service';

@Component({
  selector: 'app-update-all-customers.component',
  templateUrl: './update-all-customers.component.html',
  styleUrls: ['./update-all-customers.component.css']
})

export class UpdateAllCustomersComponent {
  step = 1; // Step 1: Form view, Step 2: Confirmation view
  confirmationMessage = 'Loading...';
  updateAllCustomers!: FormGroup;
  builds: AssignmentBuild[] = [];
  public isLoading = true;

  constructor(private fb: FormBuilder, private buildService: BuildService, private router: Router, private notify: NotificationService) {
  }
  ngOnInit(): void {
    this.isLoading = true; // Start the loader

    // Initialize the form group with form controls
    this.updateAllCustomers = this.fb.group({
      excludeMasters: [false],
      assignedUsbUtilityBuild: ['', Validators.required],
      assignedMSeriesBuild: ['', Validators.required],
      assignedIntelBuild: ['', Validators.required],
    }); // Define the FormGroup
    this.loadBuilds();
    const defaultValues = {
      updateSettings: 'dont-install',
      buildAssignment: 'Same',
      assignedMSeriesBuild: 0,
      assignedUsbUtilityBuild: 0,
      assignedIntelBuild: 0,
    };
    this.updateAllCustomers.patchValue({
      updateSettings: defaultValues.updateSettings,
      buildAssignment: defaultValues.buildAssignment,
      assignedMSeriesBuild: defaultValues.assignedMSeriesBuild,
      assignedUsbUtilityBuild: defaultValues.assignedUsbUtilityBuild,
      assignedIntelBuild: defaultValues.assignedIntelBuild
    });
  }

  // Method to handle form submission
  onSubmit() {
    if (this.updateAllCustomers.valid && (this.updateAllCustomers.get('assignedUsbUtilityBuild')?.value !== 0 || this.updateAllCustomers.get('assignedMSeriesBuild')?.value !== 0 || this.updateAllCustomers.get('assignedIntelBuild')?.value !== 0)) {
      this.isLoading = true;
      const formData = new FormData();
      // Add form control values to formData
      formData.append('excludeMasters', this.updateAllCustomers.get('excludeMasters')?.value ?? false);
      formData.append('assignedUsbUtilityBuild', this.updateAllCustomers.get('assignedUsbUtilityBuild')?.value);
      formData.append('assignedMSeriesBuild', this.updateAllCustomers.get('assignedMSeriesBuild')?.value);
      formData.append('assignedIntelBuild', this.updateAllCustomers.get('assignedIntelBuild')?.value);
      this.buildService.assignBuildToAll(formData).subscribe({
        next: (response) => {
          this.isLoading = false;
          this.notify.success(response.message);
          this.router.navigate(['/software-update']); // Redirect to login if not authenticated and not accessing release notes
          // Reload the page to refresh the content
        },
        error: (error) => {
          console.error('Error submitting build assignment:', error);
          this.notify.error('Error submitting build assignment. Please try again later.');
        },
      });
    } else {
      this.notify.error('Error submitting build assignment.  Please check Assigned Builds Carefully.');
    }
  }

  onCancel(): void {
    this.router.navigate(['/software-update']); // Redirect to login if not authenticated and not accessing release notes
  }

  getUsbUtilityBuilds() {
    return this.builds.filter(build => build.platform === 'USB Utility').sort(this.sortBy('buildNumber', true));
  }

  getMSeriesBuilds() {
    return this.builds.filter(build => build.platform === 'M-Series').sort(this.sortBy('buildNumber', true)); // Sort by buildNumber in descending order
  }

  getIntelBuilds() {
    return this.builds.filter(build => build.platform === 'Intel').sort(this.sortBy('buildNumber', true)); // Sort by buildNumber in descending order
  }

  async loadBuilds() {
    this.buildService.getAssignmentBuilds().subscribe(
      (data) => {
        this.builds = data;
        this.isLoading = false;
      },
      (error) => {
        console.error('Error fetching builds:', error);
        this.isLoading = false;
      }
    );
  }

  sortBy(field: string, descending: boolean = false) {
    return (a: any, b: any) => {
      if (a[field] < b[field]) return descending ? 1 : -1;
      if (a[field] > b[field]) return descending ? -1 : 1;
      return 0;
    };
  }

  // Fetch confirmation details from the API
  fetchConfirmation() {
    if (this.updateAllCustomers.valid && (this.updateAllCustomers.get('assignedUsbUtilityBuild')?.value !== 0 || this.updateAllCustomers.get('assignedMSeriesBuild')?.value !== 0 || this.updateAllCustomers.get('assignedIntelBuild')?.value !== 0)) {
      this.step = 2; // Switch to the confirmation step
      this.confirmationMessage = 'Loading...';
      let excludeMasters = this.updateAllCustomers.get('excludeMasters')?.value ?? false;
      const flag = excludeMasters ? 1 : 0;
      this.buildService.getCustomersCount(flag).subscribe({
        next: (data) => {
          this.confirmationMessage = `This update will affect ${data.resultCount} masters and cannot be undone. Are you sure you would like to proceed?`;
        },
        error: () => {
          this.confirmationMessage = 'Failed to load data. Please try again.';
        }
      });
    } else {
      this.notify.error('Error submitting build assignment.  Please check Assigned Builds Carefully.');
    }
  }

  goBack(): void {
    this.step = 1;
  }

}
