<div class="card">
  <div class="d-flex item-center mb-05">
    <h4 style="flex: 1 1 auto;">Station Assignments</h4>
    <mat-form-field class="filter-input" subscriptSizing="dynamic">
      <mat-label>Search stations</mat-label>
      <input matInput [formControl]="searchStationFormControl">
    </mat-form-field>
  </div>
  <div class="assigment-table" fxLayout="column">
    <div class="header-row" fxLayout="row">
      <div class="checkbox-cell">
        <mat-checkbox [(ngModel)]="allStations" (ngModelChange)="toggleAllStations($event)"
          [indeterminate]="someStationsAssigned()"></mat-checkbox>
      </div>
      <div class="assigment-cell column-title">Station</div>
      <div class="assigment-cell column-title">Warehouse</div>
    </div>
    <div *ngFor="let station of stationsToShow" fxLayout="row">
      <div class="checkbox-cell">
        <mat-checkbox [(ngModel)]="station.assigned" (ngModelChange)="updateAllStations()"></mat-checkbox>
      </div>
      <div class="assigment-cell">{{ station.name }}</div>
      <div class="assigment-cell">{{ station.warehouse.name }}</div>
    </div>
  </div>
  <h4>Share with Warehouse</h4>
  <div class="assigment-table" fxLayout="column">
    <div *ngFor="let warehouse of assignmentPayload.warehouses" fxLayout="row">
      <div class="header-row" fxLayout="row" *ngIf="warehouse.id == '999999'">
        <div class="checkbox-cell">
          <mat-checkbox [(ngModel)]="warehouse.assigned" (ngModelChange)="toggleAllWarehouses($event)"></mat-checkbox>
        </div>
        <div class="assigment-cell column-title">All Warehouses</div>
      </div>
      <div class="checkbox-cell" *ngIf="warehouse.id != '999999'">
        <mat-checkbox [(ngModel)]="warehouse.assigned"></mat-checkbox>
      </div>
      <div class="assigment-cell">{{ warehouse.name }}</div>
    </div>
  </div>

</div>
