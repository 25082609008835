import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
} from '@angular/material/snack-bar';

@Injectable()
export class NotificationService {
  private snackBarConfig: MatSnackBarConfig | undefined;
  // private snackBarRef: MatSnackBarRef<any>;
  private snackBarAutoHide = '5000'; // milliseconds for notification , 5 secs

  constructor(private sb: MatSnackBar) { }

  public success(message: string): void {
    this.snackBarConfig = new MatSnackBarConfig();
    this.snackBarConfig.duration = parseInt(this.snackBarAutoHide, 0);
    this.sb.open(message, 'Close', this.snackBarConfig);
  }

  public warn(message: string): void {
    this.snackBarConfig = new MatSnackBarConfig();
    this.snackBarConfig.duration = parseInt(this.snackBarAutoHide, 0);
    this.sb.open(message, 'Close', this.snackBarConfig);
  }

  public error(message: string): void {
    this.snackBarConfig = new MatSnackBarConfig();
    this.snackBarConfig.duration = parseInt(this.snackBarAutoHide, 0);
    this.sb.open(message, 'Close', this.snackBarConfig);
  }
}
