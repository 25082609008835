import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-mac-json-viewer-component',
  templateUrl: './mac-json-viewer.component.html',
  styleUrls: ['./mac-json-viewer.component.css']
})
export class MacJSONViewerComponent {
  json: { [key: string]: any };
  field: string;
  isGpuInfo: boolean = false;
  gpuInfoParsed: { name?: string, cores?: string }[] = [];
  temp: string | undefined;
  constructor(
    //private dialogRef: MatDialogRef<MacJSONViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { json: object, field: string },
  ) {
    const { json, field } = data;

    this.json = json;
    this.field = field;
    if (this.field === 'GPU Info') {
      this.isGpuInfo = true;
      this.parseGpuInfo();
    }

  }
  // Helper function to get object keys
  getObjectKeys(obj: { [key: string]: any }): string[] {
    return Object.keys(obj);
  }

  private parseGpuInfo(): void {
    try {
      const gpuData = this.json as { gpuTemp?: string, coreInfo?: { core: string, name: string }[] };
      this.temp = gpuData.gpuTemp;
      if (gpuData && gpuData.coreInfo) {
        this.gpuInfoParsed = gpuData.coreInfo.map(info => ({
          name: info.name,
          cores: info.core
        }));
      } else {
        this.gpuInfoParsed = [];
      }
    } catch (error) {
      console.error('Error parsing GPU info:', error);
      this.gpuInfoParsed = [];
    }
  }
}
