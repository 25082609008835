import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NotificationService } from './notification.service';
import { Page } from '../interfaces/page.interface';
import { ColumnConfiguration } from '../interfaces/columnSettings.interface';
import { DEVICE_INFO_COLUMNS } from '../device-info/columns';
import moment from 'moment-timezone';
import { Clipboard } from '@angular/cdk/clipboard';
import { LookupOption } from '../common/lookup-options.enum';
import { DeviceLookupContext, BulkDeviceLookupContext } from "../interfaces/deviceLookup.interface";
import { DownloadFormat, copy, download, getContentType, getExtension } from '../common/download';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceLookupService {
  private readonly DEFAULT_CLOUD_DB_COLUMNS_ROUTE = `${environment.apiUrl}/master/device-info/datatable-columns`;

  private readonly MASTER_DEVICE_INFO_ROUTE = `${environment.apiUrl}/master/device-info`;
  private readonly MASTER_DEVICE_INFO_FILTERS_ROUTE = `${environment.apiUrl}/master/device-info/datatable-column-settings`;
  private readonly MASTER_LOOKUP_DEVICE_ROUTE = `${environment.apiUrl}/master/deviceLookup`;

  private readonly ADMIN_DEVICE_INFO_ROUTE = `${environment.apiUrl}/admin/device-info`;
  private readonly ADMIN_DEVICE_INFO_FILTERS_ROUTE = `${environment.apiUrl}/admin/device-info/datatable-column-settings`;
  private readonly ADMIN_LOOKUP_DEVICE_INFO_ROUTE = `${environment.apiUrl}/admin/device-info/lookup`;

  constructor(
    private http: HttpClient,
    private notify: NotificationService,
    private clipboard: Clipboard,
    private authService: AuthService,
  ) { }

  private buildHttpParamsWithPages(
    context: DeviceLookupContext,
    format: DownloadFormat | undefined = undefined,
  ): HttpParams {
    const { paginatorData, latest } = context;
    const { pageIndex, pageSize } = paginatorData;
    let params = this.buildHttpParams(
      context,
      format,
    );

    params = params.append('page', pageIndex);
    params = params.append('size', pageSize);
    params = params.append('latest', latest);

    return params;
  }

  private buildHttpParams(
    context: DeviceLookupContext,
    format: DownloadFormat | undefined = undefined,
  ): HttpParams {
    const { keyword, option, columns } = context;
    let params = new HttpParams();

    params = params.append(option, keyword);

    Object.keys(columns).forEach(field => {
      params = params.append(field, columns[field]);
    });

    if (format)
      params = params.append('format', format);

    return params;
  }

  async lookup(
    context: DeviceLookupContext,
  ): Promise<Page<any> | undefined> {
    const url = `${environment.apiUrl}/master/device-lookup`;

    let params = this.buildHttpParamsWithPages(
      context,
    );

    try {
      const result = await this.http.get(url, {
        params,
      }).toPromise();

      return result as Page<any>;
    } catch (err) {
      console.error(err);
      this.notify.error(`Failed to get device lookup page`);

      return;
    }
  }

  async copyToClipboard(context: BulkDeviceLookupContext) {
    const format = DownloadFormat.CSV;
    const url = this.getLookupRoute();
    const body = this.buildBulkLookupBody(
      context,
      format,
    );

    try {
      const now = moment().format();
      const extension = getExtension(format);
      const contentType = getContentType(format);
      const request = this.http.post<Blob>(url, body, {
        responseType: this.getResponseType(format),
        observe: 'response',
      });
      copy(request, this.clipboard);
    } catch (err) {
      console.error(err);
      this.notify.error(`Failed to get device info page`);

      return;
    }

  }

  async downloadDeviceLookup(
    context: DeviceLookupContext,
    format: DownloadFormat,
  ): Promise<void> {
    const url = `${environment.apiUrl}/master/device-lookup`;
    const params = this.buildHttpParams(
      context,
      format,
    );

    try {
      const result = await this.http.get<Blob>(url, {
        params,
        responseType: this.getResponseType(format),
        observe: 'response',
      }).subscribe(response => {
        if (response.status === 200 && response.body) {
          const now = moment();
          const filename = `devicelookup_${now.format()}`;
          const file = new File([response.body], filename, {
            type: this.getContentType(format),
          });
          const url = window.URL.createObjectURL(file);
          window.open(url);
        }
      });
    } catch (err) {
      console.error(err);
      this.notify.error(`Failed to get device info page`);

      return;
    }
  }

  private allMastersContext(): boolean {
    return this.authService.isAdminContext()
      && this.authService.isAllMasterContext();
  }

  private getCloudDBFiltersRoute(): string {
    if (this.allMastersContext()) {
      return this.ADMIN_DEVICE_INFO_FILTERS_ROUTE;
    }

    return this.MASTER_DEVICE_INFO_FILTERS_ROUTE;
  }

  async getDeviceLookupColumns(): Promise<ColumnConfiguration[]> {
    const url = this.getCloudDBDefaultColumnRoute();
    let params = new HttpParams();

    params = params.append('lookup', true);

    try {
      const result = await this.http.get(url, { params }).toPromise();

      return result as ColumnConfiguration[];
    } catch (err) {
      console.error(err);

      return DEVICE_INFO_COLUMNS;
    }
  }

  private getContentType(f: 'csv' | 'excel') {
    if (f === 'csv') return 'text/csv';
    return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  }

  private getResponseType(f: 'csv' | 'excel'): 'json' {
    let result = 'arrayBuffer';

    if (f === 'csv')
      result = 'text';

    return result as 'json';
  }

  private buildBulkLookupBody(
    context: BulkDeviceLookupContext,
    format: DownloadFormat | undefined = undefined,
    gradingDataExport: boolean = false,
  ): BulkLookupPayload {
    const { paginatorData, latest } = context;
    const { pageIndex: page, pageSize: size } = paginatorData;
    let params = this.buildBulkHttpParams(
      context,
      format,
      gradingDataExport,
    );

    return { ...params, page, size, latest };
  }

  private buildBulkHttpParams(
    context: BulkDeviceLookupContext,
    format: DownloadFormat | undefined = undefined,
    gradingDataExport: boolean = false,
  ): {
      codes: string[],
      option: LookupOption,
      filters: Record<string, string>,
      format?: DownloadFormat,
      columnSettingsId: string,
      sort?: string,
      direction?: string,
      gradingDataExportOnly: boolean,
    } {
    const { codes, option, columns, columnSettingsId, sort, direction } = context;

    return {
      codes,
      option,
      filters: columns,
      format,
      columnSettingsId,
      sort,
      direction,
      gradingDataExportOnly: gradingDataExport,
    };
  }

  public async bulkLookup(context: BulkDeviceLookupContext, format?: DownloadFormat) {
    const url = this.getLookupRoute();
    const body = this.buildBulkLookupBody(context, format);

    try {
      const result = await this.http.post(url, body).toPromise();

      return result as Page<any>;
    } catch (err) {
      console.error(err);
      this.notify.error(`Failed to get device lookup page`);

      return;
    }
  }

  public async downloadBulkLookup(
    context: BulkDeviceLookupContext,
    format: DownloadFormat = DownloadFormat.EXCEL,
    gradingDataExport: boolean = false,
  ) {
    const url = this.getLookupRoute();
    const body = this.buildBulkLookupBody(
      context,
      format,
      gradingDataExport,
    );

    try {
      const now = moment().format();
      const extension = getExtension(format);
      const contentType = getContentType(format);
      const request = this.http.post<Blob>(url, body, {
        responseType: this.getResponseType(format),
        observe: 'response',
      });
      download(request, `DeviceInfo_${now}${extension}`, format, this.notify);
    } catch (err) {
      console.error(err);
      this.notify.error(`Failed to get device info page`);

      return;
    }

  }

  private getCloudDBDefaultColumnRoute(): string {
    return this.DEFAULT_CLOUD_DB_COLUMNS_ROUTE;
  }

  private getLookupRoute() {
    if (this.authService.isAllMasterContext()) {
      return this.ADMIN_LOOKUP_DEVICE_INFO_ROUTE;
    }

    return this.MASTER_LOOKUP_DEVICE_ROUTE;
  }
}

type BulkLookupPayload = {
  codes: string[],
  option: LookupOption,
  filters: Record<string, string>,
  format?: DownloadFormat,
  page: number,
  size: number,
  latest: boolean,
}

