import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PasswordProtectionService {

  constructor(private http: HttpClient) { }

  protectProfile(profileId: string) {
    const url = `${environment.apiUrl}/customization/protect-profile`;
    return this.http.put<boolean>(url, { profileId });
  }

  unProtectProfile(profileId: string) {
    const url = `${environment.apiUrl}/customization/unprotect-profile`;
    return this.http.put<boolean>(url, { profileId });
  }

  hasPasswordProtection(): Observable<boolean> {
    const url = `${environment.apiUrl}/customization/password-protected`;
    return this.http.get<boolean>(url);
  }

  async checkPassword(password: string, profileId: string) {
    const url = `${environment.apiUrl}/customization/check-password-protection`;

    try {
      const response = await this.http.post<boolean>(url, { password, profileId }).toPromise();

      return true;
    } catch (err: any) {
      if (err.status === 401) {
        return false;
      }

      throw err;
    }
  }

  savePassword(password: string | null, profileId?: string): Observable<boolean> {
    try {
      const url = `${environment.apiUrl}/customization/password`;
      return this.http.post<boolean>(url, { password, profileId });
    } catch (error) {
      console.error(error);
      return of(false);
    }
  }

}
