import { Component, Inject } from '@angular/core';
import { LookupOption, LookupOptionHeader } from '../../common/lookup-options.enum';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl } from "@angular/forms";

@Component({
  selector: 'app-device-code-selector',
  templateUrl: './device-code-selector.component.html',
  styleUrls: ['./device-code-selector.component.css']
})
export class DeviceCodeSelectorComponent {
  filename: string;
  availableDeviceCodeOptions: LookupOptionHeader[];
  lookupOptionFormControl: FormControl<LookupOption | null>;
  hasOptions: boolean;

  constructor(
    private dialogRef: MatDialogRef<DeviceCodeSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      options: LookupOptionHeader[],
      filename: string,
      mac: boolean,
    }
  ) {
    this.hasOptions = data.options?.length > 0;
    this.availableDeviceCodeOptions = data.options;
    this.filename = data.filename;
    this.lookupOptionFormControl= new FormControl<LookupOption>(this.availableDeviceCodeOptions[0]?.option || LookupOption.IMEI);
  }

  submit() {
    const option = this.lookupOptionFormControl.getRawValue();
    const selected = this.availableDeviceCodeOptions.find(a => a.option === option);
    this.dialogRef.close(selected);
  }

  onCancel(): void {
    this.dialogRef.close(undefined);
  }
}

