import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface GradingQuestionAnswer {
  question: string,
  answer: string,
  qCategory: string,
  parentId: string,
  pTitle: string,
  qId: string,
};

interface GradingQuestionsData {
  questions: GradingQuestionAnswer[],
  profileName: string,
  routeName: string,
}

@Component({
  selector: 'app-grading-questions-component',
  templateUrl: './grading-questions.component.html',
  styleUrls: ['./grading-questions.component.css']
})
export class GradingQuestionsComponent {

  public questions: GradingQuestionAnswer[] = [];
  public profileName: string = '';
  public routeName: string = '';

  constructor(
    private dialogRef: MatDialogRef<GradingQuestionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GradingQuestionsData,
  ) {
    this.questions = data.questions;
    this.profileName = data.profileName;
    this.routeName = data.routeName;

  }
}
