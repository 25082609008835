import { OnInit, Component } from '@angular/core';
import { PaginatorData } from "../interfaces/paginatorData.interface";
import { CustomReportService } from "../services/custom-reports.service";
import { EmailHistory } from "../interfaces/email-history.interface";

@Component({
  selector: 'app-email-history',
  templateUrl: './email-history.component.html',
  styleUrls: ['./email-history.component.css']
})
export class EmailHistoryComponent implements OnInit {
  paginatorData: PaginatorData = {
    currentPage: 0,
    length: 0,
    pageIndex: 0,
    pageOptions: [10, 20,50],
    pageSize: 10,
  };

  columns = [
    {
      displayName: 'Users',
      field: 'userNames',
    },
    {
      displayName: 'Status',
      field: 'status',
    },
    {
      displayName: 'Exports',
      field: 'exports',
    },
    {
      displayName: 'Filename',
      field: 'filename',
    },
    {
      displayName: 'Date',
      field: 'date',
    },
    {
      displayName: 'Time',
      field: 'time',
    },
  ];
  displayedColumns = this.columns.map(c => c.field);
  loading: boolean = false;
  history: EmailHistory[] = [];

  constructor(
    private customReportService: CustomReportService,
  ) {

  }

  public getExports(mac: boolean, device: boolean): string {
    const exports = [];

    if (mac) exports.push ('Mac');
    if (device) exports.push ('Device');

    return exports.join(', ');
  }

  async onPaginate(event: any) {
    this.paginatorData.pageIndex = event.pageIndex;
    this.paginatorData.pageSize = event.pageSize;

    return this.getEmailHistory();
  }

  private async getEmailHistory() {
    this.loading = true;

    const page = await this.customReportService.getEmailHistory(
      this.paginatorData.pageIndex,
      this.paginatorData.pageSize,
    );

    this.loading = false;

    if (page) {
      const { data, totalItems, currentPage } = page;

      this.paginatorData.length = totalItems;
      this.history = data;
    }
  }

  public ngOnInit() {
    this.getEmailHistory();
  }
}
