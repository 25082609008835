import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToggleMenuService {
  constructor() { }

  private allowAllMasterUsage = new BehaviorSubject<boolean>(false);
  public allowAllMasterUsageObservable = this.allowAllMasterUsage.asObservable();

  private seeAllMasterData = new BehaviorSubject<boolean>(false);
  public seeAllMasterDataObservable = this.seeAllMasterData.asObservable();

  private isOpen = new BehaviorSubject<boolean>(true);
  public isOpenObservable = this.isOpen.asObservable();

  public showMenu(value: boolean) {
    this.isOpen.next(value);
  }

  public hideMenu() {
    this.isOpen.next(false);
  }

  public allowUseAllMasterData(allow: boolean): void {
    this.allowAllMasterUsage.next(allow);
  }

  public enableSeeAllMasterDataCheckbox(): void {
    this.seeAllMasterData.next(true);
  }

  public disableSeeAllMasterDataCheckbox(): void {
    this.seeAllMasterData.next(false);
  }
}

