import { Injectable } from '@angular/core';
import { DateRange, toDateString } from '../common/date';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NotificationService } from './notification.service';
import { Page } from '../interfaces/page.interface';
import { ColumnSettings, ColumnConfiguration, ColumnSettingsCreationRequest, SavedColumnSettings } from '../interfaces/columnSettings.interface';
import { DeviceInfoQueryParams } from "../interfaces/deviceInfoQueryParams.interface";
import { MAC_DEVICE_INFO_COLUMNS } from '../common/mac/columns';
import moment, { Moment } from 'moment-timezone';
import { Clipboard } from '@angular/cdk/clipboard'
import { AuthService } from "./auth.service";
import { download, getContentType, getExtension, DownloadFormat } from '../common/download';
import { DeviceInfoServiceProvider } from "../interfaces/device-info-service.interface";
import { Observable, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MacDeviceInfoService implements DeviceInfoServiceProvider {
  private readonly MASTER_DEVICE_INFO_ROUTE = `${environment.apiUrl}/machineDiagnostic/deviceInfo`;
  private readonly MASTER_LOOKUP_DEVICE_INFO_ROUTE = `${environment.apiUrl}/machineDiagnostic/lookup`;
  private readonly MASTER_DEVICE_INFO_FILTERS_ROUTE = `${environment.apiUrl}/machineDiagnostic/deviceInfo/datatable-column-settings`;
  private readonly MASTER_DEVICE_INFO_DEFAULT_FILTERS_ROUTE = `${environment.apiUrl}/machineDiagnostic/deviceInfo/datatable-column-settings/default`;

  private readonly ADMIN_DEVICE_INFO_ROUTE = `${environment.apiUrl}/admin/macDeviceInfo`;
  private readonly ADMIN_LOOKUP_DEVICE_INFO_ROUTE = `${environment.apiUrl}/admin/macDeviceInfo/lookup`;
  private readonly ADMIN_DEVICE_INFO_FILTERS_ROUTE = `${environment.apiUrl}/admin/macDeviceInfo/datatableColumnSettings`;
  private readonly ADMIN_DEVICE_INFO_DEFAULT_FILTERS_ROUTE = `${environment.apiUrl}/admin/macDeviceInfo/datatable-columns`;

  constructor(
    private http: HttpClient,
    private notify: NotificationService,
    private clipboard: Clipboard,
    private authService: AuthService,
  ) { }

  public getDeviceInfo(
    queryParameters: DeviceInfoQueryParams,
    pageIndex: number = 0,
    pageSize: number = 10,
  ): Observable<Page<any> | undefined> {
    const { dateRange, keyword } = queryParameters;

    if (!dateRange) {
      throw new Error(`no date range provided to getDeviceInfo`);
    }

    const { start, end } = dateRange;
    let params = new HttpParams();

    params = params.append('page', pageIndex);
    params = params.append('size', pageSize);
    params = params.append('startDate', start);
    params = params.append('endDate', end);

    if (keyword)
      params = params.append('keyword', keyword);

    Object.entries(queryParameters.columns || {}).forEach(([field, value]) => {
      if (value !== null && value !== undefined && value !== '') {
        params = params.append(field, value);
      }
    });

    const url = this.getDeviceInfoRoute();

    return this.http.get(url, { params }).pipe(shareReplay(1)) as Observable<Page<any> | undefined>;

  }

  async getFilters(): Promise<SavedColumnSettings[] | undefined> {
    const url = this.getMacDeviceFiltersRoute();

    try {
      const filters = await this.http.get(url).toPromise() as any[];

      return filters;
    } catch (err) {
      this.notify.error(`Couldn't get saved filters`);

      return undefined;
    }
  }

  async getMacDeviceDefaultColumns(): Promise<ColumnConfiguration[]> {
    const url = this.getMacDeviceDefaultColumnsRoute();

    try {
      const columns = await this.http.get(url).toPromise() as ColumnConfiguration[];

      return columns;
    } catch (err) {
      this.notify.error(`Couldn't get saved filters`);

      return MAC_DEVICE_INFO_COLUMNS;
    }
  }

  async saveFilter(columnSettings: ColumnSettingsCreationRequest): Promise<SavedColumnSettings | undefined> {
    const url = this.getMacDeviceFiltersRoute();

    return this.http.post(url, columnSettings).toPromise().then(response => {
      this.notify.success(`Filter "${columnSettings.name}" was saved successfully.`);

      return response as SavedColumnSettings;
    }).catch(() => {
      this.notify.error(`Filter "${columnSettings.name}" failed to be saved.`);

      return undefined;
    });
  }

  async updateColumnSettings(id: string, settings: ColumnSettingsCreationRequest) {
    const url = this.getMacDeviceFiltersRoute();

    try {
      const result = await this.http.put(`${url}/${id}`, settings).toPromise();

      return settings;
    } catch (err) {
      console.error(err);

      return undefined;
    }
  }
  async deleteColumnSettings(id: string) {
    const url = this.getMacDeviceFiltersRoute();

    try {
      await this.http.delete(`${url}/${id}`).toPromise();
      this.notify.success(`Filter deleted.`);
    } catch (err) {
      console.error(err);
      this.notify.success(`Failed to delete filter.`);
    }
  }

  async saveColumnSettings(settings: ColumnSettings): Promise<SavedColumnSettings | undefined> {
    const url = this.getMacDeviceFiltersRoute();
    return this.http.post(url, settings).toPromise().then(response => {
      return response as Promise<SavedColumnSettings | undefined>;
    }).catch(() => {
      return undefined;
    });
  }

  async getDeviceInfoColumnSettings(): Promise<SavedColumnSettings[]> {
    const url = this.getMacDeviceFiltersRoute();


    try {
      const result = await this.http.get(url).toPromise();

      return result as SavedColumnSettings[];
    } catch (err) {
      console.error(err);

      return [{
        id: 'default',
        name: 'Default',
        visible: MAC_DEVICE_INFO_COLUMNS,
        hidden: [] as ColumnConfiguration[],
      }];
    }
  }

  private buildHttpParams(
    queryParameters: DeviceInfoQueryParams,
    format: 'csv' | 'excel' | undefined = undefined,
  ): HttpParams {
    const { dateRange, keyword, gradingDataExportOnly } = queryParameters;
    const { start, end } = dateRange;
    let params = new HttpParams();

    params = params.append('startDate', start);
    params = params.append('endDate', end);

    if (format)
      params = params.append('format', format);

    if (keyword)
      params = params.append('keyword', keyword);

    if (gradingDataExportOnly)
      params = params.append('gradingDataExportOnly', true);

    Object.entries(queryParameters.columns || {}).forEach(([field, value]) => {
      if (value !== null && value !== undefined && value !== '') {
        params = params.append(field, value);
      }
    });

    return params;
  }

  getFileExtension(format: 'csv' | 'excel'): string {
    switch (format) {
      case 'csv':
        return 'csv';
      case 'excel':
        return 'xlsx';
      default:
        return '';
    }
  }

  async downloadDeviceInfoReport(
    dateRange: DateRange,
    latest: boolean,
    filterId: string | undefined,
  ): Promise<void> {
    const start = toDateString(dateRange.start);
    const end = toDateString(dateRange.end);
    const params = {
      dateRange: {
        start,
        end,
      },
      columns: {
        columnSettingsId: filterId,
        latest: String(latest),
      },
      keyword: undefined,
    }

    this.downloadDeviceInfo(params, DownloadFormat.CSV);
  }

  async downloadDeviceInfo(
    queryParameters: DeviceInfoQueryParams,
    format: DownloadFormat,
  ) {
    const url = this.getDeviceInfoRoute();
    const params = this.buildHttpParams(
      queryParameters,
      format,
    );

    try {
      const response = await this.http.get<Blob>(url, {
        params,
        responseType: this.getResponseType(format),
        observe: 'response',
      }).toPromise();

      if (response && response.status === 200 && response.body) {
        const now = moment();
        const filename = `MacDeviceInfo_${now.format()}.${this.getFileExtension(format)}`;
        const dispositionHeader = response.headers.get('content-disposition');
        const dispositionMatch = dispositionHeader ? dispositionHeader.match(/filename="(.+)"/) : null;
        const suggestedFilename = dispositionMatch ? dispositionMatch[1] : filename;

        const file = new File([response.body], suggestedFilename, {
          type: this.getContentType(format),
        });

        const url = window.URL.createObjectURL(file);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = suggestedFilename;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);

        return response;
      };

      console.error("file download failed with response : ", response);
      return response;
    } catch (err) {
      console.error(err);
      this.notify.error(`Failed to get device info page`);

      return err;
    }
  }

  async copyToClipboard(
    queryParameters: DeviceInfoQueryParams,
  ) {
    const format = 'csv';
    const url = `${environment.apiUrl}/machineDiagnostic/deviceInfo`;
    const params = this.buildHttpParams(
      queryParameters,
      format,
    );

    try {
      const result = await this.http.get(url, {
        params,
        responseType: this.getResponseType(format),
      }).toPromise();

      if (result) {
        this.clipboard.copy(result.toString());
      }
    } catch (err) {
      console.error(err);
      this.notify.error(`Failed to get device info page`);

      return;
    }
  }

  private getContentType(f: 'csv' | 'excel') {
    if (f === 'csv') return 'text/csv';
    return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  }
  private getResponseType(f: 'csv' | 'excel'): 'json' {
    let result = 'arrayBuffer';

    if (f === 'csv')
      result = 'text';

    return result as 'json';
  }

  private getDeviceInfoRoute(): string {
    if (this.authService.isAllMasterContext()) {
      return this.ADMIN_DEVICE_INFO_ROUTE;
    }

    return `${environment.apiUrl}/machineDiagnostic/deviceInfo`;
  }

  private getMacDeviceFiltersRoute(): string {
    if (this.authService.isAllMasterContext()) {
      return this.ADMIN_DEVICE_INFO_FILTERS_ROUTE;
    }

    return this.MASTER_DEVICE_INFO_FILTERS_ROUTE;
  }

  private getMacDeviceDefaultColumnsRoute(): string {
    if (this.authService.isAllMasterContext()) {
      return this.ADMIN_DEVICE_INFO_DEFAULT_FILTERS_ROUTE;
    }

    return this.MASTER_DEVICE_INFO_DEFAULT_FILTERS_ROUTE;
  }

  private getMacDeviceLookupRoute(): string {
    if (this.authService.isAllMasterContext()) {
      return this.ADMIN_LOOKUP_DEVICE_INFO_ROUTE;
    }

    return this.MASTER_LOOKUP_DEVICE_INFO_ROUTE;
  }

  public async lookup(
    option: 'serial' | 'lpn',
    codes: string[],
    latest: boolean,
    filters: Record<string, string>,
    page: number,
    size: number,
  ): Promise<Page<any> | undefined> {
    const url = this.getMacDeviceLookupRoute();
    const body = {
      codes,
      option,
      latest,
      filters,
      page,
      size,
    };

    try {
      return this.http.post(url, body).toPromise() as unknown as Page<any>;
    } catch (err) {
      this.notify.error('Failed to get Mac devices info.');

      return undefined;
    }
  }

  public async downloadBulkLookup(
    format: DownloadFormat,
    option: 'serial' | 'lpn',
    codes: string[],
    latest: boolean,
    filters: Record<string, string>,
    sort: string,
    direction: string,
    columnSettingsId: string,
    gradingDataExportOnly: boolean,
  ) {
    const url = this.getMacDeviceLookupRoute();
    const body = {
      codes,
      option,
      latest,
      filters,
      format,
      sort,
      direction,
      columnSettingsId,
      gradingDataExportOnly,
    };
    const now = moment().format();
    const extension = getExtension(format);
    const contentType = getContentType(format);

    try {
      const request = this.http.post<Blob>(url, body, {
        responseType: this.getResponseType(format),
        observe: 'response',
      });
      download(request, `MacDeviceInfo_${now}${extension}`, format, this.notify);
      return;
    } catch (err) {
      this.notify.error('Failed to get Mac devices info.');

      return undefined;
    }
  }

  public async getMonthlyReportList(page: number, size: number): Promise<Page<any> | undefined> {
    const url = `${environment.apiUrl}/master/reports/monthly`;
    const params = new HttpParams()
      .append('page', page)
      .append('size', size)
      .append('type', 'mac');

    try {
      const result = await this.http.get(url, { params }).toPromise();

      return result as Page<any>;
    } catch (err) {
      console.error(err);

      return;
    }
  }
}

