import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

interface BuildData {
  version: string;
  releaseDate: string;
  features: string[];
  reports: string[];
  fixes: string[];
}


@Injectable({
  providedIn: 'root',
})
export class BuildService {
  private url;
  constructor(private http: HttpClient) {
    this.url = `${environment.apiUrl}`;
  }

  // Fetch All Os Images
  getOsImages(): Observable<any[]> {
    return this.http.get<any>(`${this.url}/build/os-images`).pipe(
      map(response => {
        if (response.success === true) {
          return response.data; // Pass only the data if status is success
        } else {
          throw new Error('Failed to fetch Os Images: ' + response.message);
        }
      }),
      catchError(error => {
        console.error('Error fetching Os Images:', error);
        return throwError(() => new Error('Error fetching Os Images'));
      })
    );
  }

  getBuilds(page: number, size: number, sort: string, order: string, filter?: string) {
    let params = new HttpParams();
    if(page >= 0){
      params = params.set('page', page.toString());
    }
    if(size){
      params = params.set('size', size);
    }
    if(sort){
      params = params.set('sort', sort);
    }
    if(order){
      params = params.set('order', order);
    }
    if (filter) {
      params = params.set('filter', filter);
    }
    return this.http.get<any>(`${this.url}/build/all?${params}`).pipe(
      map(response => {
        if (response.success === true) {
          return response; // Pass only the data if status is success
        } else {
          throw new Error('Failed to fetch builds: ' + response.message);
        }
      }),
      catchError(error => {
        console.error('Error fetching builds:', error);
        return throwError(() => new Error('Error fetching builds'));
      })
    );
  }

  getPreviousBuilds(){
    return this.http.get<any>(`${this.url}/build/all-previous`).pipe(
      map(response => {
        if (response.success === true) {
          return response.data; // Pass only the data if status is success
        } else {
          throw new Error('Failed to fetch builds: ' + response.message);
        }
      }),
      catchError(error => {
        console.error('Error fetching builds:', error);
        return throwError(() => new Error('Error fetching builds'));
      })
    );
  }

  getAssignmentBuilds(){
    return this.http.get<any>(`${this.url}/build/all-assignments`).pipe(
      map(response => {
        if (response.success === true) {
          return response.data; // Pass only the data if status is success
        } else {
          throw new Error('Failed to fetch builds: ' + response.message);
        }
      }),
      catchError(error => {
        console.error('Error fetching builds:', error);
        return throwError(() => new Error('Error fetching builds'));
      })
    );
  }

  // Fetch a specific build by ID
  getBuildById(id: number): Observable<any> {
    return this.http.get<any>(`${this.url}/build/id/${id}`).pipe(
      map(response => {
        if (response.success === true) {
          return response.data; // Pass only the data if status is success
        } else {
          throw new Error('Failed to fetch build by ID: ' + response.message);
        }
      }),
      catchError(error => {
        console.error('Error fetching build by ID:', error);
        return throwError(() => new Error('Error fetching build by ID'));
      })
    );
  }

  // Fetch a specific build by ID
  getReleaseNotes(id: number): Observable<any> {
    return this.http.get<any>(`${this.url}/release-notes/${id}`).pipe(
      map(response => {
        if (response.success === true) {
          return response.data; // Pass only the data if status is success
        } else {
          throw new Error('Failed to fetch release notes by ID: ' + response.message);
        }
      }),
      catchError(error => {
        return throwError(() => new Error('Error fetching Release Notes'));
      })
    );
  }

  // Initiate build upload
  intiateUploadRequest(build: any): Observable<any> {
    return this.http.post<any>(`${this.url}/build/intiate-upload-request`, build);
  }
  
  // Initiate build upload
  uploadChunk(build: any): Observable<any> {
    return this.http.post<any>(`${this.url}/build/upload-chunk`, build);
  }

  // Create a new build
  createBuild(build: any): Observable<any> {
    return this.http.post<any>(`${this.url}/build/create`, build);
  }

  // Update an existing build
  updateBuild(buildId: number, build: any): Observable<any> {
    return this.http.put<any>(`${this.url}/build/update/${buildId}`, build);
  }

  // Assign build
  assignBuild(build: any): Observable<any> {
    return this.http.post<any>(`${this.url}/build/assign`, build);
  }

  // Assign build to All Masters & Users
  assignBuildToAll(build: any): Observable<any> {
    return this.http.post<any>(`${this.url}/build/assign-to-all`, build);
  }

  // Delete a build
  deleteBuild(id: string): Observable<any> {
    return this.http.delete<any>(`${this.url}/build/delete/${id}`);
  }

  getCustomersCount(flag: number): Observable<any> {
    return this.http.get<any>(`${this.url}/build/masters-count/${flag}`).pipe(
      map(response => {
        if (response.totalMasters) {
          return response; // Return only the totalMasters value
        } else {
          throw new Error('Failed to fetch master count: ' + response.message);
        }
      }),
      catchError(error => {
        console.error('Error fetching master count:', error);
        return throwError(() => new Error('Error fetching master count'));
      })
    );
  }

  getAssignedBuild(masterId: string): Observable<any> {
    return this.http.get<any>(`${this.url}/build/assigned/${masterId}`).pipe(
      map(response => {
        if (response.success === true) {
          return response.data; // Return the data if status is success
        } else {
          throw new Error('Failed to fetch assigned build: ' + response.message);
        }
      }),
      catchError(error => {
        console.error('Error fetching assigned build:', error);
        return throwError(() => new Error('Error fetching assigned build'));
      })
    );
  }

}
