<div [hidden]="creatingAutomaticEmailSettings" class="main-container">
  <h1>Custom Reports - Automatic Email</h1>

  <mat-divider></mat-divider>

  <div>
    <button
        mat-raised-button
        color="primary"
        (click)="onAddNewAutomaticEmailSettings()"
        class="phonecheck-button">Add Email Report</button>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <mat-table [dataSource]="settings">
    <ng-container *ngFor="let column of columns" [matColumnDef]="column.field">
      <mat-header-cell *matHeaderCellDef>
        {{ column.displayName }}
      </mat-header-cell>
      <mat-cell *matCellDef="let entry">
        <ng-container *ngIf="column.field !== 'actions'" >
        {{ entry[column.field] }}
        </ng-container>
        <ng-container *ngIf="column.field === 'actions'" >
          <button
            mat-icon-button
            (click)="onEditSettings(entry.id)"
            matTooltip="Edit">
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="onDelete(entry.id)"
            matTooltip="Delete">
            <mat-icon>delete</mat-icon>
          </button>
        </ng-container>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let report; columns: displayedColumns"></mat-row>
    <tr *matNoDataRow>
      <td id="empty-table-td">
        You don't have any automatic email settings yet. 
      </td>
    </tr>
  </mat-table>
  <mat-paginator
    showFirstLastButtons
    [pageSize]="paginatorData.pageSize"
    [pageSizeOptions]="paginatorData.pageOptions"
    ></mat-paginator>

  <!-- Go back button not in original design -->
  <button
      mat-raised-button
      color="primary"
      (click)="onGoBack()"
      class="phonecheck-button">Go to Custom Reports</button>
</div>
<app-automatic-email-settings
    [finishedEmitter]="emailSettingsEmitter"
    [settingsId]="settingsId"
    *ngIf="creatingAutomaticEmailSettings">
</app-automatic-email-settings>
