import { RouterModule, Routes } from '@angular/router';
import { CustomizationsComponent } from './customizations/customizations.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BuildsManagementComponent } from './builds-management/builds-management.component';
import { UpdateAllCustomersComponent } from './builds-management/update-all-customers/update-all-customers.component';
import { ReleaseNotesComponent } from './builds-management/release-notes/release-notes.component';

import { LoginComponent } from './login/login.component';
import { DeviceInfoComponent } from './device-info/device-info.component';
import { MacDeviceInfoComponent } from './mac-device-info/mac-device-info.component';
import { DeviceLookupComponent } from './device-lookup/device-lookup.component';
import { PendingChangesGuard } from './pending-changes.guard';
import { MacErasureReportComponent } from './mac-erasure-report/mac-erasure-report.component';
import { DeviceInfoErasureReportComponent } from './device-info-erasure-report/device-info-erasure-report.component'
import { SkuConfigurationComponent } from './sku-configuration/sku-configuration.component';
import { ImeiComponent } from './imei/imei.component';
import { LicenseInformationComponent } from './license-information/license-information.component';
import { CustomReportsComponent } from './custom-reports/custom-reports.component';
import { ActivityReportsComponent } from './activity-reports/activity-reports.component';

const appRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard',
  },
  {
    path: 'customizations',
    canDeactivate: [PendingChangesGuard],
    component: CustomizationsComponent,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
  },
  {
    path: 'software-update',
    component: BuildsManagementComponent,
  },
  {
    path: 'release-notes/:buildId',
    component: ReleaseNotesComponent
  },
  {
    path: 'software-update/update-all-customers',
    component: UpdateAllCustomersComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'device-lookup',
    component: DeviceLookupComponent,
  },
  {
    path: 'device-info',
    component: DeviceInfoComponent,
  },
  {
    path: 'mac-device-info',
    component: MacDeviceInfoComponent,
  },
  {
    path: 'sku-configuration',
    component: SkuConfigurationComponent,
  },
  {
    path: 'custom-reports',
    component: CustomReportsComponent,
  },
  {
    path: 'activity-reports',
    component: ActivityReportsComponent,
  },
  {
    path: 'activate',
    loadChildren: () => import('./modules/activate/activate.module').then(m => m.ActivateModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule),
  },
  {
    path: 'mac-erasure-report',
    component: MacErasureReportComponent,
  },
  {
    path: 'device-erasure-report',
    component: DeviceInfoErasureReportComponent,
  },
  {
    path: 'imei-check',
    component: ImeiComponent,
  },
  {
    path: 'license-information',
    component: LicenseInformationComponent,
  }

];

export const AppRoutes = RouterModule.forRoot(appRoutes, {});
