import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';
import { PasswordProtectionService } from 'src/app/services/password-protection.service';

@Component({
  selector: 'app-password-update',
  templateUrl: './password-update.component.html',
  styleUrls: ['./password-update.component.css']
})
export class PasswordUpdateComponent {

  passwordProtected = new FormControl(true);
  hidePassword = true;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: {
      name: string;
      id: string;
      enableProtection: boolean;
    },
    private service: PasswordProtectionService,
    private notify: NotificationService
  ) {
    this.passwordProtected.setValue(this.data.enableProtection);
  }

  async protectProfile() {
    if (this.passwordProtected.value) {
      await firstValueFrom(this.service.protectProfile(this.data.id));
      this.notify.success('Password protected');
    } else {
      await firstValueFrom(this.service.unProtectProfile(this.data.id));
      this.notify.success('Password unprotected');
    }

    this.dialogRef.close({ changeProtection: this.passwordProtected.value });
  }

  updatePasswordClick(event: Event) {
    event.preventDefault();
    this.dialogRef.close({ editProfile: true });
  }
}
