<div class="dashboard-container">
  <div class="header-container">
    <iframe id="header" src="https://www.sf-mngmt.com/cld-banner" name="PhoneCheck Promo" width="1358" height="222" class="banner"></iframe>
  </div>
  <div class="body-container">
    <div class="toolbar-container">
      <mat-button-toggle-group
        [(ngModel)]="dateRange"
        aria-label="Font Style"
        (ngModelChange)="getDashData()"
        name="dateRange"
      >
        <mat-button-toggle class="date-toggle" value="Today">Today</mat-button-toggle>
        <mat-button-toggle class="date-toggle" value="Yesterday">Yesterday</mat-button-toggle>
        <mat-button-toggle class="date-toggle" value="7 Days">7 Days</mat-button-toggle>
        <mat-button-toggle class="date-toggle" value="30 Days">30 Days</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="charts-container">
      <div class="left-charts-container">
        <div class="overview-box-container" [hidden]="loading">
          <div class="overview-box">
            <div class="overview-title">Total Devices</div>
            <div class="overview-body">
              <div class="overview-count text-bold">{{deviceBreakdown.total}}</div>
              <!--              <div class="overview-change">-->
              <!--                <mat-chip class="up-chip" [disableRipple]="true">-->
              <!--                  <div class="up-chip-label">-->
              <!--                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">-->
              <!--                      <path d="M7.33652 10.4823V2.88739M7.33652 2.88739L3.53906 6.68485M7.33652 2.88739L11.134 6.68485" stroke="#12B76A" stroke-width="1.62748" stroke-linecap="round" stroke-linejoin="round"/>-->
              <!--                    </svg>-->
              <!--                    <span class="text-medium"></span>-->
              <!--                  </div>-->
              <!--                </mat-chip>-->
              <!--              </div>-->
            </div>
          </div>
          <div class="overview-box">
            <div class="overview-title">Device Licenses</div>
            <div class="overview-body">
              <div class="overview-count text-bold">{{licenseCounts.device}}</div>
              <div class="overview-change">
                <!--                <mat-chip class="up-chip" [disableRipple]="true">-->
                <!--                  <div class="up-chip-label">-->
                <!--                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">-->
                <!--                      <path d="M7.33652 10.4823V2.88739M7.33652 2.88739L3.53906 6.68485M7.33652 2.88739L11.134 6.68485" stroke="#12B76A" stroke-width="1.62748" stroke-linecap="round" stroke-linejoin="round"/>-->
                <!--                    </svg>-->
                <!--                    <span class="text-medium"></span>-->
                <!--                  </div>-->
                <!--                </mat-chip>-->
              </div>
            </div>
          </div>
          <div class="overview-box">
            <div class="overview-title">Fail Rate</div>
            <div class="overview-body">
              <div class="overview-count text-bold">{{failedPercentage}}%</div>
              <div class="overview-change">
                <!--                <mat-chip class="up-chip" [disableRipple]="true">-->
                <!--                  <div class="up-chip-label">-->
                <!--                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">-->
                <!--                      <path d="M7.33652 10.4823V2.88739M7.33652 2.88739L3.53906 6.68485M7.33652 2.88739L11.134 6.68485" stroke="#12B76A" stroke-width="1.62748" stroke-linecap="round" stroke-linejoin="round"/>-->
                <!--                    </svg>-->
                <!--&lt;!&ndash;                    <span class="text-medium">2%</span>&ndash;&gt;-->
                <!--                  </div>-->
                <!--                </mat-chip>-->
              </div>
            </div>
          </div>
        </div>
        <div class="left-chart" [hidden]="!loading">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div class="left-chart" [hidden]="loading">
          <div class="device-legend">
            <div class="device-legend-box">
              <div class="breakdown-icon devices"></div>
              <div class="device-legend-title">Devices</div>
            </div>
            <div class="device-legend-box">
              <div class="breakdown-icon licenses"></div>
              <div class="devices-legend-title">Licenses</div>
            </div>
            <div class="device-legend-box">
              <div class="breakdown-icon fail"></div>
              <div class="devices-legend-title">Failed</div>
            </div>
          </div>
          <canvas [id]="'devices'" [width]="732" [height]="266"></canvas>
        </div>
      </div>
      <div class="right-charts-container">
        <div class="additional-licenses-container">
          <span>Additional Licenses</span>
          <br>
          <div class="license-row">
            <div class="license-title">Erased:</div>
            <div class="license-count">{{licenseCounts.erased}}</div>
          </div>
          <div class="license-row">
            <div class="license-title">IMEI:</div>
            <div class="license-count">{{licenseCounts.IMEI}}</div>
          </div>
          <div class="license-row">
            <div class="license-title">SIM:</div>
            <div class="license-count">{{licenseCounts.SIM}}</div>
          </div>
          <div class="license-row">
            <div class="license-title">Carrier: </div>
            <div class="license-count">{{licenseCounts.carrier}}</div>
          </div>
          <div class="license-row">
            <div class="license-title">iCloud:</div>
            <div class="license-count">{{licenseCounts.iCloud}}</div>
          </div>
        </div>
        <div class="device-breakdown-container">
          <div class="device-breakdown-title">Device Breakdown</div>

          <div class="device-breakdown-chart-container">
            <div class="device-breakdown-chart-loading" *ngIf="loading">
              <mat-progress-spinner mode="indeterminate" diameter="164"></mat-progress-spinner>
            </div>
            <div class="device-breakdown-chart" [hidden]="loading">
              <canvas [id]="'device-breakdown'" [width]="164" [height]="164" ></canvas>
            </div>
            <div class="device-breakdown-legend-container">
              <div class="device-breakdown-legend">
                <div class="breakdown-icon apple"></div>
                <div class="legend-text-container">
                  <div class="legend-text">Apple</div>
                  <!--                  <div class="legend-percent">{{}}%</div>-->
                </div>
              </div>
              <div class="device-breakdown-legend">
                <div class="breakdown-icon samsung"></div>
                <div class="legend-text-container">
                  <div class="legend-text">Samsung</div>
                  <!--                  <div class="legend-percent">{{}}%</div>-->
                </div>
              </div>
              <div class="device-breakdown-legend">
                <div class="breakdown-icon other"></div>
                <div class="legend-text-container">
                  <div class="legend-text">Other</div>
                  <!--                  <div class="legend-percent">{{}}%</div>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-container">
    <iframe id="iframeId" src="https://www.phonecheck.com/cld-content-2" name="PhoneCheck Promo" width="1358" height="1400" style="border: none;"></iframe>
  </div>
</div>
