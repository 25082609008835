<div>
  <h2 class="p-2 pb-1 bgc-gray">Password required to modify this profile: {{data.name}}</h2>
  <div class="p-2">
    <form>
      <mat-form-field class="w-100">
        <mat-label>Password</mat-label>
        <input matInput type="text" class="input-password" (keydown.enter)="checkPassword()" [formControl]="password"
          (copy)="false" autocomplete="one-time-code" />
      </mat-form-field>
    </form>
    <div *ngIf="invalidPassword" id="invalid-password-container">Password is incorrect. Please, try again.</div>
    <br>
    <br>
    <div class="ta-center">
      <button mat-raised-button (click)="dialogRef.close()">Cancel</button>
      &nbsp;
      <button mat-raised-button color="primary" (click)="checkPassword()">Submit</button>
    </div>
  </div>
</div>
