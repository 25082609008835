import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NotificationService } from './notification.service';
import { Warehouse } from '../interfaces/warehouse.interface';
import { firstValueFrom } from 'rxjs';

export interface RawWarehouse {
  id: string,
  name: string,
  assigned: boolean,
  v1WarehouseId?: number;
}

export interface RawStation {
  id: string,
  name: string,
  warehouse: RawWarehouse,
  assigned: boolean,
  v1LicenseId?: number;
}

@Injectable({
  providedIn: 'root'
})
export class MasterService {

  constructor(
    private http: HttpClient,
    private notify: NotificationService) { }

  async getStations(): Promise<RawStation[] | undefined> {
    try {
      const url = `${environment.apiUrl}/customization/stations?raw=true`;

      const result = await this.http.get(url).toPromise();

      return result as RawStation[];
    } catch (e) {
      console.error(e);
      this.notify.warn(`Failed to get stations`);
    }

    return undefined;
  }

  async getWarehouses(): Promise<RawWarehouse[] | undefined> {
    try {
      const url = `${environment.apiUrl}/customization/warehouses?raw=true`;

      const result = await this.http.get(url).toPromise();

      return result as RawWarehouse[];
    } catch (e) {
      console.error(e);
      this.notify.warn(`Failed to get warehouses`);
    }

    return undefined;
  }

  async getWarehousesV3(): Promise<Warehouse[] | undefined> {
    try {
      const url = `${environment.apiUrl}/master/warehouses?v3=true`;

      const result = await this.http.get(url).toPromise();

      return result as Warehouse[];
    } catch (e) {
      console.error(e);
      this.notify.warn(`Failed to get warehouses`);
    }

    return undefined;
  }

  async getMasterSettings(): Promise<any> {
    const url = `${environment.apiUrl}/master/settings`;
    try {
      return firstValueFrom(this.http.get(url));
    } catch (err) {
      console.error(err);
      return undefined;
    }
  }
}

