import { Injectable } from '@angular/core';
import { HttpResponse, HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Page } from '../interfaces/page.interface';
import { NotificationService } from "./notification.service";
import { environment } from '../../environments/environment';
import exceljs from 'exceljs';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class SkuService {
  constructor(
    private http: HttpClient,
    private notify: NotificationService,
  ) { }

  async getSKUStatus(): Promise<{ skuEnabled: boolean } | undefined > {
    const url = `${environment.apiUrl}/master/sku/status`;
    try {
      const result = await this.http.get(url).toPromise()

      return result as { skuEnabled: boolean };
    } catch(err) {
      console.error(err);
      this.notify.error(`Failed to get SKU status`);

      return;
    }
  }

  async setSKUStatus(enabled: boolean) {
    const url = `${environment.apiUrl}/master/sku/status`;
    const body = {
      enabled,
    };

    try {
      const result = await this.http.post(url, body).toPromise();
    } catch (err) {
      console.error(err);
      this.notify.error('Could not update SKU status');

      return;
    }
  }

  async getSKUFileList(page: number, size: number): Promise<Page<any> | undefined> {
    const url = `${environment.apiUrl}/master/sku/files`;

    let params = new HttpParams();

    params = params.append('page', page);
    params = params.append('size', size);

    try {
      const result = await this.http.get(url, { params }).toPromise()

      return result as Page<any>;
    } catch(err) {
      console.error(err);
      this.notify.error(`Failed to get SKU file list`);

      return;
    }
  }

  async downloadSKUFile(
    id: string,
    filename: string,
  ): Promise<void> {
    const url = `${environment.apiUrl}/master/sku/files/${id}`;
    let  params = new HttpParams();

    params = params.append('download', true);

    try {
      const result = await this.http.get<Blob>(url, {
        params,
        responseType: 'arrayBuffer' as 'json',
        observe: 'response',
      }).subscribe(response => {
        if (response.status === 200 && response.body) {
          const format = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          const blob = new Blob([response.body], { type: format });

          saveAs(blob, filename, { autoBom: false });
        }
      });
    } catch (err) {
      console.error(err);
      this.notify.error(`Failed to download file "${filename}"`);

      return;
    }
  }

  async uploadFileInfo(filename: string, rows: any[]) {
    const url = `${environment.apiUrl}/master/sku/files`;
    const body = {
      filename,
      content: rows,
    };

    try {
      const result = await this.http.post(url, body).toPromise();
    } catch (err) {
      console.error(err);
      this.notify.error('Couldn\'t upload file');

      return;
    }
  }

  public downloadSKUTemplate() {
    const TEMPLATE_HEADERS = [
      { header: 'Model Number', key: 'modelNumber', width: 30 },
      { header: 'Model Name', key: 'modelName', width: 30 },
      { header: 'Storage', key: 'storage', width: 30 },
      { header: 'Carrier', key: 'carrier', width: 30 },
      { header: 'Color', key: 'color', width: 30 },
      { header: 'Grade', key: 'grade', width: 30 },
      { header: 'SKU', key: 'sku', width: 30 },
    ];
    const TEMPLATE_DATA = [
      {
        modelNumber: 'A1234',
        modelName: 'iPhone 5',
        storage: '16GB',
        carrier: 'Unlocked',
        color: 'Black',
        grade: 'A',
        sku: 'UI5-1001'
      },
      {
        modelNumber: 'A1234',
        modelName: 'iPhone 5',
        storage: '64 GB',
        carrier: 'Unlocked',
        color: 'Black',
        grade: 'A',
        sku: 'UI5-1003'
      },
    ];

    const wb = new exceljs.Workbook();
    const ws = wb.addWorksheet('SKU');
    const format = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    ws.columns = TEMPLATE_HEADERS;
    ws.addRows(TEMPLATE_DATA);

    wb.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: format });

      saveAs(blob, 'sku.xlsx', { autoBom: false });
    });
  }
}


