<h2 mat-dialog-title>{{ isEditMode ? 'Edit Build' : 'Upload Build' }}</h2>
<form [formGroup]="uploadBuildForm" (ngSubmit)="submitBuild()">
  <mat-dialog-content class="dialog-content">
    <div *ngIf="buildUploading" class="full-screen-loader">
      <img src="assets/loader.gif" alt="Uploading Build" class="loader-image" />
    </div>
    <div *ngIf="uploadInProgress && !buildUploading" class="progress-container">
      <div class="grid-container">
        <!-- Build File Progress -->
        <div *ngIf="uploadProgress['buildFile']">
          <mat-progress-bar mode="determinate"
            [value]="(uploadProgress['buildFile'].uploadedChunks / uploadProgress['buildFile'].totalChunks) * 100"
            color="primary">
          </mat-progress-bar>
          <p>
            Build File ( {{ uploadProgress['buildFile'].fileName }} ) Upload in Progress: {{
            uploadProgress['buildFile'].uploadedChunks }} out of
            {{ uploadProgress['buildFile'].totalChunks }} chunks uploaded
            ({{ ((uploadProgress['buildFile'].uploadedChunks / uploadProgress['buildFile'].totalChunks) * 100) |
            number:'1.0-2' }}% completed).
          </p>
        </div>

        <!-- OTA File Progress -->
        <div *ngIf="uploadProgress['otaFile']">
          <mat-progress-bar mode="determinate"
            [value]="(uploadProgress['otaFile'].uploadedChunks / uploadProgress['otaFile'].totalChunks) * 100"
            color="accent">
          </mat-progress-bar>
          <p>
            OTA File ( {{ uploadProgress['otaFile'].fileName }} ) Upload in Progress: {{
            uploadProgress['otaFile'].uploadedChunks }} out of
            {{ uploadProgress['otaFile'].totalChunks }} chunks uploaded
            ({{ ((uploadProgress['otaFile'].uploadedChunks / uploadProgress['otaFile'].totalChunks) * 100) |
            number:'1.0-2' }}% completed).
          </p>
        </div>
      </div>
    </div>
    <div class="grid-container" [style.pointerEvents]="uploadInProgress ? 'none' : 'auto'" [style.opacity]="uploadInProgress ? '0.5' : '1'">
      <div class="full-width">
        <!-- Platform Field -->
        <mat-form-field class="half-width left-align">
          <mat-label>Select Platform</mat-label>
          <mat-select formControlName="platform">
            <mat-option value="Mac OS">Mac OS</mat-option>
            <mat-option value="Windows">Windows</mat-option>
            <mat-option value="iOS">iOS</mat-option>
            <mat-option value="Android">Android</mat-option>
            <mat-option value="Intel">Intel</mat-option>
            <mat-option value="USB Utility">USB Utility</mat-option>
            <mat-option value="OS Image">OS Image</mat-option>
            <mat-option value="M-Series">M-Series</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Build Number Field -->
        <mat-form-field class="half-width right-align">
          <mat-label>Enter Build Number</mat-label>
          <input formControlName="buildNumber" matInput type="text">
          <mat-error *ngIf="uploadBuildForm.get('buildNumber')?.invalid && uploadBuildForm.get('buildNumber')?.touched">
            Please enter a valid build number (only numbers and a single dot allowed).
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Type Field -->
      <mat-form-field class="half-width left-align">
        <mat-label>Select Type</mat-label>
        <mat-select formControlName="type">
          <mat-option value="Latest Build">Latest Build</mat-option>
          <mat-option value="Test Build">Test Build</mat-option>
          <mat-option value="Production Build">Production Build</mat-option>
          <mat-option value="Hotfix Build">Hotfix Build</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Jira Tickets Field -->
      <mat-form-field class="full-width left-align">
        <mat-label>Enter Jira Tickets</mat-label>
        <input formControlName="jiraTickets" matInput type="text">
      </mat-form-field>
      <!-- File Selectors -->
      <div class="full-width upload-container">
        <!-- File Selectors in One Row -->
        <div class="file-selectors-row">
          <!-- DMG/EXE File Selector -->
          <div class="file-selector left-selector">
            <label for="dmgFileInput">Drag & Drop DMG/EXE or</label>
            <button type="button" (click)="dmgFileInput.click()">Browse</button>
            <input type="file" formControlName="buildFile" #dmgFileInput hidden (change)="onFileSelected($event, 'dmg')"
              accept=".dmg" />
            <div class="file-name" *ngIf="selectedFiles.dmg">{{ selectedFiles.dmg.name }}</div>
            <div class="file-name" *ngIf="selectedBuild && selectedBuild.buildKey && !selectedFiles.dmg">(Existing: {{ selectedBuild.buildKey.split('/').pop() }})</div>
          </div>

          <!-- ZIP File Selector -->
          <div class="file-selector right-selector">
            <label for="zipFileInput">Drag & Drop Windows OTA.zip or</label>
            <button type="button" (click)="zipFileInput.click()">Browse</button>
            <input type="file" #zipFileInput hidden (change)="onFileSelected($event, 'zip')" accept=".zip" />
            <div class="file-name" *ngIf="selectedFiles.zip">{{ selectedFiles.zip.name }}</div>
            <div class="file-name" *ngIf="selectedBuild?.otaKey && !selectedFiles?.zip">(Existing: {{ selectedBuild.otaKey.split('/').pop() }})</div>
          </div>
        </div>
      </div>

      <div class="full-width left-align ckeditor-section">
        <h5>Release Notes</h5>
        <p-editor formControlName="releaseNotes" [style]="{height:'130px'}"></p-editor>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="sticky-footer">
    <div class="footer-btns">
      <button mat-raised-button type="submit" [disabled]="uploadBuildForm.invalid || uploadInProgress">{{ isEditMode ? 'Update Build' : 'Submit Build' }}</button>
      <button mat-raised-button (click)="cancelBuild()" type="button">Cancel</button>
    </div>
  </mat-dialog-actions>
</form>