<div class="main-container">
  <h1>Custom Reports - Automatic Email</h1>
  <mat-progress-bar *ngIf="!formReady" mode="indeterminate"></mat-progress-bar>
  <div [hidden]="!formReady">
    <div class="user-selection-container">
      <p>
        <b>Users to email </b> <a href="/users/tab-user">Manage users</a>
      </p>
      <mat-form-field class="user-chip-list">
        <mat-label>Selected Users</mat-label>
        <mat-chip-grid #chipGrid aria-label="User selection">
          <mat-chip-row *ngFor="let user of selectedUsers" (removed)="remove(user)">
            {{user.name}}
            <button matChipRemove [attr.aria-label]="'remove ' + user">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
        </mat-chip-grid>
        <input placeholder="Add user..." #userInput [formControl]="userCtrl"
          [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add($event)"/>
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="selected($event)"
          >
          <mat-option *ngFor="let user of filteredUsers" [value]="user.id">
            {{user.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div fxLayout="row">
        <!-- <input value="12:00 pm" matInput type="text"> -->
      <mat-form-field>
        <mat-label>Time to email</mat-label>
        <input
          matInput
          [(ngModel)]="taskTime"
          [ngxTimepicker]="picker"
          [format]="24"
          readonly
          >
        <ngx-material-timepicker
          #picker
          ></ngx-material-timepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Device Info Filter</mat-label>
        <mat-select [disabled]="!device" [(ngModel)]="deviceFilterId">
          <mat-option *ngFor="let filter of filters" [value]="filter.id">{{ filter.name }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Mac Device Filter</mat-label>
        <mat-select [disabled]="!mac" [(ngModel)]="macFilterId">
          <mat-option *ngFor="let filter of macFilters" [value]="filter.id">{{ filter.name }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Warehouses</mat-label>
        <mat-select [(ngModel)]="warehouseId">
          <mat-option [value]="'all'">All warehouses</mat-option>
          <mat-option *ngFor="let warehouse of warehouses" [value]="warehouse.id">{{ warehouse.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="latest-or-all-container">
      <mat-radio-group [(ngModel)]="recordsToShow">
        <mat-radio-button [value]="'all'"><b>Show all records</b></mat-radio-button>
        <mat-radio-button [value]="'latest'"><b>Show latest record only</b></mat-radio-button>
      </mat-radio-group>
    </div>
    <table>
      <tr>
        <th>
          <label id="days-label">Days</label>
        </th>
        <th>
          <label id="format-label">File type</label>
        </th>
        <th>
          <label id="export-type-label">Export Type</label>
        </th>
      </tr>  
      <tr>
        <td>
          <section aria-labelledby="days-label" fxLayout="column">
            <mat-checkbox [(ngModel)]="mon">Monday</mat-checkbox>
            <mat-checkbox [(ngModel)]="tue">Tuesday</mat-checkbox>
            <mat-checkbox [(ngModel)]="wed">Wednesday</mat-checkbox>
            <mat-checkbox [(ngModel)]="thu">Thursday</mat-checkbox>
            <mat-checkbox [(ngModel)]="fri">Friday</mat-checkbox>
            <mat-checkbox [(ngModel)]="sat">Saturday</mat-checkbox>
            <mat-checkbox [(ngModel)]="sun">Sunday</mat-checkbox>
          </section>
        </td>
        <td>
          <section aria-labelledby="format-label" fxLayout="column">
            <mat-checkbox [(ngModel)]="excel">Excel</mat-checkbox>
            <mat-checkbox [(ngModel)]="csv">CSV</mat-checkbox>
          </section>
        </td>
        <td>
          <section aria-labelledby="export-type-label" fxLayout="column">
            <mat-checkbox [(ngModel)]="device">Device Info</mat-checkbox>
            <mat-checkbox [(ngModel)]="mac">Mac Devices</mat-checkbox>
          </section>
        </td>
      </tr>
    </table>
  </div>
  <div>
    <button (click)="onSave()" mat-raised-button color="primary" class="phonecheck-button">Save</button>
    <span><a (click)="onCancel()">Cancel</a></span>&nbsp;
    <span><a (click)="sendTestEmail()">Send Test Email</a></span>
  </div>
</div>
