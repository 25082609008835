import { Moment } from 'moment-timezone';

export enum DateOption {
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  THIS_MONTH = 'THIS_MONTH',
  LAST_MONTH = 'LAST_MONTH',
  CUSTOM_DATE_RANGE = 'CUSTOM_DATE_RANGE',
};

export interface DateRange {
  start: Moment,
  end: Moment,
}

export function toDateString(d: Moment): string {
  const DATE_FORMAT = 'YYYY-MM-DD';

  return d.format(DATE_FORMAT);
}

