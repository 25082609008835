import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DialogPreview } from "../preview-label/preview-label.component";
import { LabelService } from "../../services/label.service";
import { NotificationService } from "../../services/notification.service";
import { CustomizationService } from "../../services/customization.service";
export interface DialogDeleteProfile {
  name: string;
  id: number;
  image: string;
}
@Component({
  selector: 'app-delete-profile',
  templateUrl: './delete-profile.component.html',
  styleUrls: ['./delete-profile.component.css']
})
export class DeleteProfileComponent {
  deleting = false;
  constructor(
    private notify: NotificationService,
    private customizationService: CustomizationService,
    public dialogRef: MatDialogRef<DeleteProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDeleteProfile,
  ) {}

  async deleteProfile() {
    this.deleting = true;
    const response = await this.customizationService.deleteProfile(this.data.id);
    if (response) {
      this.notify.success('Successfully Deleted profile');
      this.dialogRef.close();
    }
  }
}
