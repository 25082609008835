import { EventEmitter, Component, Input } from '@angular/core';
import { PaginatorData } from "../interfaces/paginatorData.interface";
import { CustomReportService } from "../services/custom-reports.service";
import { ServiceResult } from "../common/service-result.enum";
import { DeleteConfirmationComponent } from "../delete-confirmation/delete-confirmation.component";
import { MatDialog } from "@angular/material/dialog";
import { NotificationService } from "../services/notification.service";

@Component({
  selector: 'app-automatic-email-settings-list',
  templateUrl: './automatic-email-settings-list.component.html',
  styleUrls: ['./automatic-email-settings-list.component.css']
})
export class AutomaticEmailSettingsListComponent {
  @Input() automaticSettingsOpenCloseStatusEmitter!: EventEmitter<boolean>;

  loading: boolean = false; 
  settings: any = [];
  settingsId: string | undefined;
  columns = [
    {
      displayName: 'Users',
      field: 'userNames',
    },
    {
      displayName: 'Warehouse',
      field: 'warehouseName',
    },
    {
      displayName: 'Exports',
      field: 'exports',
    },
    {
      displayName: 'Days',
      field: 'days',
    },
    {
      displayName: 'Date Created',
      field: 'createdAt',
    },
    {
      displayName: 'Actions',
      field: 'actions',
    },
  ];
  displayedColumns = this.columns.map(c => c.field);
  paginatorData: PaginatorData = {
    currentPage: 0,
    length: 0,
    pageIndex: 0,
    pageOptions: [10, 20,50],
    pageSize: 10,
  };
  creatingAutomaticEmailSettings: boolean = false;
  emailSettingsEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private customReportsService: CustomReportService,
    private dialog: MatDialog,
    private notify: NotificationService,
  ) {
    this.emailSettingsEmitter.subscribe((result: ServiceResult)=> {
      switch (result) {
        case ServiceResult.SAVED:
          this.creatingAutomaticEmailSettings = false;
          this.settingsId = undefined;
          this.getAutomaticEmailSettings();
          break;
        case ServiceResult.CANCELED:
          this.creatingAutomaticEmailSettings = false;
          this.settingsId = undefined;
          break;
      }
    });

    this.getAutomaticEmailSettings();
  }

  async onPage(event: any) {
    this.paginatorData.pageIndex = event.pageIndex;
    this.paginatorData.pageSize = event.pageSize;

    return this.getAutomaticEmailSettings();
  }


  async getAutomaticEmailSettings() {
    this.loading = true;

    const page =  await this.customReportsService.getAutomaticEmailSettingsList(
      this.paginatorData.currentPage,
      this.paginatorData.pageSize,
    );

    this.loading = false;

    if (page) {
      const { data } = page;

      this.settings = data;
    }

  }

  onGoBack() {
    this.automaticSettingsOpenCloseStatusEmitter.emit(false);
  }

  onAddNewAutomaticEmailSettings() {
    this.creatingAutomaticEmailSettings = true;
  }

  onEditSettings(id: string) {
    this.settingsId = id;
    this.creatingAutomaticEmailSettings = true;
  }

  async onDelete(id: string) {
    try {
      this.dialog.open(DeleteConfirmationComponent, {
        data: {
          deleteService: this.customReportsService.deleteAutomaticEmailSettings.bind(this.customReportsService),
          message: 'settings',
          id: id,
        }
      }).afterClosed().subscribe(_ => {
        this.getAutomaticEmailSettings();
      });
    } catch (error) {
      console.error(error);
      this.notify.error('An error occurred while deleting the settings.');
    }
  }
}
