/* eslint-disable spellcheck/spell-checker */

export const environment = {
  production: true,
  apiUrl: 'https://api.phonecheck.com/v2',
  imeiUrl: 'https://imei.phonecheck.com',
  toolboxUrl: 'https://toolbox.phonecheck.com',
  labelMakerUrl: 'https://label.phonecheck.com/#',
  dhrQRURL: 'https://historyreport.phonecheck.com/report-qr',
  dhrURL: 'https://historyreport.phonecheck.com',
};
