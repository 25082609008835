import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UploadBuildComponent } from '../dialogs/upload-build/upload-build.component';
import { BuildService } from 'src/app/services/build.service';
import { FormControl, Validators, FormGroup, FormBuilder } from "@angular/forms";
import { Build, PreviousBuild, AssignmentBuild } from "../interfaces/build.interface";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DeleteBuildComponent } from "../dialogs/delete-build/delete-build.component";
import { Router } from '@angular/router';
import { AuthService } from "../services/auth.service";
import { NotificationService } from '../services/notification.service';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-builds-management.component',
  templateUrl: './builds-management.component.html',
  styleUrls: ['./builds-management.component.css']
})
export class BuildsManagementComponent {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  assignmentBuilds: any = [];
  previousBuilds: any = [];
  osImages: any = [];

  selectedTabIndex: number = 1;
  isReadonly: boolean = true;
  displayedColumns: string[] = ['Version', 'Platform', 'Type', 'Uploaded', 'Actions'];
  masterId = this.authService.getCurrentMasterId();
  selectedTabLabel = '';

  selectedOsImage: any = null;
  selectedMacBuild: PreviousBuild | null = null;
  selectedClonorBuild: PreviousBuild | null = null;

  assignBuildForm!: FormGroup;
  searchBuild = new FormControl<string>('');
  buildsDataSource = new MatTableDataSource<Build>();

  assignedupdateSettings = "dont-install";
  assignedbuildAssignment = "Same";
  assignedMSeriesBuild: number | null = null;
  assignedUsbUtilityBuild: number | null = null;
  assignedIntelBuild: number | null = null;

  public isLoading = true;

  pageSize = 5;
  pageIndex = 0;
  buildsLength: number = 0;
  totalPages: number = 0;
  pageSizeOptions: number[] = [5, 10, 20];  // Correcting the type to be an array of numbers

  constructor(private fb: FormBuilder, private dialog: MatDialog, private buildService: BuildService, private router: Router, private authService: AuthService, private notify: NotificationService) {
    if (!this.authService.getSelectedMaster()) {
      this.notify.warn('Select an account to view the Software Update Page');
      this.router.navigate(['/dashboard']);
    }
    // Initialize form with default values as placeholders
    this.assignBuildForm = this.fb.group({
      updateSettings: "dont-install", // Default value set to "install"
      buildAssignment: "Same", // Default value set to "same"
      assignedMSeriesBuild: null,
      assignedUsbUtilityBuild: null,
      assignedIntelBuild: null
    });
  }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    this.assignBuildForm = this.fb.group(
      {
        assignedUsbUtilityBuild: ['', Validators.required],
        assignedMSeriesBuild: ['', Validators.required],
        assignedIntelBuild: ['', Validators.required],
        updateSettings: ['', Validators.required],
        buildAssignment: ['', Validators.required],
      },
    );

    if (this.masterId) {
      this.loadAssignedBuild(this.masterId).finally(() => {
      });
    }
    this.loadOsImages();
    await this.loadBuilds();
  }

  async ngAfterViewInit() {
    await this.loadAssignmentBuilds();
    await this.loadPreviousBuilds();
    this.searchBuild.valueChanges.pipe(
      debounceTime(600),
      distinctUntilChanged(),
    ).subscribe(async () => {
      this.pageSize = 5;
      this.pageIndex = 0;
      await this.loadBuilds();
    });
    this.sort.sortChange.subscribe(_ => {
      this.loadBuilds();
    });
    this.buildsDataSource.sort = this.sort;
  }

  onBuildAssignmentChange(selectedValue: string): void {
    if (selectedValue === 'Same') {
      this.assignBuildForm.patchValue({
        assignedMSeriesBuild: this.getMSeriesBuilds()[0],
        assignedUsbUtilityBuild: this.getUsbUtilityBuilds()[0],
        assignedIntelBuild: this.getIntelBuilds()[0],
      });
    }
  }

  async loadAssignedBuild(masterId: string): Promise<void> {
    try {
      const data = await this.buildService.getAssignedBuild(masterId).toPromise();
      // Default values if data is null or undefined
      const defaultValues = {
        updateSettings: 'dont-install',
        buildAssignment: 'Same',
        assignedMSeriesBuild: 0,
        assignedUsbUtilityBuild: 0,
        assignedIntelBuild: 0,
      };

      if (data) {
        this.assignBuildForm.patchValue({
          updateSettings: data.updateSettings ?? defaultValues.updateSettings,
          buildAssignment: data.buildAssignment ?? defaultValues.buildAssignment,
          assignedMSeriesBuild: data.assignedMSeriesBuild ? parseInt(data.assignedMSeriesBuild, 10) : 0,
          assignedUsbUtilityBuild: data.assignedUsbUtilityBuild ? parseInt(data.assignedUsbUtilityBuild, 10) : 0,
          assignedIntelBuild: data.assignedIntelBuild ? parseInt(data.assignedIntelBuild, 10) : 0,
        });

        // Assign to component properties
        this.assignedupdateSettings = data.updateSettings ?? defaultValues.updateSettings;
        this.assignedbuildAssignment = data.buildAssignment ?? defaultValues.buildAssignment;
        this.assignedMSeriesBuild = data.assignedMSeriesBuild ? parseInt(data.assignedMSeriesBuild, 10) : 0;
        this.assignedUsbUtilityBuild = data.assignedUsbUtilityBuild ? parseInt(data.assignedUsbUtilityBuild, 10) : 0;
        this.assignedIntelBuild = data.assignedIntelBuild ? parseInt(data.assignedIntelBuild, 10) : 0;
      } else {
        this.assignBuildForm.patchValue(defaultValues);
        this.assignedupdateSettings = defaultValues.updateSettings;
        this.assignedbuildAssignment = defaultValues.buildAssignment;
        this.assignedMSeriesBuild = defaultValues.assignedMSeriesBuild;
        this.assignedUsbUtilityBuild = defaultValues.assignedUsbUtilityBuild;
        this.assignedIntelBuild = defaultValues.assignedIntelBuild;
      }
    } catch (error) {
      console.error('Error loading assigned build:', error);
    }
  }

  private getSearchTerm(): string | undefined {
    return this.searchBuild.value?.trim().toLowerCase();
  }

  cancelChanges(): void {
    this.assignBuildForm.patchValue({
      updateSettings: this.assignedupdateSettings || null, // Ensure a fallback value for updateSettings
      buildAssignment: this.assignedbuildAssignment || "Same", // Ensure a fallback value for buildAssignment
      assignedMSeriesBuild: this.assignedMSeriesBuild || null,
      assignedUsbUtilityBuild: this.assignedUsbUtilityBuild || null,
      assignedIntelBuild: this.assignedIntelBuild || null,
    });
  }

  submitBuildAssignment(): void {
    this.isLoading = true;
    if (this.assignBuildForm.valid && this.masterId && (this.assignBuildForm.get('assignedUsbUtilityBuild')?.value !== 0 || this.assignBuildForm.get('assignedMSeriesBuild')?.value !== 0 || this.assignBuildForm.get('assignedIntelBuild')?.value !== 0)) {
      const formData = new FormData();
      formData.append('assignedUsbUtilityBuild', this.assignBuildForm.get('assignedUsbUtilityBuild')?.value);
      formData.append('updateSettings', this.assignBuildForm.get('updateSettings')?.value);
      formData.append('buildAssignment', this.assignBuildForm.get('buildAssignment')?.value);
      formData.append('assignedMSeriesBuild', this.assignBuildForm.get('assignedMSeriesBuild')?.value);
      formData.append('assignedIntelBuild', this.assignBuildForm.get('assignedIntelBuild')?.value);
      formData.append('masterId', this.masterId);
      this.buildService.assignBuild(formData).subscribe({
        next: (response) => {
          this.isLoading = false;
          this.assignedupdateSettings = this.assignBuildForm.get('updateSettings')?.value;
          this.assignedbuildAssignment = this.assignBuildForm.get('buildAssignment')?.value;
          this.assignedMSeriesBuild = this.assignBuildForm.get('assignedMSeriesBuild')?.value ?? 0;
          this.assignedUsbUtilityBuild = this.assignBuildForm.get('assignedUsbUtilityBuild')?.value ?? 0;
          this.assignedIntelBuild = this.assignBuildForm.get('assignedIntelBuild')?.value ?? 0;
          this.notify.success('Build assigned successfully');
        },
        error: (error) => {
          this.isLoading = false;
          console.error("Erorr: ", error);
          this.notify.error('Build assignment Error. Please try again later.');
        },
      });
    } else {
      this.notify.error('Build assignment Error. Please check Assigned Builds Carefully.');
    }
  }

  async getPaginationParams() {
    let sort = '';
    let direction = '';
    if (this.sort) {
      ({ active: sort, direction } = this.sort);
    }
    if (sort == 'Version') {
      sort = 'buildNumber';
    } else if (sort == 'Uploaded') {
      sort = 'createdAt';
    }
    let filter = this.getSearchTerm();
    if (filter == '' && this.selectedTabLabel != 'All') {
      filter = this.selectedTabLabel;
    }
    return {
      sort,
      direction,
      page: this.pageIndex,
      size: this.pageSize,
      filter: filter,
    };
  }

  async onPaginateChange({ previousPageIndex = this.pageIndex, pageSize, pageIndex }: PageEvent) {
    this.pageSize = pageSize;
    this.pageIndex = Math.max(0, Math.min(pageIndex + (pageIndex > previousPageIndex ? 1 : -1), this.totalPages - 1));
    await this.loadBuilds();
  }

  public async loadPreviousBuilds() {
    try {
      this.buildService.getPreviousBuilds().subscribe(
        (data) => {
          this.previousBuilds = data || [];
        },
        (error) => {
          this.previousBuilds = [];
        }
      );
    } catch (error) {
      this.previousBuilds = [];
    }
  }

  public async loadAssignmentBuilds() {
    try {
      this.buildService.getAssignmentBuilds().subscribe(
        (data) => {
          this.assignmentBuilds = data || [];
        },
        (error) => {
          this.assignmentBuilds = [];
        }
      );
    } catch (error) {
      this.assignmentBuilds = [];
    }
  }

  public async loadBuilds() {
    this.isLoading = true;
    try {
      const params = await this.getPaginationParams();
      if (params) {
        this.buildService.getBuilds(params.page, params.size, params.sort, params.direction, params.filter).subscribe(
          ({ totalItems, totalPages, data }) => {
            this.isLoading = false;
            Object.assign(this, { buildsLength: totalItems, totalPages, pageIndex: params.page });
            Object.assign(this.paginator, { pageIndex: params.page, length: totalItems, pageSize: params.size });
            this.buildsDataSource.data = data;
            this.buildsDataSource.sort = this.sort;
          },
          (error) => {
            this.isLoading = false;
            this.buildsDataSource.data = [];
            Object.assign(this.paginator, { pageIndex: 0, length: 0, pageSize: 0 });
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  private loadOsImages() {
    this.buildService.getOsImages().subscribe(
      (data) => {
        this.osImages = data;
        this.selectedOsImage = data[0];
      },
      (error) => {
        console.error('Error fetching OS images:', error);
      }
    );
  }

  public addBuild(): void {
    const dialogRef = this.dialog.open(UploadBuildComponent, {
      width: '80vw',
      height: 'auto',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        await this.loadBuilds();
      }
    });
  }

  public async deleteBuild(element: any) {
    const dialogRef = this.dialog.open(DeleteBuildComponent, {
      data: element,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.isLoading = true;
        this.buildService.deleteBuild(element.id).subscribe({
          next: async () => {
            await this.loadBuilds();
            this.isLoading = false;
            this.notify.success('Build deleted successfully');
          },
          error: (err) => {
            console.error('Error deleting build:', err);
          },
        });
      }
    });
  }

  public editBuild(build: Build): void {
    const dialogRef = this.dialog.open(UploadBuildComponent, {
      width: '80vw',
      height: 'auto',
      data: build,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        await this.loadBuilds();
      }
    });
  }

  public shareBuild(build: Build): void {
    const baseUrl = window.location.origin;
    const shareableLink = `${baseUrl}/release-notes/${build.id}`;
    navigator.clipboard.writeText(shareableLink).then(() => {
      this.notify.success('Link copied to clipboard!');
    });
  }

  onMacBuildSelection(event: any): void {
    this.selectedMacBuild = event.value;
  }

  onOSSelection(event: any): void {
    this.selectedOsImage = event.value;
  }

  onClonerSelection(event: any): void {
    this.selectedClonorBuild = event.value;
  }

  getMacBuilds() {
    if (!Array.isArray(this.previousBuilds) || this.previousBuilds.length === 0) {
      return [];
    }
    const macBuilds = this.previousBuilds.filter(({ platform }) => platform === 'Mac OS').sort(this.sortBy('buildNumber', true));
    if (this.selectedMacBuild == null) {
      this.selectedMacBuild = macBuilds[0];
    } else if (this.selectedMacBuild != null) {
      this.selectedMacBuild = this.previousBuilds.find((build: PreviousBuild | null) => build === this.selectedMacBuild) || null;
    }
    return macBuilds;
  }

  getUsbUtilityBuilds() {
    if (!Array.isArray(this.previousBuilds) || this.previousBuilds.length === 0) {
      return [];
    }
    const usbUtilityBuilds = this.previousBuilds.filter(({ platform }) => platform === 'USB Utility').sort(this.sortBy('buildNumber', true));
    if (this.selectedClonorBuild == null) {
      this.selectedClonorBuild = usbUtilityBuilds[0];
    } else {
      this.selectedClonorBuild = this.previousBuilds.find((build: PreviousBuild | null) => build === this.selectedClonorBuild) || null;
    }
    return usbUtilityBuilds;
  }


  getMSeriesBuilds() {
    return this.assignmentBuilds.filter((build: { platform: string; }) => build.platform === 'M-Series').sort(this.sortBy('buildNumber', true)) || [];
  }

  getIntelBuilds() {
    return this.assignmentBuilds.filter((build: { platform: string; }) => build.platform === 'Intel').sort(this.sortBy('buildNumber', true)) || [];
  }

  getUSBUtilityBuilds() {
    return this.assignmentBuilds.filter((build: { platform: string; }) => build.platform === 'USB Utility').sort(this.sortBy('buildNumber', true)) || [];
  }

  sortBy(field: string, descending: boolean = false) {
    return (a: any, b: any) => {
      if (a[field] < b[field]) return descending ? 1 : -1;
      if (a[field] > b[field]) return descending ? -1 : 1;
      return 0;
    };
  }

  public async filterByPlatform(event: any): Promise<void> {
    this.selectedTabLabel = event.tab.textLabel;
    await this.loadBuilds();
  }

}
