<div class="password-protect-container">
  <div class="password-change-header">
    <div>
      <mat-checkbox (change)="changedProtection($event)" [(ngModel)]="protectionEnabled">
        <b>Password Protect</b>
      </mat-checkbox>
    </div>
    <div>
      <button mat-raised-button [hidden]="changingPassword || !protectionEnabled" (click)="changePassword()">Change
        Password</button>
    </div>
    <br>
  </div>
  <div [formGroup]="passwordOptionsGroup" style="padding-left: 2em;" *ngIf="protectionEnabled">
    <mat-checkbox formControlName="newTransaction">
      New Transaction
    </mat-checkbox>
    <br>
    <mat-checkbox formControlName="editTransaction">
      Edit Transaction
    </mat-checkbox>
    <br>
    <mat-checkbox formControlName="updateVendorInfo">
      Update Vendor Info
    </mat-checkbox>
  </div>
  <div class="password-change-form" *ngIf="protectionEnabled && changingPassword">
    <div>
      <mat-form-field class="create-full-width mb-2">
        <mat-label>
          Password
        </mat-label>
        <input [required]="changingPassword" matInput type="text" (copy)="!hidePassword" [ngClass]="hidePassword ? 'input-password': ''"
          autocomplete="off" [(ngModel)]="password" class="login-form-field" tabindex="0" />
        <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hidePassword" tabindex="-1">
          <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>
      <br>
      <mat-form-field class="create-full-width mb-2">
        <mat-label>
          Confirm Password
        </mat-label>
        <input [required]="changingPassword" matInput type="text" (copy)="!hideConfirmPassword" [(ngModel)]="confirmPassword" autocomplete="off"
          [ngClass]="hideConfirmPassword ? 'input-password' : ''" tabindex="0" />
        <button mat-icon-button matSuffix (click)="hideConfirmPassword = !hideConfirmPassword"
          [attr.aria-label]="'Hide confirm password'" [attr.aria-pressed]="hideConfirmPassword" tabindex="-1">
          <mat-icon>{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>
      <div class="button-bar">
        <button mat-raised-button color="primary" type="button" class="save-button" (click)=savePassword()>
          Save Password
        </button>
        <button mat-raised-button type="button" (click)="cancel()">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
