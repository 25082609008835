<h2>{{ field }}</h2>
<div *ngIf="isOemParts">
  <b class="oem-status">Status: {{ oemJson.status }}</b>
  <br>
</div>
<mat-dialog-content>
  <!-- OEM Parts json columns -->
    <table class="json-table" *ngIf="isOemParts">
      <tr class="table-header">
        <td>Status</td>
        <td>Part Name</td>
        <td>Factory Serial</td>
        <td>Current Serial</td>
        <td>Notice</td>
      </tr>
      <tr *ngFor="let part of oemJson.data">
        <td>{{ part['status'] }}</td>
        <td>{{ part['name'] }}</td>
        <td>{{ part['factorySerial'] }}</td>
        <td>{{ part['currentSerial'] }}</td>
        <td>{{ part['notice'] }}</td>
      </tr>
    </table>
<!-- Other json columns -->
    <table class="json-table" *ngIf="!isOemParts">
      <tr *ngFor="let key of getObjectKeys(json)">
        <td>{{ key }}</td>
        <td>{{ json[key] }}</td>
      </tr>
    </table>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button mat-dialog-close>Close</button>
</mat-dialog-actions>
