import { Component} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
const QRCode = require('qrcode');
import { QRCodeErrorCorrectionLevel } from 'qrcode';
import { DeviceInfoService } from '../services/deviceInfo.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-device-info-erasure-report',
  templateUrl: './device-info-erasure-report.component.html',
  styleUrls: ['./device-info-erasure-report.component.css']
})
export class DeviceInfoErasureReportComponent {
  public qrErrorCorrectionLevel: QRCodeErrorCorrectionLevel = 'M';
  public qrCodeImageUrl: string = ''; // Store the QR code image URL
  public qrcodedata :string = '';
  public deviceData : any;
  public deviceId : number = 0;
  constructor(
    private deviceInfoService: DeviceInfoService,private route: ActivatedRoute, private router: Router
  ) {}
  
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.deviceId = params['id'];
    });
      if(this.deviceId){      
        this.getDeviceInfoColumns();       
      }
  }
  private async getDeviceInfoColumns() {
    const res : any = await this.deviceInfoService.getDeviceById(this.deviceId, 'deviceInfo');
    if(res && res.success){
      this.deviceData = res.data;
      this.loadQRCode();
      const pstEraseEndTime = moment.utc( this.deviceData.eraseEndTime).tz(this.deviceData.masterTimeZone);
      this.deviceData.eraseEndTime = pstEraseEndTime.format('YYYY-MM-DD HH:mm:ss');
        setTimeout(() => {
          if (this.deviceData) {
            this.printPage();
          }
        }, 900);
      }
  }

  loadQRCode(){
      // Generate the QR code
      this.qrcodedata = `id=${this.deviceData.id}, Serial=${this.deviceData.serial},Model=${this.deviceData.modelName}`;
      QRCode.toDataURL(this.qrcodedata, {
       errorCorrectionLevel: this.qrErrorCorrectionLevel
     }, (err:any, url:any) => {
       if (err) {
         console.error(err);
       } else {
         // Set the QR code URL
         this.qrCodeImageUrl = url;
       }
     });
  }
  printPage() {
    window.print();
  }
  
}
