<h2>{{title}}</h2>
<div *ngIf="!isCosmetics">
  <b class="profile_name">Route Name: {{ routeName }}</b>
  <br>
  <b class="profile_name">Profile Name: {{ profile }}</b>
</div>
<mat-dialog-content>
  <div class="array-viewer">
    <table class="two-column-table">
      <thead>
        <tr class="table-header" *ngIf="isCosmetics">
          <th>Question</th>
          <th>Answer</th>
        </tr>
        <tr *ngIf="!isCosmetics">
          <th>Category</th>
          <th>Parent ID</th>
          <th>Question</th>
          <th>QID</th>
          <th>Answer</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let item of arrayDataParsed; let i = index">
          <tr *ngIf="isCosmetics">
            <td>{{ i + 1 }}. {{ item.question }}</td>
            <td>{{ item.answer_status }}</td>
          </tr>
          <tr *ngIf="!isCosmetics">
            <td>{{ item?.qCategory ? item.qCategory : '' }}</td>
            <td>{{ item?.parentId ? item.parentId : '' }}</td>
            <td>{{ item?.pTitle ? item.pTitle : (item?.question ? item.question : '') }}</td>
            <td>{{ item?.qId ? item.qId : '' }}</td>
            <td>{{ item?.pTitle ? item.question : (item?.answer ? item.answer : '') }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button mat-raised-button mat-dialog-close>Close</button>
</mat-dialog-actions>
