import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'customization_version': 'v3', 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root'
})
export class LabelService {

  constructor(private http: HttpClient) {

  }
  async getAllLabels() {
    const url = `${environment.apiUrl}/labelMaker/getMasterAllLabels`;

    const labelsRequest: any = await this.http.get(url, httpOptions).toPromise();

    return labelsRequest;
  }

  async delete(id: number): Promise<boolean> {
    const url = `${environment.apiUrl}/labelMaker/deleteLabel/${id}`;

    const labelsRequest: any = await this.http.delete(url, httpOptions).toPromise();

    return labelsRequest.status === 'success';
  }
}
