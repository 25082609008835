import * as XLSX from 'xlsx';

export function getJsonFromXlsxAsync(file: Blob): Promise<Array<Record<string, string>>> {
  return new Promise((resolve, reject) => {
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = ((e: ProgressEvent<FileReader>): void => {
      try {
        const binary = e.target?.result;
        const wb: XLSX.WorkBook = XLSX.read(binary, { type: 'binary' });
        const [worksheetName] = wb.SheetNames;
        const ws: XLSX.WorkSheet = wb.Sheets[worksheetName];
        resolve(XLSX.utils.sheet_to_json(ws));
      }
      catch (error) {
        reject(error);
      }
    });
  });
}

