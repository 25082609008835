<div>
  <h2 class="p-2 pb-1 bgc-gray"> Password Protect this profile: {{data.name}} </h2>
  <div class="p-2">
    <div class="mb-1">
      <mat-checkbox [formControl]="protectProfile" (change)="enableFormGroup()">Password Protect this
        profile</mat-checkbox>
    </div>
    <div>
      <form [formGroup]="passwordProtection">
        <mat-form-field class="w-100">
          <mat-label>Password</mat-label>
          <input matInput type="text" (copy)="!hidePassword" [ngClass]="hidePassword ? 'input-password': ''"
            formControlName="password" autocomplete="off" />
        </mat-form-field>
        <br>
        <br>
        <mat-form-field class="w-100">
          <mat-label>Confirm password</mat-label>
          <input matInput type="text" (copy)="!hideConfirmPassword"
            [ngClass]="hideConfirmPassword ? 'input-password': ''" formControlName="confirmPassword"
            autocomplete="off" />
        </mat-form-field>
        <br>
        <br>
      </form>
    </div>

    <div class="ta-center">
      <button mat-raised-button type="button" (click)="dialogRef.close()">
        Cancel
      </button>
      &nbsp;
      <button mat-raised-button color="primary" type="button" (click)=passwordProtectProfile()
        [disabled]="!protectProfile.value">
        Update
      </button>
    </div>
  </div>
</div>
