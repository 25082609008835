import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { PaginatorData } from '../interfaces/paginatorData.interface';
import { SkuService } from "../services/skuService";
import { getJsonFromXlsxAsync } from "../common/excel";
import { NotificationService } from "../services/notification.service";
import { ToggleMenuService } from "../services/toggle-menu.service";
import { AuthService } from "../services/auth.service";
import { Router } from "@angular/router";

enum SKUSource {
  INVENTORY_SKU = 'inventorySKU',
  CUSTOM_SKU = 'customSKU',
  DISABLE_SKU = 'disableSKU',
};

interface SKUTableEntry {
  id: string,
  filename: string,
  addedOn: string,
}

interface ColumnConfiguration {
  displayName: string,
  field: string,
}

@Component({
  selector: 'app-sku-configuration',
  templateUrl: './sku-configuration.component.html',
  styleUrls: ['./sku-configuration.component.css'],
})
export class SkuConfigurationComponent {
  @ViewChild('paginator') paginator!: MatPaginator;

  public ready: boolean = false;
  public skuEnabled: boolean = false;
  private FIELDS_MAPPING: Record<string, string> = {
    'Model Name': 'modelName',
    'Model Number': 'modelNumber',
    'Storage': 'storage',
    'Carrier': 'carrier',
    'Color': 'color',
    'Grade': 'grade',
    'SKU': 'sku',
  };
  private REQUIRED_FILE_HEADERS: string[] = Object.keys(this.FIELDS_MAPPING);
  public currentFilename: string | undefined;
  public loadedRows: any[] | undefined;
  public paginatorData: PaginatorData = {
    currentPage: 0,
    length: 0,
    pageIndex: 0,
    pageOptions: [10, 20, 50],
    pageSize: 10,
  };
  public columns: ColumnConfiguration[] = [
    { displayName: 'Filename', field: 'filename' },
    { displayName: 'Added On', field: 'addedOn' },
    { displayName: 'Download Link', field: 'downloadLink' },
  ];
  public displayedColumns: string[] = this.columns.map(c => c.field);
  public tableDataSource: SKUTableEntry[] = [];
  public modeSelection: SKUSource = SKUSource.DISABLE_SKU;

  constructor(
    private skuService: SkuService,
    private notificationService: NotificationService,
    private toggleMenuService: ToggleMenuService,
    private authService: AuthService,
    private router: Router,
    private notify: NotificationService,
  ) {
    this.toggleMenuService.allowUseAllMasterData(false);
    this.authService.masterSelectedEmitter.subscribe(() => {
      this.getSKUStatus();
    });

    this.authService.warehouseSelectedEmitter.subscribe(() => {
      this.getSKUStatus();
    })

    if (authService.isMasterContext() || authService.isUserContext() || authService.getSelectedMaster()) {
      this.getSKUStatus();
    } else {
      this.notify.warn('Select an account to view the SKU configuration page.');
      this.router.navigate(['/dashboard']);
    }
  }

  getSKUStatus() {
    this.skuService.getSKUStatus().then(result => {
      this.skuEnabled = !!result?.skuEnabled;
      this.modeSelection = this.skuEnabled ? SKUSource.INVENTORY_SKU : SKUSource.DISABLE_SKU;
      this.ready = true;
      if (this.skuEnabled) this.refresh();
    });
  }

  onSKUOptionChange(o: SKUSource) {
    const skuEnabled = o !== SKUSource.DISABLE_SKU;
    this.ready = false;

    this.skuService.setSKUStatus(skuEnabled).then(() => {
      this.skuEnabled = skuEnabled;
      this.modeSelection = this.skuEnabled ? SKUSource.INVENTORY_SKU : SKUSource.DISABLE_SKU;
      if (skuEnabled)
        this.refresh().finally(() => this.ready = true);
      else
        this.ready = true;
    });
  }

  async onPaginate(event: any) {
    this.paginatorData.pageIndex = event.pageIndex;
    this.paginatorData.pageSize = event.pageSize;

    const page = await this.skuService.getSKUFileList(
      this.paginatorData.pageIndex,
      this.paginatorData.pageSize,
    );

    if (page) {
      const { data } = page;

      this.tableDataSource = data;
    }
  }

  async refresh() {
    this.ready = false;
    const page = await this.skuService.getSKUFileList(
      this.paginatorData.pageIndex,
      this.paginatorData.pageSize,
    );

    if (page) {
      const { data, totalItems } = page;

      this.tableDataSource = data;
      this.paginatorData.length = totalItems;
    }
    this.ready = true;
  }

  onDownload(id: string, filename: string) {
    this.skuService.downloadSKUFile(id, filename);
  }

  private checkRequiredHeaders(headers: string[]): boolean {
    return this.REQUIRED_FILE_HEADERS.map(header => headers.includes(header))
      .some(i => i);
  }

  private checkRowContent(headers: string[], row: any): [boolean, string[]] {
    const missing: string[] = [];
    const available: string[] = [];

    headers.forEach(header => {
      const value = row[header];

      if (value === undefined || value === null || value === '') {
        missing.push(header);
      } else {
        available.push(header);
      }
    });

    return [available.length > 0, missing];
  }

  private mapKeys(row: Record<string, string>) {
    const newRow: Record<string, string> = {};

    this.REQUIRED_FILE_HEADERS.forEach(header => {
      newRow[this.FIELDS_MAPPING[header]] = row[header];
    });

    return newRow;
  }

  async onFileSelected(event: any) {
    const files = event.target.files;

    if (files.length > 0) {
      const file = files[0];
      const content = await getJsonFromXlsxAsync(file);
      const [firstRow] = content;

      if (!firstRow) {
        this.notificationService.warn('The selected file is empty');
        this.clearLoadedFile();

        return;
      }

      const fileHeaders = Object.keys(firstRow);
      const someHeadersPresent = this.checkRequiredHeaders(fileHeaders);
      const missingRowValues = content.map(row => {
        return this.checkRowContent(this.REQUIRED_FILE_HEADERS, row);
      });
      const atLeastOneIncomplete = missingRowValues.filter(missing => {
        return !missing[0];
      }).length > 0;

      if (atLeastOneIncomplete) {
        this.notificationService.warn('Some rows don\'t have at least one of the required values.');
        return;
      }

      this.loadedRows = content.map(row => this.mapKeys(row));
      this.currentFilename = file.name;
    }
  }

  async upload() {
    if (this.currentFilename && this.loadedRows && this.loadedRows.length > 0) {
      await this.skuService.uploadFileInfo(this.currentFilename, this.loadedRows);
      this.refresh();
      this.clearLoadedFile();
    }
  }

  clearLoadedFile() {
    this.currentFilename = undefined;
    this.loadedRows = undefined;
  }

  onDownloadTemplate() {
    this.skuService.downloadSKUTemplate();
  }
}
