<div fxLayout="row">
  <div class="input-name-cell">
    <mat-checkbox [formControl]="automaticIMEICheck">
      <span class="primary-text">Enable Automatic IMEI Check</span>
    </mat-checkbox>
  </div>
</div>
<mat-divider></mat-divider>
<div fxLayout="column" [hidden]="automaticIMEICheck.value" *ngIf="isAutomaticIMEICheckEnabled()"
  [formGroup]="imeiCheckForm">
  <div fxLayout="row">
    <div class="input-name-cell">
      <span class="primary-text">Carrier Setting</span>
    </div>
    <div class="input-cell">
      <mat-checkbox formControlName="usFinancialCarrierSetting">
        <span class="primary-text">Enable Financial Eligibility Check for US Carriers</span><br>
        <span class="secondary-text">Checks US carriers Verizon, T-Mobile/Sprint, or Tracfone/Straight Talk for financial eligibility status.
          status.</span>
      </mat-checkbox>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div fxLayout="row" fxLayout.sm="column">
    <div class="input-name-cell">
      <span class="primary-text">IMEI Check Settings</span><br>
      <span class="secondary-text">Select which setting best fits your needs</span>
      <br>
    </div>
    <div class="input-cell">
      <mat-radio-group formControlName="name">
        <mat-radio-button value="Most Accurate">
          <span class="primary-text">Most Accurate</span><br>
          <span class="secondary-text">Consumes up to 5 ESN licenses and 1 Carrier Lock license. Most comprehensive
            check with US carriers.</span>
          <br>
          <a target="_blank" href="https://historyreport.phonecheck.com/report/rli9eu859">View sample report.</a>
          <br>
        </mat-radio-button>
        <br>
        <br>
        <mat-radio-button value="Cost Effective">
          <span class="primary-text">Cost Effective</span><br>
          <span class="secondary-text">Consumes 1 ESN license and 1 Carrier Lock license.</span><br>
        </mat-radio-button>
        <br>
        <br>
        <mat-radio-button value="Custom Workflow">
          <span class="primary-text">Custom</span><br>
          <span class="secondary-text">Select custom settings for the IMEI check.</span>
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</div>

<mat-divider *ngIf="isAutomaticIMEICheckEnabled()"></mat-divider>

<div fxLayout="column" fxLayout.sm="row" *ngIf="isAutomaticIMEICheckEnabled()"
  [ngClass]="{ 'disabled-text': !isCustomSelected() }" [formGroup]="configurationForm">
  <div fxLayout="row" fxLayout.sm="column" [ngClass]="{ 'disabled-text': !isCustomSelected() }"
    [formGroup]="configurationForm">
    <div class="input-name-cell">
      <span class="primary-text">Carrier / SIM Lock:</span><br>
      <span class="secondary-text">Action to take when the specified make is detected</span>
    </div>
    <div class="input-cell">
      <div fxLayout="column">
        <div fxLayout="row" fxHide.sm>
          <div class="primary-text input-name-cell">Make</div>
          <div class="primary-text input-name-cell">Action</div>
        </div>
        <div fxLayout="row" fxLayout.sm="column">
          <div class="input-name-cell">Apple iPhone Devices</div>
          <div class="input-cell">
            <mat-form-field subscriptSizing="dynamic">
              <mat-select formControlName="appleIPhoneAction">
                <mat-option value="CHECK_CARRIER_IF_UNKNOWN_AND_CHECK_SIMLOCK">Check carrier if unknown and check SIM
                  Lock</mat-option>
                <mat-option value="ALWAYS_CHECK_CARRIER_AND_SIMLOCK">Always check Carrier and Check SIM
                  Lock</mat-option>
                <mat-option value="ALWAYS_CHECK_SIMLOCK_ONLY">Always check SIM Lock only</mat-option>
                <mat-option value="DO_NOT_CHECK">Do not check</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" fxLayout.sm="column">
          <div class="input-name-cell">Apple iPad Devices</div>
          <div class="input-cell">
            <mat-form-field subscriptSizing="dynamic">
              <mat-select formControlName="appleIPadAction">
                <mat-option value="CHECK_CARRIER_IF_UNKNOWN_AND_CHECK_SIMLOCK">Check carrier if unknown and check SIM
                  Lock</mat-option>
                <mat-option value="ALWAYS_CHECK_CARRIER_AND_SIMLOCK">Always check Carrier and Check SIM
                  Lock</mat-option>
                <mat-option value="ALWAYS_CHECK_SIMLOCK_ONLY">Always check SIM Lock only</mat-option>
                <mat-option value="DO_NOT_CHECK">Do not check</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" fxLayout.sm="column">
          <div class="input-name-cell">Samsung Devices</div>
          <div class="input-cell">
            <mat-form-field subscriptSizing="dynamic">
              <mat-select formControlName="samsungAction" name="samsungAction">
                <mat-option value="CHECK_CARRIER_IF_UNKNOWN">Check carrier if unknown</mat-option>
                <mat-option value="DO_NOT_CHECK">Do not check</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<mat-divider *ngIf="isAutomaticIMEICheckEnabled()"></mat-divider>

<div fxLayout="column" *ngIf="isAutomaticIMEICheckEnabled()">
  <div fxLayout="row" fxLayout.sm="column" [ngClass]="{ 'disabled-text': !isCustomSelected() }"
    [formGroup]="configurationForm">
    <div class="input-name-cell">
      <span class="primary-text input-name-cell">Carrier specific actions</span><br>
      <span class="secondary-text">Action to take when the specified carrier is detected</span>
    </div>
    <div class="input-cell">
      <div fxLayout="column">
        <div fxLayout="row" fxHide.sm>
          <div class="primary-text input-name-cell">Carrier</div>
          <div class="primary-text input-name-cell">Action</div>
        </div>
        <div fxLayout="row" fxLayout.sm="column">
          <div class="input-name-cell">Not supported or unknown carrier</div>
          <div class="input-cell">
            <mat-form-field subscriptSizing="dynamic">
              <mat-select formControlName="notSupportedOrUnknownCarrierAction"
                name="notSupportedOrUnknownCarrierAction">
                <mat-option value="CHECK_ALL_POSIBLE_DATABASES">Check all possible databases</mat-option>
                <mat-option value="PROMPT_OPERATOR_FOR_INPUT">Promt operator for input</mat-option>
                <mat-option value="CHECK_GLOBAL_BLACKLIST_ONLY">Check global blacklist only</mat-option>
                <mat-option value="TAKE_NO_ACTION">Take no action</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" fxLayout.sm="column">
          <div class="input-name-cell">Unlocked</div>
          <div class="input-cell">
            <mat-form-field subscriptSizing="dynamic">
              <mat-select formControlName="unlockedAction" name="unlockedAction">
                <mat-option value="CHECK_ALL_POSIBLE_DATABASES">Check all possible databases</mat-option>
                <mat-option value="PROMPT_OPERATOR_FOR_INPUT">Promt operator for input</mat-option>
                <mat-option value="CHECK_GLOBAL_BLACKLIST_ONLY">Check global blacklist only</mat-option>
                <mat-option value="TAKE_NO_ACTION">Take no action</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" fxLayout.sm="column">
          <div class="input-name-cell">Verizon</div>
          <div class="input-cell">
            <mat-form-field subscriptSizing="dynamic">
              <mat-select formControlName="verizonAction" name="verizonAction">
                <mat-option value="CHECK_ALL_POSIBLE_DATABASES">Check all possible databases</mat-option>
                <mat-option value="CHECK_VERIZON">Check Verizon</mat-option>
                <mat-option value="CHECK_GLOBAL_BLACKLIST_ONLY">Check global blacklist only</mat-option>
                <mat-option value="TAKE_NO_ACTION">Take no action</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" fxLayout.sm="column">
          <div class="input-name-cell">T-Mobile</div>
          <div class="input-cell">
            <mat-form-field subscriptSizing="dynamic">
              <mat-select formControlName="tmobileAction" name="tmobileAction">
                <mat-option value="CHECK_ALL_POSIBLE_DATABASES">Check all possible databases</mat-option>
                <mat-option value="CHECK_TMOBILE">Check T-Mobile</mat-option>
                <mat-option value="CHECK_GLOBAL_BLACKLIST_ONLY">Check global blacklist only</mat-option>
                <mat-option value="TAKE_NO_ACTION">Take no action</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" fxLayout.sm="column">
          <div class="input-name-cell">AT&amp;T</div>
          <div class="input-cell">
            <mat-form-field subscriptSizing="dynamic">
              <mat-select formControlName="attAction" name="attAction">
                <mat-option value="CHECK_ALL_POSIBLE_DATABASES">Check all possible databases</mat-option>
                <mat-option value="CHECK_ATT">Check AT&amp;T</mat-option>
                <mat-option value="CHECK_GLOBAL_BLACKLIST_ONLY">Check global blacklist only</mat-option>
                <mat-option value="TAKE_NO_ACTION">Take no action</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" fxLayout.sm="column">
          <div class="input-name-cell">Tracfone/Straight Talk</div>
          <div class="input-cell">
            <mat-form-field subscriptSizing="dynamic">
              <mat-select formControlName="tracfoneAction" name="tracfoneAction">
                <mat-option value="CHECK_ALL_POSIBLE_DATABASES">Check all possible databases</mat-option>
                <mat-option value="CHECK_TRACFONE">Check Tracfone/Straight Talk</mat-option>
                <mat-option value="CHECK_GLOBAL_BLACKLIST_ONLY">Check global blacklist only</mat-option>
                <mat-option value="TAKE_NO_ACTION">Take no action</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
