import { OnInit, Component, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl } from "@angular/forms";
import { CustomizationService } from "../../services/customization.service";
import { NotificationService } from "../../services/notification.service";
import { AssignmentIdsPayload } from "../../interfaces/assignment-ids-payload.interface";


interface Assignable {
  id: string;
  name: string;
  assigned: boolean;
  warehouse?: {
    id: string;
    name: string;
  };
}

interface DialogInput {
  assignables: Assignable[],
  type: 'WAREHOUSE' | 'STATION',
  profile: CustomizationProfile;
}

interface CustomizationProfile {
  id: string,
  name: string,
  warehouses: number,
  stations: number,
  createdAt: string,
  updatedAt: string,
}

@Component({
  selector: 'app-assign-checklist',
  templateUrl: './assign-checklist.component.html',
  styleUrls: ['./assign-checklist.component.css']
})
export class AssignChecklistComponent implements OnInit {
  assignables: Assignable[] | undefined;
  formControls: Record<string, FormControl> = {};
  profile: CustomizationProfile;
  type: 'WAREHOUSE' | 'STATION';
  updating: boolean = false;
  title: string;
  nameHeader: string;
  selectAllEnabled: boolean;
  selectAllFormControl: FormControl;
  loading: boolean = true;
  searchStationFormControl: FormControl = new FormControl('');
  stationsToShow: any[] = [];

  constructor(
    private dialogRef: MatDialogRef<AssignChecklistComponent>,
    private customizationService: CustomizationService,
    private notify: NotificationService,
    @Inject(MAT_DIALOG_DATA) data: DialogInput,
  ) {
    this.type = data.type,
      this.profile = data.profile;
    this.setData(data.type, data.profile).then(() => {
      this.initializeFormControls();
      this.stationsToShow = this.assignables!;
      this.searchStationFormControl.statusChanges.subscribe(() => {
        this.onSearchTermChange();
      });
    });
    this.title = this.type === 'WAREHOUSE'
      ? `Share profile with Warehouses: ${this.profile.name}`
      : `Assign profile to Stations: ${this.profile.name}`;
    this.nameHeader = this.type === 'WAREHOUSE'
      ? 'Warehouse name'
      : 'Station';
    this.selectAllEnabled = this.type === 'STATION';
    this.selectAllFormControl = new FormControl(false);

    if (this.selectAllEnabled) {
      this.selectAllFormControl.valueChanges.subscribe(value => {
        const selected = this.selectAllFormControl?.getRawValue();

        this.selectAll(value);
      });
    }
  }

  private selectAll(value = true) {
    Object.values(this.formControls).forEach(f => {
      f.setValue(value, { emitEvent: false });
    });
  }

  public ngOnInit() {
  }

  private initializeFormControls() {
    if (this.assignables?.length) {
      for (const a of this.assignables) {
        const control = new FormControl(a.assigned);
        this.formControls[a.id] = control;
        if (a.id == '999999') {

          this.formControls['999999'].valueChanges.subscribe(checked => {
            for (let formControlsKey in this.formControls) {
              if (formControlsKey !== '999999') {
                if (checked) {
                  this.formControls[formControlsKey].setValue(false);
                  this.formControls[formControlsKey].disable();
                } else {
                  this.formControls[formControlsKey].enable();
                }
              }
            }
          });
        }

      }
    }
  }

  public async onUpdate() {
    this.updating = true;
    let { valid, payload, numberOfEntitiesSelected } = this.getAssignmentUpdatePayload();
    let result = { updated: true, numberOfEntitiesSelected };

    if (valid) {
      try {
        const removePreviousAssignment = true;
        await this.customizationService.updateAssignments(this.profile.id, payload, removePreviousAssignment);
        this.notify.success('Assignments successfully created.');
        this.updating = false;
        result.updated = true;

      } catch (err) {
        console.error('Could not update assignments.', err);

        result = { updated: false, numberOfEntitiesSelected: 0 };
      }
    }

    this.updating = false;
    this.dialogRef.close(result);
  }

  public onCancel() {
    const result = { updated: false };

    this.dialogRef.close(result);
  }

  private getAssignmentUpdatePayload(): {
    valid: boolean,
    payload: AssignmentIdsPayload,
    numberOfEntitiesSelected: number,
  } {
    let numberOfEntitiesSelected = 0;

    const ids: string[] = [];
    const payload: AssignmentIdsPayload = {
      warehouseIds: undefined,
      stationIds: undefined,
    };
    Object.keys(this.formControls).forEach(id => {
      const control = this.formControls[id];
      const checked = control.getRawValue();

      if (checked) {
        ids.push(id);
        numberOfEntitiesSelected++;
      }
    });

    if (this.type === 'WAREHOUSE') {
      payload.warehouseIds = ids;
    } else {
      payload.stationIds = ids;
    }

    return { valid: true, payload, numberOfEntitiesSelected };
  }

  private async setData(type: 'WAREHOUSE' | 'STATION', profile: CustomizationProfile) {
    let assignables;

    if (type === 'STATION') {
      const stationsResults = await this.customizationService.getStations(profile.id);
      assignables = stationsResults?.map(s => {
        return {
          id: s.id,
          name: s.name,
          assigned: s.assigned,
          warehouse: s.warehouse,
        }
      });
    } else {
      const warehouseResults = await this.customizationService.getWarehouses(profile.id);
      assignables = warehouseResults?.map(s => {
        return {
          id: s.id,
          name: s.name,
          assigned: s.assigned,
        }
      });
    }

    this.assignables = assignables;
    this.loading = false;
  }

  private onSearchTermChange() {
    const input: string = this.searchStationFormControl.getRawValue().trim();

    if (input) {
      this.stationsToShow = this.filterStations(input);
    }
    else {
      this.stationsToShow = this.assignables!;
    }
  }

  private filterStations(name: string) {
    const stations = this.assignables

    return stations!.filter(station => {
      return station.name.toLowerCase().includes(name.toLowerCase());
    });
  }
}


