import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from "@angular/forms";
import { Observable } from 'rxjs';
import { CustomizationService } from "../services/customization.service";
import { Station } from "../interfaces/station.interface";
import { NotificationService } from '../services/notification.service';

interface Profile {
  id: string,
  name: string,
}

interface Warehouse {
  id: number,
  name: string,
  stations: Station[],
}

@Component({
  selector: 'app-assignment',
  templateUrl: './assignment.component.html',
  styleUrls: ['./assignment.component.css']
})
export class AssignmentComponent implements OnInit {
  @Input() profileSubject: Observable<Profile[]> = new Observable<Profile[]>();
  @Input() selectedIndex: number = 1;
  @Output() selectedIndexChange: EventEmitter<number> = new EventEmitter<number>();

  profiles: Profile[] = [];
  stationsById: Record<string, Station> = {};
  saving: boolean = false;
  bulkProfileFormControl: FormControl = new FormControl({ value: undefined, disabled: true });
  stationsCheckFormControls: Record<string, FormControl>;
  stationsProfileFormControls: Record<string, FormControl> = {};
  customizationService: CustomizationService;
  warehouses: Warehouse[] | undefined;
  loadingWarehouses: boolean = true;
  allStations: Station[] = [];
  warehousesById: Record<string, Warehouse> = {};
  stationsToShow: Station[] = [];
  searchStationFormControl: FormControl;
  currentWarehouse: Warehouse | undefined;

  constructor(
    @Inject(CustomizationService) customizationService: CustomizationService,
    private notify: NotificationService,
  ) {
    this.customizationService = customizationService;
    this.stationsCheckFormControls = {};
    // this.stationsProfileFormControls = {};
    this.customizationService.getWarehousesWithStations().then((warehouses: any) => {
      this.loadingWarehouses = false;
      this.warehouses = warehouses;
      this.setWarehouses(this.warehouses);
    });
    this.searchStationFormControl = new FormControl('');
    this.searchStationFormControl.statusChanges.subscribe(() => {
      this.onSearchTermChange();
    });
    this.profiles = [];
    this.setStations();
  }

  private onSearchTermChange() {
    const input: string = this.searchStationFormControl.getRawValue().trim();

    if (input) {
      this.stationsToShow = this.filterStations(input);
    }
    else {
      this.stationsToShow = this.currentWarehouse?.stations || this.allStations;
    }
  }

  public showAssignedSelected(): boolean {
    let selected = false;

    Object.values(this.stationsCheckFormControls).forEach(f => {
      selected ||= f.getRawValue();
    });

    return selected;
  }

  private setWarehouses(warehouses: Warehouse[] | undefined) {
    warehouses?.forEach(warehouse => {
      this.warehousesById[warehouse.id] = warehouse;

      warehouse.stations.forEach(station => {
        const checkControl = new FormControl(false);
        const stationProfileControl = new FormControl(station.assignedProfile?.id);

        this.stationsById[station.id] = station;

        stationProfileControl.statusChanges.subscribe(() => {
          checkControl.setValue(true);
        });

        checkControl.statusChanges.subscribe(() => {
          if (this.showAssignedSelected()) {
            this.bulkProfileFormControl.enable();
          } else {
            this.bulkProfileFormControl.disable();
          }
        })

        this.stationsCheckFormControls[station.id] = checkControl;
        this.stationsProfileFormControls[station.id] = stationProfileControl;

        if (warehouse) {
          station.warehouse = { name: warehouse.name }
        }

        if (!this.allStations.some(e => e.id == station.id)) {
          this.allStations.push(station);
        }
      });
    });
    this.stationsToShow = this.allStations;
  }

  public onWarehouseChange(event: any): void {
    this.loadingWarehouses = true;
    const id = String(event.value);

    if (id === 'all') {
      this.stationsToShow = this.allStations;
      this.currentWarehouse = undefined;
    } else {
      const warehouse = this.warehousesById[id];
      this.stationsToShow = warehouse.stations;
      this.currentWarehouse = warehouse;
    }

    this.onSearchTermChange();
    this.loadingWarehouses = false;
  }

  private filterStations(name: string) {
    const stations = this.currentWarehouse?.stations || this.allStations;

    return stations.filter(station => {
      return station.name.toLowerCase().includes(name.toLowerCase());
    });
  }

  public ngOnInit() {
    this.profileSubject.subscribe(p => {
      this.setProfiles(p);
    });
  }

  public setProfiles(profiles: any[]) {
    this.profiles = profiles;
    this.setWarehouses(this.warehouses);
  }

  public getData() {
    const data: any[] = [];
    const bulkProfile = this.bulkProfileFormControl.getRawValue();



    Object.keys(this.stationsCheckFormControls).forEach(stationId => {
      const control = this.stationsCheckFormControls[stationId];
      const profileControl = this.stationsProfileFormControls[stationId];
      const selected = control.getRawValue();
      const profileId = bulkProfile || profileControl.getRawValue();

      if (selected && profileId && (bulkProfile || profileControl.dirty)) {
        data.push({
          stationId,
          profileId,
        });
      }
    });
    return { assignments: data };
  }

  public async onSave() {
    this.saving = true;
    const bulkProfile = this.bulkProfileFormControl.getRawValue();

    if (bulkProfile) {
      Object.keys(this.stationsCheckFormControls).forEach(stationId => {
        const selected = this.stationsCheckFormControls[stationId].getRawValue();

        if (selected) {
          this.stationsProfileFormControls[stationId].setValue(bulkProfile, { emitEvent: false });
        }
      });
    }

    await this.customizationService.bulkAssign(this.getData());
    this.notify.success('Assignments successfully created.');
    this.saving = false;
  }

  public async setStations() {
    const allStations: any = await this.customizationService.getAllStations();
    this.allStations = allStations;
    this.allStations.forEach(station => {
      const checkControl = new FormControl(false);
      const stationProfileControl = new FormControl(station.assignedProfile?.id);

      this.stationsById[station.id] = station;

      stationProfileControl.statusChanges.subscribe(() => {
        checkControl.setValue(true);
      });
      checkControl.statusChanges.subscribe(() => {
        if (this.showAssignedSelected()) {
          this.bulkProfileFormControl.enable();
        } else {
          this.bulkProfileFormControl.disable();
        }
      });

      this.stationsCheckFormControls[station.id] = checkControl;
      this.stationsProfileFormControls[station.id] = stationProfileControl;
      this.stationsToShow = this.allStations;
    });
  }

  public checkAllStations(event: any) {
    Object.entries(this.stationsCheckFormControls).forEach(([k, v]) => {
      if (this.stationsToShow.find(sts => sts.id.toString() == k.toString())) {
        this.stationsCheckFormControls[k].setValue(event);
      }
    });
  }

  public backToMainPage() {
    this.selectedIndexChange.emit(0);
  }
}
