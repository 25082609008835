import { debounceTime } from 'rxjs';
import { Component, OnInit, EventEmitter } from '@angular/core';
import moment, { Moment }from 'moment-timezone';
import { PaginatorData } from '../interfaces/paginatorData.interface';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { FormControl, FormGroupDirective, NgForm } from "@angular/forms";
import { NotificationService } from '../services/notification.service';
import { DeviceInfoService } from '../services/deviceInfo.service';
import { ReportRangeType } from "../common/report-range-type.enum";
import { ReportEntityType } from "../common/report-entity-type.enum";
import { ReportCountType } from "../common/report-count-type.enum";
import { DownloadFormat } from "../common/download";
import { ToggleMenuService } from "../services/toggle-menu.service";
import { ErrorStateMatcher } from "@angular/material/core";
import { AuthService } from "../services/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-activity-reports',
  templateUrl: './activity-reports.component.html',
  styleUrls: ['./activity-reports.component.css']
})
export class ActivityReportsComponent implements OnInit {
  public paginatorData: PaginatorData = {
    pageIndex: 0,
    length: 0,
    pageSize: 10,
    currentPage: 0,
    pageOptions: [10,20,50],
  };

  public startDate: Moment = moment().startOf('day');
  public endDate: Moment = moment().startOf('day');

  public rangeType: ReportRangeType = ReportRangeType.DAILY;
  public entityType: ReportEntityType = ReportEntityType.USER;
  public countType: ReportCountType = ReportCountType.COMPLETION;
  public errorLoadingData = false;
  public targetUPH:  number | null | undefined;
  public minimumUPH: number | null | undefined;
  public searchFormControl: FormControl<string | null> = new FormControl('');

  public loadingSummary = false;
  public loadingActivity = false;
  public updatingUPH = false;

  private dateChangeEmitter = new EventEmitter<DateRange>();
  private dateRangeErrorStateEmitter = new EventEmitter<boolean>();
  public dailyErrorMatcher = new DailyDateRangeErrorMatcher(
    this.dateChangeEmitter,
    this.dateRangeErrorStateEmitter,
  );

  private lastSearched = {
    startDate: this.startDate,
    endDate: this.endDate,
    entityType: this.entityType,
    countType: this.countType,
    searchTerm: null as string | null,
  };

  private lastActivityFetched = [];

  public TIME_COLUMNS = [
    { field: '0000', displayName: '00:00' },
    { field: '0100', displayName: '01:00' },
    { field: '0200', displayName: '02:00' },
    { field: '0300', displayName: '03:00' },
    { field: '0400', displayName: '04:00' },
    { field: '0500', displayName: '05:00' },
    { field: '0600', displayName: '06:00' },
    { field: '0700', displayName: '07:00' },
    { field: '0800', displayName: '08:00' },
    { field: '0900', displayName: '09:00' },
    { field: '1000', displayName: '10:00' },
    { field: '1100', displayName: '11:00' },
    { field: '1200', displayName: '12:00' },
    { field: '1300', displayName: '13:00' },
    { field: '1400', displayName: '14:00' },
    { field: '1500', displayName: '15:00' },
    { field: '1600', displayName: '16:00' },
    { field: '1700', displayName: '17:00' },
    { field: '1800', displayName: '18:00' },
    { field: '1900', displayName: '19:00' },
    { field: '2000', displayName: '20:00' },
    { field: '2100', displayName: '21:00' },
    { field: '2200', displayName: '22:00' },
    { field: '2300', displayName: '23:00' },
  ];

  public summaryColumns = [
    { field: 'totalDevices', displayName: 'Total Devices' },
    ...this.TIME_COLUMNS
  ];
  public summaryDisplayedColumns = this.summaryColumns.map(c => c.field);
  public summaryList: any[] = [];

  public BY_USER_COLUMS = [
    { field: 'userName', displayName: 'User' },
    { field: 'warehouseName', displayName: 'Warehouse' },
    { field: 'totalDevices', displayName: 'Total Devices' },
    { field: 'average', displayName: 'Average' },
    { field: 'tested', displayName: 'Tested' },
    { field: 'erased', displayName: 'Erased' },
    ...this.TIME_COLUMNS,
  ];
  public BY_STATION_COLUMNS = [
    { field: 'stationName', displayName: 'Station' },
    { field: 'warehouseName', displayName: 'Warehouse' },
    { field: 'totalDevices', displayName: 'Total Devices' },
    { field: 'average', displayName: 'Average' },
    { field: 'tested', displayName: 'Tested' },
    { field: 'erased', displayName: 'Erased' },
    ...this.TIME_COLUMNS,
  ];
  public activityColumns = this.BY_USER_COLUMS;

  public activityDisplayedColumns = this.activityColumns.map(c => c.field);
  public activityList: any[] = [];
  public allActivity: any = [];

  constructor(
    private deviceInfoService: DeviceInfoService,
    private notifier: NotificationService,
    private appService: ToggleMenuService,
    private authService: AuthService,
    private router: Router,
  ) {
    this.appService.allowUseAllMasterData(false);

    if (this.authService.isMasterSelected()) {
      this.searchFormControl.valueChanges.pipe(
        debounceTime(400)
      ).subscribe(value => {
        this.updateActivityInfo(value);
      });
      this.getUPHSettings();
      this.dateRangeErrorStateEmitter.subscribe((dateRangeError => {
        this.dateRangeError = dateRangeError;
      }))
    } else {
      this.notifier.warn('Select an account to view the Activity Reports');
      this.router.navigate(['/dashboard']);
    }
  }

  public dateRangeError: boolean = false;

  private validateDate(input: Date | null): boolean {
    const date = moment(input);

    return date.isValid();
  }

  public onDateChange() {
    this.dateChangeEmitter.emit({
      start: this.startDate,
      end: this.endDate || this.startDate
    });
  }

  public onFilterUpdate() {
    if (!this.dateRangeError) {
      this.updateActivityInfo();
      this.updateActivitySummary();
    }
  }

  public invalidDateRange = true;

  private getToday(): string {
    return moment().format("DD-MM-YYYY");
  }

  public getTableValue(v: number | string | undefined | null) {
    return v === null || v === undefined
      ? '-' : v;
  }


  public getAverageClass(field: string, avg: number | undefined): string {
    if (field !== 'average') {
      return '';
    }

    if (avg === undefined || avg === null) {
      return 'neutral-avg-color';
    }

    if (this.targetUPH !== null && this.targetUPH !== undefined && avg >= this.targetUPH) {
      return 'above-UPH';
    }

    if (this.minimumUPH !== null && this.minimumUPH !== undefined && avg < this.minimumUPH) {
      return 'below-minimum-UPH';
    }

    return 'neutral-avg-color';
  }

  public ngOnInit() {
    this.updateActivityInfo();
    this.updateActivitySummary();
  }

  private updateActivitySummary() {
    this.loadingSummary = true;

    this.deviceInfoService.getActivityReportSummary(
      this.startDate,
      this.endDate,
      this.entityType,
      this.countType,
    ).then(summary => {
      if (summary) {
        this.summaryList = [ summary ];
      }
    }).finally(() => {
      this.loadingSummary = false;
    });
  }

  private updateLastSearched(term: string | null = null) {
    this.lastSearched = {
      startDate: this.startDate,
      endDate: this.endDate,
      entityType: this.entityType,
      countType: this.countType,
      searchTerm: term,
    };
  }

  private updateActivityInfo(searchTerm?: string | null) {
    this.loadingActivity = true;
    this.updateLastSearched(searchTerm);

    if (!searchTerm) {
      this.searchFormControl.setValue('', { emitEvent: false });
    }

    this.deviceInfoService.getActivityReport(
      this.startDate,
      this.endDate,
      this.entityType,
      this.countType,
      searchTerm,
    ).then(activity => {
      this.setActivityColumns();
      if (activity) {
        this.paginatorData.pageIndex = 0;
        this.allActivity = activity || [];
        this.activityList = activity?.slice(this.paginatorData.pageIndex * this.paginatorData.pageSize, this.paginatorData.pageSize) || [];
        this.paginatorData.length = activity.length;
      }
    }).finally(() => {
      this.loadingActivity = false;
    });
  }

  private setActivityColumns() {
    if (this.entityType === ReportEntityType.USER) {
      this.activityColumns = this.BY_USER_COLUMS;
    } else if (this.entityType === ReportEntityType.STATION) {
      this.activityColumns = this.BY_STATION_COLUMNS;
    }

    this.activityDisplayedColumns = this.activityColumns.map(c => c.field);
  }

  public downloadActivityReportCSV() {
    const { startDate, endDate, entityType, countType, searchTerm } = this.lastSearched;

    this.loadingActivity = true;
    this.deviceInfoService.downloadActivityReport(
      startDate,
      endDate,
      entityType,
      countType,
      searchTerm,
      DownloadFormat.CSV
    ).finally(() => {
      this.loadingActivity = false;
    });
  }

  public downloadActivityReportExcel() {
    const { startDate, endDate, entityType, countType, searchTerm } = this.lastSearched;

    this.loadingActivity = true;
    this.deviceInfoService.downloadActivityReport(
      startDate,
      endDate,
      entityType,
      countType,
      searchTerm,
      DownloadFormat.EXCEL
    ).finally(() => {
      this.loadingActivity = false;
    });
  }

  public copyActivityReport() {
    const { startDate, endDate, entityType, countType, searchTerm } = this.lastSearched;

    this.loadingActivity = true;
    this.deviceInfoService.copyActivityReport(
      startDate,
      endDate,
      entityType,
      countType,
      searchTerm,
    ).finally(() => {
      this.loadingActivity = false;
    });
  }


  public onUPHUpdate() {
    const target = this.targetUPH === undefined ? null : this.targetUPH;
    const minimum = this.minimumUPH === undefined ? null : this.minimumUPH;

    this.updatingUPH = true;

    this.deviceInfoService.updateUPHSettings(target, minimum).finally(() => {
      this.updatingUPH = false;
    });
  }

  private getUPHSettings() {
    this.deviceInfoService.getUPHSettigs()
    .then(settings => {
      if (settings) {
        const { targetUPH, minimumUPH } = settings;

        this.targetUPH = targetUPH;
        this.minimumUPH = minimumUPH;
      }
    });
  }

  public onPaginate(event: any) {
    this.paginatorData.pageSize = event.pageSize;
    this.paginatorData.pageIndex = event.pageIndex;

    this.showPage();
  }

  public showPage() {
    const start = this.paginatorData.pageIndex * this.paginatorData.pageSize;
    const end = start + this.paginatorData.pageSize;

    this.activityList = this.allActivity.slice(start, end) || [];
  }
}

type DateRange = {
  start: Moment,
  end: Moment,
}

class DailyDateRangeErrorMatcher implements ErrorStateMatcher {
  private range: DateRange = {
    start: moment(),
    end: moment(),
  }

  private emitter: EventEmitter<DateRange>;
  private isErrorEmmiter: EventEmitter<boolean>;

  constructor(
    emitter: EventEmitter<DateRange>,
    isErrorEmmiter: EventEmitter<boolean>,
  ) {
    this.emitter = emitter;
    this.isErrorEmmiter = isErrorEmmiter;

    this.emitter.subscribe(range => {
      this.range = range;
    })
  }

  isErrorState(control: FormControl<Moment>| null, form: FormGroupDirective | NgForm | null): boolean {
    const upper = this.range.start.clone().add(1, 'days').endOf('day');
    const isError = this.range.end.isAfter(upper);

    this.isErrorEmmiter.emit(isError);

    return isError;
  }

}
