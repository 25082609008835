import exceljs from 'exceljs';
import { Injectable } from '@angular/core';
import * as fs from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  private static TEMPLATE_HEADERS = [
    { header: 'IMEI', key: 'imei', width: 30 },
    { header: 'IMEI2', key: 'imei2', width: 30 },
    { header: 'Serial', key: 'serial', width: 30 },
    { header: 'LPN', key: 'lpn', width: 30 },
    { header: 'Decimal MEID', key: 'decimalMeid', width: 30 },
    { header: 'Custom 1', key: 'custom1', width: 30 },
    { header: 'Model', key: 'modelName', width: 30 },
  ];
  private static TEMPLATE_DATA = [
    {
      imei: '123456789012345',
      imei2: '123456789012346',
      serial: 'ABCDEFGHIJX123',
      lpn: 'YXWLX',
      decimalMeid: '1234567890',
      custom1: 'devicecode',
      modelName: 'iPhone 11'
    },
  ];

  constructor() { }

  public downloadTemplate() {
    const wb = new exceljs.Workbook();
    const ws = wb.addWorksheet('Device Info');
    const format = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    ws.columns = TemplateService.TEMPLATE_HEADERS;
    ws.addRows(TemplateService.TEMPLATE_DATA);

    wb.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: format });

      fs.saveAs(blob, 'device_lookup_template.xlsx', { autoBom: false });

    })
  }
}

