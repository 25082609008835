<div class="sku-configuration-container">
  <h1>SKU Configuration</h1>

  <mat-divider></mat-divider>

  <h2>Upload Inventory SKU file</h2>

  <mat-radio-group [ngModel]="modeSelection" (ngModelChange)="onSKUOptionChange($event)">
    <mat-radio-button value="inventorySKU">Inventory SKU</mat-radio-button>
    <mat-radio-button disabled value="customSKU">Custom SKU</mat-radio-button>
    <mat-radio-button value="disableSKU">Disable SKU</mat-radio-button>
  </mat-radio-group>

  <div *ngIf="ready && skuEnabled">
    <div>
      <input
        #fileSelector
        hidden="true"
        type="file"
        onclick="this.value=null"
        (change)="onFileSelected($event)"
        accept=".xlsx"/>
      <button
        mat-raised-button
        [disabled]="!!loadedRows"
        class="phonecheck-button"
        color="primary"
        (click)="fileSelector.click()"
      >Select File</button>

      <button
        [disabled]="!loadedRows"
        class="phonecheck-button"
        mat-raised-button
        color="primary"
        type="button"
        (click)="upload()"
        >Upload</button>
      <button
        *ngIf="currentFilename"
        class="phonecheck-button"
        mat-raised-button
        color="primary"
        type="button"
        (click)="clearLoadedFile()"
        >Clear loaded file: {{currentFilename}}</button>

      <a (click)="onDownloadTemplate()"><b>Download template</b></a>
    </div>

    <mat-divider></mat-divider>

    <h2>History</h2>
    <p>System will use your latest uploaded file.</p>

    <mat-table id="sku-table" [dataSource]="tableDataSource">
      <ng-container
        *ngFor="let column of columns"
        [matColumnDef]="column.field"
      >
        <mat-header-cell *matHeaderCellDef class="table-header">
          {{ column.displayName }}
        </mat-header-cell>
        <mat-cell *matCellDef="let skuFile">
          <ng-container *ngIf="column.field === 'filename'">
            {{ skuFile[column.field] }}
          </ng-container>
          <ng-container *ngIf="column.field === 'addedOn'">
            {{ skuFile[column.field] | date }}
          </ng-container>
          <ng-container *ngIf="column.field === 'downloadLink'">
            <a (click)="onDownload(skuFile.id, skuFile.filename)">Download</a>
          </ng-container>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let skuFile; columns: displayedColumns"></mat-row>
      <tr class="mat-row empty-table" *matNoDataRow >
        <td class="mat-cell" *ngIf="tableDataSource.length === 0" [attr.colspan]="displayedColumns.length">
          No SKU files loaded yet.
        </td>
      </tr>
    </mat-table>
    <mat-paginator
      #paginator
      showFirstLastButtons
      (page)="onPaginate($event)"
      [pageSize]="paginatorData.pageSize"
      [pageSizeOptions]="paginatorData.pageOptions"
      [length]="paginatorData.length"
    ></mat-paginator>
  </div>
</div>

