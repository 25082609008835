import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { LabelService } from "../../services/label.service";
import { NotificationService } from "../../services/notification.service";
export interface DialogDeleteLabel {
  name: string;
  id: number;
  image: string;
}
@Component({
  selector: 'app-delete-label',
  templateUrl: './delete-label.component.html',
  styleUrls: ['./delete-label.component.css']
})
export class DeleteLabelComponent {
  deleting = false;
  constructor(
    private notify: NotificationService,
    public labelService: LabelService,
    public dialogRef: MatDialogRef<DeleteLabelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDeleteLabel,
  ) {}

  async deleteLabel() {
    this.deleting = true;
    const response = await this.labelService.delete(this.data.id);
    if (response) {
      this.notify.success('Successfully Deleted label');
      this.dialogRef.close();
    }
  }
}
