<mat-card>
  <mat-card-header>
    <mat-card-title>Confirm Delete</mat-card-title>

  </mat-card-header>
  <mat-progress-bar value="100" *ngIf="!deleting"></mat-progress-bar>
  <mat-progress-bar mode="indeterminate" *ngIf="deleting"></mat-progress-bar>
  <mat-card-content>
    <br>
    Are you sure you want to delete the selected {{ data.message }}?
  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button (click)="dialogRef.close()">Cancel</button>
    <div class="flex-spacer"></div>
    <button style="margin-left: 1em;" mat-raised-button color="warn" (click)="delete()" [disabled]="deleting">Delete</button>
  </mat-card-actions>
</mat-card>
