import { Component, Inject } from "@angular/core";
import { Validators, FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ColumnConfiguration, ColumnSettings } from '../../interfaces/columnSettings.interface';
import { MacDeviceInfoService } from "../../services/macDeviceInfo.service";
import { NotificationService } from "../../services/notification.service";

@Component({
  selector: 'app-mac-device-info-column-configuration',
  templateUrl: './mac-device-info-column-configuration.component.html',
  styleUrls: ['./mac-device-info-column-configuration.component.css']
})
export class MacDeviceInfoColumnConfigurationComponent {
  saving: boolean;
  visible: ColumnConfiguration[];
  hidden: ColumnConfiguration[];
  deviceInfoService: MacDeviceInfoService;
  settingsId: string;
  name: string;
  nameFormControl: FormControl<string | null>;
  createNewSettings: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<MacDeviceInfoColumnConfigurationComponent>,
    private notify: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: {
      settings: ColumnSettings,
      deviceInfoService: MacDeviceInfoService, 
      id: string,
      name: string,
    }
  ) { 
    const { id, name, settings, deviceInfoService } = data;
    const { visible, hidden } = settings;

    this.visible = visible;
    this.hidden = hidden;
    this.deviceInfoService = deviceInfoService;
    this.saving = false;
    this.settingsId = id;
    this.createNewSettings = !id || id === 'default';
    this.name = name;
    this.nameFormControl = new FormControl(this.createNewSettings ? null : name, Validators.required);
  }

  drop(event: CdkDragDrop<ColumnConfiguration[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  async onSave(): Promise<void> {
    this.saving = true;
    const newName = this.nameFormControl.getRawValue() || this.name;
    const settings = {
      id: this.settingsId,
      name: newName,
      visible: this.visible,
      hidden: this.hidden,
    };
    
    if (this.nameFormControl.invalid) {
      this.notify.warn('The filter\'s name is required.');
    } else {
      let response;

      if (this.createNewSettings){
        response = await this.deviceInfoService.saveColumnSettings(settings);
      }else{
        response = await this.deviceInfoService.updateColumnSettings(this.settingsId, settings);
      }

      if (response) {
        this.notify.success(`Settings were saved successfully.`);
        this.dialogRef.close(response);
      } else {
        this.notify.error(`Settings failed to be saved.`);
      }
    }
    this.saving = false;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onDelete(): void {
    this.deviceInfoService.deleteColumnSettings(this.settingsId);
    this.dialogRef.close(false);
  }

  canBeDeleted(): boolean {
    return !!this.settingsId && this.settingsId !== 'default';
  }
}