<div class="login-container">
  <mat-card class="mat-elevation-z24">
    <mat-card-content>
      <img src="./assets/Phonecheck_LogoHorizontal@2x.png" class="mat-card-header-logo">
      <form novalidate [formGroup]="userForm">
        <div class="login-box">
          <mat-form-field class="login-field">
            <mat-label>Username</mat-label>
            <input type="text" matInput placeholder="Username" formControlName="username">
          </mat-form-field>
          <div class="alert alert-danger error-form"
            *ngIf="userForm.controls['username'].invalid && (userForm.controls['username'].dirty || userForm.controls['username'].touched)">
            Provide a username.
          </div>
          <mat-form-field class="login-field" (keydown.enter)="login()">
            <mat-label>Password</mat-label>
            <input type="password" matInput placeholder="Password" formControlName="password"
              [type]="hidePassword ? 'password' : 'text'">
            <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hidePassword">
              <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
          </mat-form-field>
          <re-captcha formControlName="recaptchaReactive" style="margin-bottom: 1em;"></re-captcha>
          <div class="alert alert-danger error-form"
            *ngIf="userForm.controls['password'].invalid && (userForm.controls['password'].dirty || userForm.controls['password'].touched)">
            Provide a password.
          </div>
          <button mat-raised-button (click)=" login()" [disabled]="userForm.invalid && loading">
            Login
          </button>
          <div class="alert alert-danger login-erorr" *ngIf="loginError">
            {{loginError}}
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
