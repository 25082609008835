import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {

  userForm = this.fb.group({
    username: ["", Validators.required],
    password: ["", Validators.required],
    recaptchaReactive: ["", Validators.required],
  });

  loading = false;
  loginError: string | undefined;
  hidePassword = true;

  constructor(private fb: FormBuilder,
    private authService: AuthService,
    private router: Router) { }

  public async login(): Promise<void> {
    this.loading = true;
    this.loginError = undefined;
    const email = this.userForm.get('username')?.value;
    const password = this.userForm.get('password')?.value;
    const captcha = this.userForm.value?.recaptchaReactive;

    try {
      if (typeof email !== 'string') {
        throw new Error('invalid email form');
      }

      if (typeof password !== 'string') {
        throw new Error('invalid password form');
      }

      if (!captcha) {
        throw new Error('invalid captcha');
      }

      try {
        const loginResult = await this.authService.login(email, password);
        if (loginResult) {
          if (localStorage.getItem('route')) {
            this.router.navigate([localStorage.getItem('route')]);
            localStorage.removeItem('route');
          } else {
            this.router.navigate(['/']);
          }
        }
      } catch (authError: any) {
        try {
          await this.loginCloudAdmin(email, password);
        } catch (authError: any) {
          try {
            await this.loginAdmin(email, password);
          } catch {
            try {
              await this.loginUser(email, password);
            } catch (authError: any) {
              const { msg } = authError?.error;
              this.loading = false
              this.loginError = msg;
            }
          }
        }
      }
    } catch (error: any) {
      this.loading = false
      this.loginError = error.message;
    }
  }

  public async loginAdmin(email: string, password: string) {
    const loginResultAdmin = await this.authService.adminLogin(email, password);
    if (loginResultAdmin) {
      this.router.navigate(['/']);
    }
  }

  public async loginCloudAdmin(email: string, password: string) {
    const loginResultCloudAdmin = await this.authService.cloudAdminLogin(email, password);
    if (loginResultCloudAdmin) {
      this.router.navigate(['/']);
    } else {
      throw new Error('cloud admin failed');
    }
  }

  public async loginUser(email: string, password: string) {
    const loginUser = await this.authService.userLogin(email, password);
    if (loginUser) {
      this.router.navigate(['/']);
    } else {
      throw new Error('user login failed');
    }
  }
}
