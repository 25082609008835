<div>
  <h2 class="p-2 pb-1 bgc-gray">Password required to modify this profile: {{data.name}}</h2>
  <div class="p-2">
    <mat-checkbox [formControl]="passwordProtected">
      Password Protect this Profile
    </mat-checkbox>
    <br>
    <a href="#" (click)="updatePasswordClick($event)" style="margin-left: 3em;">Update password</a>
    <br>
    <br>
    <div class="ta-center">
      <button mat-raised-button (click)="dialogRef.close()">Cancel</button>
      &nbsp;
      <button mat-raised-button color="primary" (click)="protectProfile()">Update</button>
    </div>
  </div>
</div>
