interface WorkflowOption {
  value: string,
  displayValue: string,
  conflicts?: string[],
  validIndexes?: number[],
}

import { EventEmitter } from "@angular/core";
export class WorkflowBuilder {
  public selections: string[];
  private allOptions: WorkflowOption[];
  private availableOptions: WorkflowOption[];
  private changeEmitter: EventEmitter<string[]> | undefined;

  constructor(
    allOptions: WorkflowOption[],
    selected: string[] = [],
    changeEmitter?: EventEmitter<string[]>
  ) {
    this.allOptions = allOptions;
    this.availableOptions = allOptions;
    this.selections = selected;
    this.updateAvailableOptions();
    this.changeEmitter = changeEmitter;
  }

  onSelected(i: number): void {
    const newAvailable: WorkflowOption[] = [];
    const selectedValue = this.selections[i];
    const selectedOption: WorkflowOption | undefined
      = this.allOptions.find(option => option.value === selectedValue);

    this.changeEmitter?.emit(this.selections);
    this.updateAvailableOptions();
  }

  private updateAvailableOptions(): void {
    const newAvailable: WorkflowOption[] = [];

    this.allOptions.forEach(option => {
      const isOptionSelected = !!this.selections.find(value => option.value === value);

      if (!isOptionSelected && !this.hasConflictWithSelections(option)) {
        newAvailable.push(option);
      }
    });

    this.availableOptions = newAvailable;
  }

  private hasConflictWithSelections(option: WorkflowOption): boolean {
    return this.selections.map(selectedValue => {
      return !!option.conflicts?.includes(selectedValue);
    }).some(b => b);
  }

  private getConflictingOptions(selectedOption: WorkflowOption) {
    return this.allOptions.filter(option => {
      return selectedOption
        && selectedOption.conflicts?.includes(option.value);
    });
  }

  private getSelectedOptions() : WorkflowOption[] {
    return this.selections.map(selected => {
      return this.allOptions.find(o => o.value === selected)
    }).filter(o => o !== undefined) as WorkflowOption[];
  }

  private getConflictingValues(option: WorkflowOption, selected: WorkflowOption[]): string[] {
    return selected
      .map(option => option.conflicts)
      .filter(option => option !== undefined)
      .flat() as string[];
  }

  private getConflictsWithOtherSelected(option: WorkflowOption) {
    const allSelected = this.getSelectedOptions();
    const conflicts: WorkflowOption[] = [];

    allSelected.forEach(selectedOption => {
      if (selectedOption.value === option.value) {
        return;
      }

      if (selectedOption.conflicts && selectedOption.conflicts.includes(option.value)) {
        conflicts.push(selectedOption);
      }
    });

    return conflicts;
  }

  getOptions(i: number): WorkflowOption[] {
    const selectedOptionValue = this.selections[i];
    const selectedOption: WorkflowOption | undefined = this.allOptions.find(o => o.value === selectedOptionValue);
    let options: WorkflowOption[] = [];

    if (selectedOption) {
      this.allOptions.forEach(option => {
        if (option.value === selectedOption.value) {
          return;
        }
        const conflictWithSelectedInThisDropdown
          = option.conflicts?.includes(selectedOption.value);
        const conflictWithOtherSelected
          = option.conflicts && option.conflicts
              .map( v => this.selections.filter(s => s !== selectedOption.value).includes(v))
              .some(b => !!b);

        if (((conflictWithSelectedInThisDropdown || this.availableOptions.includes(option)) && !conflictWithOtherSelected)) {
          options.push(option)
        }
      });
      options = [ selectedOption, ...options ]
    } else {
      options = this.availableOptions;
    }

    return options.filter(option => {
      return !option.validIndexes || option.validIndexes.includes(i);
    });
  }

  getNextAvailableOption(i: number): WorkflowOption | undefined {
    const indexes = this.availableOptions
      .filter(option => !option.validIndexes || option.validIndexes.includes(i))
      .map(availableOption => {
        return this.allOptions.findIndex(option => availableOption.value === option.value);
      }).filter(index => index >= 0).sort((a, b) => {
        return a - b;
      });

    if (indexes.length > 0) {
      return this.allOptions[indexes[0]];
    } else {
      return undefined;
    }
  }

  addAction(): void {
    const newSelectionIndex = this.selections.length;
    const option = this.getNextAvailableOption(newSelectionIndex);

    if (option) {
      this.selections.push(option.value);
      this.onSelected(newSelectionIndex);
    }
  }

  removeOption(i: number): void {
    const valueToRemove  = this.selections[i];
    const optionToRemove = this.allOptions.find(o => o.value === valueToRemove);

    if (optionToRemove) {
      this.availableOptions.push(optionToRemove);
    }

    this.selections.splice(i, 1);
    this.changeEmitter?.emit(this.selections);
  }

  hasOptionsAvailable(): boolean {
    return this.availableOptions.length > 0;
  }

  isLastOption(i: number): boolean {
    return i === this.selections.length - 1;
  }

  showAddSelection(i: number): boolean {
    return this.hasOptionsAvailable()
      && i < this.allOptions.length - 1
      && this.isLastOption(i);
  }

  getSelections(): string[] {
    return this.selections.filter(selection => !!selection);
  }
}
