import { HttpClient, HttpParams } from '@angular/common/http';
import moment, { Moment } from 'moment-timezone';
import { Injectable } from '@angular/core';
import { firstValueFrom, map, of, takeUntil, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Page } from '../interfaces/page.interface';
import { LicenseUsageHistory } from '../interfaces/licenseUsageHistory.interface';
import { LicenseUsageActivity } from '../interfaces/licenseUsageActivity.interface';
import { SearchFilterCollection } from '../interfaces/licenseUsageHistorySearchFilters.interface';
import { isNil, isEmpty, isUndefined } from 'lodash';
import { LicenseUsageActivitySearchFilters } from '../interfaces/licenseUsageActivitySearchFilters.interface';
import { NotificationService } from '../services/notification.service';
import { Clipboard } from '@angular/cdk/clipboard';
import {
  getResponseType,
  DownloadFormat,
  getContentType,
  getExtension,
  download,
  copy
} from '../common/download';
import { SortingDirection, Sorting } from "../common/sorting.interface";

@Injectable({
  providedIn: 'root'
})
export class LicenseService {

  private unsubscribeSummary: Subject<void> = new Subject<void>();
  private unsubscribeHistory: Subject<void> = new Subject<void>();
  private unsubscribeActivity: Subject<void> = new Subject<void>();

  constructor(
    private http: HttpClient,
    private notify: NotificationService,
    private clipboard: Clipboard,
  ) { }

  async getMasterUSers(all: boolean = false): Promise<any> {
    const url = `${environment.apiUrl}/master/users`;
    const params = new HttpParams()
      .append('all', all);

    try {
      return firstValueFrom(
        this.http.get<{ available: number, licenseTypeId: number }[]>(url, { params })
      );
    } catch (err) {
      console.error(err);
      return undefined;
    }
  }

  async getLicenseBalance(): Promise<any> {
    const url = `${environment.apiUrl}/master/license/balance`;
    try {
      return firstValueFrom(
        this.http.get<{ available: number, licenseTypeId: number }[]>(url)
      ).then(balance => {
        return balance.reduce((a: Record<string, number>, b) => {
          a[b.licenseTypeId] = b.available;

          return a;
        }, {})
      });
    } catch (err) {
      console.error(err);
      return await firstValueFrom(of({ success: false, msg: err }));
    }
  }

  async getLicenseUsageSummary(start: string, end: string): Promise<any> {
    const url = `${environment.apiUrl}/master/license/usage-summary`;
    const params = new HttpParams()
      .append('startDate', start)
      .append('endDate', end);

    this.unsubscribeSummary.next();

    try {
      return firstValueFrom(
        this.http.get<{ used: number, licenseTypeId: number }[]>(url, { params })
          .pipe(takeUntil(this.unsubscribeSummary))
      ).then(balance => {
        return balance.reduce((a: Record<string, number>, b) => {
          a[b.licenseTypeId] = b.used;

          return a;
        }, {})
      });
    } catch (err) {
      console.error(err);

      return undefined;
    }
  }

  private appendHistoryFilters(filters: SearchFilterCollection, params: HttpParams): HttpParams {
    const { searchTerm, columnFilters } = filters;

    if (searchTerm) {
      params = params.append('keyword', searchTerm);
    }

    Object.keys(columnFilters).forEach(key => {
      const value: string | undefined = columnFilters[key];

      if (!isEmpty(value)) {
        params = params.append(key, value);
      }
    });

    return params;
  }

  private historyParams(
    start: string,
    end: string,
    page: number,
    size: number,
    filters: SearchFilterCollection,
  ) {
    let params = new HttpParams()
      .append('startDate', start)
      .append('endDate', end)
      .append('page', page)
      .append('size', size);

    params = this.appendHistoryFilters(filters, params);

    return params;
  }

  async getLicenseUsageHistoryBySource(
    start: string,
    end: string,
    page: number,
    size: number,
    filters: SearchFilterCollection,
    sort: Sorting = { sort: 'date', direction: SortingDirection.DESC },
  ): Promise<LicenseUsageHistory[] | undefined> {
    const url = `${environment.apiUrl}/master/license/usage-history-source`;
    const params = this.historyParams(start, end, page, size, filters)
      .append('sort', sort.sort)
      .append('direction', sort.direction);

    this.unsubscribeHistory.next();

    try {
      return firstValueFrom(
        this.http.get<LicenseUsageHistory[]>(url, { params })
          .pipe(takeUntil(this.unsubscribeHistory))
      );
    } catch (err) {
      console.error(err);

      return undefined;
    }
  }

  async getLicenseUsageHistory(
    start: string,
    end: string,
    page: number,
    size: number,
    filters: SearchFilterCollection,
    sort: Sorting = { sort: 'date', direction: SortingDirection.DESC },
  ): Promise<LicenseUsageHistory[] | undefined> {
    const url = `${environment.apiUrl}/master/license/usage-history`;
    const params = this.historyParams(start, end, page, size, filters)
      .append('sort', sort.sort)
      .append('direction', sort.direction);

    this.unsubscribeHistory.next();

    try {
      return firstValueFrom(
        this.http.get<LicenseUsageHistory[]>(url, { params })
          .pipe(takeUntil(this.unsubscribeHistory))
      );
    } catch (err) {
      console.error(err);

      return undefined;
    }
  }

  preDownloadHistoryBySource(start: string, end: string, page: number, size: number, filters: SearchFilterCollection, sort: Sorting, format: DownloadFormat) {
    const url = `${environment.apiUrl}/master/license/usage-history-source`;
    const params = this.historyParams(start, end, page, size, filters)
      .append('format', format)
      .append('sort', sort.sort)
      .append('direction', sort.direction);

    return this.http.get<Blob>(url, {
      params,
      responseType: getResponseType(format),
      observe: 'response',
    });
  }

  preDownloadHistory(
    start: string,
    end: string,
    page: number,
    size: number,
    filters: SearchFilterCollection,
    sort: Sorting,
    format: DownloadFormat,
  ) {
    const url = `${environment.apiUrl}/master/license/usageHistory`;
    const params = this.historyParams(start, end, page, size, filters)
      .append('format', format)
      .append('sort', sort.sort)
      .append('direction', sort.direction);

    return this.http.get<Blob>(url, {
      params,
      responseType: getResponseType(format),
      observe: 'response',
    });
  }

  preDownloadActivity(
    start: string,
    end: string,
    page: number,
    size: number,
    filters: LicenseUsageActivitySearchFilters,
    sort: Sorting,
    format: DownloadFormat,
  ) {
    const url = `${environment.apiUrl}/master/license/usage-activity`;
    const {
      keyword,
      licenseName,
      source,
      userId,
      type,
    } = filters;
    let params = new HttpParams()
      .append('startDate', start)
      .append('endDate', end)
      .append('page', page)
      .append('size', size)
      .append('format', format)
      .append('sort', sort.sort)
      .append('direction', sort.direction);

    if (!isNil(keyword)) {
      params = params.append('keyword', keyword);
    }
    if (!isNil(licenseName)) {
      params = params.append('licenseName', licenseName);
    }
    if (!isNil(source)) {
      params = params.append('source', source);
    }
    if (!isNil(userId)) {
      params = params.append('userName', userId);
    }
    if (type) {
      params = params.append('type', type);
    }

    return this.http.get<Blob>(url, {
      params,
      responseType: getResponseType(format),
      observe: 'response',
    });
  }

  downloadHistoryBySource(
    start: string,
    end: string,
    page: number,
    size: number,
    filters: SearchFilterCollection,
    sort: Sorting,
    format: DownloadFormat,
  ) {
    const now = moment();
    const request = this.preDownloadHistoryBySource(start, end, page, size, filters, sort, format);
    const filename = `license_history_by_source${now.format()}${getExtension(format)}`;

    return download(request, filename, format, this.notify);
  }

  downloadHistory(
    start: string,
    end: string,
    page: number,
    size: number,
    filters: SearchFilterCollection,
    sort: Sorting,
    format: DownloadFormat,
  ) {
    const now = moment();
    const request = this.preDownloadHistory(start, end, page, size, filters, sort, format);
    const filename = `license_history_by_user${now.format()}${getExtension(format)}`;

    return download(request, filename, format, this.notify);
  }

  copyHistory(
    start: string,
    end: string,
    page: number,
    size: number,
    sort: Sorting,
    filters: SearchFilterCollection,
  ) {
    const request = this.preDownloadHistory(start, end, page, size, filters, sort, DownloadFormat.CSV);

    return copy(request, this.clipboard);
  }

  copyHistoryBySource(start: string, end: string, page: number, size: number, sort: Sorting, filters: SearchFilterCollection) {
    const request = this.preDownloadHistoryBySource(start, end, page, size, filters, sort, DownloadFormat.CSV);

    return copy(request, this.clipboard);
  }

  async getLicenseUsageActivity(
    start: string,
    end: string,
    page: number,
    size: number,
    filters: LicenseUsageActivitySearchFilters,
    sort: Sorting = { sort: 'datetime', direction: SortingDirection.DESC },
  ): Promise<Page<LicenseUsageActivity> | undefined> {
    const url = `${environment.apiUrl}/master/license/usage-activity`;
    const {
      keyword,
      licenseName,
      source,
      userId,
      type,
    } = filters;
    let params = new HttpParams()
      .append('startDate', start)
      .append('endDate', end)
      .append('page', page)
      .append('size', size);

    if (keyword) {
      params = params.append('keyword', keyword);
    }
    if (licenseName) {
      params = params.append('licenseName', licenseName);
    }
    if (source) {
      params = params.append('source', source);
    }
    if (userId) {
      params = params.append('userId', userId);
    }
    if (type) {
      params = params.append('type', type);
    }

    params = params
      .append('sort', sort.sort)
      .append('direction', sort.direction);

    this.unsubscribeActivity.next();

    try {
      return firstValueFrom(
        this.http.get<Page<LicenseUsageActivity>>(url, { params })
      );
    } catch (err) {
      console.error(err);

      return undefined;
    }
  }

  downloadActivity(
    start: string,
    end: string,
    page: number,
    size: number,
    filters: LicenseUsageActivitySearchFilters,
    sort: Sorting,
    format: DownloadFormat,
  ) {
    const now = moment();
    const filename = `license_activity_${now.format()}${getExtension(format)}`;
    const request = this.preDownloadActivity(start, end, page, size, filters, sort, format);

    return download(request, filename, format, this.notify);
  }

  copyActivity(
    start: string,
    end: string,
    page: number,
    size: number,
    filters: LicenseUsageActivitySearchFilters,
    sort: Sorting,
  ) {
    const request = this.preDownloadActivity(start, end, page, size, filters, sort, DownloadFormat.CSV);

    return copy(request, this.clipboard);
  }

}

