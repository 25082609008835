<div>
  <h1 mat-dialog-title>{{ title }}</h1>

  <mat-form-field class="filter-input" style="padding-left: 1em;" subscriptSizing="dynamic">
    <mat-label>Search stations</mat-label>
    <input matInput [formControl]="searchStationFormControl">
  </mat-form-field>
  <div>
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  </div>
  <div *ngIf="!loading">
    <div class="table-fixed-header card">
      <table>
        <thead>
          <tr class="header-row">
            <th class="checklist-row-checkbox">
              <mat-checkbox *ngIf="!loading && selectAllEnabled" [formControl]="selectAllFormControl"></mat-checkbox>
            </th>
            <th class="checklist-row-name">{{ nameHeader }}</th>
            <th class="checklist-row-warehouse">{{ type === 'STATION' ? 'Warehouse' : '' }}</th>
          </tr>
        </thead>
        <tbody>
          <div *ngFor="let assignable of stationsToShow">
            <tr
              *ngIf="type === 'STATION' || formControls[999999] && formControls[999999].value === false || assignable.id === 999999">
              <!--          <tr>{{assignable | json}}-->
              <td class="checklist-row-checkbox">
                <mat-checkbox [formControl]="formControls[assignable.id]">
                </mat-checkbox>
              </td>
              <td class="checklist-row-name">
                {{ assignable?.name }}
              </td>
              <td class="checklist-row-warehouse">
                {{ type === 'STATION' ? assignable.warehouse?.name : '' }}
              </td>
            </tr>
          </div>
        </tbody>
      </table>
    </div>
    <mat-dialog-actions class="justified-content">
      <button mat-button (click)="onCancel()">Cancel</button>
      <button mat-button cdkFocusInitial (click)="onUpdate()">Update</button>
    </mat-dialog-actions>
  </div>
</div>
