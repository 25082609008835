import { Component, Inject } from "@angular/core";
import { Validators, FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-create-device-info-filter',
  templateUrl: './create-device-info-filter.component.html',
  styleUrls: ['./create-device-info-filter.component.css']
})
export class CreateDeviceInfoFilterComponent {
  public nameFormControl: FormControl;
  private name: string | undefined;

  constructor(
    public dialogRef: MatDialogRef<CreateDeviceInfoFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.nameFormControl = new FormControl(undefined, Validators.required)
  }

  onSave(): void {
    const name = this.nameFormControl.getRawValue();

    if (!this.nameFormControl.invalid) {
      this.dialogRef.close(name);
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
