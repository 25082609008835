import { Directive, inject, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService, User, UserType } from '../services/auth.service';

@Directive({
  selector: '[isGranted]'
})
export class IsGrantedDirective implements OnInit {
  private _authService = inject(AuthService);
  private _templateRef = inject(TemplateRef);
  private _viewContainer = inject(ViewContainerRef);
  private _roleOrPermission!: string[];
  private _userType: UserType = this._authService.userType;

  @Input()
  set isGranted(roleOrPermission: Actions[]) {
    this._roleOrPermission = roleOrPermission;
  }

  @Input('isGrantedFor')
  set isGrantedFor(userType: UserType) {
    this._userType = userType;
  };

  ngOnInit() {
    if (this._authService.isGranted(this._roleOrPermission) && this._authService.userType == 'user') {
      this._viewContainer.clear();
      this._viewContainer.createEmbeddedView(this._templateRef);
    } else if (this._userType == this._authService.userType) {
      this._viewContainer.clear();
      this._viewContainer.createEmbeddedView(this._templateRef);
    }
  }
}

type Actions =
  'Dashboard' |
  'Software Update' |
  'Customization' |
  'Device Lookup' |
  'Device Info' |
  'Mac Device Info' |
  'Mac Devices' |
  'IMEI Checker' |
  'Certification Toolbox' |
  'Users' |
  'Activity' |
  'Customization Profiles' |
  'Create New Users' |
  'Create New Warehouses' |
  'Station' |
  'Account Settings' |
  'License Information' |
  'SKU Configuration' |
  'Custom Reports' |
  'Email Report Settings';

