
<div class="bodyWrapper" *ngIf="deviceData">
  <div class="wrapper">
    <div class="inner">
    <div class="pageHeader">
      <div class="logo">
        <img src="assets/logo.jpg" alt="logo">
      </div>
      <div class="qrCode">
        <ul>
          <li>
            <div class="qrcodeImage qr-code-container" *ngIf="qrcodedata" style="float: right;">
              <img [src]="qrCodeImageUrl" alt="QR Code">
          </div>

        </li>
        <li>
          <p>
            ID: {{deviceId}}
            <br>Date: {{deviceData.eraseDate | date:'shortDate'}}

          </p>
        </li>
        </ul>
      </div>
    </div>

    <div class="deviceInfo">
      <p><b>Device  Info</b></p>
      <h2>{{deviceData.modelName}} {{deviceData.year}}</h2>
      <p>
        {{deviceData.modelName}} {{deviceData.year}}<br>
        Chip: {{deviceData.chip}}<br>
        Memory: {{deviceData.memory}} <br>
        Storage: {{deviceData.storage}}<br>
        Serial number : {{deviceData.serial}}<br>
        macOS: {{deviceData.os}}  {{deviceData.osVersion}}
      </p>
    </div>

    <div class="ereaseDetails">
      <img  src="assets/smallLogo.png" alt="logo" /><span>Erasure Details</span>
      <div class="eraseTable">
         <ul>
          <li><span>Status</span><span>{{deviceData.eraseStatus}}</span></li>
          <li><span>Method</span><span>OS Erasure</span></li>
          <li><span>Type</span><span>{{deviceData.eraseType}}</span></li>
         </ul>
      </div>
    </div>

    <div class="partnerLogos">
      <img src="assets/partnerLogos.png" alt="logos" />
    </div>

    <div class="phoneCheckInfo">
      <ul>
        <li>
          <img src="assets/eraseLogo.jpg" alt="logo" />
        </li>
        <li>
           <p>Phonecheck is an independent mobile device certification company  <br>
            The industry standard in mobile device certification. </p>
        </li>
      </ul>
      <p>
        This Phonecheck Erasure Report is based only on information supplied to Phonecheck<br class="br"> and available as of {{deviceData.eraseEndTime | date:'shortDate' }} at {{deviceData.eraseEndTime | date:'mediumTime' }} (PST). Other information about this device may not<br class="br"> have been reported to Phonecheck.
      </p>
    </div>
  </div>
</div>
</div>
