<h2>{{ field }}</h2>
<mat-dialog-content *ngIf="!isGpuInfo">
  <table class="json-table">
    <tr *ngFor="let key of getObjectKeys(json)">
      <td>{{ key }}</td>
      <td>{{ json[key] }}</td>
    </tr>
  </table>
</mat-dialog-content>
<mat-dialog-content *ngIf="isGpuInfo">
  <b class="profile_name">GPU Temperature : {{ temp || '' }}</b>
  <div class="array-viewer">
    <table class="two-column-table">
      <thead>
        <tr>
          <th>Sr#</th>
          <th>Name</th>
          <th>Core</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let item of gpuInfoParsed; let i = index">
          <tr>
            <td>{{ i + 1 }}</td>
            <td>{{ item.name }}</td>
            <td>{{ isGpuInfo ? item.cores : item.cores }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button mat-dialog-close>Close</button>
</mat-dialog-actions>
