import { MatTreeModule } from '@angular/material/tree';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxFileDropModule } from 'ngx-file-drop';
import { EditorModule } from 'primeng/editor';

import { AppRoutes } from "./app-routing.module";
import { AppComponent } from './app.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { LineChartComponent } from './line-chart/line-chart.component';
import { MatCardModule } from '@angular/material/card';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatGridListModule } from '@angular/material/grid-list';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { NgIf, CommonModule } from "@angular/common";
import { RouterLinkWithHref, RouterOutlet } from "@angular/router";
import { CustomizationsComponent } from './customizations/customizations.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTableModule } from "@angular/material/table";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatInputModule } from "@angular/material/input";
import { FlexModule, FlexLayoutModule } from "@angular/flex-layout";
import { LoginComponent } from "./login/login.component";
import { HttpClientModule } from "@angular/common/http";
import { interceptorProviders } from "./helpers/interceptor";
import { PreviewLabelComponent } from './dialogs/preview-label/preview-label.component';
import { MatDialogModule } from "@angular/material/dialog";
import { DeleteLabelComponent } from './dialogs/delete-label/delete-label.component';
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { NotificationService } from "./services/notification.service";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { A11yModule } from "@angular/cdk/a11y";
import { CdkListboxModule, CdkOption } from "@angular/cdk/listbox";
import { TestPlanComponent } from './test-plan/test-plan.component';
import { TestPlanFormComponent, UncheckDefaultTestDialogComponent } from './test-plan-form/test-plan-form.component';
import { ImeiCheckComponent } from './imei-check/imei-check.component';
import { MatSortModule } from '@angular/material/sort';
import { AssignChecklistComponent } from './customizations/assign-checklist/assign-checklist.component';
import { AssignmentComponent } from './assignment/assignment.component';
import { DeleteProfileComponent } from "./dialogs/delete-profile/delete-profile.component";
import { PasswordProtectComponent } from './password-protect/password-protect.component';
import { PasswordRequiredComponent } from './dialogs/password-required/password-required.component';
import { PasswordProtectProfileComponent } from './dialogs/password-protect-profile/password-protect-profile.component';
import { PasswordUpdateComponent } from './dialogs/password-update/password-update.component';
import { MatChipsModule } from '@angular/material/chips';

import { RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { MacDeviceInfoComponent } from './mac-device-info/mac-device-info.component';
import { CreateDeviceInfoFilterComponent } from './dialogs/create-device-info-filter/create-device-info-filter.component';
import { DeviceInfoColumnConfigurationComponent } from './dialogs/device-info-column-configuration/device-info-column-configuration.component';
import { JSONViewerComponent } from './dialogs/json-viewer/json-viewer.component';
import { GradingQuestionsComponent } from './dialogs/grading-questions/grading-questions.component';
import { DeviceLookupComponent } from './device-lookup/device-lookup.component';
import { CdkColumnDef } from '@angular/cdk/table';
import { MacDeviceInfoColumnConfigurationComponent } from './dialogs/mac-device-info-column-configuration/mac-device-info-column-configuration.component';
import { MacJSONViewerComponent } from './dialogs/mac-json-viewer/mac-json-viewer.component';
import { ArrayViewerComponent } from './dialogs/mac-grading-info/array-viewer.component';
import { DeviceInfoComponent } from './device-info/device-info.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AssignmentExpressComponent } from './assignment-express/assignment-express.component';
import { SavingWithoutAssignmentsDialogComponent } from './dialogs/saving-without-assigments-dialog/saving-without-assignments-dialog.component';
import { FormatArrayPipe } from './pipes/format-array.pipe';
import { DataformatPipe } from './pipes/dataformat.pipe';
import { PendingChangesGuard } from './pending-changes.guard';
import { BackWithoutSavingComponent } from './dialogs/back-without-saving/back-without-saving.component';
import { MacErasureReportComponent } from './mac-erasure-report/mac-erasure-report.component';
import { QRCodeModule } from 'angularx-qrcode';
import { AdvancedSettingsComponent } from './advanced-settings/advanced-settings.component';
import { DeviceInfoErasureReportComponent } from './device-info-erasure-report/device-info-erasure-report.component';
import { DeviceCodeSelectorComponent } from './dialogs/device-code-selector/device-code-selector.component';
import { DeviceInfoDatatableComponent } from './device-info-datatable/device-info-datatable.component';
import { SkuConfigurationComponent } from './sku-configuration/sku-configuration.component';
import { PinStationComponent } from './dialogs/pin-station/pin-station.component';
import { DeleteConfirmationComponent } from './delete-confirmation/delete-confirmation.component';
import { ImeiComponent } from './imei/imei.component';
import { LicenseInformationComponent } from './license-information/license-information.component';
import { CustomTestComponent } from './custom-test/custom-test.component';
import { MacDeviceInfoDatatableComponent } from './mac-device-info-datatable/mac-device-info-datatable.component';
import { CustomReportsComponent } from './custom-reports/custom-reports.component';
import { CustomReportsTabComponent } from './custom-reports-tab/custom-reports-tab.component';
import { AutomaticEmailSettingsComponent } from './automatic-email-settings/automatic-email-settings.component';
import { AutomaticEmailSettingsListComponent } from './automatic-email-settings-list/automatic-email-settings-list.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { EmailHistoryComponent } from './email-history/email-history.component';
import { ActivityReportsComponent } from './activity-reports/activity-reports.component';
import { IsGrantedDirective } from './directives/is-granted.directive';
import { UploadBuildComponent } from './dialogs/upload-build/upload-build.component';
import { DeleteBuildComponent } from './dialogs/delete-build/delete-build.component';
import { UpdateAllCustomersComponent } from './builds-management/update-all-customers/update-all-customers.component';
import { ReleaseNotesComponent } from './builds-management/release-notes/release-notes.component';


@NgModule({
  declarations: [
    AppComponent,
    AssignChecklistComponent,
    FormatArrayPipe,
    DataformatPipe,
    AssignmentComponent,
    BarChartComponent,
    CustomizationsComponent,
    DashboardComponent,
    UploadBuildComponent,
    DeleteBuildComponent,
    UpdateAllCustomersComponent,
    DeleteLabelComponent,
    DeleteProfileComponent,
    ImeiCheckComponent,
    LineChartComponent,
    LoginComponent,
    PasswordProtectComponent,
    PasswordProtectProfileComponent,
    PasswordRequiredComponent,
    PasswordUpdateComponent,
    PreviewLabelComponent,
    TestPlanComponent,
    TestPlanFormComponent,
    UncheckDefaultTestDialogComponent,
    ImeiCheckComponent,
    PasswordProtectComponent,
    PasswordRequiredComponent,
    PasswordProtectProfileComponent,
    PasswordUpdateComponent,
    AssignChecklistComponent,
    AssignmentComponent,
    CreateDeviceInfoFilterComponent,
    DeviceInfoComponent,
    DeviceInfoColumnConfigurationComponent,
    JSONViewerComponent,
    GradingQuestionsComponent,
    MacDeviceInfoComponent,
    MacDeviceInfoColumnConfigurationComponent,
    MacJSONViewerComponent,
    ArrayViewerComponent,
    DeviceLookupComponent,
    AssignmentExpressComponent,
    SavingWithoutAssignmentsDialogComponent,
    BackWithoutSavingComponent,
    MacErasureReportComponent,
    AdvancedSettingsComponent,
    DeviceInfoErasureReportComponent,
    DeviceCodeSelectorComponent,
    DeviceInfoDatatableComponent,
    IsGrantedDirective,
    SkuConfigurationComponent,
    PinStationComponent,
    DeleteConfirmationComponent,
    ImeiComponent,
    LicenseInformationComponent,
    CustomTestComponent,
    MacDeviceInfoDatatableComponent,
    CustomReportsComponent,
    CustomReportsTabComponent,
    AutomaticEmailSettingsComponent,
    AutomaticEmailSettingsListComponent,
    EmailHistoryComponent,
    ActivityReportsComponent,
    ReleaseNotesComponent,
  ],
  imports: [
    NoopAnimationsModule,
    MatTreeModule,
    CommonModule,
    A11yModule,
    AppRoutes,
    BrowserModule,
    CdkListboxModule,
    CdkOption,
    DragDropModule,
    FlexLayoutModule,
    FlexModule,
    FormsModule,
    EditorModule,
    HttpClientModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMomentDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    NgIf,
    NgxJsonViewerModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    RecaptchaFormsModule,
    RecaptchaModule,
    RouterLinkWithHref,
    RouterOutlet,
    MatAutocompleteModule,
    QRCodeModule,
    HttpClientModule,
    MatDialogModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    A11yModule,
    MatRadioModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    CdkListboxModule,
    CdkOption,
    A11yModule,
    MatRadioModule,
    MatExpansionModule,
    MatChipsModule,
    NgxDropzoneModule,
    NgxFileDropModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    NgIf,
    NgxMaterialTimepickerModule,
  ],
  providers: [
    PendingChangesGuard,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: '6LflBdUZAAAAAGkx8WqFKuO5GjMMUqsx_a-9u348',
      } as RecaptchaSettings,
    },
    interceptorProviders,
    NotificationService,
    CdkColumnDef,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic',
        appearance: 'outline'
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
