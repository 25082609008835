import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import Chart, {ChartConfiguration} from 'chart.js/auto';
import moment from 'moment-timezone';
import {DashService} from '../services/dash.service';
import {AuthService} from '../services/auth.service';
import {AdminService} from "../services/admin.service";
import { skip, Subscription, Subject, takeUntil } from 'rxjs';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements AfterViewInit, OnDestroy {
  dateRange = 'Today';
  public breakdownChart: any;
  public deviceChart: any;
  public loading: boolean = false;

  public deviceBreakdown = {
    apple: 0,
    samsung: 0,
    other: 0,
    total: 0,
  };

  public runningTotalDevices = 0;
  public failedPercentage = 0;

  public licenseCounts = {
    IMEI: 0,
    SIM: 0,
    carrier: 0,
    device: 0,
    erased: 0,
    iCloud: 0,
  }

  public deviceChartConfig = {
    type: 'line',
    data: {
      labels: [],
      datasets: [
        {
          label: 'Devices',
          data: [],
          borderColor: '#089FF3',
          backgroundColor: '#089FF3',
        },
        {
          label: 'Licenses',
          data: [0, 20, 20, 60, 60, 120, NaN, 180, 120, 125, 105, 110, 170],
          borderColor: '#667085',
          backgroundColor: '#667085',
        },
        {
          label: 'Fail Rate',
          data: [],
          borderColor: '#53389E',
          backgroundColor: '#53389E',
        }
      ]
    },
    options: {
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: 'top',
        },
        title: {
          display: false,
        }
      },
      scales: {
        y: {
          suggestedMin: 0,
          suggestedMax: 50,
        }
      }
    },

  };
  breakdownChartConfig: ChartConfiguration = {
    type: 'doughnut',
    data: {
      labels: ['Apple', 'Samsung', 'Other'],
      datasets: [
        {
          data: [],
          borderWidth: 0,
          backgroundColor: [
            '#089FF3',
            '#6CE9A6',
            '#B692F6',
          ],
        }
      ]
    },
    options: {
      responsive: false,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
          text: ''
        },
      }
    },
  };
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  private reloadSubscription: Subscription | undefined;

  constructor(
    private dashService: DashService,
    private auth: AuthService,
    private adminService: AdminService,
    private notify: NotificationService,
  ) {
    // this will handle the initial warehouse setting.
    this.getDashData();
    // if not the initial warehouse setting, process the warehouse change
    this.reloadSubscription = this.auth.warehouseSelectedEmitter.pipe(skip(1)).subscribe(warehouse => {
      this.getDashData();
    });
  }

  ngAfterViewInit(): void {

  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.reloadSubscription?.unsubscribe();
  }

  public async getDashData() {

    this.cancelDashRequest();
    this.resetDashboard();

    this.loading = true;

    let dateStart: string = '';
    let dateEnd: string = '';
    const nowStart = moment();
    const nowEnd = moment();

    if (!this.auth.getCurrentMasterId()) {
      this.loading = false;

      return;
    }

    if (this.dateRange === 'Today') {
      dateStart = nowStart.format(`YYYY-MM-DD`);
      dateEnd = nowEnd.format(`YYYY-MM-DD`);
    } else if (this.dateRange === 'Yesterday') {
      dateStart = nowStart.subtract(1, 'day').format(`YYYY-MM-DD`);
      dateEnd = nowEnd.subtract(1, 'day').format(`YYYY-MM-DD`);
    } else if (this.dateRange === 'Yesterday') {
      dateStart = nowStart.subtract(7, 'day').format(`YYYY-MM-DD`);
      dateEnd = nowEnd.format(`YYYY-MM-DD`);
    } else if (this.dateRange === '7 Days') {
      dateStart = nowStart.subtract(7, 'day').format(`YYYY-MM-DD`);
      dateEnd = nowEnd.format(`YYYY-MM-DD`);
    } else if (this.dateRange === '30 Days') {
      dateStart = nowStart.subtract(30, 'day').format(`YYYY-MM-DD`);
      dateEnd = nowEnd.endOf('day').format(`YYYY-MM-DD`);
    }

    let httpRequest = this.dashService.getDashData(dateStart, dateEnd);

    httpRequest
      .pipe( takeUntil(this.ngUnsubscribe) )
      .subscribe(
        (response) => this.setDashData(response),
        (error) => this.setDashDataError(error),
      );
  }

  private setDashData(result: any) {
    this.deviceBreakdown = result.deviceBreakdown;
    this.runningTotalDevices = result.currentRunningTotal;
    this.failedPercentage = result.failedPercentage;
    this.licenseCounts = result.licenseCounts;

    const breakdown = result['deviceBreakdown'];
    this.breakdownChartConfig.data.datasets[0].data = [breakdown.apple, breakdown.samsung, breakdown.other];
    this.deviceChartConfig.data.datasets[0].data = result.devicesArray;
    this.deviceChartConfig.data.datasets[1].data = result.licensesArray;
    this.deviceChartConfig.data.datasets[2].data = result.failArray;
    this.deviceChartConfig.data.labels = result.labelArray;
    this.loading = false;

    this.createChart();
  }

  private setDashDataError(error: any) {
    const message = `Error retrieving dash data`;
    this.notify.warn(message);
    console.error(error);
  }

  public cancelDashRequest() {
    this.ngUnsubscribe.next();
  }

  public async createChart() {

    if (this.breakdownChart) {
      this.breakdownChart.destroy();
    }
    if (this.deviceChart) {
      this.deviceChart.destroy();
    }
    this.breakdownChart = new Chart('device-breakdown', this.breakdownChartConfig);

    // @ts-ignore
    this.deviceChart = new Chart('devices', this.deviceChartConfig);

  }

  public async resetDashboard() {
    if (this.breakdownChart) {
      this.breakdownChart.destroy();
    }
    if (this.deviceChart) {
      this.deviceChart.destroy();
    }

    this.deviceBreakdown = {
      apple: 0,
      samsung: 0,
      other: 0,
      total: 0,
    };

    this.runningTotalDevices = 0;
    this.failedPercentage = 0;

    this.licenseCounts = {
      IMEI: 0,
      SIM: 0,
      carrier: 0,
      device: 0,
      erased: 0,
      iCloud: 0,
    }

    this.deviceChartConfig = {
      type: 'line',
      data: {
        labels: [],
        datasets: [
          {
            label: 'Devices',
            data: [],
            borderColor: '#089FF3',
            backgroundColor: '#089FF3',
          },
          {
            label: 'Licenses',
            data: [],
            borderColor: '#667085',
            backgroundColor: '#667085',
          },
          {
            label: 'Failed',
            data: [],
            borderColor: '#53389E',
            backgroundColor: '#53389E',
          }
        ]
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
            position: 'top',
          },
          title: {
            display: false,
          }
        },
        scales: {
          y: {
            suggestedMin: 0,

            suggestedMax: 20,
          }
        }
      },
    };
    this.breakdownChartConfig = {
      type: 'doughnut',
      data: {
        labels: ['Apple', 'Samsung', 'Other'],
        datasets: [
          {
            data: [],
            borderWidth: 0,
            backgroundColor: [
              '#089FF3',
              '#6CE9A6',
              '#B692F6',
            ],
          }
        ]
      },
      options: {
        responsive: false,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
            text: ''
          },
        },
      },
    };
  }
}
