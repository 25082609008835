import { EventEmitter, Inject, Component, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { CustomizationService } from '../services/customization.service';
import { FormControl } from '@angular/forms';
import {
  DeviceTestCategory,
  DeviceMasterTest,
  DeviceTest,
  SelectedDeviceTest,
  DeviceTestValue,
} from '../interfaces/deviceTest.interfaces';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule} from '@angular/material/dialog';

@Component({
  selector: 'app-test-plan-form',
  templateUrl: './test-plan-form.component.html',
  styleUrls: ['./test-plan-form.component.css']
})
export class TestPlanFormComponent implements OnInit , OnDestroy {
  @Input() tests!: Record<string, DeviceTestCategory>;
  @Input() selectedDeviceTests!: SelectedDeviceTest[] | undefined;
  @Input() platform!: 'ios' | 'android' | 'both' | 'watch' | 'airpods';
  @Input() testFilter!: (test: DeviceTest) => boolean;
  @Input() skipUncheckingDefaultTestDialog: boolean = false;
  @Output() updateEmitter = new EventEmitter<SelectedDeviceTest[]>();
  @Output() onSkipDefaultTestDialog = new EventEmitter<boolean>();

  public searchInput = new FormControl('');
  private testPlanSkeleton!: Record<string, DeviceTestCategory>;

  private testsFormControls: Record<string, FormControl> = {};
  private masterTestFormControls: Record<string, FormControl> = {};
  private parameterFormControls: Record<string, FormControl> = {};

  private allDeviceTests!: DeviceTest[];
  private allDeviceTestsById: Record<string, DeviceTest> = {};

  private allMasterTests!: DeviceMasterTest[];
  private allMasterTestsById: Record<string, DeviceMasterTest> = {};

  private masterTestIdForDeviceTest: Record<string, string> = {};
  private masterTestsByCategory: Record<string, DeviceMasterTest[]> = {};

  private activeSection!: string;
  private sectionMode: boolean = true;

  private displayedMasterTests: DeviceMasterTest[] = [];

  constructor(
    public dialog: MatDialog,
  ) {
    this.searchInput.statusChanges.subscribe(s => {
      const text = this.getSearchBarInput();

      // If input in search bar in non-empty,
      // enter list mode
      this.sectionMode = text === '';

      if (!this.sectionMode) {
        this.displayedMasterTests = this.filterTests(text);
      }
    });
  }

  public ngOnInit() {
    const categories = Object.values(this.tests);

    this.testPlanSkeleton = this.tests;
    this.clasifyTests(this.tests);
    this.activeSection = categories.length > 0 ? categories[0].id : '';
    this.initializeAllFormControls(
      this.allMasterTests,
      this.allDeviceTests,
    );
  }

  public getDefaultSection(): DeviceTestCategory | undefined {
    const categories = Object.values(this.tests) as DeviceTestCategory[];

    return  categories[0];
  }

  public ngOnDestroy() {
    const data = this.getData();

    this.updateEmitter.emit(data);
  }

  private clasifyTests(testPlanSkeleton: Record<string, DeviceTestCategory>) {
    const matchesPlatform = (test: DeviceTest) => {
      const testPlatform = test.platform.toLowerCase();

      return testPlatform === 'both'
        || testPlatform === 'android'
        || testPlatform === 'ios';
    }

    const categories = Object.values(testPlanSkeleton);
    const masterTests: DeviceMasterTest[] = [];
    let deviceTests: DeviceTest[] = [];
    const masterTestsByCategory: Record<string, DeviceMasterTest[]> = {};

    categories.forEach(c => {
      masterTestsByCategory[c.id] = [];

      c.masterTests.forEach(mt => {
        const actualTests = mt.tests.filter(t => this.testFilter(t));

        if (actualTests.length > 0) {
          const masterTestClone = { ...mt, tests: actualTests };

          masterTestClone.tests.forEach(t => {
            this.masterTestIdForDeviceTest[t.id] = mt.id;
            this.allDeviceTestsById[t.id] = t;
          });

          this.allMasterTestsById[mt.id] = masterTestClone;
          masterTests.push(masterTestClone);
          deviceTests = deviceTests.concat(masterTestClone.tests);
          masterTestsByCategory[c.id].push(masterTestClone);
        }
      });
    });

    this.allMasterTests = masterTests;
    this.allDeviceTests = deviceTests;
    this.masterTestsByCategory = masterTestsByCategory;
  }

  public getTestCategories(): DeviceTestCategory[] {
    return Object.values(this.testPlanSkeleton);
  }

  public getActiveSectionTests() {
    return this.masterTestsByCategory[this.getActiveSection()];
    //return this.testPlanSkeleton[this.getActiveSection()].masterTests;
  }

  private getSearchBarInput(): string {
    const text = this.searchInput.getRawValue();

    return text ? text.trim() : '';
  }

  public getActiveSection(): string {
    return this.activeSection;
  }

  public onSectionClick(category: DeviceTestCategory) {
    this.activeSection = category.id;
  }

  public isSectionModeActive(): boolean {
    return this.sectionMode;
  }

  public getTestFormControl(id: string): FormControl {
    return this.testsFormControls[id];
  }

  public getTestParameterFormControl(id: string): FormControl {
    return this.parameterFormControls[id];
  }

  private getDefaultValue(test: DeviceTest): string | undefined {
    const defaultValue = test.values.find(v => {
      return v.isDefault;
    });

    if (defaultValue) {
      return defaultValue.value;
    }

    if (test.values.length > 0) {
      return test.values[0].value;
    }

    return undefined;
  }

  public isMasterTestSelected(id: string): boolean {
    const formControl = this.getMasterTestFormControl(id);

    return !!formControl?.getRawValue();
  }

  public getMasterTestFormControl(id: string): FormControl {
    return this.masterTestFormControls[id];
  }

  public getData(): SelectedDeviceTest[] {
    const selectedTests: SelectedDeviceTest[] = [];

    this.allDeviceTests.forEach(t => {
      const masterTestId = this.masterTestIdForDeviceTest[t.id];
      const masterTestSelected = this.isMasterTestSelected(masterTestId);
      const selected = this.testsFormControls[t.id].getRawValue();
      let inputParam;

      if (!selected || !masterTestSelected) {
        return;
      }

      if (t.requiresParameter) {
        const paramFormControl = this.parameterFormControls[t.id];

        inputParam = paramFormControl.getRawValue();
      }

      const selectedTest = {
        inputValue: inputParam,
        id: t.id,
        key: t.key,
        displayName: t.displayName,
        requiresParameter: t.requiresParameter,
        description: t.description,
        platform: t.platform,
      };

      selectedTests.push(selectedTest);
    });

    return selectedTests;
  }

  public isCategorySelected(id: string): boolean {
    return this.activeSection === id;
  }

  public isDeviceTestSelected(id: string): boolean {
    const formControl = this.getTestFormControl(id);

    return formControl?.getRawValue();
  }

  public openDialog(mt: DeviceMasterTest, f: FormControl): MatDialogRef<UncheckDefaultTestDialogComponent> {
    const dialogRef = this.dialog.open(UncheckDefaultTestDialogComponent, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(keepTestChecked => {
      f.setValue(keepTestChecked, { emitEvent: false });

      this.skipUncheckingDefaultTestDialog ||= !keepTestChecked;
      this.setAll(mt, keepTestChecked);

      if (!keepTestChecked) {
        this.onSkipDefaultTestDialog.emit(this.skipUncheckingDefaultTestDialog);
        this.checkAllChildTests(mt, false);
      }
    });

    return dialogRef;
  }

  public isMasterTestDefault(masterTest: DeviceMasterTest): boolean {
    return masterTest.isDefault;
  }

  public hasEnumValues(test: DeviceTest): boolean {
    return test.requiresParameter && test.values.length > 1;
  }

  public requiresStringValue(test: DeviceTest): boolean {
    return test.requiresParameter && test.values.length <= 1;
  }

  public getDeviceTest(id: string): DeviceTest {
    const test = this.allDeviceTestsById[id];
    if (test) {
      return test;
    }

    throw Error(`this test does not exist: ${id}`);
  }

  public displayDropdown(test: DeviceTest): boolean {
    return this.hasEnumValues(test);
  }

  public displayTextInput(test: DeviceTest): boolean {
    return this.requiresStringValue(test);
  }

  private getMasterTest(id: string): DeviceMasterTest | undefined {
    return this.allMasterTestsById[id];
  }

  public filterTests(searchTerm: string): DeviceMasterTest[] {
    const all = this.allMasterTests;

    if (searchTerm)  {
      return all
        .map(m => this.filterMasterTest(m, searchTerm))
        .filter(m => !!m) as DeviceMasterTest[];
    } else {
      return all;
    }
  }

  public filterDeviceTest(test: DeviceTest, searchTerm: string): boolean {
    const s = searchTerm.toLowerCase();
    const displayName = test.displayName.toLowerCase();

    return displayName.includes(s);
  }

  public filterMasterTest(
    masterTest: DeviceMasterTest,
    searchTerm: string
  ): DeviceMasterTest | undefined {
    const filteredTests = masterTest.tests.filter(t => {
      return this.filterDeviceTest(t, searchTerm);
    });

    if (filteredTests.length > 0) {
      const clone: DeviceMasterTest = {
        id: masterTest.id,
        displayName: masterTest.displayName,
        tests: filteredTests,
        isDefault: masterTest.isDefault,
      }

      return clone;
    } else {
      return undefined;
    }
  }

  public getDisplayedMasterTests(): DeviceMasterTest[] {
    return this.displayedMasterTests;
  }

  private initializeAllFormControls(
    masterTests: DeviceMasterTest[],
    tests: DeviceTest[],
  ): void {
    this.inititializeMasterTestFormControls(masterTests);
    this.initializeTestFormControls(tests);
    this.initializeTestParameterFormControls(tests);

    if (this.selectedDeviceTests) {
      this.deselectAllTests();
      this.selectedDeviceTests.forEach(s => {
        const testId = s.id;
        let currentTestState;

        try {
          currentTestState = this.getDeviceTest(testId);
        } catch (e) {
          console.error(e);
        }

        const inputValue = s.inputValue;
        const formControl = this.testsFormControls[testId];
        const masterTestFormControl = this.masterTestFormControls[this.masterTestIdForDeviceTest[testId]];

        if (currentTestState && formControl) {
          formControl.setValue(true, { emitEvent: false });
          masterTestFormControl.setValue(true, { emitEvent: false });

          if (currentTestState.requiresParameter) {
            const paramFormControl = this.getTestParameterFormControl(currentTestState.id);

            paramFormControl.setValue(inputValue);
          }
        }
      });
    }
  }

  private deselectAllTests(): void {
    const deselectAll = (controls: FormControl[]) => {
      controls.forEach(f => {
        f.setValue(false, { emitEvent: false });
      });
    };

    deselectAll(Object.values(this.testsFormControls));
    deselectAll(Object.values(this.masterTestFormControls));
  }

  private getTestValueInstance(testId: string, testValue: string | undefined): DeviceTestValue | undefined {
    if (testValue) {
      const test = this.getDeviceTest(testId);
      const value = test?.values.find(v => {
        return v.value === testValue;
      });

      return value;
    }

    return undefined;
  }

  private initializeTestParameterFormControls(tests: DeviceTest[]): void {
    tests.forEach(t => {
      if (t.requiresParameter) {
        const hasEnumValues = this.hasEnumValues(t);
        const hasStringValue = this.requiresStringValue(t);
        const defaultValue = this.getDefaultValue(t);
        const controlForm = new FormControl(defaultValue);

        this.parameterFormControls[t.id] = controlForm;
      }
    });
  }

  private initializeTestFormControl(t: DeviceTest): FormControl {
    const formControl = new FormControl(t.viewDefault);

    formControl.valueChanges.subscribe(checked => {
      if (checked) {
        const masterTestId = this.masterTestIdForDeviceTest[t.id];
        const masterControl = this.masterTestFormControls[masterTestId];

        masterControl.setValue(true, { emitEvent: false });
      } else {
        const masterTestId = this.masterTestIdForDeviceTest[t.id];
        const masterTest = this.allMasterTestsById[masterTestId];
        const masterControl = this.masterTestFormControls[masterTestId];
        let allTestsUnchecked = true;

        if (masterTest.isDefault && !this.skipUncheckingDefaultTestDialog) {
          const dialogRef = this.dialog.open(UncheckDefaultTestDialogComponent, {
            disableClose: true,
          });

          dialogRef.afterClosed().subscribe(keepTestChecked => {
            if (keepTestChecked) {
              this.testsFormControls[t.id].setValue(true, { emitEvent: false });
            } else {
              this.skipUncheckingDefaultTestDialog = true;
              masterTest.tests.forEach(t => {
                const testControl = this.testsFormControls[t.id];
                const checked = testControl.getRawValue();

                allTestsUnchecked &&= !checked;
              });

              if (allTestsUnchecked) {
                const masterControl = this.masterTestFormControls[masterTestId];

                masterControl.setValue(false);
              }
            }
          });
        }
      }
    });

    this.testsFormControls[t.id] = formControl;

    return formControl;
  }

  private initializeTestFormControls(tests: DeviceTest[]): void {
    tests.forEach(t => {
      this.initializeTestFormControl(t);
    });
  }

  private checkAllChildTests(masteTest: DeviceMasterTest, value = true): void {
    const tests = masteTest.tests;

    tests.forEach(test => {
      const control = this.getTestFormControl(test.id);

      control.setValue(value, { emitEvent: false });
    });
  }

  private inititializeMasterTestFormControls(masterTests: DeviceMasterTest[]): void {
    masterTests.forEach(mt => {
      const formControl = new FormControl(mt.isDefault);

      formControl.valueChanges.subscribe(checked => {
        if (!checked && mt.isDefault && !this.skipUncheckingDefaultTestDialog) {
          this.openDialog(mt, formControl);
        }

        if (this.skipUncheckingDefaultTestDialog && !checked) {
          this.checkAllChildTests(mt, false);
        }

        if (checked) {
          this.checkAllChildTests(mt);
        }
      });

      this.masterTestFormControls[mt.id] = formControl;
    });
  }

  public openExpandable(masterTest: DeviceMasterTest): boolean {
    const control = this.getMasterTestFormControl(masterTest.id);

    return control.dirty && control.getRawValue();
  }

  public masterTestIndeterminate(masterTest: DeviceMasterTest): boolean {
    return masterTest.tests.some((t) => this.getTestFormControl(t.id).value)
      && masterTest.tests.some((t) => !this.getTestFormControl(t.id).value);
  }

  public setAll(masterTest: DeviceMasterTest, completed: boolean) {
    masterTest.tests.forEach(t => {
      this.getTestFormControl(t.id).setValue(completed, {
        emitEvent: false,
      });
    });
  }
}

@Component({
  selector: 'app-uncheck-default-test-dialog',
  templateUrl: './dialog/uncheck-default-test-dialog.component.html',
  styleUrls: ['./dialog/uncheck-default-test-dialog.component.css']
})
export class UncheckDefaultTestDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<UncheckDefaultTestDialogComponent>,
  ) {}

  onNoClick(): void {
  }
}

