<div class="main-container">
  <h1>Device Lookup</h1>
  <div class="info-type-selector">
    <mat-radio-group id="mac-mobile-device-radio-group" [formControl]="deviceTypeFormControl">
      <mat-radio-button [value]="'deviceInfo'"><b>Search Mobile Devices</b></mat-radio-button>
      <mat-radio-button [value]="'mac'"><b>Search Mac Devices</b></mat-radio-button>
    </mat-radio-group>
  </div>
  <div fxLayout="column" id="lookup-container">
    <div fxLayout="row">
    <div class="search-option-container">
      Search:
      <mat-button-toggle-group [formControl]="lookupOptionFormControl">
        <mat-button-toggle *ngIf="!isMacSelected" value="imei">IMEI</mat-button-toggle>
        <mat-button-toggle *ngIf="!isMacSelected"  value="imei2">IMEI2</mat-button-toggle>
        <mat-button-toggle value="serial">Serial</mat-button-toggle>
        <mat-button-toggle value="lpn">LPN</mat-button-toggle>
        <mat-button-toggle *ngIf="!isMacSelected" value="custom1">Custom 1</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
  <div>
    <mat-checkbox [(ngModel)]="showLatest">Show only latest record</mat-checkbox>
  </div>
  <div fxLayout="row">
  <mat-form-field class="manual-input-codes">
    <textarea [(ngModel)]="csvData" cdkTextareaAutosize cdkAutosizeMaxRows="9" matInput></textarea>
  </mat-form-field>
  <div class="upload-options" fxLayout="column" >

  <div fxLayout="row" >
    <p>Or</p>
    <input
      #fileSelector
      hidden="true"
      type="file"
      onclick="this.value=null"
      (change)="onFileSelected($event)"
      accept=".xlsx,.csv"/>
    <button
      mat-raised-button
      class="phonecheck-button upload-button"
      color="primary"
      (click)="fileSelector.click()"
    >Upload File</button>
    </div>
    <a (click)="downloadTemplate()" class="template-link">Download Template</a>
  </div>

  </div>
  <p>For multiple, separate by commas or by line.</p>
  <div>
    <button class="search-button" mat-raised-button (click)="search()">Search</button>
  </div>

  <div [hidden]="!lookupPerformed || isMacSelected">
    <div class="button-row" fxLayout="column">
      <div fxLayout="row">
        <div class="export-container" fxLayout="row">
          <button mat-raised-button (click)="onDownloadExcel()" title="Download Excel">Excel</button>
          <button mat-raised-button (click)="onDownloadCSV()" title="Download CSV">CSV</button>
          <button mat-raised-button (click)="onCopyToClipboard()" title="Copy">Copy</button>
          <button mat-raised-button *ngIf="gradingDataExport" (click)="onGradingDataExport()" title="Grading Data Export">Grading Data Export</button>
        </div>
        <mat-form-field class="filter-selection">
          <mat-select [formControl]="selectedDeviceFilter">
            <mat-option *ngFor="let filter of deviceFilters" value="{{ filter.id }}" >{{ filter.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <app-device-info-datatable
      [mode]="'single'"
      [allColumns]="allColumns"
      [columns]="allColumns"
      >
    </app-device-info-datatable>
  </div>
  <div [hidden]="!lookupPerformed || !isMacSelected">
    <div class="button-row" fxLayout="column">
      <div fxLayout="row">
        <div class="export-container" fxLayout="row">
          <button mat-raised-button (click)="onDownloadExcel()" title="Download Excel">Excel</button>
          <button mat-raised-button (click)="onDownloadCSV()" title="Download CSV">CSV</button>
          <button mat-raised-button (click)="onCopyToClipboard()" title="Copy">Copy</button>
          <button mat-raised-button *ngIf="gradingDataExport" (click)="onGradingDataExport()" title="Grading Data Export">Grading Data Export</button>
        </div>
        <mat-form-field class="filter-selection">
          <mat-select [formControl]="selectedMacFilter">
            <mat-option *ngFor="let filter of macFilters" value="{{ filter.id }}">{{ filter.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <app-mac-device-info-datatable
      [hidden]="!lookupPerformed || !isMacSelected"
      [columns]="macAllColumns"
      [allColumns]="macAllColumns"
      [lookupMode]="true"
    >
  </app-mac-device-info-datatable>
  </div>
</div>

