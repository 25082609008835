<div class="container">
  <div class="header">
    <div class="action-container">
      <div class="action-container">
        Software Update
      </div>
      <div class="action-container__right">
        <button mat-raised-button type="button" (click)="addBuild()">
          Upload Build
        </button>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="table-builds-management-container">
    <div class="action-container">
      <div class="action-container__left">
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" [(selectedIndex)]="selectedTabIndex">
          <mat-tab label="Desktop" [disabled]="true"></mat-tab>
          <mat-tab label="Mac Check"></mat-tab>
        </mat-tab-group>
      </div>
    </div>
    <!-- Customer Build Management -->
    <ng-container>
      <div class="action-container">
        <div class="action-container__left">
          <h2>Builds</h2>
        </div>
      </div>
      <mat-divider></mat-divider>
      <ng-container>
        <div class="action-container">
          <div class="full-width-container">
            <!-- Type Field in Single Row -->
            <mat-form-field class="select-field">
              <mat-label>
                <h3>OS Image</h3>
              </mat-label>
              <mat-select [(ngModel)]="selectedOsImage" (selectionChange)="onOSSelection($event)">
                <mat-option *ngFor="let os of osImages" [value]="os">
                  {{ os.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Rounded Containers -->
            <div class="rounded-containers">
              <a *ngIf="selectedOsImage && selectedOsImage.intelOsUrl" [href]="selectedOsImage.intelOsUrl" download>
                <div class="rounded-container">
                  <img src="./../../assets/Mac Intel logo.png" alt="Mac Intel Logo">
                  <span>Download For Intel<br><small>Mac OS 10.15 or later</small></span>
                </div>
              </a>
              <a *ngIf="selectedOsImage && selectedOsImage.mSeriesOsUrl" [href]="selectedOsImage.mSeriesOsUrl" download>
                <div class="rounded-container">
                  <img src="./../../assets/M-Series logo.png" alt="M-Series Logo">
                  <span>Download For M-Series<br><small>Mac OS 10.15 or later</small></span>
                </div>
              </a>
            </div>

            <!-- Release Notes -->
            <div class="release-notes-container">
              <a *ngIf="selectedOsImage" target="_blank" [class.disabled]="true" href="#" class="release-notes-link">
                <div class="release-notes-content" [class.disabled]="true">
                  <img src="./../../assets/release_notes.png" alt="Release Notes Logo" class="release-notes-icon">
                  <span class="release-notes-text">Release Notes</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </ng-container>
      <mat-divider></mat-divider>
      <ng-container>
        <div class="action-container">
          <div class="full-width-container">
            <!-- Type Field in Single Row -->
            <mat-form-field class="select-field">
              <mat-label>
                <h3>Mac Check</h3>
              </mat-label>
              <mat-select [(ngModel)]="selectedMacBuild" (selectionChange)="onMacBuildSelection($event)">
                <mat-option *ngFor="let build of getMacBuilds()" [value]="build">
                  {{ build.buildNumber }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Rounded Containers -->
            <div class="rounded-containers">
              <a *ngIf="selectedMacBuild && selectedMacBuild.buildUrl" [href]="selectedMacBuild.buildUrl" download>
                <div class="rounded-container">
                  <img src="./../../assets/Mac Intel logo.png" alt="Mac Intel Logo">
                  <span>
                    Download Mac Build<br>
                    <small>{{selectedMacBuild.buildNumber}}</small>
                  </span>
                </div>
              </a>
              <a *ngIf="selectedMacBuild && selectedMacBuild.otaUrl" [href]="selectedMacBuild.otaUrl" download>
                <div class="rounded-container">
                  <img src="./../../assets/Mac Intel logo.png" alt="Mac Intel Logo">
                  <span>
                    Download OTA Build<br>
                    <small>{{selectedMacBuild.buildNumber}}</small>
                  </span>
                </div>
              </a>
            </div>

            <!-- Release Notes -->
            <div class="release-notes-container">
              <a *ngIf="selectedMacBuild" target="_blank" [href]="selectedMacBuild ? 'release-notes/' + selectedMacBuild.id : '#'"
                class="release-notes-link">
                <div class="release-notes-content">
                  <img src="./../../assets/release_notes.png" alt="Release Notes Logo" class="release-notes-icon">
                  <span class="release-notes-text">Release Notes</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </ng-container>
      <mat-divider></mat-divider>
      <ng-container>
        <div class="action-container">
          <div class="full-width-container">
            <!-- Type Field in Single Row -->
            <mat-form-field class="select-field">
              <mat-label>
                <h3>USB Utility</h3>
              </mat-label>
              <mat-select [(ngModel)]="selectedClonorBuild" (selectionChange)="onClonerSelection($event)">
                <mat-option *ngFor="let build of getUsbUtilityBuilds()" [value]="build">
                  {{ build.buildNumber }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Rounded Containers -->
            <div class="rounded-containers">
              <a *ngIf="selectedClonorBuild && selectedClonorBuild.buildUrl" [href]="selectedClonorBuild.buildUrl"
                download>
                <div class="rounded-container">
                  <img src="./../../assets/Mac Intel logo.png" alt="Mac Intel Logo">
                  <span>
                    Download USB Utility Build<br>
                    <small>{{selectedClonorBuild.buildNumber}}</small>
                  </span>
                </div>
              </a>
              <a *ngIf="selectedClonorBuild && selectedClonorBuild.otaUrl" [href]="selectedClonorBuild.otaUrl" download>
                <div class="rounded-container">
                  <img src="./../../assets/Mac Intel logo.png" alt="Mac Intel Logo">
                  <span>
                    Download OTA Build<br>
                    <small>{{selectedClonorBuild.buildNumber}}</small>
                  </span>
                </div>
              </a>
            </div>

            <!-- Release Notes -->
            <div class="release-notes-container">
              <a *ngIf="selectedClonorBuild" target="_blank" [href]="'release-notes/' + selectedClonorBuild.id"
                class="release-notes-link" [class.disabled]="!selectedClonorBuild.releaseNotes">
                <div class="release-notes-content">
                  <img src="./../../assets/release_notes.png" alt="Release Notes Logo" class="release-notes-icon">
                  <span class="release-notes-text">Release Notes</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <mat-divider></mat-divider>
    <div class="action-container">
      <div class="action-container__left">
        <div *ngIf="isLoading" class="full-screen-loader">
          <img src="assets/loader.gif" alt="Loading" class="loader-image" />
        </div>
        <div *ngIf="!isLoading">
          <form [formGroup]="assignBuildForm" (ngSubmit)="submitBuildAssignment()">
            <!-- Automatic Update Settings -->
            <div class="section">
              <h3>Automatic Update Settings</h3>
              <div class="radio-group">
                <mat-radio-group formControlName="updateSettings">
                  <mat-radio-button value="install">Install updates when available</mat-radio-button>
                  <br>
                  <mat-radio-button value="dont-install">Do not automatically install updates</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <!-- Build Assignments -->
            <div class="section">
              <h3>Build Assignments</h3>
              <div class="radio-group">
                <mat-radio-group formControlName="buildAssignment" (change)="onBuildAssignmentChange($event.value)">
                  <mat-radio-button value="Same">Use same builds for all</mat-radio-button>
                  <mat-radio-button value="Custom" [disabled]="isReadonly">Custom Assignments</mat-radio-button>
                </mat-radio-group>
              </div>

              <!-- Dropdowns for "same" builds -->
              <div class="build-select-container">
                <div class="build-select">
                  <mat-form-field hideRequiredMarker>
                    <mat-label>
                      <h3>M-Series</h3>
                    </mat-label>
                    <mat-select formControlName="assignedMSeriesBuild">
                      <mat-option [value]="0">None</mat-option>
                      <mat-option *ngFor="let build of getMSeriesBuilds(); let isFirst = first" [value]="build.id">
                        {{ build.buildNumber }} <span *ngIf="isFirst">(Latest)</span>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="build-select">
                  <mat-form-field hideRequiredMarker>
                    <mat-label>
                      <h3>Intel</h3>
                    </mat-label>
                    <mat-select formControlName="assignedIntelBuild">
                      <mat-option [value]="0">None</mat-option>
                      <mat-option *ngFor="let build of getIntelBuilds(); let isFirst = first" [value]="build.id">
                        {{ build.buildNumber }} <span *ngIf="isFirst">(Latest)</span>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="build-select">
                  <mat-form-field hideRequiredMarker>
                    <mat-label>
                      <h3>USB Utility</h3>
                    </mat-label>
                    <mat-select formControlName="assignedUsbUtilityBuild">
                      <mat-option [value]="0">None</mat-option>
                      <mat-option *ngFor="let build of getUsbUtilityBuilds(); let isFirst = first" [value]="build.id">
                        {{ build.buildNumber }} <span *ngIf="isFirst">(Latest)</span>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <br>
              <!-- Action Buttons -->
              <div class="action-buttons">
                <button mat-raised-button color="primary" type="submit" [disabled]="assignBuildForm.invalid">Save Changes</button>
                <button mat-button color="accent" (click)="cancelChanges()" type="button">Cancel Changes</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<mat-divider></mat-divider>
<div class="container">
  <!-- All Builds Listing -->
  <ng-container>
    <div class="action-container">
      <div class="action-container__left">
        <h3>Build Management</h3>
      </div>
    </div>
    <br>
    <div class="action-container table-container">
      <div class="action-container__left">
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" (selectedTabChange)="filterByPlatform($event)">
          <mat-tab label="All"></mat-tab>
          <mat-tab label="Mac OS"></mat-tab>
          <mat-tab label="Windows"></mat-tab>
          <mat-tab label="iOS"></mat-tab>
          <mat-tab label="Android"></mat-tab>
          <mat-tab label="M-Series"></mat-tab>
          <mat-tab label="Intel"></mat-tab>
          <mat-tab label="USB Utility"></mat-tab>
          <mat-tab label="OS Image"></mat-tab>
        </mat-tab-group>
      </div>
      <div class="action-container__right">
        <mat-form-field class="filter-input">
          <mat-label>Search</mat-label>
          <input matInput [formControl]="searchBuild" class="search-input" type="search" autocomplete="off" />
        </mat-form-field>
      </div>
    </div>
    <div class="builds-table-container">
      <ng-container>
        <table mat-table [dataSource]="buildsDataSource" class="mat-elevation-z8" matSort matSortActive="createdAt"
          matSortDirection="desc">

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tbody *ngIf="!buildsDataSource || buildsDataSource.data.length === 0">
            <tr>
              <td [attr.colspan]="displayedColumns.length" class="placeholder">
                <div class="no-data">No data found.</div>
              </td>
            </tr>
          </tbody>

          <ng-container matColumnDef="Version">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Version</th>
            <td mat-cell *matCellDef="let build"> {{ build.buildNumber }} </td>
          </ng-container>

          <ng-container matColumnDef="Platform">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Platform</th>
            <td mat-cell *matCellDef="let build"> {{ build.platform }} </td>
          </ng-container>

          <ng-container matColumnDef="Type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
            <td mat-cell *matCellDef="let build"> {{ build.type }} </td>
          </ng-container>

          <ng-container matColumnDef="Uploaded">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Uploaded</th>
            <td mat-cell *matCellDef="let build">{{ build.createdAt | date:'MM-dd-yyyy' }}</td>
          </ng-container>

          <ng-container matColumnDef="Actions">
            <th mat-header-cell *matHeaderCellDef [style.width.%]="15">Action</th>
            <td mat-cell *matCellDef="let build" [style.display]="'flex'" [style.height]="'52px'">
              <a *ngIf="build.buildUrl" [href]="build.buildUrl" download>
                <button mat-icon-button matTooltip="Download">
                  <mat-icon>system_update_alt</mat-icon>
                </button>
              </a>
              <button [disabled]="build.isReserved" mat-icon-button (click)="editBuild(build)" matTooltip="Edit">
                <mat-icon>edit</mat-icon>
              </button>
              <button [disabled]="build.isReserved" mat-icon-button (click)="deleteBuild(build)" matTooltip="Delete">
                <mat-icon>delete</mat-icon>
              </button>
              <button *ngIf="build.buildUrl" mat-icon-button (click)="shareBuild(build)" matTooltip="Share">
                <mat-icon>publish</mat-icon>
              </button>
            </td>
          </ng-container>
        </table>
        <mat-paginator #paginator (page)="onPaginateChange($event)" [pageSize]="pageSize"
          [pageSizeOptions]="pageSizeOptions" [length]="buildsLength" showFirstLastButtons>
        </mat-paginator>
      </ng-container>
    </div>
    <div class="action-container bottom-container">
      <div class="action-container__left">
        <a routerLink="/software-update/update-all-customers">Update all Customers</a>
      </div>
    </div>
  </ng-container>
</div>