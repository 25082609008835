import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

export type UserType = 'master' | 'cloudAdmin' | 'user';

type Master = {
  id: string,
  name: string,
  v1MasterId: number,
};

type Warehouse = {
  id: string;
  name: string;
};

type UserContext = {
  id: string;
  type: UserType;
  name: string;
  v1MasterId?: number;
  targetMaster?: Master;
  targetWarehouse?: Warehouse;
  expires: number;
};

export type User = {
  id?: string;
  userId: string;
  sub: string;
  name: string;
  userName: string;
  v1MasterId: number;
  iat: number;
  exp: number;
  email?: string;
  actions: string[];
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private VIEW_AS_ADMIN_KEY = 'viewAsAdmin';

  masterSelectedEmitter: EventEmitter<Master> = new EventEmitter<Master>();
  warehouseSelectedEmitter: EventEmitter<Warehouse> = new EventEmitter<Warehouse>();
  allMasterContextEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  loginEmitter: EventEmitter<UserType> = new EventEmitter<UserType>();

  token: string | null = null;
  v1MasterId: string = this.getLocalStorageKey('masterSelected')?.v1MasterId || '';
  user: User | undefined;
  authenticated = false;
  masterId: string = this.getLocalStorageKey('masterSelected')?.id || '';
  userType: UserType = localStorage.getItem('userType') as UserType || 'master';
  tokenMasterGenerated: string = localStorage.getItem('masterTokenGenerated') || '';
  warehouseId = null;
  constructor(private http: HttpClient, private router: Router) { }
  userType$ = new BehaviorSubject<UserType>(this.userType);

  allMasterContext: boolean = false;

  AUTH_API = `${environment.apiUrl}/auth/master/login`;
  AUTH_CLOUD_ADMIN_API = `${environment.apiUrl}/auth/cloudAdmin/login`;
  AUTH_ADMIN_API = `${environment.apiUrl}/auth/admin/login`;
  AUTH_USER_API = `${environment.apiUrl}/auth/user/login`;

  public async login(username: string, password: string): Promise<boolean | Error> {
    const authAttempt: any = await firstValueFrom(this.http.post(this.AUTH_API, { username, password }, httpOptions));

    if (!authAttempt) {
      console.error(`no login result`);
    }

    const { token, error } = authAttempt;

    if (!token || typeof token !== 'string') {
      throw new Error('Invalid token');
    }

    if (error) {
      throw new Error(error);
    }

    this.setFreshAuth(token, 'master');
    this.userType$.next(this.userType);

    this.getLoginEventEmitter().emit('master');

    return true;
  }

  public isGranted(permission: string[]) {
    if (!permission) return true;
    if (this.userType != 'user') return true;

    if (this.user?.actions) {
      for (const p of permission) {
        for (const u of this.user.actions) {
          if (u == p) {
            return true;
          }
        }
      }
    }
    return false;
  }

  public getLoginEventEmitter(): EventEmitter<UserType> {
    return this.loginEmitter;
  }

  public async getMasterToken(masterId: string) {
    const masterToken = await firstValueFrom(this.http.get<{ token: string }>(`${environment.apiUrl}/admin/auth/masters/token/${masterId}`, {
      headers: {
        token_admin_cloud: this.token!,
      },
    }));
    if (!masterToken.token) {
      throw new Error('couldn\' generate token');
    }

    this.tokenMasterGenerated = masterToken.token;

    return masterToken.token;
  }


  public async adminLogin(username: string, password: string): Promise<boolean | Error> {
    const authAttempt = await firstValueFrom(this.http.post<{ success: boolean; cloudAdmin: string; token: string; error: string }>(this.AUTH_ADMIN_API, { username, password }));
    if (!authAttempt) {
      console.error('no login result');
    }

    const { token, error } = authAttempt;

    if (!token || typeof token !== 'string') {
      throw new Error('Invalid token');
    }

    if (error) {
      throw new Error(error);
    }

    this.setFreshAuth(token, 'cloudAdmin');

    this.userType$.next(this.userType);
    this.getLoginEventEmitter().emit('cloudAdmin');

    return true;
  }

  public async userLogin(username: string, password: string) {
    const authAttempt = await firstValueFrom(this.http.post<{ success: boolean; cloudAdmin: string; token: string; error: string }>(this.AUTH_USER_API, { username, password, actions: true }));
    if (!authAttempt) {
      console.error('no login result');
    }

    const { token, error } = authAttempt;

    if (!token || typeof token !== 'string') {
      throw new Error("Invalid token");
    }

    if (error) {
      throw new Error(error);
    }

    this.setUserInfo(token, 'user');

    return true;
  }

  private setUserInfo(token: string, type: UserType) {
    this.createUserContext(token, type);
    this.setAuth(token, type);
    this.userType$.next(this.userType);
    this.getLoginEventEmitter().emit(type);
  }

  public async cloudAdminLogin(username: string, password: string): Promise<boolean | Error> {
    const authAttempt = await firstValueFrom(this.http.post<{ success: boolean; cloudAdmin: string; token: string; error: string }>(this.AUTH_CLOUD_ADMIN_API, { username, password }));
    if (!authAttempt) {
      console.error('no login result');
    }

    const { token, error } = authAttempt;

    if (!token || typeof token !== 'string') {
      throw new Error('Invalid token');
    }

    if (error) {
      throw new Error(error);
    }

    this.setFreshAuth(token, 'cloudAdmin');
    this.userType$.next(this.userType);
    this.getLoginEventEmitter().emit('cloudAdmin');

    return true;
  }

  public logout(): void {
    this.authenticated = false;
    this.user = undefined;
    this.token = null;
    this.masterId = '';
    this.userType = 'master';

    localStorage.removeItem('token');
    localStorage.removeItem('userType');
    localStorage.removeItem('masterId');
    localStorage.removeItem('masterSelected');
    localStorage.removeItem('masterTokenGenerated');
    localStorage.removeItem('viewAsAdmin');
    this.clearUserContext();
  }

  public setAuth(token: string, type: UserType = 'master'): void {
    this.user = jwt_decode(token);
    const currentTime = Math.floor(Date.now() / 1000);

    if (this.user && this.user.exp <= currentTime && this.router.url !== '/login') {
      console.log(`token expired... logging out!`);

      this.router.navigate(['/login']);
    } else {
      this.authenticated = true;
      this.token = token;
      this.userType = type;
      localStorage.setItem('token', token);
      localStorage.setItem('userType', type);
      this.setLogoutTimer(this.user!.exp);
    }
  }

  public setFreshAuth(token: string, type: 'master' | 'cloudAdmin' = 'master'): void {
    this.user = jwt_decode(token);
    this.setLogoutTimer(this.user!.exp);
    const currentTime = Math.floor(Date.now() / 1000);


    if (!(this.user && this.user.exp <= currentTime)) {
      this.setAuth(token, type);
      this.createUserContext(token, type);

      if (type === 'cloudAdmin') {
        this.setViewAsAdmin(true);
      }
    }
  }

  public isAuthenticated(): boolean {
    if (!this.authenticated) {
      const token = localStorage.getItem('token');
      const type = localStorage.getItem('userType') as 'master' | 'cloudAdmin';

      const masterId = localStorage.getItem('masterId');
      if (masterId != null || masterId != undefined) {
        this.masterId = masterId
      }

      if (token) {
        this.setAuth(token, type);
      }
    }

    return this.authenticated;
  }

  private getLocalStorageKey(key: string) {
    try {
      return JSON.parse(localStorage.getItem(key)!);
    } catch {
      return {};
    }
  }

  /***** Helpers *****/
  private getFromLocalStorage(item: string): string | null {
    return localStorage.getItem(item);
  }

  public getToken(): string {
    const TOKEN_KEY = 'token';
    const token = this.getFromLocalStorage(TOKEN_KEY);

    if (token) {
      return token;
    }

    throw new Error('No token found');
  }

  private getUserContext(): UserContext {
    const USER_CONTEXT_KEY = 'userContext';
    const context = JSON.parse(this.getFromLocalStorage(USER_CONTEXT_KEY) || 'null') as UserContext | null;

    if (!context) {
      throw new Error('No user context');
    }

    return context;
  }

  private setUserContext(context: UserContext): void {
    const USER_CONTEXT_KEY = 'userContext';

    localStorage.setItem(USER_CONTEXT_KEY, JSON.stringify(context));
  }

  public setSelectedWarehouse(warehouse: Warehouse, emitEvent = true): void {
    const userContext = this.getUserContext();
    const currentMaster = userContext.targetWarehouse;

    userContext.targetWarehouse = warehouse;

    if (currentMaster?.id !== warehouse.id) {
      this.setUserContext(userContext);

      if (emitEvent) {
        this.warehouseSelectedEmitter.emit(warehouse);
      }
    }
  }

  public setSelectedMaster(master: Master, emitEvent = true): void {
    const userContext = this.getUserContext();
    const currentMaster = userContext.targetMaster;

    userContext.targetMaster = master;

    if (currentMaster?.id !== master.id) {
      this.setUserContext(userContext);

      if (emitEvent) {
        this.masterSelectedEmitter.emit(master);
      }
    }
  }

  public removeSelectedMaster() {
    const userContext = this.getUserContext();

    delete userContext.targetMaster;
    this.setUserContext(userContext);
  }

  public removeSelectedWarehouse(emit = true) {
    const userContext = this.getUserContext();

    delete userContext.targetWarehouse;
    this.setUserContext(userContext);

    if (emit) {
      this.warehouseSelectedEmitter.emit();
    }
  }

  public isAdminContext(): boolean {
    try {
      const userContext = this.getUserContext();

      return userContext.type === 'cloudAdmin';
    } catch (err) {
      return false;
    }

  }

  public isUserContext(): boolean {
    try {
      const userContext = this.getUserContext();

      return userContext.type === 'user';
    } catch (err) {
      return false;
    }

  }

  public isMasterContext(): boolean {
    try {
      const userContext = this.getUserContext();

      return userContext.type === 'master';
    } catch (err) {
      return false;
    }

  }

  private clearUserContext(): void {
    const USER_CONTEXT_KEY = 'userContext';

    localStorage.removeItem(USER_CONTEXT_KEY);
  }

  private createUserContext(token: string, userType: UserType): void {
    const { sub, id, name, userName, v1MasterId, exp } = jwt_decode(token) as { sub: any, id: string, userName: string, name: string, v1MasterId?: number, exp: number };
    const userContext: UserContext = {
      id: id || sub,
      name: name || userName,
      v1MasterId,
      type: userType,
      expires: exp,
    }
    this.setUserContext(userContext);
  }

  public getCurrentWarehouseId(): string | null | undefined {
    const context = this.getUserContext();

    return context.targetWarehouse?.id;
  }

  public getCurrentMasterId(): string | null | undefined {
    const context = this.getUserContext();

    if (this.isAdminContext()) {
      return context.targetMaster?.id;
    } else {
      return context.id;
    }
  }

  public getSelectedMaster(): Master | undefined {
    try {
      const context = this.getUserContext();

      return context.targetMaster;
    } catch (err) {
      console.error(err);
      return undefined;

    }
  }

  public getSelectedWarehouse(): Warehouse | undefined {

    try {
      const context = this.getUserContext();

      return context.targetWarehouse;
    } catch (err) {
      console.error(err);
      return undefined;

    }
  }

  public setAllMasterContext(flag: boolean): void {
    this.allMasterContext = flag;
    this.allMasterContextEmitter.next(flag);
  }

  public isAllMasterContext(): boolean {
    return this.allMasterContext;
  }

  public setViewAsAdmin(viewAllData: boolean): void {
    localStorage.setItem(this.VIEW_AS_ADMIN_KEY, JSON.stringify(viewAllData));
  }

  public getViewAsAdmin(): boolean {
    const stored = localStorage.getItem(this.VIEW_AS_ADMIN_KEY);

    if (stored) {
      return JSON.parse(stored);
    } else {
      return false;
    }
  }

  public getAccountNameToShow(): string | undefined {
    const viewAsAdmin = this.getViewAsAdmin();
    const masterContext = this.isMasterContext();
    const userContext = this.isUserContext();
    const context = this.getUserContext();

    if (viewAsAdmin || masterContext || userContext) {
      return context.name;
    } else {
      return context.targetMaster?.name;
    }
  }

  setLogoutTimer(ms: number) {
    const currentTime = Math.floor(Date.now() / 1000);
    const expireTime = (ms - currentTime) * 1000;

    setTimeout(() => {
      if (this.router.url !== '/login') {
        this.logout();
        localStorage.setItem('route', this.router.url);
        this.router.navigate(['/login']);
      }
    }, expireTime);
  }

  public async getWarehousesByUser() {
    if (this.userType != 'user' && this.user) return [];
    const url = `${environment.apiUrl}/warehouse/user/${this.user!.userId}`;
    try {
      const result = await firstValueFrom(this.http.get<any[]>(url));

      return result;
    } catch (error) {
      console.error(error);
    }

    return [];
  }

  public isMasterSelected() {
    return this.isMasterContext() || this.isUserContext() || this.getSelectedMaster();
  }

}

