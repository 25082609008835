import moment, { Moment } from 'moment-timezone';
import { debounceTime, Observable, Subject, takeUntil } from 'rxjs';
import { environment } from '../../environments/environment';

import { OnDestroy, ViewChild, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CdkDragDrop, CdkDragStart, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { DeviceInfoService } from '../services/deviceInfo.service';
import { Page } from '../interfaces/page.interface';
import { DateOption, toDateString, DateRange } from '../common/date';
import { MatDialog } from '@angular/material/dialog';
import { CreateDeviceInfoFilterComponent } from '../dialogs/create-device-info-filter/create-device-info-filter.component';
import { DeviceInfoColumnConfigurationComponent } from '../dialogs/device-info-column-configuration/device-info-column-configuration.component';
import { ColumnConfiguration, ColumnSettings, SavedColumnSettings, ColumnSettingsCreationRequest } from '../interfaces/columnSettings.interface';
import { NotificationService } from '../services/notification.service';
import { MatPaginator } from '@angular/material/paginator';
import { DEVICE_INFO_COLUMNS } from './columns';
import { JSONViewerComponent } from '../dialogs/json-viewer/json-viewer.component';
import { ArrayViewerComponent } from '../dialogs/mac-grading-info/array-viewer.component';
import { MatSort } from "@angular/material/sort";
import { PaginatorData } from '../interfaces/paginatorData.interface';
import { DeviceInfo } from '../interfaces/deviceInfo.interface';
import { ToggleMenuService } from '../services/toggle-menu.service';
import { AuthService } from '../services/auth.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { DownloadFormat } from '../common/download';
import { MasterService } from "../services/master.service";
import { GradingQuestionsComponent } from "../dialogs/grading-questions/grading-questions.component";
@Component({
  selector: 'app-device-info',
  templateUrl: './device-info.component.html',
  styleUrls: ['./device-info.component.css']
})
export class DeviceInfoComponent implements OnInit, OnDestroy {
  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  copyingToClipboard: boolean = false;
  loading: boolean = false;
  filesLoading: boolean = false;
  filters: SavedColumnSettings[] = [];
  paginatorData: PaginatorData = {
    pageIndex: 0,
    length: 0,
    pageSize: 10,
    currentPage: 0,
    pageOptions: [10, 20, 50],
  };
  displayedColumns: string[] = [];
  allColumns: ColumnConfiguration[] = DEVICE_INFO_COLUMNS;
  columns: ColumnConfiguration[] = this.allColumns;

  deviceInfoList: DeviceInfo[] = [];
  dateOptionFormControl = new FormControl<DateOption>(DateOption.TODAY);
  keywordFormControl = new FormControl<string | null>(null);
  selectedFilterFormControl = new FormControl();
  startDate: moment.Moment;
  endDate: moment.Moment;
  columnFilterFormControls!: Record<string, FormControl<string | null>>;

  private ngUnsubscribe: Subject<void> = new Subject();

  accountSettings: {
    gradingDataExport?: boolean,
  } = {};

  private fresh = true;

  constructor(
    private deviceInfoService: DeviceInfoService,
    private dialog: MatDialog,
    private notify: NotificationService,
    private headerMenuService: ToggleMenuService,
    private authService: AuthService,
    private router: Router,
    private masterService: MasterService,
  ) {
    this.startDate = moment();
    this.endDate = moment();
    this.headerMenuService.allowUseAllMasterData(true);
    this.sort.active = 'updatedDate';
    this.sort.direction = 'desc';

    this.authService.allMasterContextEmitter.asObservable().pipe(takeUntil(this.ngUnsubscribe)).subscribe(allMasterContext => {
      this.refresh();
    });

    this.authService.masterSelectedEmitter.asObservable().pipe(takeUntil(this.ngUnsubscribe)).subscribe(master => {
      this.refresh();
    });

    this.authService.warehouseSelectedEmitter.asObservable().pipe(takeUntil(this.ngUnsubscribe)).subscribe(warehouse => {
      this.refresh();
    });

    this.columnFilterFormControls = {};

    this.dateOptionFormControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      if (value && value != 'CUSTOM_DATE_RANGE') {
        this.updateDatePickerRange(value);
        this.resetPagination();
        this.getDeviceInfo();
      }
      if (!value) {
        return;
      }
      const filter = this.findFilter(value);

      if (filter) {
        this.applyColumnSettings(filter);
      }
    });

    this.selectedFilterFormControl.valueChanges.subscribe(value => {
      const filter = this.findFilter(value);

      if (filter) {
        this.applyColumnSettings(filter);
      }
    });

    this.keywordFormControl.valueChanges
      .pipe(debounceTime(300), takeUntil(this.ngUnsubscribe))
      .subscribe(value => {
        this.getDeviceInfo();
      });

    this.headerMenuService.allowUseAllMasterData(true);
  }

  private updateDatePickerRange(option: DateOption | null): void {
    if (option) {
      const range = this.getDateRangeFromDateOption(option);

      if (range) {
        const { start, end } = range;

        this.updateDatePickerInputs(start, end)
      }
    }
  }

  private updateDatePickerInputs(start: Moment, end: Moment): void {
    this.startDate = start;
    this.endDate = end;
  }

  public datePickerEnabled(): boolean {
    const dateOption = this.dateOptionFormControl.getRawValue();

    return dateOption === DateOption.CUSTOM_DATE_RANGE;
  }

  private setMasterSettings(settings: any) {
    this.accountSettings = settings || {};
  }

  public ngOnInit(): void {
    this.getDeviceInfoColumns();
    this.getFilters();
    this.refresh();

    if (this.authService.isMasterSelected() || this.authService.isMasterContext()) {
      this.masterService.getMasterSettings()
        .then(settings => {
          this.setMasterSettings(settings);
        });
    }
  }

  dragStarted(event: CdkDragStart, i: number): void {
  }

  dropListDropped(event: CdkDragDrop<string[]>): void {
    if (event) {
      moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
      this.setDisplayedColumns();

      if (event.currentIndex !== event.previousIndex) {
        this.updateColumnOrderPreference();
      }
    }
  }

  setDisplayedColumns() {
    const inputs: Record<string, FormControl<string | null>> = {};
    const newDisplayedColumns: string[] = [];

    this.columns.forEach((column, index) => {
      let control = this.columnFilterFormControls[column.field];

      column.index = index;
      this.displayedColumns[index] = column.field;
      newDisplayedColumns.push(column.field);

      if (control) {
        inputs[column.field] = control;
      } else {
        control = new FormControl<string | null>(null);

        control.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
          this.getDeviceInfo();
        });
      }
    });

    this.displayedColumns = newDisplayedColumns;
    this.columnFilterFormControls = inputs;
  }

  getColumnFilterFormControl(field: string): FormControl {
    let control = this.columnFilterFormControls[field];
    if (!control) {
      control = new FormControl(null);

      control.valueChanges.pipe(
        debounceTime(200),
        takeUntil(this.ngUnsubscribe)
      ).subscribe(value => {
        this.getDeviceInfo();
      });

      this.columnFilterFormControls[field] = control;
    }

    return control;
  }

  public saveFilter() {
    const currentColumnSettings = this.getColumnSettings();
    const saveFilterDialogRef = this.dialog.open(CreateDeviceInfoFilterComponent, {
      disableClose: false,
    });

    saveFilterDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (chosenName) => {
      if (chosenName) {
        const newColumnSettings: ColumnSettingsCreationRequest = {
          name: chosenName,
          ...currentColumnSettings,
        };

        try {
          const settings = await this.deviceInfoService.saveFilter(newColumnSettings);

          if (settings) {
            this.filters.push(settings);
          }
        } catch (err) {
          console.error(err);
        };
      }
    });
  }

  private getColumnSettings(): ColumnSettings {
    const visible: ColumnConfiguration[] = this.columns;
    const hidden: ColumnConfiguration[] = [];

    this.allColumns.forEach(column => {
      const found = this.displayedColumns.find(field => column.field === field);

      if (!found) {
        hidden.push(column);
      }
    });

    return { visible, hidden };
  }

  async updateColumnOrderPreference(): Promise<void> {
    const settingsId = this.selectedFilterFormControl.getRawValue();
    const settings = this.findFilter(settingsId);
    const newSettings: ColumnSettings = this.getColumnSettings();
    const payload = {
      id: settingsId,
      ...newSettings,
      name: '',
    };

    if (settings) {
      const { name } = settings;
      payload.name = name;

      if (this.canUpdateColumnSettings()) {
        const result = await this.deviceInfoService.updateColumnSettings(settingsId, payload);
        this.updateLocalFilter(payload);
      }
    }
  }

  private updateLocalFilter(filter: SavedColumnSettings): void {
    const index = this.filters.findIndex(f => f.id === filter.id);

    if (index >= 0) {
      this.filters[index] = filter;
    }
  }

  onPaginate(event: any): void {
    this.paginatorData.pageIndex = event.pageIndex;
    this.paginatorData.pageSize = event.pageSize;
    this.getDeviceInfo();
  }

  handleInputClick(event: MouseEvent): void {
    event.stopPropagation();
  }

  async getDeviceInfo() {
    this.loading = true;

    const page = this.paginatorData.pageIndex;
    const size = this.paginatorData.pageSize;
    const params = this.getDeviceInfoParams();

    if (params.columns['sort'] === 'reports') {
      this.loading = false;
      return;
    }

    this.deviceInfoService.getDeviceInfo(
      params,
      page,
      size,
    ).pipe(takeUntil(this.ngUnsubscribe)).subscribe(devicesPage => {

      this.paginatorData.length = devicesPage.totalItems;

      if (devicesPage) {
        const { data } = devicesPage;

        this.deviceInfoList = data;
        this.deviceInfoList.forEach((item: any, index) => {
          const keys = Object.keys(item);

          if (keys.length > 0) {
            const clonedItem: any = { ...item };

            if (clonedItem.passed) {
              clonedItem.passed = clonedItem.passed.split(',');
            }
            if (clonedItem.failed) {
              clonedItem.failed = clonedItem.failed.split(',');
            }
            if (clonedItem.pending) {
              clonedItem.pending = clonedItem.pending.split(',');
            }

            clonedItem.reports = item['id'];
            this.deviceInfoList[index] = clonedItem;
          }
        });
      }
      this.loading = false;
    });
  }

  private getTodayDateRange(): DateRange {
    const start = moment();
    const end = start.clone();

    return { start, end };
  }
  private getYesterdayDateRange(): DateRange {
    const start = moment().add(-1, 'days');
    const end = start.clone();


    return { start, end };
  }
  private getLastSevenDaysDateRange(): DateRange {
    const end = moment();
    const start = end.clone().add(-6, 'days');

    return { start, end };
  }
  private getLastThirtyDaysDateRange(): DateRange {
    const end = moment();
    const start = end.clone().add(-29, 'days');

    return { start, end };
  }
  private getThisMonthDateRange(): DateRange {
    const end = moment();
    const start = end.clone().startOf('month');

    return { start, end };
  }
  private getLastMonthDateRange(): DateRange {
    const now = moment();
    const start = now.clone().add(-1, 'months').startOf('month');
    const end = now.clone().add(-1, 'months').endOf('month');

    return { start, end };
  }

  private getDateRangeFromDateOption(option: DateOption): { start: Moment, end: Moment } | undefined {
    switch (option) {
      case DateOption.TODAY:
        return this.getTodayDateRange();
      case DateOption.YESTERDAY:
        return this.getYesterdayDateRange();
      case DateOption.LAST_7_DAYS:
        return this.getLastSevenDaysDateRange();
      case DateOption.LAST_30_DAYS:
        return this.getLastThirtyDaysDateRange();
      case DateOption.THIS_MONTH:
        return this.getThisMonthDateRange();
      case DateOption.LAST_MONTH:
        return this.getLastMonthDateRange();
    }

    return undefined;
  }

  private getDateRangeFromDateOptionString(option: DateOption): { start: string, end: string } | undefined {
    const dateFormat = 'YYYY-MM-DD';
    const s = this.getDateRangeFromDateOption(option);

    if (s) {
      const { start, end } = s;

      return { start: start.format(dateFormat), end: end.format(dateFormat) };
    }

    return undefined;
  }

  public configureColumns(): void {
    const settingsId = this.selectedFilterFormControl.getRawValue();
    const settings = this.findFilter(settingsId);

    if (settings) {
      const { name } = settings;
      const configureColumnsDialogRef = this.dialog.open(DeviceInfoColumnConfigurationComponent, {
        disableClose: false,
        height: '95vh',
        data: {
          settings: this.getColumnSettings(),
          deviceInfoService: this.deviceInfoService,
          id: settingsId,
          name,
        }
      });

      configureColumnsDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe((preference: SavedColumnSettings) => {
        if (preference) {
          const index = this.filters.findIndex(f => f.id == preference.id);

          if (index >= 0) {
            this.filters[index] = preference;
          } else {
            this.filters.push(preference);
          }
          this.selectedFilterFormControl.setValue(preference.id);
        } else if (typeof preference === 'boolean') {
          const index = this.filters.findIndex(f => f.id === settingsId);

          if (index >= 0) {
            this.filters.splice(index, 1);
            this.setDefaultFilter();
          }
        }
      });
    }
  }

  private setDefaultFilter(): void {
    this.selectedFilterFormControl.setValue('default');
  }

  private async getFilters() {
    const filters: SavedColumnSettings[] | undefined = await this.deviceInfoService.getFilters();

    if (filters) {
      this.filters = filters;
      this.setDefaultFilter();
    }
  }

  private findFilter(id: string) {
    return this.filters.find(f => f.id === id);
  }

  private applySavedFilter(filter: ColumnSettings) {
    this.applyColumnSettings(filter);
  }

  private getCurrentColumnSettingsId(): string {
    return this.selectedFilterFormControl.getRawValue() || 'default';
  }

  private getDeviceInfoParams(gradingDataExportOnly: boolean = false) {
    const start = this.startDate;
    const end = this.endDate;
    const keyword = this.keywordFormControl.getRawValue();
    const columns: Record<string, string> = {};
    const columnSettingsId = this.getCurrentColumnSettingsId();
    const sort = this.sort.active;
    const direction = this.sort.direction || 'desc';

    if (!this.isDateRangeValid()) {
      throw new Error('Invalid date range');
    }

    Object.keys(this.columnFilterFormControls).forEach(field => {
      const control = this.columnFilterFormControls[field];
      const value = control.getRawValue();

      if (value) {
        columns[field] = value;
      }
    });

    if (columnSettingsId) {
      columns['columnSettingsId'] = columnSettingsId;
    }

    if (sort) {
      columns['sort'] = sort;
      columns['direction'] = direction;
    }

    return {
      gradingDataExportOnly,
      dateRange: {
        start: toDateString(start),
        end: toDateString(end),
      },
      keyword,
      columns,
    }
  }

  public openJSONViewer(field: string, data: object) {
    const jsonViewer = this.dialog.open(JSONViewerComponent, {
      data: {
        json: data,
        field,
      },
      width: field == 'OEM Parts'? '50%' : '33vw',
      maxHeight: '90vh',
    });
  }

  public openGradingViewer(profileName: string, data: object[], routeName: string,) {
    const arrayViewer = this.dialog.open(GradingQuestionsComponent, {
      data: {
        questions: data,
        profileName,
        routeName,
      },
      width: '90%',
      maxHeight: '90vh',
    });
  }

  public openArrayViewer(profile: string, data: object[], routeName: string,) {
    const arrayViewer = this.dialog.open(ArrayViewerComponent, {
      data: {
        arrayData: data,
        profile,
        routeName,
      },
      width: '50%',
      maxHeight: '90vh',
    });
  }

  public isObject(o: any) {
    return o !== null && typeof o === 'object';
  }

  public isStringArray(value: string): boolean {
    try {
      const parsedValue = JSON.parse(value);
      return Array.isArray(parsedValue);
    } catch (error) {
      return false;
    }
  }

  private async getDeviceInfoColumns() {
    const columns = await this.deviceInfoService.getDeviceInfoColumns();
    this.allColumns = columns;
  }

  private applyColumnSettings(settings: ColumnSettings): void {
    const { visible } = settings;

    this.columns = visible;

    this.setDisplayedColumns();
  }

  public addEllipsis(o: any) {
    const s = JSON.stringify(o);

    return s.length >= 20;
  }

  public onGradingDataExport() {
    this.onDownload(DownloadFormat.EXCEL, true);
  }

  public onDownloadCSV() {
    this.onDownload(DownloadFormat.CSV);
  }

  public onDownloadExcel() {
    this.onDownload(DownloadFormat.EXCEL);
  }

  private async onDownload(format: DownloadFormat, gradingDataExportOnly: boolean = false) {
    this.filesLoading = true;
    const params = this.getDeviceInfoParams(gradingDataExportOnly);
    await this.deviceInfoService.downloadDeviceInfo(params, format);
    this.filesLoading = false;
  }

  public async onCopy() {
    this.copyingToClipboard = true;
    const params = this.getDeviceInfoParams();
    await this.deviceInfoService.copyToClipboard(params);
    this.copyingToClipboard = false;
  }

  private clearSelectedFilter(): void {
    this.selectedFilterFormControl.setValue(null, { emitEvent: false });
  }

  public onClearFilter(): void {
    this.keywordFormControl.setValue(null, { emitEvent: false });
    this.dateOptionFormControl.setValue(DateOption.TODAY);
  }

  public canUpdateColumnSettings(): boolean {
    const settingsId = this.selectedFilterFormControl.getRawValue();

    return settingsId !== 'default' && settingsId;
  }

  public onSortChange(event: any): void {
    this.getDeviceInfo();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.headerMenuService.allowUseAllMasterData(false);
  }

  private refresh(): void {
    this.headerMenuService.disableSeeAllMasterDataCheckbox();
    Promise.all([
      this.setDisplayedColumns(),
      this.getDeviceInfo(),
    ]).finally(() => {
      this.headerMenuService.enableSeeAllMasterDataCheckbox();
    });
  }


  // Check if eraseStatus matches any of the valid values


  public isErased(deviceInfo: any): boolean {
    const eraseStatus = deviceInfo['eraseStatus'];

    return eraseStatus === 'Erased';
  }

  openDHR(guid: string): void {
    window.open(`${environment.dhrQRURL}/${guid}`, '_blank');
  }

  openErasureReport(id: any): void {
    const newTab = window.open('', '_blank');

    if (newTab) {
      newTab.location.href = this.router.createUrlTree(['/device-erasure-report'], { queryParams: { id } }).toString();
    } else {
      console.error('Unable to open a new tab.');
    }
  }

  isEmptyObject(obj: any): boolean {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  public getPublicValue(o: Record<string, any>, k: string) {
    const v = o[k];

    return v === null || v === undefined ? '' : v;
  }

  public onDateRangeChanged() {
    if (this.isDateRangeValid()) {
      this.resetPagination();
      this.getDeviceInfo();
    }
  }

  private isDateRangeValid(): boolean {
    const start = this.startDate;
    const end   = this.endDate;

    return !!start && !!end && start.isValid() && end.isValid()
      && start.isSameOrBefore(end);
  }

  private resetPagination() {
    this.paginatorData.pageIndex = 0;
    this.paginator.pageIndex = 0;

    this.paginatorData.pageSize = 10;
    this.paginator.pageSize = 10;
  }

  public gradingQuestionAnswerField(field: string): boolean {
    const GRADING_QUESTION_FIELDS = ['questionAnswer', 'gradingQuestionAnswer'];

    return GRADING_QUESTION_FIELDS.includes(field);
  }

  hasDHRLink(device: any) {
    return !!device['guid'];
  }

}

