<mat-toolbar color="primary" class="toolbar" *ngIf="showHeader && showHeaderFooter">
  <img src="../assets/pcLogoSquare.bmp" alt="Phonecheck Logo" class="logo">
  <div style="padding-top: 1em;">
    <ng-container *ngIf="showWarehousesForAdmin() || userType === 'user'">
      <mat-form-field id="no-border" (click)="onFocusWarehouse()">
        <mat-label style="color: white;">Warehouse</mat-label>
        <input #warehouseFilter type="text" matInput [matAutocomplete]="auto" class="custom-select"
          [formControl]="warehouseFormControl" style="color: white" (input)="onFilterWarehouseTermChange()" />
        <mat-autocomplete requireSelection autoActiveFirstOption autoActiveFirstOption #auto="matAutocomplete"
          [displayWith]="displayFn.bind(this)" (closed)="onWarehouseListClosed()" (open)="onWarehouseListOpened()">
          <mat-option *ngFor="let warehouse of filteredWarehouses" [value]="warehouse">{{warehouse.name}}</mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>
  </div>
  <div *ngIf="!showWarehousesForAdmin() && userType != 'user'">
    <span style="font-size: 0.8em;">Warehouse</span>
    <br>
    <span>{{title}}</span>
  </div>
  <span class="flex-spacer"></span>
  <div fxLayout="row" fxLayoutAlign="center center" *ngIf="showHeaderFooter">
    <mat-form-field style="background-color: white" *ngIf="userType === 'cloudAdmin'" (click)="onFocus()">
      <input #masterFilter type="text" matInput [formControl]="masterSearchInputControlForm" [matAutocomplete]="auto"
        (input)="onFilterTermChange()" placeholder="Select account">
      <mat-autocomplete requireSelection autoActiveFirstOption #auto="matAutocomplete" (opened)="onMasterListOpened()"
        (optionSelected)="onMasterSelected($event)" (closed)="onMasterListClosed()">
        <mat-option *ngFor="let option of filteredMasters" [value]="option.name">{{option.name}}</mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div class="username-box">
      <div (click)="showContext = !showContext">
        <span>
          {{ accountNameToShow }}
        </span>
        <mat-icon *ngIf="!showContext" aria-label="signout">expand_more</mat-icon>
        <mat-icon *ngIf="showContext" aria-label="signout">expand_less</mat-icon>
      </div>
      <div class="context" *ngIf="showContext">
        <ng-container *isGranted="['Account Settings']">
          <button mat-button [routerLink]="['account']" (click)="showContext = false;">Account settings</button>
        </ng-container>
        <ng-container *isGranted="['License Information']">
          <button mat-button [routerLink]="['license-information']" (click)="showContext = false;">License
            Information</button>
        </ng-container>
        <button mat-button (click)="logout(); showContext = false;">
          Sign out
        </button>
      </div>
    </div>
  </div>

  <div class="header-buttons">
  </div>
  <div id="loginContainer"></div>
</mat-toolbar>
<mat-progress-spinner [diameter]="150" mode="indeterminate" *ngIf="changingUserModeOrMaster"></mat-progress-spinner>
<mat-sidenav-container class="sidenav-container" autosize>
  <mat-sidenav #sidenav class="sidenav" [attr.role]="'navigation'" [mode]="'side'" [opened]="true"
    *ngIf="showHeader && showHeaderFooter " [ngStyle]="{'display': showMenu ? 'block' : 'none' }">
    <mat-nav-list>
      <a *isGranted="['Dashboard']" mat-list-item [routerLink]="['/dashboard']"
        [routerLinkActive]="['is-active']">Dashboard</a>
      <ng-container *ngIf="adminContext">
        <a *isGranted="['Software Update']" mat-list-item [routerLink]="['/software-update']"
          [routerLinkActive]="['is-active']">
          Software Update
        </a>
      </ng-container>
      <a *isGranted="['Customization', 'Customization Profiles']" mat-list-item [routerLink]="['/customizations']"
        [routerLinkActive]="['is-active']">Customizations</a>
      <a *isGranted="['Station', 'Create New Warehouses']" mat-list-item [routerLink]="['/activate']"
        [routerLinkActive]="['is-active']">Station</a>
      <a *isGranted="['Device Lookup']" mat-list-item [routerLink]="['/device-lookup']"
        [routerLinkActive]="['is-active']">Device Lookup</a>
      <a *isGranted="['Device Info']" mat-list-item [routerLink]="['/device-info']"
        [routerLinkActive]="['is-active']">Device Info</a>
      <a *isGranted="['Mac Device Info', 'Mac Devices']" mat-list-item [routerLink]="['/mac-device-info']"
        [routerLinkActive]="['is-active']">Mac Device Info</a>
      <a *isGranted="['IMEI Checker']" mat-list-item [routerLink]="['/imei-check']"
        [routerLinkActive]="['is-active']">IMEI Checker</a>
      <a *isGranted="['Certification Toolbox']" mat-list-item (click)="goToToolbox()">Certification Toolbox</a>
      <a *isGranted="['Users', 'Create New Users']" mat-list-item [routerLink]="['/users']"
        [routerLinkActive]="['is-active']">Users</a>
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" *isGranted="['SKU Configuration']">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding [routerLink]="[node.route]"
          [routerLinkActive]="['is-active']">
          <ng-container *isGranted="node.permissions">
            {{node.name}}
          </ng-container>
        </mat-tree-node>
        <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding matTreeNodeToggle>
          <button mat-icon-button matTreeNodeToggle>
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          {{node.name}}
        </mat-tree-node>
        <mat-tree-node *matTreeNodeDef="let node;when: isRoot" matTreeNodePadding>
          <button disabled mat-icon-button></button>
          {{node.name}}
        </mat-tree-node>
      </mat-tree>
      <mat-tree [dataSource]="reportsMenu" [treeControl]="treeControl">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding [routerLink]="[node.route]"
          [routerLinkActive]="['is-active']">
          <ng-container *isGranted="node.permissions">
            {{node.name}}
          </ng-container>
        </mat-tree-node>
        <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding matTreeNodeToggle>
          <button mat-icon-button matTreeNodeToggle>
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          {{node.name}}
        </mat-tree-node>
        <mat-tree-node *matTreeNodeDef="let node;when: isRoot" matTreeNodePadding>
          <button disabled mat-icon-button></button>
          {{node.name}}
        </mat-tree-node>
      </mat-tree>

    </mat-nav-list>
    <div class="weglot"></div>
  </mat-sidenav>
  <mat-sidenav-content style="background-color: white;">
    <router-outlet>
    </router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>