<mat-radio-group [formControl]="planSelected">
  <div fxLayout="column">
    <mat-radio-button value="certified-test-suite">Certified Test Suite (recommended)</mat-radio-button>
    <mat-radio-button value="custom-test-plan">Custom Test Plan</mat-radio-button>
  </div>
</mat-radio-group>
<mat-divider></mat-divider>
<div *ngIf="isReady() && isCustomTestPlanSelected()">
  <div *ngIf="deviceType === 'device'">
    <mat-checkbox [formControl]="globalPlanEnabled">Use the same plan for both Android and Apple</mat-checkbox>
    <mat-divider></mat-divider>
  </div>
  <div *ngIf="isGlobalPlanEnabled()">
    <app-test-plan-form
      #global
      [platform]="deviceType === 'device' ? 'both' : deviceType"
      [selectedDeviceTests]="selectedGlobalDeviceTests"
      [skipUncheckingDefaultTestDialog]="skipUncheckingDefaultTestDialog"
      [tests]="tests"
      (updateEmitter)="setGlobalSelectedDeviceTests($event)"
      (onSkipDefaultTestDialog)="setUncheckingDefaultTestDialog($event)"
      [testFilter]="deviceType === 'watch' ? watchFilter : (deviceType === 'airpods' ? airpodsFilter : deviceFilter)"
      >
    </app-test-plan-form>
  </div>

  <!-- does not show for watch and airpods -->
  <div *ngIf="deviceType === 'device' && !isGlobalPlanEnabled()" id="makeContainer">
    <mat-tab-group>
      <mat-tab label="Apple">
        <app-test-plan-form #apple
          [platform]="'ios'"
          [selectedDeviceTests]="selectedAppleDeviceTests"
          [skipUncheckingDefaultTestDialog]="skipUncheckingDefaultTestDialog"
          (updateEmitter)="setAppleSelectedDeviceTests($event)"
          (onSkipDefaultTestDialog)="setUncheckingDefaultTestDialog($event)"
          [tests]="tests"
          [testFilter]="appleFilter"
          >
        </app-test-plan-form>
      </mat-tab>
      <mat-tab label="Android">
        <app-test-plan-form
          #android
          [platform]="'android'"
          [selectedDeviceTests]="selectedAndroidDeviceTests"
          [skipUncheckingDefaultTestDialog]="skipUncheckingDefaultTestDialog"
          (updateEmitter)="setAndroidSelectedDeviceTests($event)"
          (onSkipDefaultTestDialog)="setUncheckingDefaultTestDialog($event)"
          [testFilter]="androidFilter"
          [tests]="tests">
        </app-test-plan-form>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

