import moment, { Moment } from 'moment-timezone';
import { ViewChild, Component, OnInit } from '@angular/core';
import { AccountService } from '../modules/account/account.service';
import { AuthService } from '../services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PaginatorData } from '../interfaces/paginatorData.interface';
import lodash, { sortBy, sortedIndex } from 'lodash';
import { SearchFilterCollection } from '../interfaces/licenseUsageHistorySearchFilters.interface';
import { debounceTime, filter } from 'rxjs';
import { LicenseUsageActivitySearchFilters } from '../interfaces/licenseUsageActivitySearchFilters.interface';
import { DownloadFormat } from '../common/download';
import { LicenseService } from '../services/license.service';
import { ToggleMenuService } from '../services/toggle-menu.service';
import { Router } from '@angular/router';
import { NotificationService } from '../services/notification.service';
import { MatSort, Sort } from '@angular/material/sort';
import { Sorting, SortingDirection } from '../common/sorting.interface';
import { MatPaginator } from '@angular/material/paginator';
import { LicenseUsageHistory } from "../interfaces/licenseUsageHistory.interface";

const { isEmpty, isArray } = lodash;

interface AccountInfo {
  name: string,
  phoneNumber: string,
  email: string,
  startDate: string,
  endDate: string,
  billCycle: string,
  status: number,
  type: string,
}

interface BalanceInfo {
  esn: number,
  device: number,
  erase: number,
  simLock: number,
  carrierLock: number,
  iCloud: number,
  macDiagnostics: number,
  macBookICloud: number,
  mdm: number,
  appInstall: number,
  provision: number,
  consumer: number,
  oem: number,
  airpodDiagnostics: number,
  watchDiagnostics: number,
}

enum LicenseType {
  DEVICE = 1,
  ERASE = 2,
  ESN = 3,
  SIM_LOCK = 4,
  CARRIER_LOCK = 5,
  ICLOUD = 6,
  MAC_DIAGNOSTICS = 7,
  MACBOOK_ICLOUD = 8,
  MDM = 9,
  APP_INSTALL = 10,
  PROVISION = 11,
  CONSUMER = 12,
  OEM = 13,
  AIRPOD_DIAGNOSTICS = 14,
  WATCH_DIAGNOSTICS = 15,
};

@Component({
  selector: 'app-license-information',
  templateUrl: './license-information.component.html',
  styleUrls: ['./license-information.component.css']
})
export class LicenseInformationComponent {
  private static DATE_FORMAT = 'YYYY-MM-DD';
  private static SEARCH_DEBOUNCE_TIME = 300;
  private static LOCAL_FILTERING_DEBOUNCE_TIME = 100;

  public downloadingHistoryExport: boolean = false;
  public downloadingHistoryBySourceExport: boolean = false;
  public downloadingActivityExport: boolean = false;
  public users: { id: string, name: string }[] = [];

  CHARGE_TYPE_OPTIONS = [
    { value: 'all', displayName: 'All' },
    { value: 'deposit', displayName: 'Deposit' },
    { value: 'withdrawal', displayName: 'Withdrawal' },
  ];

  LICENSE_TYPE_OPTIONS = [
    'Device',
    'Erased',
    'ESN',
    'SIM Lock',
    'Carrier Lock',
    'iCloud',
    'Mac Diagnostic',
    'MacBook iCloud',
    'MDM',
    'App Install',
    'Provision',
    'Consumer',
    'OEM',
    'Airpod Diagnostics',
    'Watch Diagnostics',
  ];
  LICENSE_SOURCE_OPTIONS = [
    {
      displayValue: 'Billing',
      value: 'billing',
    },
    {
      displayValue: 'Bulk',
      value: 'bulk',
    },
    {
      displayValue: 'Consumer',
      value: 'consumer',
    },
    {
      displayValue: 'Desktop',
      value: 'desktop',
    },
    {
      displayValue: 'Cloud',
      value: 'cloud',
    },
    {
      displayValue: 'Mac Diagnostics',
      value: 'Mac Diagnostics',
    },
  ];

  public accountInfo: AccountInfo | undefined;
  public balance: BalanceInfo | undefined;
  public usage: BalanceInfo | undefined;
  public startDate = moment();
  public endDate = moment();
  public loadingSummary: boolean = false;
  public loadingBalance: boolean = false;


  //// BEGIN HISTORY BY SOURCE

  onHistoryBySourceCopy() {
    this.downloadingHistoryBySourceExport = true;
    return this.licenseService.copyHistoryBySource(
      this.getStartDate(),
      this.getEndDate(),
      this.historyBySourcePaginatorData.currentPage,
      this.historyBySourcePaginatorData.pageSize,
      this.historyBySourceSort,
      this.getFiltersBySource(),
    ).finally(() => {
      this.downloadingHistoryBySourceExport = false;
    });
  }

  onHistoryBySourceDownloadExcel() {
    this.downloadingHistoryBySourceExport = true;
    return this.licenseService.downloadHistoryBySource(
      this.getStartDate(),
      this.getEndDate(),
      this.historyBySourcePaginatorData.currentPage,
      this.historyBySourcePaginatorData.pageSize,
      this.getFiltersBySource(),
      this.historyBySourceSort,
      DownloadFormat.EXCEL,
    ).finally(() => {
      this.downloadingHistoryBySourceExport = false;
    });
  }

  onHistoryBySourceDownloadCSV() {
    this.downloadingHistoryBySourceExport = true;
    return this.licenseService.downloadHistoryBySource(
      this.getStartDate(),
      this.getEndDate(),
      this.historyBySourcePaginatorData.currentPage,
      this.historyBySourcePaginatorData.pageSize,
      this.getFiltersBySource(),
      this.historyBySourceSort,
      DownloadFormat.CSV,
    ).finally(() => {
      this.downloadingHistoryBySourceExport = false;
    });
  }

  ////// BEGIN history
  private allHistory: LicenseUsageHistory[] = [];
  private historySort: Sorting = { sort: 'date', direction: SortingDirection.DESC };
  private historyBySourceSort: Sorting = { sort: 'date', direction: SortingDirection.DESC };
  public loadingHistory: boolean = false;
  public historySearchTermFormControl: FormControl<string | null> = new FormControl<string>('');
  public historyBysourceSearchTermFormControl: FormControl<string | null> = new FormControl<string>('');
  public historySearchFormControls: Record<string, FormControl> = {};
  public historyBySourceSearchFormControls: Record<string, FormControl> = {};
  public historyPaginatorData: PaginatorData = {
    currentPage: 0,
    length: 0,
    pageIndex: 0,
    pageOptions: [5, 10, 15],
    pageSize: 5,
  };

  public historyBySourcePaginatorData: PaginatorData = {
    currentPage: 0,
    length: 0,
    pageIndex: 0,
    pageOptions: [5, 10, 15],
    pageSize: 5,
  };

  public historySourceColumns = [
    {
      field: 'warehouseName',
      displayName: 'Warehouse',
    },
    {
      field: 'stationName',
      displayName: 'source',
    },
    {
      field: 'device',
      displayName: 'Device',
    },
    {
      field: 'appInstall',
      displayName: 'App',
    },
    {
      field: 'erase',
      displayName: 'Erase',
    },
    {
      field: 'esn',
      displayName: 'ESN',
    },
    {
      field: 'consumer',
      displayName: 'Consumer',
    },
    {
      field: 'iCloud',
      displayName: 'iCloud',
    },
    {
      field: 'simLock',
      displayName: 'SIM Lock',
    },
    {
      field: 'provision',
      displayName: 'Provision',
    },
    {
      field: 'carrierLock',
      displayName: 'Carrier Lock',
    },
    {
      field: 'macDiagnostics',
      displayName: 'MacBook Diagnostics',
    },
    {
      field: 'macBookICloud',
      displayName: 'MacBook iCloud',
    },
    {
      field: 'mdm',
      displayName: 'MacBook MDM',
    },
    {
      field: 'oem',
      displayName: 'OEM',
    },
    {
      field: 'airpodDiagnostics',
      displayName: 'Airpod Diagnostics',
    },
    {
      field: 'watchDiagnostics',
      displayName: 'Watch Diagnostics',
    },
  ];

  public historyColumns = [
    {
      field: 'userName',
      displayName: 'User',
    },
    {
      field: 'device',
      displayName: 'Device',
    },
    {
      field: 'appInstall',
      displayName: 'App',
    },
    {
      field: 'erase',
      displayName: 'Erase',
    },
    {
      field: 'esn',
      displayName: 'ESN',
    },
    {
      field: 'consumer',
      displayName: 'Consumer',
    },
    {
      field: 'iCloud',
      displayName: 'iCloud',
    },
    {
      field: 'simLock',
      displayName: 'SIM Lock',
    },
    {
      field: 'provision',
      displayName: 'Provision',
    },
    {
      field: 'carrierLock',
      displayName: 'Carrier Lock',
    },
    {
      field: 'macDiagnostics',
      displayName: 'MacBook Diagnostics',
    },
    {
      field: 'macBookICloud',
      displayName: 'MacBook iCloud',
    },
    {
      field: 'mdm',
      displayName: 'MacBook MDM',
    },
    {
      field: 'oem',
      displayName: 'OEM',
    },
    {
      field: 'airpodDiagnostics',
      displayName: 'Airpod Diagnostics',
    },
    {
      field: 'watchDiagnostics',
      displayName: 'Watch Diagnostics',
    },
  ];
  public historyDisplayedColumns = this.historyColumns.map(c => c.field);
  public historyBySourceDisplayedColumns = this.historySourceColumns.map(c => c.field);
  public historyDataSource: any[] = [];
  public historyBySourceDataSource: any[] = [];
  loadingHistoryBySource = false;
  public allHistoryBySource: LicenseUsageHistory[] = [];

  getHistoryColumnFilterFormControl(field: string): FormControl {
    let control = this.historySearchFormControls[field];

    if (!control) {
      control = new FormControl('');
      control.valueChanges.pipe(
        debounceTime(LicenseInformationComponent.LOCAL_FILTERING_DEBOUNCE_TIME)
      ).subscribe(value => {
        const filters = this.getFilters();
        this.processHistoryFiltering();
      });
      this.historySearchFormControls[field] = control;
    }

    return control;
  }

  getHistoryBySourceColumnFilterFormControl(field: string): FormControl {
    // let control = this.historySearchFormControls[field];
    let control = this.historyBySourceSearchFormControls[field];


    if (!control) {
      control = new FormControl('');
      control.valueChanges.pipe(
        debounceTime(LicenseInformationComponent.LOCAL_FILTERING_DEBOUNCE_TIME)
      ).subscribe(_ => {
        const filters = this.getFiltersBySource();
        this.processHistoryBySourceFiltering();
      });
      this.historyBySourceSearchFormControls[field] = control;
    }

    return control;
  }

  onHistoryDownloadExcel() {
    this.downloadingHistoryExport = true;
    return this.licenseService.downloadHistory(
      this.getStartDate(),
      this.getEndDate(),
      this.historyPaginatorData.currentPage,
      this.historyPaginatorData.pageSize,
      this.getFilters(),
      this.historySort,
      DownloadFormat.EXCEL,
    ).finally(() => {
      this.downloadingHistoryExport = false;
    });
  }

  onHistoryDownloadCSV() {
    this.downloadingHistoryExport = true;
    return this.licenseService.downloadHistory(
      this.getStartDate(),
      this.getEndDate(),
      this.historyPaginatorData.currentPage,
      this.historyPaginatorData.pageSize,
      this.getFilters(),
      this.historySort,
      DownloadFormat.CSV,
    ).finally(() => {
      this.downloadingHistoryExport = false;
    });
  }

  onHistoryCopy() {
    this.downloadingHistoryExport = true;
    return this.licenseService.copyHistory(
      this.getStartDate(),
      this.getEndDate(),
      this.historyPaginatorData.currentPage,
      this.historyPaginatorData.pageSize,
      this.historySort,
      this.getFilters(),
    ).finally(() => {
      this.downloadingHistoryExport = false;
    });
  }
  //// END




  public chargeTypeFormControl = new FormControl<string>('all');
  ////// BEGIN activity
  private activitySort: Sorting = { sort: 'date', direction: SortingDirection.DESC };
  public loadingActivity: boolean = false;
  public activityLicenseTypeFormControl = new FormControl<string>('');
  public activityUserFormControl = new FormControl<string>('');
  public activitySourceFormControl = new FormControl<string>('');
  public activityKeywordFormControl = new FormControl<string>('');
  public activityPaginatorData: PaginatorData = {
    currentPage: 0,
    length: 0,
    pageIndex: 0,
    pageOptions: [5, 10, 15],
    pageSize: 5,
  };
  public activityColumns = [
    {
      field: 'datetime',
      displayName: 'Date',
    },
    {
      field: 'source',
      displayName: 'Source',
    },
    {
      field: 'userName',
      displayName: 'User',
    },
    {
      field: 'serial',
      displayName: 'Serial',
    },
    {
      field: 'imei',
      displayName: 'IMEI',
    },
    {
      field: 'licenseName',
      displayName: 'License',
    },
    {
      field: 'withdrawal',
      displayName: 'Withdrawal',
    },
    {
      field: 'deposit',
      displayName: 'Deposit',
    },
    {
      field: 'balance',
      displayName: 'Balance',
    },
  ];
  public activityDisplayedColumns = this.activityColumns.map(c => c.field);
  public activityDataSource: any[] = [];
  public onActivityPaginate(event: any) {
    this.activityPaginatorData.pageIndex = event.pageIndex;
    this.activityPaginatorData.pageSize = event.pageSize;
    this.getLicenseUsageActivity();
  }

  onActivityDownloadExcel() {
    this.downloadingActivityExport = true;
    this.licenseService.downloadActivity(
      this.getStartDate(),
      this.getEndDate(),
      this.activityPaginatorData.currentPage,
      this.activityPaginatorData.pageSize,
      this.getActivityFilters(),
      this.activitySort,
      DownloadFormat.EXCEL,
    ).finally(() => {
      this.downloadingActivityExport = false;
    });
  }

  onActivityDownloadCSV() {
    this.downloadingActivityExport = true;
    this.licenseService.downloadActivity(
      this.getStartDate(),
      this.getEndDate(),
      this.activityPaginatorData.currentPage,
      this.activityPaginatorData.pageSize,
      this.getActivityFilters(),
      this.activitySort,
      DownloadFormat.CSV,
    ).finally(() => {
      this.downloadingActivityExport = false;
    });
  }

  onActivityCopy() {
    this.downloadingActivityExport = true;
    this.licenseService.copyActivity(
      this.getStartDate(),
      this.getEndDate(),
      this.activityPaginatorData.currentPage,
      this.activityPaginatorData.pageSize,
      this.getActivityFilters(),
      this.activitySort,
    ).finally(() => {
      this.downloadingActivityExport = false;
    });
  }

  //// END

  constructor(
    private authService: AuthService,
    private accountService: AccountService,
    private licenseService: LicenseService,
    private notify: NotificationService,
    private toggleMenuService: ToggleMenuService,
    private router: Router,
  ) {
    this.toggleMenuService.allowUseAllMasterData(false);
    this.authService.masterSelectedEmitter.subscribe(() => {
      this.refresh();
    });

    this.authService.warehouseSelectedEmitter.subscribe(() => {
      this.refresh()
    })

    if (this.authService.isMasterContext() || this.authService.isUserContext() || this.authService.getSelectedMaster()) {
      this.licenseService.getMasterUSers().then(users => {
        this.users = users;
      });
      this.historySearchTermFormControl.valueChanges.pipe(
        debounceTime(LicenseInformationComponent.LOCAL_FILTERING_DEBOUNCE_TIME)
      ).subscribe(value => {
        this.processHistoryFiltering()
      });
      this.historyBysourceSearchTermFormControl.valueChanges.pipe(
        debounceTime(LicenseInformationComponent.SEARCH_DEBOUNCE_TIME)
      ).subscribe(value => {
        this.processHistoryBySourceFiltering();
      });
      this.activityKeywordFormControl.valueChanges.pipe(
        debounceTime(LicenseInformationComponent.SEARCH_DEBOUNCE_TIME)
      ).subscribe(value => {
        this.getLicenseUsageActivity(true);
      });
      this.activitySourceFormControl.valueChanges.pipe(
        debounceTime(LicenseInformationComponent.SEARCH_DEBOUNCE_TIME)
      ).subscribe(value => {
        this.getLicenseUsageActivity(true);
      });
      this.activityLicenseTypeFormControl.valueChanges.pipe(
        debounceTime(LicenseInformationComponent.SEARCH_DEBOUNCE_TIME)
      ).subscribe(value => {
        this.getLicenseUsageActivity(true);
      });
      this.activityUserFormControl.valueChanges.pipe(
        debounceTime(LicenseInformationComponent.SEARCH_DEBOUNCE_TIME)
      ).subscribe(value => {
        this.getLicenseUsageActivity(true);
      });
      this.chargeTypeFormControl.valueChanges.pipe(
        debounceTime(LicenseInformationComponent.SEARCH_DEBOUNCE_TIME)
      ).subscribe(value => {
        this.getLicenseUsageActivity(true);
      });
    } else {
      this.notify.warn('Select an account to view the license information page');
      this.router.navigate(['/dashboard']);
    }
  }

  ngOnInit() {
    this.refresh();
  }

  public refresh() {
    return this.sequentialRefresh();
    // return this.parallelRefresh();
  }

  private parallelRefresh() {
    this.getAccountDetails();
    this.getUsageSummary();
    this.getLicenseUsageHistory(true);
    this.getLicenseUsageHistoryBySource(true);
    this.getLicenseUsageActivity(true);
  }

  private refreshLicenseDetails() {
    this.loadingSummary = true;
    this.loadingHistory = true;
    this.loadingActivity = true;

    return this.getBalance().then(() => {
      return this.getLicenseUsageHistory(true);
    }).then(() => {
      return this.getLicenseUsageHistoryBySource(true);
    }).then(() => {
      return this.getLicenseUsageActivity(true);
    }).then(() => {
      return this.getUsageSummary();
    }).finally(() => {
      this.loadingSummary = false;
      this.loadingHistory = false;
      this.loadingActivity = false;
      this.loadingHistoryBySource = false;
    });
  }

  private sequentialRefresh() {
    this.getAccountDetails().then(() => {
      return this.refreshLicenseDetails();
    });
  }

  public getStatusDisplayName(status: number): string {
    switch (Number(status)) {
      case 1: return 'Active';
      case 2: return 'On Hold';
      case 3: return 'Closed';
      case 4: return 'Pending Training';
    }

    return String(status);
  }

  datePickerEnabled(): boolean {
    return !this.loadingSummary
      && !this.loadingHistory
      && !this.loadingActivity;
  }

  getStartDate(): string {
    const m = this.startDate;

    return m.format(LicenseInformationComponent.DATE_FORMAT);
  }

  getEndDate(): string {
    const m = this.endDate;

    return m.format(LicenseInformationComponent.DATE_FORMAT);
  }

  getBalance() {
    this.loadingBalance = true;
    return this.licenseService.getLicenseBalance().then(balance => {
      this.balance = {
        device: balance[LicenseType.DEVICE],
        erase: balance[LicenseType.ERASE],
        esn: balance[LicenseType.ESN],
        simLock: balance[LicenseType.SIM_LOCK],
        carrierLock: balance[LicenseType.CARRIER_LOCK],
        iCloud: balance[LicenseType.ICLOUD],
        macDiagnostics: balance[LicenseType.MAC_DIAGNOSTICS],
        macBookICloud: balance[LicenseType.MACBOOK_ICLOUD],
        mdm: balance[LicenseType.MDM],
        appInstall: balance[LicenseType.APP_INSTALL],
        provision: balance[LicenseType.PROVISION],
        consumer: balance[LicenseType.CONSUMER],
        oem: balance[LicenseType.OEM],
        airpodDiagnostics: balance[LicenseType.AIRPOD_DIAGNOSTICS],
        watchDiagnostics: balance[LicenseType.WATCH_DIAGNOSTICS],
      }
    }).finally(() => {
      this.loadingBalance = false;
    });
  }

  getUsageSummary() {
    const start = this.getStartDate();
    const end = this.getEndDate();

    this.loadingSummary = true;
    return this.licenseService.getLicenseUsageSummary(start, end).then(usage => {
      if (!usage) return;

      this.usage = {
        device: usage[LicenseType.DEVICE],
        erase: usage[LicenseType.ERASE],
        esn: usage[LicenseType.ESN],
        simLock: usage[LicenseType.SIM_LOCK],
        carrierLock: usage[LicenseType.CARRIER_LOCK],
        iCloud: usage[LicenseType.ICLOUD],
        macDiagnostics: usage[LicenseType.MAC_DIAGNOSTICS],
        macBookICloud: usage[LicenseType.MACBOOK_ICLOUD],
        mdm: usage[LicenseType.MDM],
        appInstall: usage[LicenseType.APP_INSTALL],
        provision: usage[LicenseType.PROVISION],
        consumer: usage[LicenseType.CONSUMER],
        oem: usage[LicenseType.OEM],
        airpodDiagnostics: usage[LicenseType.AIRPOD_DIAGNOSTICS],
        watchDiagnostics: usage[LicenseType.WATCH_DIAGNOSTICS],
      }
    }).finally(() => {
      this.loadingSummary = false;
    });
  }

  getAccountDetails() {
    return this.accountService.getAccountSettings().then(data => {
      const { name, status, masterBilling, masterContacts, type } = data;
      const { start_date: startDate, end_date: endDate, bill_cycle: billCycle } = masterBilling;
      let phoneNumber;
      let email;

      if (masterContacts?.type === 'email') {
        email = masterContacts.contact;
      }
      if (masterContacts?.type === 'phone') {
        phoneNumber = masterContacts.contact;
      }

      this.accountInfo = {
        name,
        email,
        billCycle,
        startDate,
        endDate,
        phoneNumber,
        status,
        type,
      }
    });
  }

  getInitialStartDate(): Moment {
    return moment().startOf('day');
  }

  getInitialEndDate(): Moment {
    return moment();
  }

  async getLicenseUsageHistoryBySource(backToFirstPage = false) {
    this.loadingHistoryBySource = true;
    const filters = this.getFiltersBySource();

    if (backToFirstPage) {
      this.historyBySourcePaginatorData.pageIndex = 0;
    }

    this.loadingHistoryBySource = true;

    const history = await this.licenseService.getLicenseUsageHistoryBySource(
      this.getStartDate(),
      this.getEndDate(),
      this.historyBySourcePaginatorData.pageIndex,
      this.historyBySourcePaginatorData.pageSize,
      filters,
      this.historyBySourceSort,
    ).finally(() => {
      this.loadingHistoryBySource = true;
    });

    if (!history) return;
    this.allHistoryBySource = history;
    this.historyBySourcePaginatorData.length = this.allHistoryBySource.length;
    this.refreshHistoryBySourcePage();
  }

  async getLicenseUsageHistory(backToFirstPage: boolean = false) {
    this.loadingHistory = true;
    const filters = this.getFilters();

    if (backToFirstPage) {
      this.historyPaginatorData.pageIndex = 0;
    }

    this.loadingHistory = true;

    const history = await this.licenseService.getLicenseUsageHistory(
      this.getStartDate(),
      this.getEndDate(),
      this.historyPaginatorData.pageIndex,
      this.historyPaginatorData.pageSize,
      filters,
      this.historySort,
    ).finally(() => {
      this.loadingHistory = false;
    });

    this.allHistory = history || [];
    this.historyPaginatorData.length = this.allHistory.length;
    this.refreshHistoryPage();

    return Promise.resolve();
  }

  private getActivityFilters(): LicenseUsageActivitySearchFilters {
    return {
      keyword: this.activityKeywordFormControl.getRawValue(),
      licenseName: this.activityLicenseTypeFormControl.getRawValue(),
      source: this.activitySourceFormControl.getRawValue(),
      userId: this.activityUserFormControl.getRawValue(),
      type: this.chargeTypeFormControl.getRawValue() || 'all',
    };
  }

  async getLicenseUsageActivity(backToFirstPage: boolean = false) {
    this.loadingActivity = true;
    const filters: LicenseUsageActivitySearchFilters = this.getActivityFilters();

    if (backToFirstPage) {
      this.activityPaginatorData.pageIndex = 0;
    }

    this.loadingActivity = true;
    const activity = await this.licenseService.getLicenseUsageActivity(
      this.getStartDate(),
      this.getEndDate(),
      this.activityPaginatorData.pageIndex,
      this.activityPaginatorData.pageSize,
      filters,
      this.activitySort,
    ).finally(() => {
      this.loadingActivity = false;
    });

    if (activity) {
      const { data, totalItems, currentPage } = activity;

      this.activityDataSource = data;
      this.activityPaginatorData.length = totalItems;
    }

    return Promise.resolve();
  }

  private getColumnFiltersBySource(): Record<string, string> {
    const filters: Record<string, string> = {};
    Object.keys(this.historyBySourceSearchFormControls).forEach(columnField => {
      const control = this.historySearchFormControls[columnField];
      const value = control?.getRawValue();

      if (control && !isEmpty(value)) {
        filters[columnField] = value;

      }
    });

    return filters;
  }

  private getColumnFilters(): Record<string, string> {
    const filters: Record<string, string> = {};

    Object.keys(this.historySearchFormControls).forEach(columnField => {
      const control = this.historySearchFormControls[columnField];
      const value = control?.getRawValue();

      if (control && !isEmpty(value)) {
        filters[columnField] = value;
      }
    });

    return filters;
  }


  private getFiltersBySource(): SearchFilterCollection {
    const searchTerm = this.historyBysourceSearchTermFormControl.getRawValue();
    const columnFilters = this.getColumnFiltersBySource();
    return {
      searchTerm: searchTerm || undefined,
      columnFilters,
    }
  }

  private getFilters(): SearchFilterCollection {
    const searchTerm = this.historySearchTermFormControl.getRawValue();
    const columnFilters = this.getColumnFilters();

    return {
      searchTerm: searchTerm || undefined,
      columnFilters,
    }
  }

  public sortHistory(l: Record<string, string>[], sorting: Sorting) {
    const { sort, direction } = sorting;
    const numberTest = /^\d+$/;

    const compareHistory = (a: Record<string, string>, b: Record<string, string>) => {
      let valueA = a[sort];
      let valueB = b[sort];
      const valueType = typeof valueA;
      let result = 1;

      const areNumeric = numberTest.test(valueA) && numberTest.test(valueB);

      if (areNumeric) {
        const compare = Number(valueA) - Number(valueB);

        result = direction === 'asc' ? compare : -compare;
      } else {
        valueA = String(valueA);
        valueB = String(valueB);

        const compare = valueA.localeCompare(valueB);

        result = direction === 'asc' ? compare : -compare;
      }
      return result;
    }
    return [...l].sort(compareHistory);
  }

  public onHistorySortChange(event: any) {
    const { active: sort, direction } = event;
    this.historySort = { sort, direction };
    this.refreshHistoryPage();
  }

  public onHistoryBySortChange(event: any) {
    const { active: sort, direction } = event;
    this.historyBySourceSort = { sort, direction };
    this.refreshHistoryBySourcePage();
  }

  public onActivitySortChange(event: any) {
    const { active: sort, direction } = event;

    this.activitySort = { sort, direction }
    this.getLicenseUsageActivity();
  }

  private isDateRangeValid(): boolean {
    return !!this.startDate
      && !!this.endDate
      && this.startDate.isValid()
      && this.endDate.isValid()
      && this.startDate.isSameOrBefore(this.endDate);
  }

  public onDateRangeChanged() {
    if (this.isDateRangeValid()) {
      // this.refreshLicenseDetails();
    }
  }

  private processHistoryFiltering() {
    const allHistory = this.allHistory;
    const filters = this.getFilters();

    this.historyDataSource = this.filterHistory(allHistory, filters);
  }

  private processHistoryBySourceFiltering() {
    const allHistory = this.allHistoryBySource;
    const filters = this.getFiltersBySource();

    this.historyBySourceDataSource = this.filterHistory(allHistory, filters);

  }

  private filterHistory(rows: any[], filters: any) {
    return rows.filter(r => this.showHistoryEntry(r, filters));
  }

  private showHistoryEntry(row: any, filters: any) {
    let { searchTerm, columnFilters } = filters;
    const columns = Object.keys(row);
    const loweredRow: Record<string, string> = {};
    let show = true;

    searchTerm = searchTerm || '';

    for (let i = 0; i < columns.length && show; i++) {
      const column = columns[i];
      const value = row[column];
      const typeOfValue = typeof value;
      const filter = (columnFilters[column]?.trim() || '').toLowerCase();
      let stringValue;

      if ((value === null || value === undefined) && filter) {
        return false;
      }

      if (!isArray(value) && typeOfValue === 'object') {
        stringValue = JSON.stringify(value);
      } else {
        stringValue = String(value);
      }
      let loweredValue = stringValue?.toLowerCase();

      loweredRow[column] = loweredValue;
      show &&= loweredValue.includes(filter.toLowerCase())
    }

    return show && Object.values(loweredRow).some(v => v.includes(searchTerm.toLowerCase()));
  }

  public onHistoryPaginate(event: any) {
    this.historyPaginatorData.pageIndex = event.pageIndex;
    this.historyPaginatorData.pageSize = event.pageSize;
    this.refreshHistoryPage();
  }

  public onHistoryBySourcePaginate(event: any) {
    this.historyBySourcePaginatorData.pageIndex = event.pageIndex;
    this.historyBySourcePaginatorData.pageSize = event.pageSize;
    this.refreshHistoryBySourcePage();
  }

  private refreshHistoryPage() {
    this.historyDataSource = this.refreshHistoryPageHelper(
      this.allHistory as unknown as Record<string, string>[],
      this.historySort,
      this.historyPaginatorData.pageIndex,
      this.historyPaginatorData.pageSize,
    );
  }

  private refreshHistoryBySourcePage() {
    this.historyBySourceDataSource = this.refreshHistoryPageHelper(
      this.allHistoryBySource as unknown as Record<string, string>[],
      this.historyBySourceSort,
      this.historyBySourcePaginatorData.pageIndex,
      this.historyBySourcePaginatorData.pageSize,
    );
  }

  private refreshHistoryPageHelper(
    l: Record<string, string>[],
    sorting: Sorting,
    page: number,
    size: number,
  ): any[] {
    const sorted = this.sortHistory(l, sorting);
    const i = page * size;
    const newList = sorted.slice(i, i + size);

    return newList;
  }
}

