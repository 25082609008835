import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {EventEmitter, Injectable, Output} from '@angular/core';
import { firstValueFrom, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root'
})

export class AdminService {

  @Output() adminEmitter = new EventEmitter<boolean>();

  constructor(private http: HttpClient, private authService: AuthService) { }
  ADMIN_ALL_MASTERS_ROUTE = `${environment.apiUrl}/admin/auth/users?all=true`;

  public async getAllMasters(page: number = 0, size: number = 25, filter: string = ''): Promise<any[]> {
    const headers = {
      token_admin_cloud: this.authService.token!,
    };
    const params: { filter?: string }= {};

    if (filter) params['filter'] = filter;

    try {
      return firstValueFrom(this.http.get<any[]>(`${this.ADMIN_ALL_MASTERS_ROUTE}`, {
        headers,
        params,
      }).pipe(
        map(response => response.map(i => ({ id: i.id, v1MasterId: i.v1MasterId, name: i.name })))
      ));
    }
    catch {
      return firstValueFrom(of([]));
    }
  }

  public changeAdmin() {
    this.adminEmitter.next(true)
  }
}
