<div class="card">
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <mat-table
    cdkDropListGroup
    cdkDropList
    cdkDropListOrientation="horizontal"
    (cdkDropListDropped)="dropListDropped($event)"
    [dataSource]="deviceInfoList"
    matSort
    (matSortChange)="onSortChange($event)"
    matSortActive="updatedAt"
    matSortDirection="desc"
    >
    <ng-container *ngFor="let column of columns; let i = index" [matColumnDef]="column.field">
      <mat-header-cell
      [ngClass]="{
        'hide-element': column.field === 'guid' || ( !isAdminUser() && column.field === 'masterName'), 'test-info-column': isTestInfoColumn(column.field) }"
        *matHeaderCellDef
        [mat-sort-header]="column.field !== 'reports' ? '' : ''"
        cdkDrag
        (cdkDragStarted)="dragStarted($event, i)"
        [cdkDragData]="{ name: column.field, columnIndex: i }"
        cdkDragBoundary="#drop-area"
        class="drag-header"
        >
        <div fxLayout="column">
          <span class="column-header">{{ column.displayName }}</span>
          <input [ngClass]="{'test-info-search': isTestInfoColumn(column.field), 'column-search': true}"
           [formControl]="getColumnFilterFormControl(column.field)" type="search" (click)="handleInputClick($event)">
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let device" [ngClass]="{
        'hide-element': column.field === 'guid' || ( !isAdminUser() && column.field === 'masterName'), 'test-info-column': isTestInfoColumn(column.field)
        }">
        <ng-container *ngIf="(isObject(device[column.field]) || isStringArray(device[column.field])) && (!isTestInfoColumn(column.field)); else nativeView">
          <div class="json-container" *ngIf="isObject(device[column.field]) && column.displayName != 'GPU Info'" (click)="openJSONViewer(column.displayName, device[column.field])">
            {{ device[column.field] | formatArray: column.field }}
          </div>
          <div class="json-container" *ngIf="!isObject(device[column.field]) && isStringArray(device[column.field]) && column.displayName == 'Grading Q/A'" (click)="openArrayViewer( device.profileName, device[column.field], device.routeName)">
            {{ device[column.field] | formatArray: column.field }}
          </div>
          <div class="json-container" *ngIf="column.displayName == 'GPU Info'" (click)="openJSONViewer(column.displayName, device[column.field])">
           Click to View
          </div>
        </ng-container>
        <ng-template #nativeView>
          <div class="centered-content">
            <ng-container  *ngIf="column.field === 'reports'; else otherContent">

              <img src="./assets/er.svg" [ngClass]="{ 'report-img': true, 'disabled': findEraseStatusById(device[column.field]) === false }"
              (click)="findEraseStatusById(device[column.field]) !== false ? openErasureReport(device[column.field]) : null">
              <img src="./assets/dhr.svg" class="dhr-img"  (click)="openDHRreport(device[column.field])">
            </ng-container>
            <ng-template #otherContent>
              {{
                column.field === 'manualEntry' ? (device[column.field] === 1 ? 'Yes' : 'No') :
                (column.field === 'createdAt' || column.field === 'updatedAt') ?
                  (device[column.field] | date:'yyyy-MM-dd HH:mm:ss') :
                (column.field === 'platform') ?
                  (device[column.field] + ' Diagnostics') :
                (column.field === 'eraseStatus') ?
                  (device[column.field]?.toLowerCase() === 'pass' ? 'Yes' : 'No') :
                (isTestInfoColumn(column.field)) ?
                  formatTestNames(device[column.field]) :
                (device[column.field] === null || device[column.field] === undefined ? '' : device[column.field])
              }}
            </ng-template>
          </div>
        </ng-template>
      </mat-cell>
    </ng-container>
    <mat-header-row class="column-list" id="drop-area" *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let device; columns: displayedColumns"></mat-row>
    <tr class="mat-row empty-table" *matNoDataRow >
      <td class="mat-cell" *ngIf="!loading" [attr.colspan]="displayedColumns.length">
        No results matching the filter
      </td>
    </tr>
  </mat-table>
  <mat-paginator #paginator (page)="onPaginate($event)" [pageSize]="paginatorData.pageSize" [pageSizeOptions]="paginatorData.pageOptions" [length]="paginatorData.length" showFirstLastButtons></mat-paginator>
</div>

