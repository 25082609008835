import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '../services/notification.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { PasswordOptions } from '../interfaces/passwordProtect.interface';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-password-protect',
  templateUrl: './password-protect.component.html',
  styleUrls: ['./password-protect.component.css']
})
export class PasswordProtectComponent implements OnInit {
  @Input() protectionEnabled: boolean = false;
  @Input() passwordOptionsInput: Partial<PasswordOptions> = {
    newTransaction: false,
    editTransaction: false,
    updateVendorInfo: false,
  };
  @Output() saved: EventEmitter<boolean> = new EventEmitter();
  @Output() passwordEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() passwordOptionsEmitter: EventEmitter<Partial<PasswordOptions>> = new EventEmitter<Partial<PasswordOptions>>();
  password: string = '';
  confirmPassword: string = '';
  hidePassword: boolean = true;
  hideConfirmPassword: boolean = true;
  changingPassword = false;
  passwordOptionsGroup!: FormGroup;

  constructor(
    public dialog: MatDialog,
    private notify: NotificationService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.passwordOptionsGroup = this.fb.group(this.passwordOptionsInput);
    this.passwordOptionsGroup.valueChanges.subscribe(vc => {
      if (this.protectionEnabled) {
        this.passwordOptionsEmitter.next(vc);
      }
    });
  }

  public getData() {
    return this.protectionEnabled;
  }

  public async changePassword() {
    if (this.protectionEnabled) {
      this.changingPassword = true;
    }
  }

  public async savePassword() {
    if (this.password !== this.confirmPassword) {
      this.notify.error('Password and Confirm do not match!');
    } else {
      this.saved.next(this.protectionEnabled);
      this.passwordEmitter.emit(this.password);
      this.notify.success('Password saved');
      this.changingPassword = false;
    }
  }

  changedProtection(event: MatCheckboxChange) {
    this.saved.next(event.checked);
    if (this.protectionEnabled) {
      this.changePassword();
    }
  }

  cancel() {
    this.changingPassword = false;
    this.password = '';
    this.confirmPassword = '';
  }
}
