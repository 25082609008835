
<div class="bodyWrapper" *ngIf="deviceData">
    <div class="wrapper">
      <div class="inner">
      <div class="pageHeader">
        <div class="logo">
          <img src="assets/logo.jpg" alt="logo">
        </div>
        <div class="qrCode">
          <ul> 
            <li> 
              <div class="qrcodeImage qr-code-container" *ngIf="qrcodedata" style="float: right;">
                <img [src]="qrCodeImageUrl" alt="QR Code">  
            </div>
        
          </li>
          <li>
            <p>
              ID: {{deviceId}}
              <br>Date: {{deviceData.eraseEndTime | date:'shortDate'}}
             
            </p>
          </li>
          </ul>
        </div>
      </div>
      <div class="deviceInfo">
        <p class="bold-text">Device  Info</p>
        <h2>{{deviceData.modelName}}</h2>
        <p>
          Manufactured by: {{deviceData.make}}<br>
          Serial No : {{deviceData.serial}}<br>
          Model : {{deviceData.modelName}}<br>
          Regulatory Model Number: {{deviceData.regulatoryModelNo}} <br>
          IMEI: {{deviceData.imei}}<br>
          IMEI2: {{deviceData.imei2}}<br>
        </p>
      </div>
  
      <div class="ereaseDetails">
        <img  src="assets/smallLogo.png" alt="logo" /><span class="bold-text">Erasure Details</span>
        <div class="eraseTable">
           <ul>
            <li><span>Status</span><span>{{deviceData.eraseStatus}}</span></li>
            <li><span>Method</span><span>{{deviceData.os}} Erasure</span></li>
            <li><span>Type</span><span>{{deviceData.eraseInfo.type}}</span></li>
            <li><span>Sim</span><span>{{deviceData.simErased}}</span></li>
            <li><span>eSim</span><span>{{deviceData.esimErased}}</span></li>
           </ul> 
        </div>
      </div>
  
      <div class="partnerLogos">
        <img src="assets/partnerLogos.png" alt="logos" />
      </div>
  
      <div id="phoneinfo" class="phoneCheckInfo">
        <ul>
          <li>
            <div class="inline"><img  src="assets/smallLogo.png" alt="logo" /></div><div class="inline-margin"><span class="bold-text">Phonecheck</span><br><span class="small-text displayBlock">Device History Report</span></div>
          </li>
          <li>
             <p class="partner-text">Phonecheck is an independent mobile device certification company  <br>
                The industry standard in mobile device certification.<br>
                <a href="https://phonecheck.com">Visit Phonecheck.com for more information.</a> </p>
          </li>
        </ul>
      
      </div>
    </div>
  </div>
  </div>