<div *ngIf="isLoading" class="full-screen-loader">
    <img src="assets/loader.gif" alt="Loading" class="loader-image" />    
</div>
<div *ngIf="!isLoading">
    <div *ngIf="buildData" class="release-notes-container">
        <!-- Header Section -->
        <div class="header">
            <div class="action-container">
                <div class="action-container__left">
                    <h2>Version: {{ buildData?.buildNumber }}</h2>
                </div>
                <div class="action-container__right">
                    <img src="./../../../assets/Phonecheck_LogoHorizontal@2x.png" class="phonecheck_logo"
                        alt="Phonecheck Logo" />
                </div>
            </div>
        </div>
        <div class="version-info">
            <!-- Rounded Containers -->
            <div class="rounded-containers">
                <a *ngIf="buildData && buildData.buildUrl" [href]="buildData.buildUrl" download
                    class="release-notes-link">
                    <div class="rounded-container">
                        <img src="./../../assets/Mac Intel logo.png" alt="Mac Intel Logo">
                        <span>
                            Download Mac Build<br>
                            <small>{{buildData.buildNumber}}</small>
                        </span>
                    </div>
                </a>
                <a *ngIf="buildData && buildData.otaUrl" [href]="buildData.otaUrl" download class="release-notes-link">
                    <div class="rounded-container">
                        <img src="./../../assets/Mac Intel logo.png" alt="Mac Intel Logo">
                        <span>
                            Download OTA Build<br>
                            <small>{{buildData.buildNumber}}</small>
                        </span>
                    </div>
                </a>
            </div>
        </div>
        <br>
        <mat-divider></mat-divider>
        <!-- Release Notes Section -->
        <div class="release-notes-content">
            <h3>Release Notes</h3>
            <div class="section" [innerHTML]="buildData.releaseNotes"></div>
        </div>
    </div>
    <mat-card *ngIf="!buildData" class="error-card">
        <div class="section">
            <h3>No Release Notes Found</h3>
        </div>
    </mat-card>
</div>