<!-- profile list-->
<ng-container *ngIf="!embedMode && selectedIndex == 0">
  <div class="action-container">
    <div class="action-container__left">
      <button *isGranted="['Customization Profiles']" mat-raised-button color="primary" type="button"
        (click)="showProfileCreation()">
        Create Profile
      </button>
      <a href="" (click)="$event.preventDefault();selectedIndex = 1">Profile Assignments</a>
      <mat-checkbox class="filter-input" [formControl]="profilesNotInUseFormControl">
        <b>Hide profiles not in use</b>
      </mat-checkbox>
    </div>
    <div class="action-container__right">
      <mat-form-field class="filter-input">
        <mat-label>Search Profiles </mat-label>
        <input matInput [formControl]="searchProfileFormControl" class="search-input" type="search"
          autocomplete="one-time-code" role="presentation">
      </mat-form-field>
    </div>
  </div>

  <mat-progress-bar *ngIf="loadingProfiles" mode="indeterminate"></mat-progress-bar>
  <table [ngStyle]="{'display': loadingProfiles ? 'none' : 'table'}" mat-table [dataSource]="profilesDataSource" matSort
    matSortActive="createdAt" matSortDirection="desc" matSortDisableClear="true" style="padding: 0 2em;">

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Profile Name </th>
      <td mat-cell *matCellDef="let element; let i = index"> <b>{{element.name}}</b> </td>
    </ng-container>

    <!-- Created Column -->
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Created </th>
      <td mat-cell *matCellDef="let element"> {{element.createdAt | date: 'short' }} </td>
    </ng-container>

    <!-- Updated Column -->
    <ng-container matColumnDef="updatedAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Modified </th>
      <td mat-cell *matCellDef="let element"> {{element.updatedAt | date: 'short' }} </td>
    </ng-container>

    <!-- Stations Column -->
    <ng-container matColumnDef="stations">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Stations </th>
      <td mat-cell *matCellDef="let element">
        <span class="assigned-entities" (click)="openDialog('STATION', element)">
          <u><b>{{ element.stations }}</b></u>
        </span>
      </td>
    </ng-container>

    <!-- Warehouses Column -->
    <ng-container matColumnDef="warehouses">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Warehouses </th>
      <td mat-cell *matCellDef="let element">
        <span class="assigned-entities" (click)="openDialog('WAREHOUSE', element)">
          <u><b>{{ element.warehouses || 'Share'}}</b></u>
        </span>
      </td>
    </ng-container>


    <ng-container matColumnDef="buttons">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element; let i = index">
        <button mat-icon-button (click)="duplicateProfile(element)"
          matTooltip="Duplicate"><mat-icon>file_copy</mat-icon></button>
        <button *ngIf="!element.protectionEnabled" mat-icon-button (click)="openPasswordProtectProfile(element)"
          matTooltip="Password protect this profile"><mat-icon>lock_open</mat-icon></button>
        <button *ngIf="element.protectionEnabled" mat-icon-button (click)="unlockProfile(element)"
          matTooltip="Modify Password Protection"><mat-icon>lock</mat-icon></button>
        <button mat-icon-button (click)="editProfile(element)" matTooltip="Edit"><mat-icon>edit</mat-icon></button>
        <button mat-icon-button (click)="deleteProfile(element)"
          matTooltip="Delete"><mat-icon>delete</mat-icon></button>
        <ng-container *ngIf="element.protectionEnabled">
          <button *isGranted="[]; for: 'cloudAdmin'" mat-icon-button (click)="resetPassword(element)"
            matTooltip="Reset password"><mat-icon>lock_reset</mat-icon></button>
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</ng-container>

<!-- assignments -->
<ng-container label="Assign" *ngIf="!embedMode && selectedIndex == 1">
  <app-assignment [profileSubject]="profileObserver" (selectedIndexChange)="backToMainPage()"></app-assignment>
</ng-container>

<!-- create profile-->
<div class="create-tab" *ngIf="selectedIndex === 2" [ngStyle]="{'display': selectedIndex == 2 ? 'block' : 'none'}">
  <mat-sidenav-container class="customizations-container" autosize>
    <mat-sidenav mode="side" opened class="customization-sidenav">
      <mat-action-list>
        <div style="display:flex;font-size: 0.9em;">
          <button mat-list-item (click)="backToMainPage()" class="back-arrow-button">
            <mat-icon>arrow_back</mat-icon>
          </button>
          <div>
            <span>
              Customizations
            </span>
            <br>
            <span>
              <b>
                {{nameFormControl.value || 'New Profile'}}
              </b>
            </span>
          </div>
        </div>
        <button mat-list-item [style.padding-left]="'2em'" (click)="changePage(page.name, page.label)"
          [activated]="currentPage === page.name" *ngFor="let page of pages"> {{page.label}}
        </button>
      </mat-action-list>
    </mat-sidenav>
    <mat-sidenav-content class="customization-sidenav-content">
      <div class="action-container sidenav-content-title">
        <div class="action-container__left">
          <h2>
            {{currentPageLabel}}
          </h2>
        </div>
        <div class="action-container__right" *ngIf="creatingCustomTest">
          <button mat-raised-button color="primary" style="margin-right: 1em;" (click)="saveCustomTest()">
            Save Test
          </button>
          <button mat-raised-button (click)="resetCustomTest()">
            Cancel
          </button>
        </div>
        <div class="action-container__right" *ngIf="!creatingCustomTest">
          <button color="primary" mat-raised-button class="add-profile-btn"
            [disabled]="disabledProfileCreation(nameFormControl.value)" (click)="saveCustomization()">
            Save Changes
          </button>
          <button mat-raised-button class="add-profile-btn" (click)="cancelCustomization()">
            Cancel Changes
          </button>
        </div>
      </div>

      <div *ngIf="currentPage == 'general'">
        <div class="card">
          <div class="profile-name-container">
            <mat-form-field class="create-full-width" appearance="outline">
              <mat-label>Profile Name</mat-label>
              <input matInput #nameInput placeholder="Enter Profile Name" value="" [formControl]="nameFormControl"
                autocomplete="one-time-code">
            </mat-form-field>
          </div>
          <app-password-protect [protectionEnabled]="protectionEnabled" (passwordEmitter)="updatePassword($event)"
            (saved)="setProtection($event)" (passwordOptionsEmitter)="updatePasswordOptions($event)"
            [passwordOptionsInput]="passwordOptions">
          </app-password-protect>
        </div>
      </div>
      <div *ngIf="currentPage === 'test_plan'">
        <div class="card">
          <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
            <mat-tab label="iOS & Android">
              <app-test-plan #deviceTestPlan [deviceType]="'device'" [selectedDeviceTests]="selectedDeviceTests"
                [skipUncheckingDefaultTestDialog]="skipUncheckingDefaultTestDialog"
                (onSkipDefaultTestDialog)="setSkipUncheckingDefaultTestDialog($event)"
                (updateDeviceEmitter)="setSelectedDeviceTests($event)"></app-test-plan>
            </mat-tab>
            <mat-tab label="Watch">
              <app-test-plan #watchTestPlan [deviceType]="'watch'" [selectedDeviceTests]="selectedWatchTests"
                [skipUncheckingDefaultTestDialog]="skipUncheckingDefaultTestDialog"
                (onSkipDefaultTestDialog)="setSkipUncheckingDefaultTestDialog($event)"
                (updateWatchEmitter)="setSelectedWatchTests($event)"></app-test-plan>
            </mat-tab>
            <mat-tab label="Airpods">
              <app-test-plan
                #airpodsTestPlan
                [deviceType]="'airpods'"
                [selectedDeviceTests]="selectedAirpodsTests"
                [skipUncheckingDefaultTestDialog]="skipUncheckingDefaultTestDialog"
                (onSkipDefaultTestDialog)="setSkipUncheckingDefaultTestDialog($event)"
                (updateAirpodsEmitter)="setSelectedAirpodsTests($event)"></app-test-plan>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
      <div *ngIf="currentPage === 'custom_test'">
        <div class="card">
          <app-custom-test [customTests]="customTest" (creatingCustomTestEmitter)="creatingCustomTest = $event"
            (customTestEmitter)="setCustomTests($event)"></app-custom-test>
        </div>
      </div>
      <div *ngIf="currentPage === 'esn_imei_health'">
        <div class="card">
          <app-imei-check [state]="imeiCheckState" (changeEmitter)="setIMEICheckState($event)"></app-imei-check>
        </div>
      </div>
      <div [hidden]="currentPage !== 'fields'">
        <div class="sidenav-content-title" *ngIf="currentFieldsView !== 'main'">
          <button mat-icon-button (click)="currentFieldsView = 'main'">
            <mat-icon>arrow_back</mat-icon>
          </button>
        </div>
        <div class="card" *ngIf="currentFieldsView === 'main'">
          <div class="fields-grade-container">
            <b>Grade</b>
            <div class="flex-spacer"></div>
            <button mat-raised-button (click)="currentFieldsView = 'grading'">View / Edit Grading Options</button>
            <div class="flex-spacer"></div>
            <div class="dummy-checkbox"></div>
          </div>
          <div>
            <mat-divider></mat-divider>
          </div>
          <div class="fields-grade-container">
            <b>Carrier</b>
            <div class="flex-spacer"></div>
            <button mat-raised-button (click)="currentFieldsView = 'carrier'">View / Edit Carrier Options</button>
            <div class="flex-spacer"></div>
            <div class="dummy-checkbox"></div>
          </div>
          <div>
            <mat-divider></mat-divider>
          </div>
          <div class="fields-grade-container" *ngIf="currentFieldsView === 'main'">
            <b>Color</b>
            <div class="flex-spacer"></div>
            <button mat-raised-button (click)="currentFieldsView = 'color'">View / Edit Color Options</button>
            <div class="flex-spacer"></div>
            <div class="dummy-checkbox"></div>
          </div>
          <div>
            <mat-divider></mat-divider>
          </div>
          <div class="required-fields-container">
            <div class="card-section-header">
              <mat-checkbox [(ngModel)]="requiredFields.enabled" (ngModelChange)="changeEnableFields($event)">
                <span class="card-section-title"> Enable Required Fields</span>
              </mat-checkbox>
            </div>
            <div class="flex-spacer" *ngIf="requiredFields.enabled"></div>
            <div class="checkbox-list" *ngIf="requiredFields.enabled">
              <ul>
                <li><mat-checkbox [(ngModel)]="requiredFields.LPN">LPN</mat-checkbox></li>
                <li><mat-checkbox [(ngModel)]="requiredFields.custom">Custom 1</mat-checkbox></li>
                <li><mat-checkbox [(ngModel)]="requiredFields.color">Color</mat-checkbox></li>
                <li><mat-checkbox [(ngModel)]="requiredFields.carrier">Carrier</mat-checkbox></li>
                <li><mat-checkbox [(ngModel)]="requiredFields.grade">Grade</mat-checkbox></li>
                <li><mat-checkbox [(ngModel)]="requiredFields.ESN">ESN</mat-checkbox></li>
                <li><mat-checkbox [(ngModel)]="requiredFields.unlockStatus">Unlock Status</mat-checkbox></li>
                <li><mat-checkbox [(ngModel)]="requiredFields.cosmetics">Cosmetics</mat-checkbox></li>
              </ul>
            </div>
            <div class="flex-spacer" *ngIf="requiredFields.enabled"></div>
          </div>
          <div>
            <mat-divider></mat-divider>
          </div>
          <div class="restrictive-actions-container" *ngIf="requiredFields.enabled">
            <div class="card-section-header">
              <div class="card-section-title">Restrictive Actions</div>
              <div class="card-section-subtitle">These action will be prevented if the above fields aren’t met</div>
            </div>
            <div class="flex-spacer"></div>
            <div class="checkbox-list">
              <ul>
                <li><mat-checkbox [(ngModel)]="restrictiveActions.erase">Erase</mat-checkbox></li>
                <li><mat-checkbox [(ngModel)]="restrictiveActions.print">Print</mat-checkbox></li>
              </ul>
            </div>
            <div class="flex-spacer"></div>
            <!--            <div class="dummy-checkbox"></div>-->
          </div>
          <div *ngIf="requiredFields.enabled">
            <mat-divider></mat-divider>
          </div>
          <div>
            <mat-checkbox [(ngModel)]="fields.allowUserModifyColorField"> <span class="card-section-title">Allow user to
                modify the Color field</span> </mat-checkbox>
          </div>
        </div>
        <div class="grading-card" *ngIf="currentFieldsView === 'grading'">
          <div class="grading-section-title">Edit Grading options</div>
          <div class="flex-spacer"></div>
          <div class="grading-options" cdkDropList (cdkDropListDropped)="dropGrade($event)">
            <div class="grading-option" *ngFor="let element of gradingOptions; let i = index" cdkDrag>
              <div class="grading-option-icon" matTooltip="Drag to reorder">
                <mat-icon cdkDragHandle class="drag">drag_handle</mat-icon>
              </div>
              <div class="grading-option-title">
                <mat-form-field *ngIf="element.renaming" class="grading-name-form" appearance="outline">
                  <input matInput [formControl]="gradeRename" (blur)="blurGrade(element)" [(ngModel)]="element.name"
                    [cdkTrapFocusAutoCapture]="gradeInputFocus" [cdkTrapFocus]="gradeInputFocus"
                    (submit)="blurGrade(element)" [maxlength]="36">
                </mat-form-field>
                <div *ngIf="!element.renaming" matTooltip="Rename" class="grade-name" (click)="renameGrade(element)">
                  {{element.name | slice: 0 : 12}}<span *ngIf="element.name.length >= 12">...</span>
                </div>
              </div>
              <div class="flex-spacer"></div>
              <div class="grading-button" *ngIf="defaultGradingOption !== element.name">
                <button mat-button class="grading-default-set-button" (click)="defaultGradingOption = element.name">Set
                  as Default</button>
              </div>
              <div class="grading-button" *ngIf="defaultGradingOption === element.name">
                <div class="grading-default-button">
                  Default Option
                  <button mat-icon-button (click)="clearDefaultGrade()">
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
              </div>
              <div class="grading-button"><button mat-button (click)="renameGrade(element)">Rename</button></div>
              <div class="grading-button"><button mat-button (click)="deleteGrade(i)">Delete</button></div>
            </div>
            <div class="grading-buttons-container">
              <div class="grading-button-bottom">
                <button mat-raised-button (click)="addGrade()">Add Option</button>
              </div>
              <div *ngIf="gradeInputEmptyError" class="grade-input-empty-error">
                <div class="grade-input-empty-error-icon">
                  <mat-icon>error_outline</mat-icon>
                </div>
                <div class="grade-input-empty-error-text">
                  Please enter a name for all Grades
                </div>
              </div>
            </div>
          </div>
          <div class="flex-spacer"></div>
        </div>
        <div class="grading-card" *ngIf="currentFieldsView === 'carrier'">
          <div class="grading-section-title">Edit Carrier options</div>
          <div class="flex-spacer"></div>
          <div class="grading-options" cdkDropList (cdkDropListDropped)="dropCarrier($event)">
            <div class="grading-option" *ngFor="let element of carrierOptions; let i = index" cdkDrag>
              <div class="grading-option-icon" matTooltip="Drag to reorder">
                <mat-icon cdkDragHandle class="drag">drag_handle</mat-icon>
              </div>
              <div class="grading-option-title">
                <mat-form-field *ngIf="element.renaming" class="grading-name-form" appearance="outline">
                  <input matInput [formControl]="carrierRename" (blur)="blurCarrier(element)" [(ngModel)]="element.name"
                    [cdkTrapFocusAutoCapture]="carrierInputFocus" [cdkTrapFocus]="carrierInputFocus"
                    (submit)="blurCarrier(element)">
                </mat-form-field>
                <div *ngIf="!element.renaming" matTooltip="Rename" class="grade-name" (click)="renameCarrier(element)">
                  {{element.name}}
                </div>
              </div>
              <div class="flex-spacer"></div>
              <div class="grading-button" *ngIf="defaultCarrierOption !== element.name">
                <button mat-button class="grading-default-set-button" (click)="defaultCarrierOption = element.name">Set
                  as Default</button>
              </div>
              <div class="grading-button" *ngIf="defaultCarrierOption === element.name">
                <div class="grading-default-button">
                  Default Option
                  <button mat-icon-button (click)="clearDefaultCarrier()">
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
              </div>
              <div class="grading-button"><button mat-button (click)="renameCarrier(element)">Rename</button></div>
              <div class="grading-button"><button mat-button (click)="deleteCarrier(i)">Delete</button></div>
            </div>
            <div class="grading-buttons-container">
              <div class="grading-button-bottom">
                <button mat-raised-button (click)="addCarrier()">Add Option</button>
              </div>
              <div *ngIf="gradeInputEmptyError" class="grade-input-empty-error">
                <div class="grade-input-empty-error-icon">
                  <mat-icon>error_outline</mat-icon>
                </div>
                <div class="grade-input-empty-error-text">
                  Please enter a name for all Carriers
                </div>
              </div>
            </div>
          </div>
          <div class="flex-spacer"></div>
        </div>
        <div class="grading-card" *ngIf="currentFieldsView === 'color'">
          <div class="grading-section-title">Edit Color options</div>
          <div class="flex-spacer"></div>
          <div class="grading-options" cdkDropList (cdkDropListDropped)="dropColor($event)">
            <div class="grading-option" *ngFor="let element of colorOptions; let i = index" cdkDrag>
              <div class="grading-option-icon" matTooltip="Drag to reorder">
                <mat-icon cdkDragHandle class="drag">drag_handle</mat-icon>
              </div>
              <div class="grading-option-title">
                <mat-form-field *ngIf="element.renaming" class="grading-name-form" appearance="outline">
                  <input matInput [formControl]="colorRename" (blur)="blurColor(element)" [(ngModel)]="element.name"
                    [cdkTrapFocusAutoCapture]="colorInputFocus" [cdkTrapFocus]="colorInputFocus"
                    (submit)="blurColor(element)">
                </mat-form-field>
                <div *ngIf="!element.renaming" matTooltip="Rename" class="grade-name" (click)="renameColor(element)">
                  {{element.name}}
                </div>
              </div>
              <div class="flex-spacer"></div>
              <div class="grading-button"><button mat-button (click)="renameColor(element)">Rename</button></div>
              <div class="grading-button"><button mat-button (click)="deleteColor(i)">Delete</button></div>
            </div>
            <div class="grading-buttons-container">
              <div class="grading-button-bottom">
                <button mat-raised-button (click)="addColor()">Add Option</button>
              </div>
            </div>
          </div>
          <div class="flex-spacer"></div>
        </div>
      </div>
      <div [hidden]="currentPage !== 'labels'">
        <div *ngIf="previewImage">
          <img [src]="previewImage" class="preview-image">
        </div>
        <div class="card">
          <div class="labels-button-bar">
            <mat-form-field class="create-full-width" appearance="outline">
              <mat-label>Filter</mat-label>
              <input (keyup)="applyFilter($event)" matInput value="" type="text"
                [formControl]="labelFilterFormControl" />
              <button *ngIf="labelFilterFormControl.value" matSuffix mat-icon-button aria-label="Clear"
                (click)="clearFilter()">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <div class="flex-spacer"></div>
            <button mat-icon-button (click)="getLabels()"><mat-icon>refresh</mat-icon></button>
            <button mat-fab extended (click)="createLabel()" color="primary"><mat-icon>add_circle</mat-icon>New
              Label</button>
          </div>
          <table #labelsTable mat-table [dataSource]="labelsDataSource" matSort matSortActive="updated"
            matSortDirection="desc" matSortDisableClear="true">
            <!-- Enabled Column -->
            <ng-container matColumnDef="enabled" class="enabled-column">
              <th mat-header-cell *matHeaderCellDef class="enabled-column">Enabled</th>
              <td mat-cell *matCellDef="let element">
                <mat-checkbox color="accent" [checked]="element.enabled"
                  (change)="element.enabled = $event.checked"></mat-checkbox>
              </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <!-- Canvas Column -->
            <ng-container matColumnDef="canvas_size">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Canvas Size </th>
              <td mat-cell *matCellDef="let element"> {{element.canvas_size}} </td>
            </ng-container>

            <!-- Modified Column -->
            <ng-container matColumnDef="updated_at">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Updated</th>
              <td mat-cell *matCellDef="let element">{{element.updated_at | date: 'short'}}</td>
            </ng-container>

            <ng-container matColumnDef="buttons">
              <th mat-header-cell *matHeaderCellDef>Actions</th>
              <td mat-cell *matCellDef="let element">
                <button mat-icon-button (click)="openPreviewDialog(element.image)" [matTooltip]="'Preview'">
                  <mat-icon>preview</mat-icon>
                </button>
                <button mat-icon-button (click)="editLabel(element)" [matTooltip]="'edit'">
                  <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button [matTooltip]="'Delete'"
                  (click)="deleteLabel(element)"><mat-icon>delete</mat-icon></button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="labelsColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: labelsColumns;"></tr>
          </table>
        </div>
      </div>
      <div [hidden]="currentPage !== 'workflow'">
        <div class="card">
          <div class="card-section-body" *ngIf="workflow.enabled">
            <!-- INIT -->
            <div class="fields-erase-container">
              <div class="card-section-header-full">
                <div class="card-section-title">
                  <mat-checkbox [(ngModel)]="workflow.warningMessagesEnabled" (ngModelChange)="updateCardWarnings()">
                    <div class="checkbox-header">Enable Warning Messages</div>
                  </mat-checkbox><br>
                  <div class="checkbox-width">
                    These are actions that take place when the device is
                    connected.
                  </div>
                </div>
                <div *ngIf="workflow.warningMessagesEnabled" style="padding-left: 2em;">
                  <mat-checkbox [(ngModel)]="workflow.warningMessages.simCardIsDetected" (change)="resetFailSimCard()">
                    <div class="checkbox-header">SIM Card is detected</div>
                  </mat-checkbox><br>
                  <div *ngIf="workflow.warningMessages.simCardIsDetected" style="padding-left: 2em;">
                    <mat-checkbox [(ngModel)]="workflow.warningMessages.failSimCardNotRemoved">
                      <div class="checkbox-header">Fail if SIM Card is not removed</div>
                    </mat-checkbox><br>
                  </div>
                  <mat-checkbox [(ngModel)]="workflow.warningMessages.sdCardIsDetected" (change)="resetFailSDCard()">
                    <div class="checkbox-header">SD Card is detected</div>
                  </mat-checkbox><br>
                  <div *ngIf="workflow.warningMessages.sdCardIsDetected" style="padding-left: 2em;">
                    <mat-checkbox [(ngModel)]="workflow.warningMessages.failSDCardNotRemoved">
                      <div class="checkbox-header">Fail if SD Card is not removed</div>
                    </mat-checkbox><br>
                  </div>
                </div>
              </div>
              <div class="flex-spacer"></div>
              <div class="right-section">
              </div>
            </div>

            <mat-divider></mat-divider>
            <div>
              <div>
                <div class="card-section-title">
                  <mat-checkbox [(ngModel)]="workflow.skipAllSetupAssistant" (ngModelChange)="updateSkipAssistant()">
                    <div class="checkbox-header">Skip all setup assistant steps (iOS)</div>
                  </mat-checkbox><br>
                  <div class="checkbox-width">
                    Choose which steps will be skipped for the user in the Setup Assistant.
                  </div>
                </div>
                <div *ngIf="!workflow.skipAllSetupAssistant"
                  style="padding-left: 2em; column-count: 2; column-gap: 5em; width: 50vw">
                  <div *ngFor="let element of skipAssistantSteps">
                    <mat-checkbox [(ngModel)]="workflow.skipSetupAssistant[element.key]">
                      <div>{{ element.text }}</div>
                    </mat-checkbox><br>
                  </div>
                </div>
              </div>
              <div class="flex-spacer"></div>
              <div class="right-section">
              </div>
            </div>
            <!-- END -->
            <mat-divider></mat-divider>
            <div class="fields-erase-container">
              <div class="card-section-header-full">
                <div class="card-section-title">
                  <mat-checkbox [(ngModel)]="workflow.connectionWorkflowEnabled">
                    <div class="checkbox-header">Connection Workflow</div>
                  </mat-checkbox><br>
                  <div class="checkbox-width">These are actions that take place when the device is connected</div>
                </div>
              </div>
              <div class="flex-spacer"></div>
              <div class="right-section">
                <div *ngFor="let element of connectionWorkflowBuilder.selections; let i = index">
                  <div class="row">
                    <div class="workflow-item">
                      <mat-form-field appearance="outline">
                        <mat-select [(ngModel)]="connectionWorkflowBuilder.selections[i]"
                          (ngModelChange)="connectionWorkflowBuilder.onSelected(i)" (opened)="true">
                          <mat-option *ngFor="let option of connectionWorkflowBuilder.getOptions(i)"
                            value="{{ option.value }}">{{ option.displayValue }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <button mat-icon-button (click)="connectionWorkflowBuilder.removeOption(i)">
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </div>
                  <div
                    *ngIf="!connectionWorkflowBuilder.isLastOption(i) || connectionWorkflowBuilder.showAddSelection(i)"
                    class="line-box-full">
                    <div class="line"></div>
                    <i class="arrow down"></i>
                  </div>
                  <div class="button-field-width"
                    *ngIf="connectionWorkflowBuilder.showAddSelection(i) && connectionWorkflowBuilder.selections.length">
                    <mat-icon class="add-button" (click)="connectionWorkflowBuilder.addAction()"
                      fontSet="material-icons-outlined">
                      add_circle
                    </mat-icon>
                  </div>

                </div>
                <div *ngIf="!connectionWorkflowBuilder.selections.length" class="add-action-box"
                  [class.mat-elevation-z0]="!mouseOverAddActionBox" [class.mat-elevation-z4]="mouseOverAddActionBox"
                  (mouseenter)="mouseOverAddActionBox = true" (mouseleave)="mouseOverAddActionBox = false"
                  (click)="connectionWorkflowBuilder.addAction()">
                  <mat-icon class="add-button" fontSet="material-icons-outlined">
                    add_circle
                  </mat-icon>
                  Add Action
                </div>
              </div>
              <div class="flex-spacer"></div>
            </div>
            <mat-divider></mat-divider>
            <div class="fields-erase-container" [ngClass]="{'disabled-text': dataVerificationEnabled}">
              <div class="card-section-header-full">
                <div class="card-section-title">
                  <mat-checkbox [disabled]="dataVerificationEnabled"
                    [(ngModel)]="workflow.initialDefectsWorkflowEnabled" (click)="initialDefectsWorkflowResetOptions()">
                    <div class="checkbox-header">Initial Defects Workflow</div>
                  </mat-checkbox><br>
                  <div class="checkbox-width" [ngClass]="{'disabled-text': dataVerificationEnabled}">These are the
                    actions that take place when any of the following tests fail
                    on initial connection</div>
                </div>
                <div style="padding-left: 2em;">
                  <mat-checkbox [disabled]="!workflow.initialDefectsWorkflowEnabled || dataVerificationEnabled"
                    [(ngModel)]="workflow.initialDefectsWorkflow.IMEISerialValidation">
                    <div class="checkbox-header">IMEI/Serial Validation</div>
                  </mat-checkbox><br>
                  <mat-checkbox [disabled]="!workflow.initialDefectsWorkflowEnabled || dataVerificationEnabled"
                    [(ngModel)]="workflow.initialDefectsWorkflow.noIMEI">
                    <div class="checkbox-header">No IMEI</div>
                  </mat-checkbox><br>
                  <mat-checkbox [disabled]="!workflow.initialDefectsWorkflowEnabled || dataVerificationEnabled"
                    [(ngModel)]="workflow.initialDefectsWorkflow.noEID">
                    <div class="checkbox-header">No EID</div>
                  </mat-checkbox><br>
                  <mat-checkbox [disabled]="!workflow.initialDefectsWorkflowEnabled || dataVerificationEnabled"
                    [(ngModel)]="workflow.initialDefectsWorkflow.appleIcloudGoogleLock">
                    <div class="checkbox-header">Apple iCloud/Google Lock</div>
                  </mat-checkbox><br>
                  <mat-checkbox [disabled]="!workflow.initialDefectsWorkflowEnabled || dataVerificationEnabled"
                    [(ngModel)]="workflow.initialDefectsWorkflow.mdm">
                    <div class="checkbox-header">MDM</div>
                  </mat-checkbox><br>
                  <mat-checkbox [disabled]="!workflow.initialDefectsWorkflowEnabled || dataVerificationEnabled"
                    [(ngModel)]="workflow.initialDefectsWorkflow.samsungKnox">
                    <div class="checkbox-header">Samsung KNOX</div>
                  </mat-checkbox><br>
                  <mat-checkbox [disabled]="!workflow.initialDefectsWorkflowEnabled || dataVerificationEnabled"
                    [(ngModel)]="workflow.initialDefectsWorkflow.batteryHealth">
                    <div class="checkbox-header">Battery Health</div>
                  </mat-checkbox><br>
                  <mat-checkbox [disabled]="!workflow.initialDefectsWorkflowEnabled || dataVerificationEnabled"
                    [(ngModel)]="workflow.initialDefectsWorkflow.betaOS">
                    <div class="checkbox-header">Beta OS</div>
                  </mat-checkbox><br>
                  <mat-checkbox [disabled]="!workflow.initialDefectsWorkflowEnabled || dataVerificationEnabled"
                    [(ngModel)]="workflow.initialDefectsWorkflow.badESN">
                    <div class="checkbox-header">Bad ESN</div>
                  </mat-checkbox><br>
                </div>
              </div>
              <div class="flex-spacer"></div>
              <div class="right-section" *ngIf="workflow.initialDefectsWorkflowEnabled">
                <div class="row">
                  <div class="thirtysix-width" *ngIf="initialDefectsWorkflowBuilder.selections.length">
                  </div>
                </div>
                <div *ngFor="let element of initialDefectsWorkflowBuilder.selections; let i = index">
                  <div class="row">
                    <div class="workflow-item">
                      <mat-form-field appearance="outline">
                        <mat-select [(ngModel)]="initialDefectsWorkflowBuilder.selections[i]"
                          (ngModelChange)="initialDefectsWorkflowBuilder.onSelected(i)" appearance="outline">
                          <mat-option *ngFor="let option of initialDefectsWorkflowBuilder.getOptions(i)"
                            value="{{ option.value }}">{{ option.displayValue }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <button mat-icon-button (click)="initialDefectsWorkflowBuilder.removeOption(i)">
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </div>
                  <div
                    *ngIf="!initialDefectsWorkflowBuilder.isLastOption(i) || initialDefectsWorkflowBuilder.showAddSelection(i)"
                    class="line-box-full">
                    <div class="line"></div>
                    <i class="arrow down"></i>
                  </div>
                  <div class="button-field-width"
                    *ngIf="initialDefectsWorkflowBuilder.showAddSelection(i) && initialDefectsWorkflowBuilder.selections.length">
                    <mat-icon class="add-button" (click)="initialDefectsWorkflowBuilder.addAction()"
                      fontSet="material-icons-outlined">
                      add_circle
                    </mat-icon>
                  </div>
                </div>
                <div class="row">
                  <div class="thirtysix-width" *ngIf="initialDefectsWorkflowBuilder.selections.length">
                  </div>
                  <div *ngIf="!initialDefectsWorkflowBuilder.selections.length" class="add-action-box"
                    [class.mat-elevation-z2]="!mouseOverTestAddActionBox"
                    [class.mat-elevation-z6]="mouseOverTestAddActionBox" (mouseenter)="mouseOverTestAddActionBox = true"
                    (mouseleave)="mouseOverTestAddActionBox = false"
                    (click)="initialDefectsWorkflowBuilder.addAction()">
                    <mat-icon class="add-button" fontSet="material-icons-outlined">
                      add_circle
                    </mat-icon>
                    Add Action
                  </div>
                </div>
                <div class="row margin-top-24" *ngIf="workflow.separateFlow">
                  <div class="thirtysix-width" *ngIf="initialDefectsWorkflowBuilder.selections.length">
                  </div>
                </div>
              </div>
              <div class="flex-spacer"></div>
            </div>
            <mat-divider></mat-divider>
            <div class="fields-erase-container" [ngClass]="{'disabled-text': dataVerificationEnabled}">
              <div class="card-section-header-full">
                <div class="card-section-title">
                  <mat-checkbox [disabled]="dataVerificationEnabled" [(ngModel)]="workflow.testResultWorkflowEnabled">
                    <div class="checkbox-header">Test Result Workflow</div>
                  </mat-checkbox><br>
                  <div class="checkbox-width" [ngClass]="{'disabled-text': dataVerificationEnabled}">These are actions
                    that take place when the device finishes diagnostic
                    tests</div>
                  <div class="checkbox-width">
                    <mat-radio-group [(ngModel)]="workflow.separateFlow"
                      [disabled]="!workflow.testResultWorkflowEnabled || dataVerificationEnabled">
                      <mat-radio-button [value]="false">One flow for all results</mat-radio-button>
                      <mat-radio-button [value]="true">Separate Flow for working / failed</mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
              </div>
              <div class="flex-spacer"></div>
              <div class="right-section" *ngIf="workflow.testResultWorkflowEnabled">
                <div class="row">
                  <div class="thirtysix-width"
                    *ngIf="testResultWorkflowBuilder.selections.length || failedTestResultWorkflowBuilder.selections.length">
                  </div>
                  <div>
                    <div class="row">
                      <div *ngIf="workflow.separateFlow" class="separate-box">
                        Fully Working
                      </div>
                    </div>
                    <div class="line-box" *ngIf="workflow.separateFlow">
                      <div class="line"></div>
                      <i class="arrow down"></i>
                    </div>
                  </div>
                </div>
                <div *ngFor="let element of testResultWorkflowBuilder.selections; let i = index">
                  <div class="row">
                    <div class="workflow-item">
                      <mat-form-field appearance="outline">
                        <mat-select [(ngModel)]="testResultWorkflowBuilder.selections[i]"
                          (ngModelChange)="testResultWorkflowBuilder.onSelected(i)" appearance="outline">
                          <mat-option *ngFor="let option of testResultWorkflowBuilder.getOptions(i)"
                            value="{{ option.value }}">{{ option.displayValue }}</mat-option>
                        </mat-select>
                      </mat-form-field>

                    </div>
                    <button mat-icon-button (click)="testResultWorkflowBuilder.removeOption(i)">
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </div>
                  <div
                    *ngIf="!testResultWorkflowBuilder.isLastOption(i) || testResultWorkflowBuilder.showAddSelection(i)"
                    class="line-box-full">
                    <div class="line"></div>
                    <i class="arrow down"></i>
                  </div>
                  <div class="button-field-width"
                    *ngIf="testResultWorkflowBuilder.showAddSelection(i) && testResultWorkflowBuilder.selections.length">
                    <mat-icon class="add-button" (click)="testResultWorkflowBuilder.addAction()"
                      fontSet="material-icons-outlined">
                      add_circle
                    </mat-icon>
                  </div>
                </div>
                <div class="row">
                  <div class="thirtysix-width"
                    *ngIf="testResultWorkflowBuilder.selections.length || failedTestResultWorkflowBuilder.selections.length">
                  </div>
                  <div *ngIf="!testResultWorkflowBuilder.selections.length" class="add-action-box"
                    [class.mat-elevation-z2]="!mouseOverTestAddActionBox"
                    [class.mat-elevation-z6]="mouseOverTestAddActionBox" (mouseenter)="mouseOverTestAddActionBox = true"
                    (mouseleave)="mouseOverTestAddActionBox = false" (click)="testResultWorkflowBuilder.addAction()">
                    <mat-icon class="add-button" fontSet="material-icons-outlined">
                      add_circle
                    </mat-icon>
                    Add Action
                  </div>
                </div>
                <div class="row margin-top-24" *ngIf="workflow.separateFlow">
                  <div class="thirtysix-width"
                    *ngIf="testResultWorkflowBuilder.selections.length || failedTestResultWorkflowBuilder.selections.length">
                  </div>
                  <div>
                    <div class="row">
                      <div class="separate-box">
                        Failed
                      </div>
                    </div>
                    <div class="line-box" *ngIf="workflow.separateFlow">
                      <div class="line"></div>
                      <i class="arrow down"></i>
                    </div>
                  </div>
                </div>
                <div *ngIf="workflow.separateFlow">
                  <div *ngFor="let element of failedTestResultWorkflowBuilder.selections; let i = index">
                    <div class="row">
                      <div class="workflow-item">
                        <mat-form-field appearance="outline">
                          <mat-select [(ngModel)]="failedTestResultWorkflowBuilder.selections[i]"
                            (ngModelChange)="failedTestResultWorkflowBuilder.onSelected(i)">
                            <mat-option *ngFor="let option of failedTestResultWorkflowBuilder.getOptions(i)"
                              value="{{ option.value }}">{{ option.displayValue }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <button mat-icon-button (click)="failedTestResultWorkflowBuilder.removeOption(i)">
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </div>
                    <div
                      *ngIf="!failedTestResultWorkflowBuilder.isLastOption(i) || failedTestResultWorkflowBuilder.showAddSelection(i)"
                      class="line-box-full">
                      <div class="line"></div>
                      <i class="arrow down"></i>
                    </div>
                    <div class="button-field-width"
                      *ngIf="failedTestResultWorkflowBuilder.showAddSelection(i) && failedTestResultWorkflowBuilder.selections.length">
                      <mat-icon class="add-button" (click)="failedTestResultWorkflowBuilder.addAction()"
                        fontSet="material-icons-outlined">
                        add_circle
                      </mat-icon>
                    </div>
                  </div>
                  <div class="row">
                    <div class="thirtysix-width"
                      *ngIf="workflow.testResultWorkflow.length || workflow.failedWorkFlow.length"></div>
                    <div *ngIf="!failedTestResultWorkflowBuilder.selections.length" class="add-action-box"
                      [class.mat-elevation-z2]="!mouseOverFailedTestAddActionBox"
                      [class.mat-elevation-z6]="mouseOverFailedTestAddActionBox"
                      (mouseenter)="mouseOverFailedTestAddActionBox = true"
                      (mouseleave)="mouseOverFailedTestAddActionBox = false"
                      (click)="failedTestResultWorkflowBuilder.addAction()">
                      <mat-icon class="add-button" fontSet="material-icons-outlined">
                        add_circle
                      </mat-icon>
                      Add Action
                    </div>
                  </div>
                  <div class="button-width" *ngIf="testResultWorkflowBuilder.selections.length === 0"></div>
                </div>
              </div>
              <div class="flex-spacer"></div>
            </div>
            <mat-divider></mat-divider>
            <div fxLayout="row">
              <div id="custom-trigger-workflow-header-container" class="card-section-header-full"
                [ngClass]="{'disabled-text': dataVerificationEnabled }">
                <div class="card-section-title">
                  <mat-checkbox [disabled]="dataVerificationEnabled"
                    [(ngModel)]="workflow.customTriggerWorkflowEnabled">
                    <div class="checkbox-header">Custom Trigger Workflow</div>
                  </mat-checkbox><br>
                  <div class="checkbox-width" [ngClass]="{'disabled-text': dataVerificationEnabled }">"These are the
                    actions that can take place when custom trigger occurs
                  </div>
                </div>
              </div>
              <div class="flex-spacer"></div>
              <!-- INIT manual erase -->
              <div id="custom-trigger-workflow-settings-container" fxLayout="column">
                <div class="flex-spacer"></div>
                <div class="right-section-custom-trigger" *ngIf="workflow.customTriggerWorkflowEnabled">
                  <div class="row aligned-workflow-item">
                    <div>
                      <div class="row">
                        <div class="separate-box">
                          Manual Erase
                        </div>
                      </div>
                      <div class="line-box">
                        <div class="line"></div>
                        <i class="arrow down"></i>
                      </div>
                    </div>
                  </div>
                  <div *ngFor="let element of manualErase.selections; let i = index">
                    <div class="row">
                      <div class="workflow-item">
                        <mat-form-field appearance="outline">
                          <mat-select [(ngModel)]="manualErase.selections[i]"
                            (ngModelChange)="manualErase.onSelected(i)" appearance="outline">
                            <mat-option *ngFor="let option of manualErase.getOptions(i)" value="{{ option.value }}">{{
                              option.displayValue }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <button mat-icon-button (click)="manualErase.removeOption(i)">
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </div>
                    <div *ngIf="!manualErase.isLastOption(i) || manualErase.showAddSelection(i)" class="line-box-full">
                      <div class="line"></div>
                      <i class="arrow down"></i>
                    </div>
                    <div class="button-field-width"
                      *ngIf="manualErase.showAddSelection(i) && manualErase.selections.length">
                      <mat-icon class="add-button" (click)="manualErase.addAction()" fontSet="material-icons-outlined">
                        add_circle
                      </mat-icon>
                    </div>
                  </div>
                  <div class="row aligned-workflow-item">
                    <div class="add-action-box" [class.mat-elevation-z2]="!mouseOverTestAddActionBox"
                      [class.mat-elevation-z6]="mouseOverTestAddActionBox"
                      (mouseenter)="mouseOverTestAddActionBox = true" (mouseleave)="mouseOverTestAddActionBox = false"
                      *ngIf="!manualErase.selections.length" (click)="manualErase.addAction()">
                      <mat-icon class="add-button" fontSet="material-icons-outlined">
                        add_circle
                      </mat-icon>
                      Add Action
                    </div>
                  </div>
                </div>
                <!-- END manual erase -->
                <!-- INIT manual restore -->
                <div class="right-section-custom-trigger" *ngIf="workflow.customTriggerWorkflowEnabled">
                  <div class="row aligned-workflow-item">
                    <div>
                      <div class="row">
                        <div class="separate-box">
                          Manual Restore
                        </div>
                      </div>
                      <div class="line-box">
                        <div class="line"></div>
                        <i class="arrow down"></i>
                      </div>
                    </div>
                  </div>
                  <div *ngFor="let element of manualRestore.selections; let i = index">
                    <div class="row">
                      <div class="workflow-item">
                        <mat-form-field appearance="outline">
                          <mat-select [(ngModel)]="manualRestore.selections[i]"
                            (ngModelChange)="manualRestore.onSelected(i)" appearance="outline">
                            <mat-option *ngFor="let option of manualRestore.getOptions(i)" value="{{ option.value }}">{{
                              option.displayValue }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <button mat-icon-button (click)="manualRestore.removeOption(i)">
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </div>
                    <div *ngIf="!manualRestore.isLastOption(i) || manualRestore.showAddSelection(i)"
                      class="line-box-full">
                      <div class="line"></div>
                      <i class="arrow down"></i>
                    </div>
                    <div class="button-field-width"
                      *ngIf="manualRestore.showAddSelection(i) && manualRestore.selections.length">
                      <mat-icon class="add-button" (click)="manualRestore.addAction()"
                        fontSet="material-icons-outlined">
                        add_circle
                      </mat-icon>
                    </div>
                  </div>
                  <div class="row aligned-workflow-item">
                    <div class="add-action-box" [class.mat-elevation-z2]="!mouseOverTestAddActionBox"
                      [class.mat-elevation-z6]="mouseOverTestAddActionBox"
                      (mouseenter)="mouseOverTestAddActionBox = true" (mouseleave)="mouseOverTestAddActionBox = false"
                      *ngIf="!manualRestore.selections.length" (click)="manualRestore.addAction()">
                      <mat-icon class="add-button" fontSet="material-icons-outlined">
                        add_circle
                      </mat-icon>
                      Add Action
                    </div>
                  </div>
                </div>
                <!-- END manual restore -->
                <!-- INIT manual print -->
                <div class="right-section-custom-trigger" *ngIf="workflow.customTriggerWorkflowEnabled">
                  <div class="row aligned-workflow-item">
                    <div>
                      <div class="row">
                        <div class="separate-box">
                          Manual Print
                        </div>
                      </div>
                      <div class="line-box">
                        <div class="line"></div>
                        <i class="arrow down"></i>
                      </div>
                    </div>
                  </div>
                  <div *ngFor="let element of manualPrint.selections; let i = index">
                    <div class="row">
                      <div class="workflow-item">
                        <mat-form-field appearance="outline">
                          <mat-select [(ngModel)]="manualPrint.selections[i]"
                            (ngModelChange)="manualPrint.onSelected(i)" appearance="outline">
                            <mat-option *ngFor="let option of manualPrint.getOptions(i)" value="{{ option.value }}">{{
                              option.displayValue }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <button mat-icon-button (click)="manualPrint.removeOption(i)">
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </div>
                    <div *ngIf="!manualPrint.isLastOption(i) || manualPrint.showAddSelection(i)" class="line-box-full">
                      <div class="line"></div>
                      <i class="arrow down"></i>
                    </div>
                    <div class="button-field-width"
                      *ngIf="manualPrint.showAddSelection(i) && manualPrint.selections.length">
                      <mat-icon class="add-button" (click)="manualPrint.addAction()" fontSet="material-icons-outlined">
                        add_circle
                      </mat-icon>
                    </div>

                  </div>
                  <div class="row aligned-workflow-item">
                    <div class="add-action-box" [class.mat-elevation-z2]="!mouseOverTestAddActionBox"
                      [class.mat-elevation-z6]="mouseOverTestAddActionBox"
                      (mouseenter)="mouseOverTestAddActionBox = true" (mouseleave)="mouseOverTestAddActionBox = false"
                      *ngIf="!manualPrint.selections.length" (click)="manualPrint.addAction()">
                      <mat-icon class="add-button" fontSet="material-icons-outlined">
                        add_circle
                      </mat-icon>
                      Add Action
                    </div>
                  </div>
                </div>
                <!-- END manual restore -->
                <!-- INIT manual power off -->
                <div class="right-section-custom-trigger" *ngIf="workflow.customTriggerWorkflowEnabled">
                  <div class="row aligned-workflow-item">
                    <div>
                      <div class="row">
                        <div class="separate-box">
                          Manual<br>
                          Power Off
                        </div>
                      </div>
                      <div class="line-box">
                        <div class="line"></div>
                        <i class="arrow down"></i>
                      </div>
                    </div>
                  </div>
                  <div *ngFor="let element of manualPowerOff.selections; let i = index">
                    <div class="row">
                      <div class="workflow-item">
                        <mat-form-field appearance="outline">
                          <mat-select [(ngModel)]="manualPowerOff.selections[i]"
                            (ngModelChange)="manualPowerOff.onSelected(i)" appearance="outline">
                            <mat-option *ngFor="let option of manualPowerOff.getOptions(i)"
                              value="{{ option.value }}">{{ option.displayValue }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <button mat-icon-button (click)="manualPowerOff.removeOption(i)">
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </div>
                    <div *ngIf="!manualPowerOff.isLastOption(i) || manualPowerOff.showAddSelection(i)"
                      class="line-box-full">
                      <div class="line"></div>
                      <i class="arrow down"></i>
                    </div>
                    <div class="button-field-width"
                      *ngIf="manualPowerOff.showAddSelection(i) && manualPowerOff.selections.length">
                      <mat-icon class="add-button" (click)="manualPowerOff.addAction()"
                        fontSet="material-icons-outlined">
                        add_circle
                      </mat-icon>
                    </div>
                  </div>
                  <div class="row aligned-workflow-item">
                    <div class="add-action-box" [class.mat-elevation-z2]="!mouseOverTestAddActionBox"
                      [class.mat-elevation-z6]="mouseOverTestAddActionBox"
                      (mouseenter)="mouseOverTestAddActionBox = true" (mouseleave)="mouseOverTestAddActionBox = false"
                      *ngIf="!manualPowerOff.selections.length" (click)="manualPowerOff.addAction()">
                      <mat-icon class="add-button" fontSet="material-icons-outlined">
                        add_circle
                      </mat-icon>
                      Add Action
                    </div>
                  </div>
                </div>
                <!-- END manual power off-->
              </div>
              <div class="flex-spacer"></div>
            </div>
          </div>
        </div>
      </div>
      <div [hidden]="currentPage !== 'wifi'">
        <div class="card">
          <div class="fields-grade-container">
            <div class="card-section-header">
              <div class="card-section-title">SSID / Network Name</div>
              <div class="card-section-subtitle">Case sensitive</div>
            </div>
            <div class="flex-spacer"></div>
            <div class="wifi-name-field">
              <mat-form-field class="create-full-width" appearance="outline">
                <mat-label>Network Name</mat-label>
                <input matInput type="text" [(ngModel)]="wifiSettings.name" autocomplete="one-time-code" />
              </mat-form-field>
            </div>
            <div class="flex-spacer"></div>
          </div>
          <div class="fields-grade-container">
            <div class="card-section-header">
              <div class="card-section-title">Password</div>
              <div class="card-section-subtitle-extended">Case sensitive. Leave the field empty if the WiFi does not
                require a password.</div>
            </div>
            <div class="flex-spacer"></div>
            <div class="wifi-name-field">
              <mat-form-field class="create-full-width" appearance="outline">
                <mat-label>Password</mat-label>
                <input matInput [(ngModel)]="wifiSettings.password" (copy)="hidePassword ? false : true"
                  [ngClass]="hidePassword ? 'input-password' : ''" type="text" class="login-form-field"
                  autocomplete="one-time-code">
                <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword"
                  [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
                  <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
              </mat-form-field>
            </div>
            <div class="flex-spacer"></div>
          </div>
          <div class="wifi-disconnect-container">
            <mat-checkbox [(ngModel)]="wifiSettings.disconnect">Disconnect Device from WiFi Once the Test is
              Done</mat-checkbox>
            <mat-card-subtitle class="wifi-disconnect-subtitle">Keeps your WiFi clear by removing inactive
              devices</mat-card-subtitle>
          </div>
        </div>
      </div>
      <div [hidden]="currentPage !== 'battery'">
        <div class="card">
          <div class="card-section-container">
            <div class="card-section-header">
              <div class="card-section-title">
                Battery Testing
              </div>
            </div>
            <div class="flex-spacer"></div>
            <div class="battery-testing-container">
              <mat-radio-group aria-label="Select an option" [(ngModel)]="batterySettings.customizeBattery"
                (ngModelChange)="changeEnableCustomizeBattery($event)" class="radio-group-vertical">
                <div class="battery-testing-radio-container">
                  <mat-radio-button [value]="false">Default Battery Testing Rules</mat-radio-button>
                  <mat-card-subtitle class="radio-subtitle">Fail when Battery Health warning is
                    detected.</mat-card-subtitle>
                </div>
                <div class="battery-testing-radio-container">
                  <mat-radio-button [value]="true">Customize Advanced Battery Testing Rules</mat-radio-button>
                  <mat-card-subtitle class="radio-subtitle">Create more detailed and complex
                    scenarios</mat-card-subtitle>
                </div>
              </mat-radio-group>
            </div>
            <div class="flex-spacer"></div>
          </div>
          <ng-template [ngIf]="batterySettings.customizeBattery">
            <div class="card-section-divider">
              <mat-divider></mat-divider>
            </div>
            <div class="card-section-container">
              <div class="card-section-header">
                <div class="card-section-title">
                  Battery Health
                </div>
              </div>
              <div class="flex-spacer"></div>
              <div class="battery-testing-container">
                <div class="card-section-subtitle">
                  Custom Battery Health Criteria
                </div>
                <div class="criteria-container">
                  <div class="criteria" *ngFor="let element of batterySettings.healthCriteria; let i = index">
                    <div class="criteria-name">{{element?.name || '' }}</div>
                    <div class="flex-spacer"></div>
                    <div class="criteria-buttons">
                      <button mat-button (click)="editBatteryHealthCriteria(i)">Edit</button>
                      <button mat-button (click)="deleteBatteryHealthCriteria(i)">Delete</button>
                    </div>
                  </div>
                  <div class="add-criteria-container">
                    <button mat-raised-button (click)="addBatteryHealthCriteria()">Add Criteria</button>
                  </div>
                </div>

              </div>
              <div class="flex-spacer"></div>
            </div>
            <div class="card-section-container">
              <div class="card-section-header">
                <div class="card-section-title">
                  Battery Capacity
                </div>
              </div>
              <div class="flex-spacer"></div>
              <div class="battery-testing-container">
                <div class="battery-testing-radio-container">
                  <mat-checkbox [(ngModel)]="batterySettings.appleFailBatteryWarningMessage">
                    Fail when a Battery Health warning is detected.
                  </mat-checkbox>
                </div>
              </div>
              <div class="flex-spacer"></div>
            </div>
          </ng-template>
          <mat-divider></mat-divider>
          <div class="card-section-container">
            <div class="card-section-header">
              <div class="card-section-title">
                Battery Drain
              </div>
            </div>
            <div class="flex-spacer"></div>
            <div class="battery-testing-container">
              <div class="battery-drain-radio-container">
                <div>
                  <mat-checkbox [(ngModel)]="batterySettings.drain"
                    (ngModelChange)="changeEnableBatteryDrainSettings($event)">
                    <b>Enable Battery Drain</b>
                  </mat-checkbox>
                </div>
              </div>
              <div *ngIf="batterySettings.drain">
                <mat-radio-group aria-label="Select an option" [(ngModel)]="batterySettings.drainAlways"
                  class="radio-group-vertical">
                  <mat-radio-button [value]="true">Perform on All Devices</mat-radio-button>
                  <mat-radio-button [value]="false">Perform when Battery Health is Not Available<br>
                    Performs extensive battery testing for devices that do not report battery health.
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <div class="flex-spacer"></div>
          </div>
          <mat-divider *ngIf="batterySettings.drain"></mat-divider>
          <div class="card-section-container" *ngIf="batterySettings.drain">
            <div class="card-section-header">
              <div class="card-section-title">
                Execute Battery Drain Test
              </div>
            </div>
            <div class="flex-spacer"></div>
            <div class="battery-testing-container">
              <div>
                <mat-radio-group aria-label="Select an option" [(ngModel)]="batterySettings.drainTestAfterTestResults"
                  class="radio-group-vertical">
                  <mat-radio-button [value]="false">On Device Connection</mat-radio-button>
                  <mat-radio-button [value]="true">After Test Results</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <div class="flex-spacer"></div>
          </div>
          <mat-divider *ngIf="batterySettings.drain"></mat-divider>
          <div class="card-section-container" *ngIf="batterySettings.drain">
            <div class="card-section-header">
              <div class="card-section-title">
                Complete Battery Drain Test After
              </div>
            </div>
            <div class="flex-spacer"></div>
            <div class="battery-testing-container">
              <div>
                <!-- <mat-radio-button >Duration (in minutes): </mat-radio-button> -->
                <div>Duration (in minutes):
                  <mat-form-field appearance="outline" class="battery-drain-form">
                    <input matInput type="number" [(ngModel)]="batterySettings.drainDuration" value="5">
                  </mat-form-field>
                </div>
                <br>
                <mat-checkbox [(ngModel)]="batterySettings.allowDrainDurationChange">allow user to
                  adjust</mat-checkbox>
              </div>
            </div>
            <div class="flex-spacer"></div>
          </div>
          <mat-divider *ngIf="batterySettings.drain"></mat-divider>
          <div class="card-section-container" *ngIf="batterySettings.drain">
            <div class="card-section-header">
              <div class="card-section-title">
                Battery Drain Failure Criteria
              </div>
            </div>
            <div class="flex-spacer"></div>
            <div class="battery-testing-container">
              <div>
                <mat-checkbox [(ngModel)]="batterySettings.failDrainIfDischarge">Fail battery if Discharge greater
                  than </mat-checkbox>
                <mat-form-field appearance="outline" class="battery-drain-form">
                  <input matInput type="number" [(ngModel)]="batterySettings.failDrainIfDischargePercentage" value="5">
                  <mat-icon matSuffix class="percentage">%</mat-icon>
                </mat-form-field>
                <br>
              </div>
            </div>
            <div class="flex-spacer"></div>
          </div>
        </div>
      </div>
      <div *ngIf="currentPage === 'new-battery'">
        <div class="sidenav-content-title">
          <button mat-icon-button (click)="backToBatteryPage()">
            <mat-icon>arrow_back</mat-icon>
          </button>
        </div>
        <div class="card">
          <div class="card-section-container">
            <div class="card-section-header">
              <div class="card-section-title">
                Custom Battery Health Criteria
              </div>
            </div>
            <div class="flex-spacer"></div>
            <div class="battery-testing-container">
              <div class="criteria-name">
                <mat-form-field appearance="outline" class="criteria-field">
                  <mat-label>Criteria Name</mat-label>
                  <input matInput [(ngModel)]="selectedCriteria().name" type="text">
                </mat-form-field>
              </div>
              <div class="criteria-description">
                <mat-form-field appearance="outline" class="criteria-field">
                  <mat-label>Description - optional</mat-label>
                  <input matInput placeholder="" [(ngModel)]="selectedCriteria().description" type="text">
                </mat-form-field>
              </div>
              <div class="all-devices-checkbox-container">
                <mat-checkbox [disabled]="selectedCriteria().specifyDevices"
                  [(ngModel)]="selectedCriteria().applyAllDevices">Apply this Criteria to All Devices</mat-checkbox>
              </div>
              <ng-template [ngIf]="!selectedCriteria().applyAllDevices">
                <div class="all-devices-checkbox-container">
                  <mat-checkbox [disabled]="selectedCriteria().specifyDevices"
                    [(ngModel)]="selectedCriteria().applyAndroidDevices">All Android Devices</mat-checkbox>
                </div>
                <div class="all-devices-checkbox-container">
                  <mat-checkbox [disabled]="selectedCriteria().specifyDevices"
                    [(ngModel)]="selectedCriteria().applyAppleIphoneDevices">All Apple iOS Devices</mat-checkbox>
                </div>
                <div class="all-devices-checkbox-container">
                  <mat-checkbox [disabled]="selectedCriteria().specifyDevices"
                    [(ngModel)]="selectedCriteria().applyAppleIpadDevices">All Apple iPadOS Devices</mat-checkbox>
                </div>
                <div class="all-devices-checkbox-container">
                  <mat-checkbox (change)="toggleSpecifyDevices($event)">Specify Devices</mat-checkbox>
                </div>
                <div class="search-devices-container" *ngIf="selectedCriteria().specifyDevices">
                  <mat-form-field appearance="outline" class="listbox-field">
                    <mat-label>Search devices</mat-label>
                    <input [(ngModel)]="deviceSearchTerm" (input)="searchDevices()" matInput />
                  </mat-form-field>
                </div>
                <div class="specific-devices-container" *ngIf="selectedCriteria().specifyDevices">

                  <div class="specific-devices">
                    <label class="listbox-label" id="battery-health-devices-label">
                      Add Devices
                    </label>

                    <div class="listbox-container">
                      <ul cdkListbox cdkListboxMultiple aria-labelledby="battery-health-devices-label" class="listbox"
                        [(ngModel)]="devicesToAddToCriteria">
                        <li *ngFor="let element of selectableDevices" [cdkOption]="element" class="option">
                          {{element.modelName}}
                        </li>
                      </ul>

                    </div>
                  </div>
                  <div class="listbox-center-buttons">
                    <button mat-raised-button (click)="addDevices()">Add</button>
                    <button mat-raised-button (click)="removeDevices()">Remove</button>
                  </div>
                  <div class="specific-devices">
                    <label class="example-listbox-label" id="battery-health-devices-selected-label">
                      To Selected Criteria
                    </label>
                    <div class="listbox-container">
                      <ul cdkListbox cdkListboxMultiple aria-labelledby="battery-health-devices-selected-label"
                        class="listbox" [(ngModel)]="devicesToRemoveFromCriteria">
                        <li *ngFor="let element of selectedCriteria().devices" [cdkOption]="element" class="option">
                          {{element.modelName}}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
            <div class="flex-spacer"></div>
          </div>
          <div class="card-section-divider">
            <mat-divider></mat-divider>
          </div>
          <div class="card-section-container">
            <div class="card-section-header">
              <div class="card-section-title">
              </div>
            </div>
            <div class="battery-health-criteria-container">
              <div class="battery-criteria-row">
                <div class="battery-criteria-column">
                  Code
                </div>
                <div class="battery-criteria-column">
                  Minimum<br>Capacity
                </div>
                <div class="battery-criteria-column">
                  Maximum<br>Cycles
                </div>
                <div class="battery-criteria-delete-column">
                </div>
              </div>
              <div class="battery-criteria-row" *ngFor="let element of selectedCriteria().rules; let i = index;">
                <div class="battery-criteria-entry">
                  <div class="battery-criteria-column">
                    <div class="battery-criteria-name">
                      {{element.code}}
                    </div>
                  </div>
                  <div class="battery-criteria-column">
                    <mat-form-field appearance="outline" class="battery-criteria-field">
                      <input matInput [(ngModel)]="element.minimumCapacity" style="text-align:center;" type="number"
                        [min]="0">
                      <mat-icon matSuffix class="percentage">%</mat-icon>
                    </mat-form-field>
                  </div>
                  <div class="battery-criteria-column">
                    <mat-form-field appearance="outline" class="battery-criteria-field">
                      <input matInput [(ngModel)]="element.maximumCycle" style="text-align:center;" type="number"
                        [min]="0">
                    </mat-form-field>
                  </div>
                </div>
                <div class="battery-criteria-delete-column">
                  <button mat-icon-button (click)="deleteBatteryCriteria(i)"><mat-icon>clear</mat-icon></button>
                </div>
              </div>
              <div class="battery-criteria-add-button-container">
                <button mat-stroked-button [disabled]="selectedCriteria().rules.length >=4"
                  (click)="addBatteryHealthCriteriaRule()">Add a Rule</button>
              </div>
            </div>
          </div>
          <div class="flex-spacer"></div>
        </div>
      </div>

      <div [hidden]="currentPage !== 'cosmetics'">
        <div class="card">
          <div class="fields-grade-container">
            <div class="card-section-header">
              <div class="card-section-title">Cosmetics</div>
            </div>
            <div class="flex-spacer"></div>
            <div>Enable Cosmetics</div>
            <div class="flex-spacer"></div>
            <div class="wifi-name-field">
              <mat-slide-toggle [(ngModel)]="cosmeticSettings.enableCosmetics"
                (ngModelChange)="changeEnableCosmetics($event)"></mat-slide-toggle>
            </div>
          </div>
          <mat-divider *ngIf="cosmeticSettings.enableCosmetics"></mat-divider>
          <div class="cosmetic-container" *ngIf="cosmeticSettings.enableCosmetics">
            <div class="card-section-header">
              <div class="card-section-title">Cosmetic Type</div>
            </div>
            <div class="flex-spacer"></div>
            <div class="cosmetic-name-field">
              <mat-radio-group aria-label="Select an option" [(ngModel)]="cosmeticSettings.cosmeticType"
                class="radio-group-vertical">
                <mat-radio-button value="multi"><b>Multi Choice List</b><br>Provides users with custom cosmetic
                  list</mat-radio-button>
                <mat-radio-button value="qa"><b>Question & Answer Style</b><br>Guides user through custom cosmetic
                  question and answer</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="flex-spacer"></div>
          </div>
          <mat-divider *ngIf="cosmeticSettings.enableCosmetics"></mat-divider>
          <div class="cosmetic-container" *ngIf="cosmeticSettings.enableCosmetics">
            <mat-divider></mat-divider>
            <div class="card-section-header">
              <div class="card-section-title">Where to Save Cosmetic Data?</div>
              <div class="card-section-subtitle">Where should cosmetic data be placed within Phonecheck data?</div>
            </div>
            <div class="flex-spacer"></div>
            <div class="cosmetic-name-field">
              <mat-form-field class="cosmetic-field">
                <mat-select [(value)]="cosmeticSettings.saveCosmetics"
                  [disabled]="cosmeticSettings.cosmeticType === 'multi'">
                  <mat-option value="cosmeticOnly">
                    Save in the Cosmetics field only
                  </mat-option>
                  <mat-option value="cosmeticAndPassedOrFailed">
                    Save in the Cosmetics field and Passed or Failed test results
                  </mat-option>
                  <mat-option value="cosmeticAndPassed">
                    Save in the Cosmetics field and Passed test results
                  </mat-option>
                  <mat-option value="cosmeticAndFailed">
                    Save in the Cosmetics field and Failed test results
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="flex-spacer"></div>
          </div>
          <div class="cosmetic-container" *ngIf="cosmeticSettings.enableCosmetics">
            <mat-divider></mat-divider>
            <div class="card-section-header">
              <div class="card-section-title">What cosmetic Data to Save?</div>
              <div class="card-section-subtitle">What is the preferred format for displaying cosmetic information?
              </div>
            </div>
            <div class="flex-spacer"></div>
            <div class="cosmetic-name-field">
              <mat-form-field class="cosmetic-field">
                <mat-select [(value)]="cosmeticSettings.preferredFormat"
                  [disabled]="cosmeticSettings.cosmeticType === 'multi'">
                  <mat-option value="name">
                    Cosmetic Name
                  </mat-option>
                  <mat-option value="name-failed">
                    Cosmetic Name and failed response
                  </mat-option>
                  <mat-option value="name-passed">
                    Cosmetic Name and passed response
                  </mat-option>
                  <mat-option value="name-both">
                    Cosmetic Name and both responses
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="flex-spacer"></div>
          </div>
          <mat-divider *ngIf="cosmeticSettings.enableCosmetics"></mat-divider>
          <div class="cosmetic-container"
            *ngIf="cosmeticSettings.enableCosmetics && cosmeticSettings.cosmeticType === 'multi'">
            <div class="card-section-header">
              <div class="card-section-title">Cosmetic Name</div>
              <div class="card-section-subtitle">
                Provide short name for the cosmetic that is saved in the database. ie. Frame Damage, Cracked Camera
                Lens, etc.
              </div>
            </div>
            <div class="flex-spacer"></div>
            <div class="cosmetic-name-field">
              <mat-form-field class="cosmetic-field" appearance="outline">
                <input matInput [(ngModel)]="newCosmeticName">
              </mat-form-field>
            </div>
            <div class="add-response-container form-height">
              <button mat-raised-button (click)="addCosmetic()">Add Response</button>
            </div>
          </div>
          <div class="cosmetic-container"
            *ngIf="cosmeticSettings.enableCosmetics && cosmeticSettings.cosmeticType === 'multi'">
            <div class="multi-choice-list" cdkDropList (cdkDropListDropped)="dropCosmetic($event)">
              <mat-divider></mat-divider>
              <div class="choice-list-item" *ngFor="let element of cosmeticSettings.cosmetics; let i = index" cdkDrag>
                <div class="list-item-title">{{element}}</div>
                <div class="flex-spacer"></div>
                <div class="list-item-buttons">
                  <div class="drag-handle-button">
                    <div class="drag-handle"><mat-icon cdkDragHandle class="drag">drag_handle</mat-icon></div>
                  </div>
                  <button mat-icon-button (click)="deleteCosmetic(i)"><mat-icon>delete</mat-icon></button>
                </div>
              </div>
            </div>
          </div>
          <div class="qa-container" *ngIf="cosmeticSettings.enableCosmetics && cosmeticSettings.cosmeticType === 'qa'"
            cdkDropList (cdkDropListDropped)="dropQuestion($event)">
            <mat-card class="qa-card" *ngFor="let question of cosmeticSettings.cosmeticQuestions; let i = index"
              cdkDrag>
              <div class="qa-header">
                <div class="qa-header-title">
                  <button mat-icon-button (click)="moveUp(i)"
                    [disabled]="i === 0"><mat-icon>arrow_upward</mat-icon></button>
                  <div class="button-height">{{i+1}}</div>
                  <button mat-icon-button (click)="moveDown(i)"
                    [disabled]="i === cosmeticSettings.cosmeticQuestions.length - 1"><mat-icon>arrow_downward</mat-icon></button>
                </div>
                <div class="flex-spacer"></div>
                <div class="qa-header-buttons">
                  <div class="drag-handle"><mat-icon cdkDragHandle class="drag">drag_handle</mat-icon></div>
                  <button mat-icon-button (click)="deleteQuestion(i)"><mat-icon>delete</mat-icon></button>
                </div>
              </div>
              <div class="qa-os">
                <div>
                  <div class="qa-question-header">
                    <b>Operating System</b><br>
                    Which operating system do you want to ask this cosmetic question for?
                  </div>
                  <div class="qa-question-os-radio">
                    <mat-radio-group aria-label="Select an option" [(ngModel)]="question['os']">
                      <mat-radio-button value="ios">iOS</mat-radio-button>
                      <mat-radio-button value="android">Android</mat-radio-button>
                      <mat-radio-button value="both">Both</mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
                <div class="flex-spacer"></div>
              </div>
              <div class="qa-form">
                <b>Cosmetic Name</b>
                <mat-card-subtitle>Provide short name for the cosmetic that is saved in the database. ie. Frame
                  Damage, Cracked Camera Lens, etc.</mat-card-subtitle>
                <mat-form-field appearance="outline">
                  <input matInput [(ngModel)]="question.name">
                </mat-form-field>
              </div>
              <div class="qa-form">
                <b>Question</b>
                <mat-form-field appearance="outline">
                  <input matInput [(ngModel)]="question.question">
                </mat-form-field>
              </div>
              <div cdkDropList class="response-container" (cdkDropListDropped)="sortResponse(question, $event)">
                <div class="qa-form-bar" *ngFor="let response of question.responses; let responseIndex = index" cdkDrag>
                  <div class="half-form">
                    <b>Response</b>
                    <mat-form-field class="half-width" appearance="outline">
                      <input matInput [(ngModel)]="response.response">
                    </mat-form-field>
                  </div>
                  <div class="half-form">
                    <b>Criteria</b>
                    <mat-form-field class="half-width" appearance="outline">
                      <mat-select [(ngModel)]="response.criteria">
                        <mat-select-trigger>
                          <div *ngIf="response.criteria" class="inline-icon"><mat-icon
                              class="passed">check_box</mat-icon>Passed</div>
                          <div *ngIf="!response.criteria" class="inline-icon"><mat-icon
                              class="failed">close</mat-icon>Failed</div>
                        </mat-select-trigger>
                        <mat-option [value]="true">
                          <mat-icon class="passed">check_box</mat-icon>Passed
                        </mat-option>
                        <mat-option [value]="false">
                          <mat-icon color="warn">close</mat-icon>Failed
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="half-button form-height">
                    <button mat-raised-button (click)="addResponse(question, responseIndex)">Add Response</button>
                    <mat-icon cdkDragHandle class="drag">drag_handle</mat-icon>
                    <button mat-icon-button
                      (click)="deleteCosmeticResponse(question, responseIndex)"><mat-icon>delete</mat-icon></button>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
          <div class="add-question-container"
            *ngIf="cosmeticSettings.enableCosmetics && cosmeticSettings.cosmeticType === 'qa'">
            <button mat-raised-button class="full-width-button" (click)="addQuestion()">Add Question</button>
          </div>
        </div>
      </div>
      <div [hidden]="currentPage !== 'assignments'">
        <app-assignment-express [(assignmentPayload)]="assignmentPayload"></app-assignment-express>
      </div>
      <div *ngIf="currentPage === 'advanced_settings'">
        <app-advanced-settings [advancedSettings]="advancedSettingsState"
          (changeEmitter)="setAdvancedSettingsState($event)"></app-advanced-settings>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
