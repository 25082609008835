import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/services/notification.service';
import { PasswordProtectionService } from 'src/app/services/password-protection.service';

@Component({
  selector: 'app-password-required',
  templateUrl: './password-required.component.html',
  styleUrls: ['./password-required.component.css']
})
export class PasswordRequiredComponent {
  password = new FormControl('', [Validators.required]);
  hidePassword = true;
  invalidPassword = false;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: { name: string; id: string },
    private service: PasswordProtectionService,
    private notify: NotificationService
  ) {
    this.password.valueChanges.subscribe(value => {
      this.invalidPassword = false;
    });
  }

  async checkPassword() {
    if (!this.password.valid) {
      return;
    }

    try {
      const validPassword = await this.service.checkPassword(this.password.value as string, this.data.id);

      if (validPassword) {
        this.dialogRef.close(this.password.value);
      } else {
        this.invalidPassword = true;
      }
    } catch (err) {
      this.notify.error('Could not verify password!');
    }
  }
}
