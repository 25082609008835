import { takeUntil, debounceTime, Subject, from, take } from 'rxjs';
import { ViewChild, Component, Input, EventEmitter, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { PaginatorData } from '../interfaces/paginatorData.interface';
import { MacDeviceInfoService } from '../services/macDeviceInfo.service';
import { Page } from '../interfaces/page.interface';
import { MAC_DEVICE_INFO_COLUMNS } from '../common/mac/columns';
import { MacDeviceInfo } from "../common/mac/mac-device-info.interface";
import { FormControl } from '@angular/forms';
import { ColumnConfiguration, ColumnSettings, SavedColumnSettings } from '../interfaces/columnSettings.interface';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { ArrayViewerComponent } from '../dialogs/mac-grading-info/array-viewer.component';
import { MatDialog } from '@angular/material/dialog';
import { MacJSONViewerComponent } from '../dialogs/mac-json-viewer/mac-json-viewer.component';
import { CdkDragStart, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent  } from '@angular/material/paginator';
import { LookupOption } from '../common/lookup-options.enum';
import { DownloadFormat } from '../common/download';
import { AuthService } from '../services/auth.service';
import _ from 'lodash';

@Component({
  selector: 'app-mac-device-info-datatable',
  templateUrl: './mac-device-info-datatable.component.html',
  styleUrls: ['./mac-device-info-datatable.component.css']
})
export class MacDeviceInfoDatatableComponent implements AfterViewInit, OnDestroy {
  @Input() allColumns: ColumnConfiguration[] = this.authService.userType !== 'cloudAdmin'
    ? MAC_DEVICE_INFO_COLUMNS.filter(obj => obj.field !== 'masterName')
    : MAC_DEVICE_INFO_COLUMNS;
  @Input() columns: ColumnConfiguration[] = this.allColumns;
  @Input() filters!: any[];
  @Input() params!: any;
  @Input() lookupMode: boolean = false;

  @ViewChild(MatSort) sort: MatSort = new MatSort();
  @ViewChild('paginator') paginator!: MatPaginator;

  private readonly INPUT_FIELD_DEBOUNCE_TIME = 300;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  loading: boolean = false;
  paginatorData: PaginatorData = {
    pageIndex: 0,
    length: 0,
    pageSize: 10,
    currentPage: 0,
    pageOptions: [10, 20, 50],
  };
  deviceInfoList: MacDeviceInfo[] = [];
  displayedColumns: string[] = [];
  columnFilterFormControls!: Record<string, FormControl<string | null>>;
  filterId: string = 'default';

  previousPaginatorData = {
    pageIndex: 0,
    pageSize: 10
  };

  previousParams: any;

  constructor(
    private deviceInfoService: MacDeviceInfoService,
    private router: Router,
    private dialog: MatDialog,
    private authService: AuthService,
  ) {
    this.columnFilterFormControls = {};
  }

  public ngAfterViewInit(): void {
    this.setDisplayedColumnsHelper();

    if (!this.lookupMode) {
      this.getDeviceInfo();
    }
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public resetPagination() {
    this.paginatorData.pageIndex = 0;
    this.paginatorData.pageSize = 10;

    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 10;
  }

  public resetDataTable() {
    const pageEvent = new PageEvent();

    pageEvent.pageIndex = 0;
    pageEvent.pageSize = 10;
    this.resetPagination();
    this.paginator.page.emit(pageEvent);
  }

  public setDisplayedColumns(columns: ColumnConfiguration[]) {
    this.columns = columns;
    this.setDisplayedColumnsHelper();
  }

  public getColumnFilters(): Record<string, string> {
    const columns: Record<string, string> = {};
    const sort = this.sort.active;
    const direction = this.sort.direction || 'desc';

    Object.keys(this.columnFilterFormControls).forEach(field => {
      const control = this.columnFilterFormControls[field];
      const value = control.getRawValue();

      if (value) {
        columns[field] = value;
      }
    });

    if (sort) {
      columns['sort'] = sort;
      columns['direction'] = direction;
    }

    return columns;
  }

  private setDisplayedColumnsHelper() {
    const inputs: Record<string, FormControl<string | null>> = {};
    const newDisplayedColumns: string[] = [];

    this.columns.forEach((column, index) => {
      let control = this.columnFilterFormControls[column.field];

      column.index = index;
      this.displayedColumns[index] = column.field;
      newDisplayedColumns.push(column.field);

      if (control) {
        inputs[column.field] = control;
      } else {
        control = new FormControl<string | null>(null);

        control.valueChanges.pipe(takeUntil(this.ngUnsubscribe), debounceTime(this.INPUT_FIELD_DEBOUNCE_TIME))
          .subscribe(() => {
            if (!this.lookupMode) {
              this.getDeviceInfo();
            }
          });
      }
    });

    this.displayedColumns = newDisplayedColumns;
    this.columnFilterFormControls = inputs;
  }

  public onSortChange(event: any): void {
    this.getDeviceInfo();
  }

  public setParams(params: { option: LookupOption, codes: string[], latest: boolean }) {
    this.params = params;
  }

  public getBulkDeviceInfo(option: LookupOption, codes: string[], latest: boolean) {
    return this.onParamsChange({ option, codes, latest });
  }

  public formatTestNames(value: string): string {
    if (!value) return '';
    let testNames: string[] = [];
    try {
      testNames = JSON.parse(value);
    } catch (e) {
      console.error('Invalid JSON string provided:', e);
      return '';
    }
    return testNames.map(test => this.extractSubTestName(test)).join(', ');
  }
  
  public extractSubTestName(testName: string): string {
    const masterTestNames = ['speaker_&_microphone', 'ports', 'battery', 'Battery', 'keyboard', 'display'];
    const normalizedTestName = testName.toLowerCase().trim();
    if (masterTestNames.includes(normalizedTestName)) {
      return testName;
    }
    let parts = testName.split(/\s+/);
    masterTestNames.forEach(masterTest => {
      const firstIndex = parts.indexOf(masterTest);
      if (firstIndex !== -1) {
        parts.splice(firstIndex, 1);
      }
    });
    return parts.join(' ');
  }  

  public onParamsChange(params: any) {
    if (this.previousParams && _.isEqual(this.previousParams, params)) {
      return;
    }

    this.previousParams = this.params;
    this.params = params;

    if (this.lookupMode) {
      const page = this.paginatorData.pageIndex;
      const size = this.paginatorData.pageSize;
      const columns = this.getColumnFilters();
      const { option, codes, latest } = params;

      return this.lookup(option, codes, latest, columns, page, size);
    } else {
      return this.getDeviceInfo();
    }
  }

  private async lookup(
    option: 'serial' | 'lpn',
    codes: string[],
    latest: boolean,
    columnFilters: Record<string, string>,
    page: number,
    size: number,
  ) {
    this.loading = true;
    const macPage = await this.deviceInfoService.lookup(
      option,
      codes,
      latest,
      columnFilters,
      page,
      size,
    );

    if (macPage) {
      this.paginatorData.length = macPage.totalItems;
      this.deviceInfoList = macPage.data;
      this.createReportKey();
    }
    this.loading = false;
  }

  private async getDeviceInfo() {
    const page = this.paginatorData.pageIndex;
    const size = this.paginatorData.pageSize;
    const columns = this.getColumnFilters();
    const params = { columns, ...this.params };

    if (!this.loading) {
      this.loading = true;
    }

    if (params.columns['sort'] !== 'reports') {
      this.deviceInfoService.getDeviceInfo(
        params,
        page,
        size,
      ).pipe(take(1)).subscribe((devicesPage) => {
        if (devicesPage) {
          const { data } = devicesPage;

          this.paginatorData.length = devicesPage.totalItems;
          this.deviceInfoList = data;
          this.createReportKey();
          this.loading = false;
        }
        }
      );
    }
  }

  private createReportKey() {
    this.deviceInfoList.forEach((item: any, index) => {
      const keys = Object.keys(item);

      if (keys.length > 0) {
        const clonedItem: any = { ...item };
        clonedItem.reports = item['id'];
        this.deviceInfoList[index] = clonedItem;
      }
    });
  }

  public getColumnFilterFormControl(field: string): FormControl {
    let control = this.columnFilterFormControls[field];

    if (!control) {
      control = new FormControl(null);

      control.valueChanges
        .pipe(debounceTime(200), takeUntil(this.ngUnsubscribe))
        .subscribe(value => {
          if (!this.lookupMode) {
            this.getDeviceInfo();
          }

        });

      this.columnFilterFormControls[field] = control;
    }

    return control;
  }

  public onPaginate(event: any): void {

    this.paginatorData.pageIndex = event.pageIndex;
    this.paginatorData.pageSize = event.pageSize;

    if (this.lookupMode) {
      const page = this.paginatorData.pageIndex;
      const size = this.paginatorData.pageSize;
      const columns = this.getColumnFilters();
      const { option, codes, latest } = this.params;
      this.lookup(option, codes, latest, columns, page, size);
    } else {

      this.getDeviceInfo();
    }

  }

  public isTestInfoColumn = (columnName: string): boolean => ['passedTest', 'failedTest', 'pendingTest'].includes(columnName);

  public openDHRreport(id: number) {
    this.deviceInfoList.forEach((item: any) => {
      if (item['id'] === id) {
        if (item['guid']) {
          const reportUrl = `${environment.dhrQRURL}/${item['guid']}`;
          // Open the URL in a new tab
          const newTab = window.open(reportUrl, '_blank');
        }
      }
    });
  }

  public openErasureReport(id: any): void {
    const newTab = window.open('', '_blank');

    if (newTab) {
      newTab.location.href = this.router.createUrlTree(['/mac-erasure-report'], { queryParams: { id } }).toString();
    } else {
      console.error('Unable to open a new tab.');
    }
  }

  public findEraseStatusById(id: number): boolean {
    let res: boolean = false;

    this.deviceInfoList.forEach((item: any) => {
      if (item['id'] === id) {
        if ((item['eraseStatus'] === 'true' || item['eraseStatus'] === 'pass')) {
          res = true;
        }
      }
    });

    return res;
  }

  public openArrayViewer(profile: string, data: object[], routeName: string,) {
    this.dialog.open(ArrayViewerComponent, {
      data: {
        arrayData: data,
        profile,
        routeName,
      },
      width: '80%',
      maxHeight: '90vh',
    });
  }

  public isStringArray(value: string): boolean {
    try {
      const parsedValue = JSON.parse(value);
      return Array.isArray(parsedValue);
    } catch (error) {
      return false;
    }
  }

  public isObject(o: any) {
    return o !== null && typeof o === 'object';
  }

  public openJSONViewer(field: string, data: any) {
    if(field === 'Cosmetics'){
      const { status, question_answer } = data;
      this.dialog.open(ArrayViewerComponent, {
        data: {
          arrayData: question_answer,
          profile: status,
          cosmetics: true
        },
        width: '50%',
        maxHeight: '90vh',
      });
      return;
    }
    this.dialog.open(MacJSONViewerComponent, {
      data: {
        json: data,
        field,
      },
      width: '33vw',
      maxHeight: '90vh',
    });
  }

  public handleInputClick(event: MouseEvent): void {
    event.stopPropagation();
  }

  public dragStarted(event: CdkDragStart, i: number): void {
  }

  public dropListDropped(event: CdkDragDrop<string[]>): void {
    if (event) {
      moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
      this.setDisplayedColumnsHelper();

      if (event.currentIndex !== event.previousIndex) {
        this.updateColumnOrderPreference();
      }
    }
  }

  private findFilter(id: string) {
    return this.filters.find(f => f.id === id);
  }

  public getColumnSettings(): ColumnSettings {
    const visible: ColumnConfiguration[] = this.columns;
    const hidden: ColumnConfiguration[] = [];

    this.allColumns.forEach(column => {
      const found = this.displayedColumns.find(c => c === column.field);

      if (!found) {
        hidden.push(column);
      }
    });

    return { visible, hidden };
  }

  public canUpdateColumnSettings(): boolean {
    const settingsId = this.filterId;

    return settingsId !== 'default' && !!settingsId;
  }

  private updateLocalFilter(filter: SavedColumnSettings): void {
    const index = this.filters.findIndex(f => f.id === filter.id);

    if (index >= 0) {
      this.filters[index] = filter;
    }
  }

  private async updateColumnOrderPreference(): Promise<void> {
    const settingsId = this.filterId;
    const settings = this.findFilter(settingsId);
    const newSettings: ColumnSettings = this.getColumnSettings();
    const payload = {
      id: settingsId,
      ...newSettings,
      name: '',
    };

    if (settings) {
      const { name } = settings;
      payload.name = name;

      if (this.canUpdateColumnSettings()) {
        const result = await this.deviceInfoService.updateColumnSettings(settingsId, payload);
        this.updateLocalFilter(payload);
      }
    }
  }

  public setAllColumns(filterId: string, allColumns: ColumnConfiguration[]) {
    const uniqueColumns: ColumnConfiguration[] = [];
    const seen: Record<string, boolean> = {};

    // Some user's saved filters have duplicated columns.
    // This break the datatable. We add this workaround here.
    allColumns.forEach((column: ColumnConfiguration) => {
      if (!seen[column.field]) {
        uniqueColumns.push(column);
        seen[column.field] = true;
      }
    });

    this.filterId = filterId;
    this.allColumns = uniqueColumns;
    this.setDisplayedColumns(allColumns);
  }

  public downloadBulkLookup(
    format: DownloadFormat,
    columnSettingsId = 'default',
    gradingDataExportOnly: boolean = false,
  ) {
    const filters = this.getColumnFilters();
    const sort = this.sort.active;
    const direction = this.sort.direction;

    return this.deviceInfoService.downloadBulkLookup(
      format,
      this.params.option,
      this.params.codes,
      this.params.latest,
      filters,
      sort,
      direction,
      this.filterId,
      gradingDataExportOnly,
    );
  }

  isAdminUser(): boolean {
    return this.authService.userType === 'cloudAdmin';
  }

  public clear(): void {
    this.deviceInfoList = [];
  }

}

