<div id="table-container">
<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
<mat-table
  id="lookup-table"
  (matSortChange)="onSortChange($event)"
  [dataSource]="deviceInfoList"
  >
  <ng-container *ngFor="let column of columns; let i = index" [matColumnDef]="column.field">
    <mat-header-cell *matHeaderCellDef>
      <div fxLayout="column">
        <span class="column-header">{{ column.displayName }}</span>
        <input class="column-search" [formControl]="getColumnFilterFormControl(column.field)" type="search">
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let device">
        <ng-container  *ngIf="isObject(device[column.field]) && !isLinksValue(device[column.field]); else alternateView">
          <div class="json-container" (click)="openJSONViewer(column.displayName, device[column.field])">
            {{ device[column.field] | dataformat: column }}
          </div>
        </ng-container>
        <ng-template #alternateView>
          <ng-container  *ngIf="isLinksValue(device[column.field]); else nativeView">
            <div>
              <button mat-icon-button (click)="openLink(device[column.field].a4ReportLink)" [disabled]="!hasA4ReportLink(device[column.field])">
                <mat-icon [ngClass]="{ 'disabled': !hasA4ReportLink(device[column.field])}" svgIcon="a4"></mat-icon>
              </button>
              <button mat-icon-button (click)="openLink(device[column.field].erasureReportLink)" [disabled]="!hasErasureReportLink(device[column.field])">
                <mat-icon [ngClass]="{ 'disabled': !hasErasureReportLink(device[column.field])}" svgIcon="er"></mat-icon>
              </button>
              <button mat-icon-button (click)="openLink(device[column.field].scrReportLink)" [disabled]="!hasSCRReportLink(device[column.field])">
                <mat-icon [ngClass]="{ 'disabled': !hasSCRReportLink(device[column.field])}" svgIcon="scr"></mat-icon>
              </button>
              <button mat-icon-button (click)="openLink(device[column.field].dhrLink)" [ngClass]="{ 'disabled': !hasDHRLink(device[column.field])}" [disabled]="!hasDHRLink(device[column.field])">
                <mat-icon svgIcon="dhr"></mat-icon>
              </button>
            </div>
          </ng-container>
          <ng-template #nativeView>
            {{ getPublicValue(device, column.field) }}
          </ng-template>
        </ng-template>
    </mat-cell>
  </ng-container>
  <mat-header-row
    id="drop-area"
    *matHeaderRowDef="displayedColumns; sticky: true"
    ></mat-header-row>
  <mat-row *matRowDef="let device; columns: displayedColumns"></mat-row>
  <tr class="mat-row empty-table" *matNoDataRow >
    <td class="mat-cell" *ngIf="!loading" [attr.colspan]="displayedColumns.length">
      No results matching the filter
    </td>
  </tr>

</mat-table>
<mat-paginator #paginator (page)="onPaginate($event)" [pageSize]="paginatorData.pageSize" [pageSizeOptions]="paginatorData.pageOptions" [length]="paginatorData.length" showFirstLastButtons></mat-paginator>
</div>
