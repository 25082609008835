<div class="main-container">
  <h1>License Information</h1>
  <mat-divider></mat-divider>
  <div class="license-section" *ngIf="accountInfo">
    <h2>Account</h2>
    <b>{{ accountInfo.name }}</b><br>
    {{ accountInfo.phoneNumber }}<br>
    {{ accountInfo.email }}<br>

    <div fxLayout="column">
      <div fxLayout="row">
        <div class="account-cell"><b>Bill cycle</b><br> {{ accountInfo.billCycle }}</div>
        <div class="account-cell"><b>Account Status</b><br> {{ getStatusDisplayName(accountInfo.status) }}</div>
      </div>
      <div fxLayout="row">
        <div class="account-cell"><b>Start Date</b><br> {{ accountInfo.startDate | date }}</div>
        <div class="account-cell"><b>End Date</b><br> {{ accountInfo.endDate | date }}</div>
      </div>
      <div fxLayout="row">
        <div class="account-cell"><b>Account Type</b><br> {{ accountInfo.type }}</div>
        <div class="account-cell"></div>
      </div>
    </div>
  </div>
  <div class="license-section">
    <h2>License Balance</h2>
    <div fxLayout="column">
      <mat-progress-bar mode="indeterminate" *ngIf="loadingBalance"></mat-progress-bar>
      <div fxLayout="row" class="fixed-table-row">
        <div class="balance-cell"><b>Device</b><br>{{ balance?.device || 0 }}</div>
        <div class="balance-cell"><b>App</b><br>{{ balance?.appInstall || 0 }}</div>
        <div class="balance-cell"><b>Erase</b><br>{{ balance?.erase || 0 }}</div>
        <div class="balance-cell"><b>ESN</b><br>{{ balance?.esn || 0 }}</div>
        <div class="balance-cell"><b>Consumer</b><br>{{ balance?.consumer || 0 }}</div>
      </div>
      <div fxLayout="row" class="fixed-table-row">
        <div class="balance-cell"><b>iCloud</b><br>{{ balance?.iCloud || 0 }}</div>
        <div class="balance-cell"><b>SIM Lock</b><br>{{ balance?.simLock || 0 }}</div>
        <div class="balance-cell"><b>Provision</b><br>{{ balance?.provision || 0 }}</div>
        <div class="balance-cell"><b>Carrier Lock</b><br>{{ balance?.carrierLock || 0 }}</div>
        <div class="balance-cell"><b>MacBook Diagnostics</b><br>{{ balance?.macDiagnostics || 0 }}</div>
      </div>
      <div fxLayout="row" class="fixed-table-row">
        <div class="balance-cell"><b>MacBook iCloud</b><br>{{ balance?.macBookICloud || 0 }}</div>
        <div class="balance-cell"><b>MacBook MDM</b><br>{{ balance?.mdm || 0 }}</div>
        <div class="balance-cell"><b>OEM</b><br>{{ balance?.oem || 0 }}</div>
        <div class="balance-cell"><b>Airpod Diagnostics</b><br>{{ balance?.airpodDiagnostics || 0 }}</div>
        <div class="balance-cell"><b>Watch Diagnostics</b><br>{{ balance?.watchDiagnostics || 0 }}</div>
      </div>
    </div>
    <mat-divider></mat-divider>
  </div>
  <h1>Activity</h1>
  <div>
    <div class="license-section">
      <h3>Date range</h3>
      <div fxLayout="row">
        <mat-form-field>
          <mat-date-range-input [disabled]="!datePickerEnabled()" [rangePicker]="picker">
            <input matStartDate (blur)="onDateRangeChanged()" [(ngModel)]="startDate" placeholder="Start">
            <input matEndDate (blur)="onDateRangeChanged()" [(ngModel)]="endDate" placeholder="End">
          </mat-date-range-input>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker (closed)="onDateRangeChanged()"></mat-date-range-picker>
        </mat-form-field>
        <button mat-raised-button color="primary" class="load-button" [disabled]="!datePickerEnabled()"
          (click)="refresh()"><mat-icon>refresh</mat-icon></button>
      </div>
    </div>
    <div class="license-section" fxLayout="column">
      <h2>License Usage Summary</h2>
      <mat-progress-bar *ngIf="loadingSummary" style="margin-bottom: 20px;" mode="indeterminate"></mat-progress-bar>
      <div [ngClass]="{ 'opaque': loadingSummary }">
        <div fxLayout="row" class="fixed-table-row">
          <div class="balance-cell"><b>Device</b><br>{{ usage?.device || 0 }}</div>
          <div class="balance-cell"><b>App</b><br>{{ usage?.appInstall || 0 }}</div>
          <div class="balance-cell"><b>Erase</b><br>{{ usage?.erase || 0 }}</div>
          <div class="balance-cell"><b>ESN</b><br>{{ usage?.esn || 0 }}</div>
          <div class="balance-cell"><b>Consumer</b><br>{{ usage?.consumer || 0 }}</div>
        </div>
        <div fxLayout="row" class="fixed-table-row">
          <div class="balance-cell"><b>iCloud</b><br>{{ usage?.iCloud || 0 }}</div>
          <div class="balance-cell"><b>SIM Lock</b><br>{{ usage?.simLock || 0 }}</div>
          <div class="balance-cell"><b>Provision</b><br>{{ usage?.provision || 0 }}</div>
          <div class="balance-cell"><b>Carrier Lock</b><br>{{ usage?.carrierLock || 0 }}</div>
          <div class="balance-cell"><b>MacBook Diagnostics</b><br>{{ usage?.macDiagnostics || 0 }}</div>
        </div>
        <div fxLayout="row" class="fixed-table-row">
          <div class="balance-cell"><b>MacBook iCloud</b><br>{{ usage?.macBookICloud || 0 }}</div>
          <div class="balance-cell"><b>MacBook MDM</b><br>{{ usage?.mdm || 0 }}</div>
          <div class="balance-cell"><b>OEM</b><br>{{ usage?.oem || 0 }}</div>
          <div class="balance-cell"><b>Airpod Diagnostics</b><br>{{ usage?.airpodDiagnostics || 0 }}</div>
          <div class="balance-cell"><b>Watch Diagnostics</b><br>{{ usage?.watchDiagnostics || 0 }}</div>
        </div>
      </div>
    </div>

    <div class="license-section">
      <h2>License History by User</h2>
      <div class="table-actions-container" fxLayout="row">
        <div class="download-buttons-container">
          <button mat-raised-button [disabled]="!datePickerEnabled()" (click)="onHistoryCopy()">Copy</button>
          <button mat-raised-button [disabled]="!datePickerEnabled()" (click)="onHistoryDownloadCSV()">CSV</button>
          <button mat-raised-button [disabled]="!datePickerEnabled()" (click)="onHistoryDownloadExcel()">Excel</button>
        </div>
        <div class="global-search-container">
          <mat-form-field>
            <mat-label>Search</mat-label>
            <input matInput class="column-search" type="search" [formControl]="historySearchTermFormControl">
          </mat-form-field>
        </div>
      </div>
      <div>
        <div class="table-container">
          <mat-progress-bar mode="indeterminate" *ngIf="loadingHistory"></mat-progress-bar>
          <mat-progress-bar mode="query" *ngIf="downloadingHistoryExport"></mat-progress-bar>
          <mat-table class="license-table" [dataSource]="historyDataSource" matSort matSortActive="date"
            matSortDirection="desc" matSortDisableClear (matSortChange)="onHistorySortChange($event)">
            <ng-container *ngFor="let column of historyColumns" [matColumnDef]="column.field">
              <mat-header-cell mat-sort-header *matHeaderCellDef class="table-header">
                <div fxLayout="column">
                  <span>{{ column.displayName }}</span>
                  <input class="column-search" [formControl]="getHistoryColumnFilterFormControl(column.field)"
                    type="search">
                </div>
              </mat-header-cell>
              <mat-cell *matCellDef="let historyItem">
                <ng-container>
                  {{ historyItem[column.field] }}
                </ng-container>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="historyDisplayedColumns"></mat-header-row>
            <mat-row *matRowDef="let historyColumn; columns: historyDisplayedColumns"></mat-row>
            <tr class="empty-table" *matNoDataRow>
              <td class="" [attr.colspan]="historyDisplayedColumns.length">
                No results matching the filter
              </td>
            </tr>
          </mat-table>
        </div>
        <mat-paginator #historyPaginator showFirstLastButtons (page)="onHistoryPaginate($event)"
          [pageSize]="historyPaginatorData.pageSize" [pageIndex]="historyPaginatorData.pageIndex"
          [pageSizeOptions]="historyPaginatorData.pageOptions" [length]="historyPaginatorData.length"></mat-paginator>
      </div>
    </div>

    <div class="license-section">
      <h2>License History by Sources</h2>
      <div class="table-actions-container" fxLayout="row">
        <div class="download-buttons-container">
          <button [disabled]="!datePickerEnabled()" mat-raised-button (click)="onHistoryBySourceCopy()">Copy</button>
          <button [disabled]="!datePickerEnabled()" mat-raised-button
            (click)="onHistoryBySourceDownloadCSV()">CSV</button>
          <button [disabled]="!datePickerEnabled()" mat-raised-button
            (click)="onHistoryBySourceDownloadExcel()">Excel</button>
        </div>
        <div class="global-search-container">
          <mat-form-field>
            <mat-label>Search</mat-label>
            <input matInput class="column-search" type="search" [formControl]="historyBysourceSearchTermFormControl">
          </mat-form-field>
        </div>
      </div>
      <div>
        <div class="table-container">
          <mat-progress-bar mode="indeterminate" *ngIf="loadingHistoryBySource"></mat-progress-bar>
          <mat-progress-bar mode="query" *ngIf="downloadingHistoryExport"></mat-progress-bar>
          <mat-table class="license-table" [dataSource]="historyBySourceDataSource" matSort matSortActive="date"
            matSortDirection="desc" matSortDisableClear (matSortChange)="onHistoryBySortChange($event)">
            <ng-container *ngFor="let column of historySourceColumns" [matColumnDef]="column.field">
              <mat-header-cell mat-sort-header *matHeaderCellDef class="table-header">
                <div fxLayout="column">
                  <span>{{ column.displayName }}</span>
                  <input class="column-search" [formControl]="getHistoryColumnFilterFormControl(column.field)"
                    type="search">
                </div>
              </mat-header-cell>
              <mat-cell *matCellDef="let historyItem">
                <ng-container>
                  {{ historyItem[column.field] }}
                </ng-container>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="historyBySourceDisplayedColumns"></mat-header-row>
            <mat-row *matRowDef="let historyColumn; columns: historyBySourceDisplayedColumns"></mat-row>
            <tr class="empty-table" *matNoDataRow>
              <td class="" [attr.colspan]="historyBySourceDisplayedColumns.length">
                No results matching the filter
              </td>
            </tr>
          </mat-table>
        </div>
        <mat-paginator #historyPaginator showFirstLastButtons (page)="onHistoryBySourcePaginate($event)"
          [pageSize]="historyBySourcePaginatorData.pageSize" [pageIndex]="historyBySourcePaginatorData.pageIndex"
          [pageSizeOptions]="historyBySourcePaginatorData.pageOptions"
          [length]="historyBySourcePaginatorData.length"></mat-paginator>
      </div>
    </div>
    <div class="license-section">
      <h2>License Activity</h2>
      <div>
        <div>
          <h3>Filters</h3>
          <div class="filters-container" fxLayout="row">
            <mat-form-field class="filter-search-box">
              <mat-label>Source</mat-label>
              <mat-select [formControl]="activitySourceFormControl">
                <mat-option [value]="licenseSource.value" *ngFor="let licenseSource of LICENSE_SOURCE_OPTIONS">
                  {{ licenseSource.displayValue }}
                </mat-option>
              </mat-select>
              <button *ngIf="activitySourceFormControl.value" matSuffix mat-icon-button type="button" aria-label="Clear"
                (click)="activitySourceFormControl.setValue(null)">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="filter-search-box">
              <mat-label>License Type</mat-label>
              <mat-select [formControl]="activityLicenseTypeFormControl">
                <mat-option [value]="licenseType" *ngFor="let licenseType of LICENSE_TYPE_OPTIONS">
                  {{ licenseType }}
                </mat-option>
              </mat-select>
              <button *ngIf="activityLicenseTypeFormControl.value" matSuffix mat-icon-button type="button"
                aria-label="Clear" (click)="activityLicenseTypeFormControl.setValue(null)">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field class="filter-search-box">
              <mat-label>User</mat-label>
              <mat-select [formControl]="activityUserFormControl">
                <mat-option [value]="user.id" *ngFor="let user of users">
                  {{ user.name }}
                </mat-option>
              </mat-select>
              <button *ngIf="activityUserFormControl.value" matSuffix mat-icon-button type="button" aria-label="Clear"
                (click)="activityUserFormControl.setValue(null)">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <!-- Charge Type -->
            <mat-form-field class="filter-search-box">
              <mat-label>Deposit/Withdrawal</mat-label>
              <mat-select [formControl]="chargeTypeFormControl">
                <mat-option [value]="chargeType.value" *ngFor="let chargeType of CHARGE_TYPE_OPTIONS">
                  {{ chargeType.displayName }}
                </mat-option>
              </mat-select>
              <button *ngIf="chargeTypeFormControl.value !== 'all'" matSuffix mat-icon-button type="button"
                aria-label="Clear" (click)="chargeTypeFormControl.setValue('all')">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
        <div class="table-actions-container" fxLayout="row">
          <div class="download-buttons-container">
            <button mat-raised-button (click)="onActivityCopy()"
              [disabled]="downloadingActivityExport || downloadingHistoryExport">Copy</button>
            <button mat-raised-button (click)="onActivityDownloadCSV()"
              [disabled]="downloadingActivityExport || downloadingHistoryExport">CSV</button>
            <button mat-raised-button (click)="onActivityDownloadExcel()"
              [disabled]="downloadingActivityExport || downloadingHistoryExport">Excel</button>
          </div>
          <div class="global-search-container">
            <mat-form-field>
              <mat-label>Search</mat-label>
              <input matInput class="column-search" type="search" [formControl]="activityKeywordFormControl">
            </mat-form-field>
          </div>
        </div>
        <div class="table-container">
          <mat-progress-bar mode="indeterminate" *ngIf="loadingActivity"></mat-progress-bar>
          <mat-progress-bar mode="query" *ngIf="downloadingActivityExport"></mat-progress-bar>
          <mat-table class="license-table" [dataSource]="activityDataSource" matSort matSortActive="datetime"
            matSortDirection="desc" (matSortChange)="onActivitySortChange($event)" matSortDisableClear>
            <ng-container *ngFor="let column of activityColumns" [matColumnDef]="column.field">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="table-header">
                {{ column.displayName }}
              </mat-header-cell>
              <mat-cell *matCellDef="let activityItem">
                <ng-container *ngIf="column.field !== 'source'">
                  {{ activityItem[column.field] }}
                </ng-container>
                <ng-container *ngIf="column.field === 'source'">
                  {{ activityItem[column.field] | titlecase }}
                </ng-container>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="activityDisplayedColumns"></mat-header-row>
            <mat-row *matRowDef="let activityColumn; columns: activityDisplayedColumns"></mat-row>
            <tr class="empty-table" *matNoDataRow>
              <td class="" [attr.colspan]="activityDisplayedColumns.length">
                No results matching the filter
              </td>
            </tr>
          </mat-table>
        </div>
        <mat-paginator #activityPaginator showFirstLastButtons (page)="onActivityPaginate($event)"
          [pageIndex]="activityPaginatorData.pageIndex" [pageSize]="activityPaginatorData.pageSize"
          [pageSizeOptions]="activityPaginatorData.pageOptions" [length]="activityPaginatorData.length"></mat-paginator>
      </div>
    </div>
  </div>
</div>
