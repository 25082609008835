import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { BuildService } from "../../services/build.service";
import { Build } from "../../interfaces/build.interface";

@Component({
  selector: 'app-delete-build',
  templateUrl: './delete-build.component.html',
  styleUrls: ['./delete-build.component.css']
})
export class DeleteBuildComponent {
  deleting = false;
  constructor(
    public dialogRef: MatDialogRef<DeleteBuildComponent>,
    private buildService: BuildService,
    @Inject(MAT_DIALOG_DATA) public data: Build,
  ) { }

  async deleteBuild() {
    this.deleting = true;
    // Perform any necessary operations before closing, if needed
    this.dialogRef.close(true); // Pass `true` to indicate confirmation
  }
}
