<div id="container">
  <h3>This profile has no assignments</h3>
  <div id="description">
    This profile is currently not assigned to any station or warehouse.
  </div>
  <div id="button-container" mat-dialog-actions>
    <button mat-raised-button color="primary" [mat-dialog-close]="true">Modify Assignments</button>
    <button mat-raised-button [mat-dialog-close]="false">Close</button>
  </div>
</div>
