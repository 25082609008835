import { DeviceInfoServiceProvider } from '../interfaces/device-info-service.interface';
import { Injectable, Input } from '@angular/core';
import { HttpResponse, HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NotificationService } from './notification.service';
import { DeviceTestCategory } from '../interfaces/deviceTest.interfaces';
import { firstValueFrom, Observable, shareReplay } from 'rxjs';
import { Page } from '../interfaces/page.interface';
import { ColumnConfiguration, ColumnSettingsCreationRequest, SavedColumnSettings } from '../interfaces/columnSettings.interface';
import { DeviceInfoQueryParams } from '../interfaces/deviceInfoQueryParams.interface';
import { DEVICE_INFO_COLUMNS } from '../device-info/columns';
import moment from 'moment-timezone';
import { Clipboard } from '@angular/cdk/clipboard';
import { AuthService } from './auth.service';
import { DateRange, toDateString } from '../common/date';
import { downloadFiles, getContentType, getExtension, DownloadFormat } from '../common/download';
import { ReportEntityType } from "../common/report-entity-type.enum";
import { ReportCountType } from "../common/report-count-type.enum";
import { Moment } from 'moment-timezone';
import { ServiceResult } from "../common/service-result.enum";

@Injectable({
  providedIn: 'root'
})
export class DeviceInfoService implements DeviceInfoServiceProvider {
  private readonly DEFAULT_CLOUD_DB_COLUMNS_ROUTE = `${environment.apiUrl}/master/device-info/datatable-columns`;
  private readonly MASTER_DEVICE_INFO_ROUTE = `${environment.apiUrl}/master/device-info`;
  private readonly MASTER_DEVICE_INFO_FILTERS_ROUTE = `${environment.apiUrl}/master/device-info/datatable-column-settings`;
  private readonly ADMIN_DEVICE_INFO_ROUTE = `${environment.apiUrl}/admin/device-info`;
  private readonly ADMIN_DEVICE_INFO_FILTERS_ROUTE = `${environment.apiUrl}/admin/device-info/datatable-column-settings`;

  private readonly MASTER_REPORT_ACTIVITY = `${environment.apiUrl}/master/reports/activity`;
  private readonly MASTER_REPORT_ACTIVITY_SUMMARY = `${environment.apiUrl}/master/reports/activity-summary`;
  private readonly MASTER_UPH_SETTINGS = `${environment.apiUrl}/master/settings/uph-settings`;

  constructor(
    private http: HttpClient,
    private notify: NotificationService,
    private clipboard: Clipboard,
    private authService: AuthService,
  ) { }

  private getResponseType(f: 'csv' | 'excel'): 'json' {
    let result = 'arrayBuffer';

    if (f === 'csv')
      result = 'text';

    return result as 'json';
  }

  private buildHttpParamsWithPages(
    queryParameters: DeviceInfoQueryParams,
    page: number,
    size: number,
  ): HttpParams {
    let params = this.buildHttpParams(
      queryParameters,
    );

    params = params.append('page', page);
    params = params.append('size', size);

    return params;
  }

  private buildHttpParams(
    queryParameters: DeviceInfoQueryParams,
    format: 'csv' | 'excel' | undefined = undefined,
  ): HttpParams {
    const { dateRange, keyword, gradingDataExportOnly } = queryParameters;
    const { start, end } = dateRange;
    let params = new HttpParams();

    params = params.append('startDate', start);
    params = params.append('endDate', end);

    if (format)
      params = params.append('format', format);

    if (keyword)
      params = params.append('keyword', keyword);

    if (gradingDataExportOnly)
      params = params.append('gradingDataExportOnly', true);

    Object.entries(queryParameters.columns || {}).forEach(([field, value]) => {
      if (value !== null && value !== undefined && value !== '') {
        params = params.append(field, value);
      }
    });

    return params;
  }

  async copyToClipboard(
    queryParameters: DeviceInfoQueryParams,
  ) {
    const format = 'csv';
    const url = this.getDeviceInfoRoute();
    const params = this.buildHttpParams(
      queryParameters,
      format,
    );

    try {
      const result = await firstValueFrom(this.http.get(url, {
        params,
        responseType: this.getResponseType(format),
      }));

      if (result) {
        this.clipboard.copy(result.toString());
      }
    } catch (err) {
      console.error(err);
      this.notify.error(`Failed to get device info page`);

      return;
    }
  }

  async downloadDeviceInfoReport(
    dateRange: DateRange,
    latest: boolean,
    filterId: string | undefined,
  ): Promise<void> {
    const start = toDateString(dateRange.start);
    const end = toDateString(dateRange.end);
    const params = {
      dateRange: {
        start,
        end,
      },
      columns: {
        columnSettingsId: filterId,
        latest: String(latest),
      },
      keyword: undefined,
    }

    this.downloadDeviceInfo(params, DownloadFormat.CSV);
  }

  async downloadDeviceInfo(
    queryParameters: DeviceInfoQueryParams,
    format: DownloadFormat,
  ) {
    const url = this.getDeviceInfoRoute();
    const params = this.buildHttpParams(
      queryParameters,
      format,
    );

    try {
      const now = moment();
      const extension = getExtension(format);
      const filename = `deviceinfo_${now.format()}${extension}`;
      // const contentType = getContentType(format);
      const response = await firstValueFrom(this.http.get<Blob>(url, {
        params,
        responseType: this.getResponseType(format),
        observe: 'response',
      }));

      if (response) {
        downloadFiles(response, filename, format, this.notify);
      }

      return;
    } catch (err) {
      console.error(err);
      this.notify.error(`Failed to get device info page`);

      return;
    }
  }


  getDeviceInfo(
    queryParameters: DeviceInfoQueryParams,
    pageIndex: number = 0,
    pageSize: number = 10,
    // sort: string = 'updatedAt',
    // direction: string = 'desc',
  ): Observable<any> {
    const url = this.getDeviceInfoRoute();

    let params = this.buildHttpParamsWithPages(
      queryParameters,
      pageIndex,
      pageSize,
    );

    try {
      const result = this.http.get(url, {
        params,
      }).pipe(shareReplay(1));

      return result;
    } catch (err) {
      console.error(err);
      this.notify.error(`Failed to get device info page`);

      throw err;
    }
  }

  async getFilters(): Promise<SavedColumnSettings[] | undefined> {
    const url = this.getCloudDBFiltersRoute();

    try {
      const filters = await this.http.get(url).toPromise() as SavedColumnSettings[];

      return filters;
    } catch (err) {
      this.notify.error(`Couldn't get saved filters`);

      return undefined;
    }
  }

  async saveFilter(columnSettings: ColumnSettingsCreationRequest): Promise<SavedColumnSettings | undefined> {
    const url = this.getCloudDBFiltersRoute();

    return this.http.post(url, columnSettings).toPromise().then(response => {
      this.notify.success(`Filter "${columnSettings.name}" was saved successfully.`);

      return response as SavedColumnSettings;
    }).catch(() => {
      this.notify.error(`Filter "${columnSettings.name}" failed to be saved.`);

      return undefined;
    });
  }

  async updateColumnSettings(id: string, settings: ColumnSettingsCreationRequest) {
    const url = `${this.getCloudDBFiltersRoute()}/${id}`;

    try {
      const result = await this.http.put(url, settings).toPromise();

      return settings;
    } catch (err) {
      console.error(err);

      return undefined;
    }
  }

  async getDeviceById(id: number, type: string) {

    const endpoint = (type === 'mac') ? 'machineDiagnostic/getMacDeviceById' : 'master/getDeviceById';
    const url = `${environment.apiUrl}/${endpoint}/${id}`;
    try {
      return await this.http.get(url).toPromise();
    } catch (err) {
      console.error(err);
      return undefined;
    }
  }

  async deleteColumnSettings(id: string) {
    const url = `${this.getCloudDBFiltersRoute()}/${id}`;

    try {
      await this.http.delete(url).toPromise();
      this.notify.success(`Filter deleted.`);
    } catch (err) {
      console.error(err);
      this.notify.success(`Failed to delete filter.`);
    }
  }


  async getDeviceInfoColumnSettings(): Promise<SavedColumnSettings[]> {
    const url = this.getCloudDBFiltersRoute();

    try {
      const result = await this.http.get(url).toPromise();

      return result as SavedColumnSettings[];
    } catch (err) {
      console.error(err);

      return [{
        id: 'default',
        name: 'Default',
        visible: DEVICE_INFO_COLUMNS,
        hidden: [] as ColumnConfiguration[],
      }];
    }
  }

  async getDeviceInfoColumns(): Promise<ColumnConfiguration[]> {
    const url = this.getCloudDBDefaultColumnRoute();

    try {
      const result = await this.http.get(url).toPromise();

      return result as ColumnConfiguration[];
    } catch (err) {
      console.error(err);

      return DEVICE_INFO_COLUMNS;
    }
  }

  public async getMonthlyReportList(page: number, size: number): Promise<Page<any> | undefined> {
    const url = `${environment.apiUrl}/master/reports/monthly`;
    const params = new HttpParams()
      .append('page', page)
      .append('size', size)
      .append('type', 'device_info');

    try {
      const result = await this.http.get(url, { params }).toPromise();

      return result as Page<any>;
    } catch (err) {
      console.error(err);

      return;
    }
  }


  private getCloudDBFiltersRoute(): string {
    if (this.allMastersContext()) {
      return this.ADMIN_DEVICE_INFO_FILTERS_ROUTE;
    }

    return this.MASTER_DEVICE_INFO_FILTERS_ROUTE;
  }

  private getCloudDBDefaultColumnRoute(): string {
    return this.DEFAULT_CLOUD_DB_COLUMNS_ROUTE;
  }

  private getDeviceInfoRoute(): string {
    if (this.allMastersContext()) {
      return this.ADMIN_DEVICE_INFO_ROUTE;
    }

    return this.MASTER_DEVICE_INFO_ROUTE;
  }

  private allMastersContext(): boolean {
    return this.authService.isAdminContext()
      && this.authService.isAllMasterContext();
  }
  public async getActivityReportSummary(
    startDate: Moment,
    endDate: Moment,
    entityType: ReportEntityType,
    countType: ReportCountType,
  ) {
    const url = this.MASTER_REPORT_ACTIVITY_SUMMARY;
    const DATE_FORMAT = 'YYYY-MM-DD';
    let params = new HttpParams()
      .append('startDate', startDate.format(DATE_FORMAT))
      .append('endDate', endDate.format(DATE_FORMAT))
      .append('entityType', entityType)
      .append('countType', countType);

    try {
      const result = await firstValueFrom(this.http.get(url, {
        params,
      }));

      return result as any[];
    } catch (err) {
      console.error(err);
      this.notify.error(`Failed to get activity summary info`);

      return;
    }
  }

  public async getActivityReport(
    startDate: Moment,
    endDate: Moment,
    entityType: ReportEntityType,
    countType: ReportCountType,
    searchTerm: string | null | undefined,
  ) {
    const url = this.MASTER_REPORT_ACTIVITY;
    const DATE_FORMAT = 'YYYY-MM-DD';
    let params = new HttpParams()
      .append('startDate', startDate.format(DATE_FORMAT))
      .append('endDate', endDate.format(DATE_FORMAT))
      .append('entityType', entityType)
      .append('countType', countType);

    if (searchTerm) {
      params = params.append('search', searchTerm);
    }

    try {
      const result = await firstValueFrom(this.http.get(url, {
        params,
      }));

      return result as any[];
    } catch (err) {
      console.error(err);
      this.notify.error(`Failed to get activity info`);

      return;
    }
  }

  public async updateUPHSettings(targetUPH: number | null, minimumUPH: number | null) {
    const url = this.MASTER_UPH_SETTINGS;
    const data = { targetUPH, minimumUPH };

    try {
      const result = await this.http.put(url, data).toPromise()
      this.notify.success(`UPH Settings saved`);

      return ServiceResult.SAVED;
    } catch(err) {
      console.error(err);

      this.notify.error(`Failed to update UPH Settings`);

      return ServiceResult.ERROR;
    }
  }

  public async getUPHSettigs(): Promise<{ targetUPH: number | null, minimumUPH: number | null } | undefined> {
    const url = this.MASTER_UPH_SETTINGS;

    try {
      const r = await this.http.get(url).toPromise() as { targetUPH: number | null, minimumUPH: number | null };

      return r;
    } catch(err) {
      console.error(err);
      this.notify.error(`Failed to update UPH settings`);

      return;
    }
  }

  async downloadActivityReport(
    startDate: Moment,
    endDate: Moment,
    entityType: ReportEntityType,
    countType: ReportCountType,
    searchTerm: string | null | undefined,
    format: DownloadFormat,
  ) {
    const url = this.MASTER_REPORT_ACTIVITY;
    const DATE_FORMAT = 'YYYY-MM-DD';
    const now = moment();
    const extension = getExtension(format);
    const filename = `activity_report_${now.format()}${extension}`;
    let params = new HttpParams()
      .append('startDate', startDate.format(DATE_FORMAT))
      .append('endDate', endDate.format(DATE_FORMAT))
      .append('entityType', entityType)
      .append('countType', countType)
      .append('format', format);

    if (searchTerm) {
      params = params.append('search', searchTerm);
    }

    try {
      const response = await firstValueFrom(this.http.get<Blob>(url, {
        params,
        responseType: this.getResponseType(format),
        observe: 'response',
      }));

      if (response) {
        downloadFiles(response, filename, format, this.notify);
      }

      return;
    } catch (err) {
      console.error(err);
      this.notify.error(`Failed to download activity report`);

      return;
    }
  }

  async copyActivityReport(
    startDate: Moment,
    endDate: Moment,
    entityType: ReportEntityType,
    countType: ReportCountType,
    searchTerm: string | null | undefined,
  ) {
    const format = DownloadFormat.CSV;
    const url = this.MASTER_REPORT_ACTIVITY;
    const DATE_FORMAT = 'YYYY-MM-DD';
    let params = new HttpParams()
      .append('startDate', startDate.format(DATE_FORMAT))
      .append('endDate', endDate.format(DATE_FORMAT))
      .append('entityType', entityType)
      .append('countType', countType)
      .append('format', format);

    if (searchTerm) {
      params = params.append('search', searchTerm);
    }

    try {
      const response = await firstValueFrom(this.http.get<Blob>(url, {
        params,
        responseType: this.getResponseType(format),
        observe: 'response',
      }));

      if (response) {
        this.clipboard.copy(response.toString());
      }

      return;
    } catch (err) {
      console.error(err);
      this.notify.error(`Failed to copy activity report`);

      return;
    }
  }
}

