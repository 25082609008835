import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-json-viewer-component',
  templateUrl: './json-viewer.component.html',
  styleUrls: ['./json-viewer.component.css']
})
export class JSONViewerComponent {
  json: { [key: string]: any };
  oemJson: any = {};
  isOemParts: boolean = false; // Adjust the type according to your data structure
  field: string;
  constructor(
    private dialogRef: MatDialogRef<JSONViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { json: object, field: string },
  ) {
    const { json, field } = data;
    if(field == "Battery Info"){
      this.json = this.transformBatteryInfo(json);
    } else if (field === "OEM Parts") {
      this.json = this.transformOemPartsInfo(json);
      this.oemJson = json;
      this.isOemParts = true;
    } else {
      this.json = json;
    }
    this.field = field;
  }

  // Function to transform drainInfo object
  private transformBatteryInfo(json: any): any {
    const batteryInfo = json;
    if (batteryInfo.hasOwnProperty('drainInfo')) {
      const drainInfoString = batteryInfo['drainInfo'];

      const drainInfo = JSON.parse(drainInfoString.replace(/'/g, '"'));

      for (const key in drainInfo) {
        if (drainInfo.hasOwnProperty(key)) {
          batteryInfo[key + ' Min'] = drainInfo[key];
        }
      }
      delete batteryInfo['drainInfo'];
    }
    return batteryInfo;
  }

  // New function to transform OEM Parts Data
  private transformOemPartsInfo(json: any): any {
    const transformedData: any = {
      status: json.status,
      parts: []
    };

    // Map data to an array format for easier rendering
    json.data.forEach((part: any) => {
      transformedData.parts.push({
        status: part.status,
        name: part.name,
        factorySerial: part.factorySerial,
        currentSerial: part.currentSerial,
        notice: part.notice || ''
      });
    });
    return transformedData;
  }

  // Helper function to get object keys
  getObjectKeys(obj: { [key: string]: any }): string[] {
    return Object.keys(obj);
  }
}
