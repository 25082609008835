<mat-card>
  <mat-card-header>
    <mat-card-title>Confirm Delete {{data.name}}</mat-card-title>

  </mat-card-header>
  <mat-progress-bar value="100" *ngIf="!deleting"></mat-progress-bar>
  <mat-progress-bar mode="indeterminate" *ngIf="deleting"></mat-progress-bar>
  <mat-card-content>

    <img [src]="data.image">
    <br>
    Are you sure you want to delete this customization profile?
  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button (click)="dialogRef.close()">Cancel</button>
    <div class="flex-spacer"></div>
    <button mat-raised-button color="warn" (click)="deleteProfile()" [disabled]="deleting">Delete</button>
  </mat-card-actions>
</mat-card>
