import moment, { Moment } from 'moment-timezone';
import { OnInit, EventEmitter, Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PaginatorData } from '../interfaces/paginatorData.interface';
import { DateRange } from '../common/date';
import { DeviceInfoServiceProvider } from '../interfaces/device-info-service.interface';
import { SavedColumnSettings } from "../interfaces/columnSettings.interface";
import { CustomReportService } from "../services/custom-reports.service";

@Component({
  selector: 'app-custom-reports-tab',
  templateUrl: './custom-reports-tab.component.html',
  styleUrls: ['./custom-reports-tab.component.css']
})
export class CustomReportsTabComponent implements OnInit {
  @Input() openSettingsEmitter!: EventEmitter<boolean>;
  @Input() deviceInfoService!: DeviceInfoServiceProvider;

  reports: any[] = [];
  emailStatusCheckboxDisabled = false;
  emailsEnabled: boolean = false;
  paginatorData: PaginatorData = {
    currentPage: 0,
    length: 0,
    pageIndex: 0,
    pageOptions: [10, 20,50],
    pageSize: 10,
  };
  dateRangeFormGroup: FormGroup;
  startDateFormControl: FormControl<Moment | null>;
  endDateFormControl: FormControl<Moment | null>;
  filterId: string = 'default';
  columns = [
    {
      displayName: 'Filename',
      field: 'filename',
    },
    {
      displayName: 'Added On',
      field: 'createdAt',
    },
    {
      displayName: 'Download Link',
      field: 'fileURL',
    },
  ];
  displayedColumns = this.columns.map(c => c.field);
  recordsToShow: 'all' | 'latest' = 'all';
  filters: SavedColumnSettings[] = [];

  constructor(
    private customReportService: CustomReportService,

  ) {
    this.filters = [];
    this.startDateFormControl = new FormControl<Moment | null>(moment(), Validators.required);
    this.endDateFormControl = new FormControl<Moment | null>(moment(), Validators.required);
    this.dateRangeFormGroup = new FormGroup({
      start: this.startDateFormControl,
      end: this.endDateFormControl,
    });
  }

  public updateEmailStatus() {
    this.emailStatusCheckboxDisabled = true;
    this.customReportService
      .updateAutomaticEmailSettingsStatus(this.emailsEnabled)
      .finally(() => {
        this.emailStatusCheckboxDisabled = false;
      });
  }

  public getEmailStatus() {
    this.emailStatusCheckboxDisabled = true;
    this.customReportService
      .getAutomaticEmailSettingsStatus()
      .then(enabled => {
        this.emailsEnabled = !!enabled;
      })
      .finally(() => {
        this.emailStatusCheckboxDisabled = false;
      });
  }

  public onDownload() {
    const start = this.startDateFormControl.getRawValue();
    const end = this.endDateFormControl.getRawValue();
    const latest = this.recordsToShow === 'latest';

    if (start && end) {
      const range: DateRange = { start, end };
      const filterId = this.filterId === 'default' ? undefined : this.filterId;

      this.deviceInfoService.downloadDeviceInfoReport(range, latest, filterId);
    }
  }

  public onClearFilter() {
    this.startDateFormControl.setValue(moment());
    this.endDateFormControl.setValue(moment());
  }

  public onOpenAutomaticEmailSettings() {
    this.openSettingsEmitter.emit(true);
  }

  public ngOnInit() {
    this.deviceInfoService.getFilters().then(filters => {
      this.filters = filters || [];
    });
    this.getEmailStatus();
    this.getMonthlyReports();
  }

  loading = false;

  private async getMonthlyReports() {
    this.loading = true;

    const page = await this.deviceInfoService.getMonthlyReportList(
      this.paginatorData.pageIndex,
      this.paginatorData.pageSize,
    );

    this.loading = false;

    if (page) {
      const { data, totalItems, currentPage } = page;

      this.paginatorData.length = totalItems;
      this.reports = data;
    }
  }

  public onPaginate(event: any) {
    this.paginatorData.pageIndex = event.pageIndex;
    this.paginatorData.pageSize = event.pageSize;

    this.getMonthlyReports();
  }
}
