import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { debounceTime, distinctUntilChanged, firstValueFrom } from 'rxjs';
import { DeleteConfirmationComponent } from '../delete-confirmation/delete-confirmation.component';

@Component({
  selector: 'app-custom-test',
  templateUrl: './custom-test.component.html',
  styleUrls: ['./custom-test.component.css']
})
export class CustomTestComponent implements OnInit, AfterViewInit {
  @Input() customTests!: any[];
  @Output() customTestEmitter: EventEmitter<Partial<ICustomTest>[]> = new EventEmitter();
  @Output() creatingCustomTestEmitter: EventEmitter<boolean> = new EventEmitter();
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  displayedColumns = ['testName', 'instructions', 'platform', 'modelNumber', 'modifiedDate', 'buttons'];
  platforms = ['iOS', 'Android'];
  createOrModify = false;
  editMode = false;
  customTestFormGroup = this.fb.group({
    id: null,
    testName: '',
    instructions: '',
    platform: '',
    modelNumber: '',
    modifiedDate: null,
  });
  searchCustomTestsControl = this.fb.control('');
  customTestsToShow: any[] = [];
  customTestsDataSource = new MatTableDataSource(this.customTestsToShow);
  prevoiusValue: any = {};

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
  ) { }

  ngAfterViewInit(): void {
    this.customTestsDataSource.sort = this.sort;
    this.customTestFormGroup.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(500)
    ).subscribe((vc) => {
      if (this.editMode) {
        const indexToModify = this.customTests.findIndex(ct => ct.id == vc.id);
        this.customTests[indexToModify] = { ...vc, modifiedDate: new Date() };
        this.customTestsDataSource.data = this.customTests;
      } else {
        this.customTests[this.customTests.length - 1] = { ...vc, modifiedDate: new Date(), id: this.customTests.length };
        this.customTestsDataSource.data = this.customTests;
      }
      this.customTestEmitter.emit(this.customTests);
    });
  }

  ngOnInit(): void {
    this.customTestsDataSource.data = this.customTests;
    this.searchCustomTestsControl.valueChanges.subscribe(() => {
      this.customTestsToShow = this.filterStationList(this.customTests);
      this.customTestsDataSource.data = this.customTestsToShow;
      this.customTestsDataSource.sort = this.sort;
    });
  }

  private getSearchTerm(): string | undefined {
    return this.searchCustomTestsControl.getRawValue()?.trim().toLowerCase();
  }

  private filterStationList(customTests: any[]): any[] {
    const term = this.getSearchTerm();

    if (term) {
      customTests = customTests.filter(test => {
        const testName = test.testName.toLowerCase();
        return testName.includes(term);
      });
    }

    return customTests;
  }

  public deleteCustomTest(element: any): void {
    const indexToDelete = this.customTests.findIndex(ct => ct.id == element.id)

    firstValueFrom(this.dialog.open(DeleteConfirmationComponent, {
      data: {
        message: 'test',
        deleteService: (id: number) => this.customTests.splice(id, 1),
        id: indexToDelete,
      }
    }).afterClosed()).then(() => {
      this.customTestsDataSource.data = this.customTests;
      this.customTestEmitter.emit(this.customTests);
    });
  }

  public editCustomTest(element: any) {
    const indexFound = this.customTests.findIndex(ct => ct.id == element.id);
    this.prevoiusValue = this.customTests[indexFound];
    this.customTestFormGroup.setValue(this.customTests[indexFound]);
    this.createOrModify = true;
    this.editMode = true;
    this.customTestEmitter.emit(this.customTests);
    this.searchCustomTestsControl.reset();
    this.creatingCustomTestEmitter.emit(true);
  }

  public saveTest() {
    if (this.editMode) {
      const indexFound = this.customTests.findIndex(ct => ct.id == this.prevoiusValue.id)
      this.customTests[indexFound] = this.customTestFormGroup.value;
      this.customTestsDataSource.data = this.customTests;
    } else {
      this.customTests[this.customTests.length - 1] = { ...this.customTestFormGroup.value, modifiedDate: new Date(), id: this.customTests.length };
      this.customTestsDataSource.data = this.customTests;
    }
    this.customTestsDataSource = this.customTestsDataSource;
    this.customTestFormGroup.reset({}, { emitEvent: false });
    this.createOrModify = false;
    this.editMode = false;
    this.customTestEmitter.emit(this.customTests);
    this.creatingCustomTestEmitter.emit(false);
  }

  public resetCustomTest() {
    this.customTestFormGroup.reset({}, { emitEvent: false });
    this.createOrModify = false;
    if (!this.editMode) {
      this.customTests.splice(this.customTests.length - 1, 1);
      this.customTestsDataSource.data = this.customTests;
    } else {
      const indexFound = this.customTests.findIndex(ct => ct.id == this.prevoiusValue.id);
      this.customTests[indexFound] = { ...this.prevoiusValue };
      this.customTestsDataSource.data = this.customTests;
    }
    this.customTestEmitter.emit(this.customTests);
    this.creatingCustomTestEmitter.emit(false);
    this.editMode = false;
  }

  public fnCreateOrModify() {
    this.createOrModify = true;
    this.customTests.push({ ...this.customTestFormGroup.value, modifiedDate: new Date(), id: this.customTests.length + 1 })
    this.customTestsDataSource.data = this.customTests;
    this.customTestEmitter.emit(this.customTestsDataSource.data);
    this.creatingCustomTestEmitter.emit(true);
  }


  public getData(): any[] {
    return this.customTests;
  }
}

interface ICustomTest {
  id: number | null;
  testName: string | null;
  instructions: string | null;
  platform: string | null;
  modelNumber: string | null;
}
