<mat-card class="delete-dialog-card">
  <mat-card-header>
    <mat-card-title class="delete-title">Confirm Delete</mat-card-title>
  </mat-card-header>

  <mat-divider></mat-divider>

  <mat-card-content class="delete-content">
    <h3 class="delete-heading">Are you sure you want to delete this build?</h3>
    <p><strong>Platform:</strong> {{ data.platform }}</p>
    <p><strong>Version:</strong> {{ data.buildNumber }}</p>
    <p><strong>Type:</strong> {{ data.type }}</p>
    <p *ngIf="data.buildKey"><strong>Build File: </strong><a [href]="data.buildUrl" download>{{ data.buildKey.split('/').pop() }}</a></p>
    <p *ngIf="data.otaKey"><strong>OTA File: </strong><a [href]="data.otaUrl" download>{{ data.otaKey.split('/').pop() }}</a></p>
  </mat-card-content>

  <mat-divider></mat-divider>

  <mat-card-actions class="delete-actions">
    <button mat-raised-button (click)="dialogRef.close()" type="button">Cancel</button>
    <button mat-raised-button color="warn" (click)="deleteBuild()" [disabled]="deleting"
      class="delete-button">Delete</button>
  </mat-card-actions>
</mat-card>