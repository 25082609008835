import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { OnInit, EventEmitter, Input, ViewChild, Component, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Day } from '../common/day.enum';
import { DeviceInfoType } from '../common/device-info-type.enum';
import { RecordsToShow } from '../common/records-to-show.enum';
import { DownloadFormat } from '../common/download';
import { AutomaticEmailSettingsCreationPayload } from "./creation-payload";
import { CustomReportService } from "../services/custom-reports.service";
import { LicenseService } from "../services/license.service";
import { DeviceInfoService } from "../services/deviceInfo.service";
import { MacDeviceInfoService } from "../services/macDeviceInfo.service";
import { SavedColumnSettings } from "../interfaces/columnSettings.interface";
import { Warehouse } from "../interfaces/warehouse.interface";
import { MasterService } from "../services/master.service";
import { ServiceResult } from "../common/service-result.enum";
import { NotificationService } from "../services/notification.service";
import { SendTestEmailPayload } from "./test-email-payload";

type User = {
  id: string,
  name: string,
}

@Component({
  selector: 'app-automatic-email-settings',
  templateUrl: './automatic-email-settings.component.html',
  styleUrls: ['./automatic-email-settings.component.css']
})
export class AutomaticEmailSettingsComponent implements OnInit {
  @Input() finishedEmitter!: EventEmitter<any>; 
  @Input() settingsId!: string | undefined; 

  @ViewChild('userInput') userInput!: ElementRef<HTMLInputElement>;

  formReady: boolean = false;
  allUsers: User[] = [];
  selectableUsers: User[] = this.allUsers;
  selectedUsers: User[] = [];

  userCtrl = new FormControl('');
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredUsers!: User[];

  deviceFilterId: string | null = 'default';
  macFilterId: string | null = 'default';
  warehouseId: string = 'all';
  recordsToShow: RecordsToShow = RecordsToShow.ALL;
  warehouses: Warehouse[] = [];
  filters: SavedColumnSettings[] = [];
  macFilters: SavedColumnSettings[] = [];

  taskTime: string = '12:00';
  mon: boolean = true;
  tue: boolean = false;
  wed: boolean = false;
  thu: boolean = false;
  fri: boolean = false;
  sat: boolean = false;
  sun: boolean = false;

  excel: boolean = true;
  csv: boolean = false;

  device: boolean = true;
  mac: boolean = false;

  constructor(
    private customReportService: CustomReportService,
    private licenseService: LicenseService,
    private deviceInfoService: DeviceInfoService,
    private macDeviceInfoService: MacDeviceInfoService,
    private masterService: MasterService,
    private notificationService: NotificationService,
  ) {
    this.getUsers();
    this.deviceInfoService.getDeviceInfoColumnSettings().then(filters => {
      this.filters = filters;
    });
    this.macDeviceInfoService.getDeviceInfoColumnSettings().then(filters => {
      this.macFilters = filters;
    });
    this.masterService.getWarehousesV3().then(warehouses => {
      if (warehouses)
        this.warehouses = warehouses;
    });

    this.userCtrl.valueChanges.subscribe(term => {
      this.filteredUsers = term ? this._filter(term) : this.selectableUsers;
    });
  }

  async getUsers() {
    this.licenseService.getMasterUSers(true).then(users => {
      this.allUsers = users;
      this.selectableUsers = users;
      this.setFiltered();
    });
  }

  setFiltered() {
    this.filteredUsers = this.selectableUsers;
  }

  private _filter(userId: string): User[] {
    return this.selectableUsers.filter(user => user.name?.toLowerCase().includes(userId.toLowerCase()));
  }

  add(event: any): void {
    const value = (event.value || '').trim();

    if (value) {
      const index = this.selectableUsers.indexOf(value);

      if (index >= 0) {
        this.selectableUsers.splice(index, 1);
        this.selectedUsers.push(value);
      }
    }

    // Clear the input value
    event.chipInput!.clear();

    this.userCtrl.setValue(null);
  }

  remove(user: User): void {
    const index = this.selectedUsers.findIndex(u => u.id === user.id);

    if (index >= 0) {
      this.selectedUsers.splice(index, 1);
      this.selectableUsers.push(user);
      this.setFiltered();
    }
  }

  selected(event: any): void {
    const userId = event.option.value;
    const index = this.selectableUsers.findIndex(u => u.id === userId);

    this.userInput.nativeElement.value = '';
    this.userCtrl.setValue(null, { emitEvent: false });

    if (index >= 0) {
      const user = this.selectableUsers[index];

      this.selectedUsers.push(user);
      this.selectableUsers.splice(index, 1);

      this.setFiltered();
    }
  }

  onCancel() {
    this.finishedEmitter.emit(ServiceResult.CANCELED);
  }

  private checkTestPayload(payload: SendTestEmailPayload): string | undefined {
    if (payload.users.length === 0) {
      return 'The user list cannot be empty.';
    }

    const { mac, device } = payload;

    if (![device, mac].some(e => e)) {
      return 'You must select at least one type of export: Mac Devices or Device Info'
    }

    const { excel, csv } = payload;

    if (![excel, csv].some(e => e)) {
      return 'You must select at least one format: CSV or Excel'
    }

    return undefined;
  }


  private checkPayload(payload: AutomaticEmailSettingsCreationPayload): string | undefined {
    if (payload.users.length === 0) {
      return 'The user list cannot be empty.';
    }

    const { mon, tue, wed, thu, fri, sat, sun } = payload;

    if (![mon, tue, wed, thu, fri, sat, sun].some(d => d)) {
      return 'You must select at least one day for the report';
    }

    const { mac, device } = payload;

    if (![device, mac].some(e => e)) {
      return 'You must select at least one type of export: Mac Devices or Device Info'
    }

    const { excel, csv } = payload;

    if (![excel, csv].some(e => e)) {
      return 'You must select at least one format: CSV or Excel'
    }

    return undefined;
  }

  async onSave() {
    const payload = this.getPayload();
    let r;

    const errorMsg = this.checkPayload(payload);

    if (errorMsg) {
      this.notificationService.warn(errorMsg);

      return;
    }

    if (this.settingsId) {
      r = await this.customReportService.updateAutomaticEmailSettings(this.settingsId, payload);
    } else {
      r = await this.customReportService.createAutomaticEmailSettingsList(payload);
    }

    this.finishedEmitter.emit(r);
  }

  async sendTestEmail() {
    const payload: SendTestEmailPayload = this.getTestPayload();
    let r;

    const errorMsg = this.checkTestPayload(payload);

    if (errorMsg) {
      this.notificationService.warn(errorMsg);

      return;
    }

    this.customReportService.sendTestEmail(payload);

    this.notificationService.success('You should receive the test email in a few seconds.');
  }


  private getTestPayload(): SendTestEmailPayload {
    return {
      deviceColumnSettingsId:
        this.deviceFilterId === 'default' || !this.device
          ? undefined
          : this.deviceFilterId,
      macColumnSettingsId:
        this.macFilterId === 'default' || !this.mac
          ? undefined
          : this.macFilterId, 
      warehouseId: this.warehouseId === 'all' ? undefined : this.warehouseId,
      latest: this.recordsToShow === RecordsToShow.LATEST,
      csv: this.csv,
      excel: this.excel,
      mac: this.mac,
      device: this.device,
      time: this.taskTime,
      users: this.selectedUsers.map(u => u.id),
    };
  }

  private getPayload(): AutomaticEmailSettingsCreationPayload {
    return {
      deviceColumnSettingsId:
        this.deviceFilterId === 'default' || !this.device
          ? undefined
          : this.deviceFilterId,
      macColumnSettingsId:
        this.macFilterId === 'default' || !this.mac
          ? undefined
          : this.macFilterId, 
      warehouseId: this.warehouseId === 'all' ? undefined : this.warehouseId,
      latest: this.recordsToShow === RecordsToShow.LATEST,
      csv: this.csv,
      excel: this.excel,
      mac: this.mac,
      device: this.device,
      mon: this.mon,
      tue: this.tue,
      wed: this.wed,
      thu: this.thu,
      fri: this.fri,
      sat: this.sat,
      sun: this.sun,
      time: this.taskTime,
      users: this.selectedUsers.map(u => u.id),
    };
  }

  private setupForm() {
    if (this.settingsId) {
      return this.customReportService.getAutomaticEmailSettings(this.settingsId)
      .then(settings => {
        if (settings) {
          this.deviceFilterId = settings.deviceColumnSettingsId || 'default';
          this.macFilterId = settings.macColumnSettingsId || 'default';
          this.warehouseId = settings.warehouseId || 'all';
          this.recordsToShow = settings.latest ? RecordsToShow.LATEST : RecordsToShow.ALL;
          this.csv = settings.csv;
          this.excel = settings.excel;
          this.mac = settings.mac;
          this.device = settings.device;
          this.mon = settings.mon;
          this.tue = settings.tue;
          this.wed = settings.wed;
          this.thu = settings.thu;
          this.fri = settings.fri;
          this.sat = settings.sat;
          this.sun = settings.sun;
          this.taskTime = settings.time.substring(0, 5);
          this.selectedUsers = settings.users
            .map(id => this.allUsers.find(u => u.id === id))
            .filter(u => u !== undefined) as User[];

          this.setFiltered();
        }
      });
    } else {
      return Promise.resolve();
    }
  }

  public ngOnInit() {
    this.getUsers().then(() => {
      return this.setupForm();
    }).then(() => {
      this.formReady = true;
    });
  }
}

