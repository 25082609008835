import { Input, Output, EventEmitter, Component, AfterViewInit } from '@angular/core';
import { AssignmentPayload } from '../interfaces/assignment-payload.interface';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-assignment-express',
  templateUrl: './assignment-express.component.html',
  styleUrls: ['./assignment-express.component.css']
})
export class AssignmentExpressComponent implements AfterViewInit {

  @Input() assignmentPayload!: AssignmentPayload;
  @Output() assignmentPayloadChange = new EventEmitter<AssignmentPayload>();
  allStations = false;
  searchStationFormControl: FormControl = new FormControl('');
  stationsToShow: any[] = [];

  constructor() {
    this.searchStationFormControl.statusChanges.subscribe(() => {
      this.onSearchTermChange();
    });
  }

  ngAfterViewInit() {
    this.allStations = this.allStationsAssigned();
    this.stationsToShow = this.assignmentPayload.stations!;
  }

  toggleAllStations(event: boolean) {
    this.assignmentPayload?.stations?.forEach(station => station.assigned = event);
  }

  updateAllStations() {
    const allStations = this.assignmentPayload.stations?.filter(station => !station.assigned);
    if (!allStations?.length) {
      this.allStations = true;
    }
  }

  toggleAllWarehouses(event: boolean) {
    this.assignmentPayload.warehouses?.forEach(warehouse => warehouse.assigned = event);
  }

  someStationsAssigned() {
    const found = this.assignmentPayload.stations?.filter(station => station.assigned);

    return !!(found?.length && found.length !== this.assignmentPayload.stations?.length);
  }

  allStationsAssigned() {
    const found = this.assignmentPayload.stations?.filter(station => !station.assigned);
    return (!found?.length);
  }

  private onSearchTermChange() {
    const input: string = this.searchStationFormControl.getRawValue().trim();

    if (input) {
      this.stationsToShow = this.filterStations(input);
    }
    else {
      this.stationsToShow = this.assignmentPayload.stations!;
    }
  }

  private filterStations(name: string) {
    const stations = this.assignmentPayload.stations;

    return stations!.filter(station => {
      return station.name.toLowerCase().includes(name.toLowerCase());
    });
  }
}
