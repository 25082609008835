import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-advanced-settings',
  templateUrl: './advanced-settings.component.html',
  styleUrls: ['./advanced-settings.component.css']
})
export class AdvancedSettingsComponent implements OnInit, OnDestroy {
  @Input() advancedSettings!: Partial<AdvancedSettings>;
  @Output() changeEmitter = new EventEmitter<Partial<AdvancedSettings>>();

  advancedSettingsFormGroup = this.fb.group<Partial<AdvancedSettings>>(this.defaultAdvancedSettings());

  constructor(private fb: FormBuilder) {
  }

  ngOnInit(): void {
    let advancedSettings;

    if (Object.keys(this.advancedSettings).length) {
      advancedSettings = this.advancedSettings;
    } else {
      advancedSettings = this.defaultAdvancedSettings();
    }

    this.advancedSettingsFormGroup.get('sourceEnabled')?.valueChanges.subscribe(sourceEnabled => {
      if (!sourceEnabled) {
        this.advancedSettingsFormGroup.get('sourceURL')?.disable();
        this.advancedSettingsFormGroup.get('sourceAPIKey')?.disable();
      } else {
        this.advancedSettingsFormGroup.get('sourceURL')?.enable();
        this.advancedSettingsFormGroup.get('sourceAPIKey')?.enable();
      }
    });

    this.advancedSettingsFormGroup.get('resultsEnabled')?.valueChanges.subscribe(resultsEnabled => {
      if (!resultsEnabled) {
        this.advancedSettingsFormGroup.get('resultsURL')?.disable();
        this.advancedSettingsFormGroup.get('resultsAPIKey')?.disable();
      } else {
        this.advancedSettingsFormGroup.get('resultsURL')?.enable();
        this.advancedSettingsFormGroup.get('resultsAPIKey')?.enable();
      }
    });
    this.advancedSettingsFormGroup.get('labelEnabled')?.valueChanges.subscribe(labelEnabled => {
      if (!labelEnabled) {
        this.advancedSettingsFormGroup.get('labelURL')?.disable();
        this.advancedSettingsFormGroup.get('labelAPIKey')?.disable();
      } else {
        this.advancedSettingsFormGroup.get('labelURL')?.enable();
        this.advancedSettingsFormGroup.get('labelAPIKey')?.enable();
      }
    });

    this.advancedSettingsFormGroup.setValue(advancedSettings);
  }

  ngOnDestroy(): void {
    this.changeEmitter.emit(this.getData());
  }

  private defaultAdvancedSettings(): Partial<AdvancedSettings> {
    return {
      sourceURL: null,
      sourceAPIKey: null,
      sourceEnabled: false,
      labelURL: null,
      labelAPIKey: null,
      labelEnabled: false,
      resultsURL: null,
      resultsAPIKey: null,
      resultsEnabled: false,
      shopfloorEnabled: false,
      apiDataSharingEnabled: false,
    };
  }

  public getData(): Partial<AdvancedSettings> {
    return this.advancedSettingsFormGroup.getRawValue();
  }
}

type Settings = 'source' | 'results' | 'label';
type StringSettings = 'APIKey' | 'URL';
type BooleanSettings = 'Enabled'
type CustomStringSettings = `${Settings}${StringSettings}`
type CustomBooleanSettings = `${Settings}${BooleanSettings}`;

type AdvancedSettings = {
  [K in CustomStringSettings]: string | null | undefined;
} & {
  [K in CustomBooleanSettings]: boolean | null | undefined;
} & {
  shopfloorEnabled: boolean | null | undefined;
  apiDataSharingEnabled: boolean | null | undefined;
};

