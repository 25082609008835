import { NotificationService } from "../services/notification.service";
import * as fs from 'file-saver';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { Clipboard } from '@angular/cdk/clipboard';

export enum DownloadFormat {
  CSV = 'csv',
  EXCEL = 'excel',
}

export function getResponseType(f: DownloadFormat): 'json' {
  let result = 'arrayBuffer';

  if (f === DownloadFormat.CSV)
    result = 'text';

  return result as 'json';
}

export function getContentType(f: DownloadFormat) {
  if (f === DownloadFormat.CSV) return 'text/csv';
  return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
}

export function getExtension(f: DownloadFormat) {
  switch(f) {
    case DownloadFormat.CSV: return '.csv';
    default: return '.xlsx';
  }
}

export function download(
  request: Observable<HttpResponse<Blob>>,
  filename: string,
  format: DownloadFormat,
  notify: NotificationService,
) {
  try {
    return request.toPromise().then((response: HttpResponse<Blob> | undefined) => {
      if (response?.status === 200 && response?.body) {
        const contentType = getContentType(format);
        const blob = new Blob([response.body], { type: format });

        fs.saveAs(blob, filename, { autoBom: false } );
      }
    });
  } catch (err) {
    console.error(err);
    notify.error(`Failed to download info`);

    return Promise.resolve();
  }
}

export function downloadFiles(
  response: HttpResponse<Blob>,
  filename: string,
  format: DownloadFormat,
  notify: NotificationService,
) {
  try {
    if (response.status === 200 && response.body) {
      const dispositionHeader = response.headers.get('content-disposition');
      const dispositionMatch = dispositionHeader ? dispositionHeader.match(/filename="(.+)"/) : null;
      const suggestedFilename = dispositionMatch ? dispositionMatch[1] : filename;

      const file = new File([response.body], suggestedFilename, {
        type: getContentType(format),
      });

      const url = window.URL.createObjectURL(file);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = suggestedFilename;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      return ;
    }

    return;
  } catch (err) {
    console.error(err);
    notify.error(`Failed to download info`);

    return;
  }
}

export function copy(
  request: Observable<HttpResponse<Blob>>,
  clipboard: Clipboard,
) {
  return request.toPromise().then((response: HttpResponse<Blob> | undefined) => {
    const content = response?.body?.toString()

    if (content)
      clipboard.copy(content);
  });
}

function getFileExtension(format: 'csv' | 'excel'): string {
  switch (format) {
    case 'csv':
      return 'csv';
    case 'excel':
      return 'xlsx';
    default:
      return '';
  }
}
