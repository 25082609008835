import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.css']
})
export class DeleteConfirmationComponent {

  deleting = false;
  constructor(
    private notify: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: {
      message: string;
      deleteService: any;
      id: string;
    },
    public dialogRef: MatDialogRef<any>,
  ) {
  }

  async delete() {
    this.deleting = true;
    const response = await this.data.deleteService(this.data.id);

    if (response) {
      this.notify.success('Successfully deleted ' + this.data.message);
      this.deleting = false;
      this.dialogRef.close();
    }
  }
}
