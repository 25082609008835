import { HTTP_INTERCEPTORS, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { NotificationService } from '../services/notification.service';

@Injectable()
export class Interceptor implements HttpInterceptor {
  retryRequest: HttpRequest<any> | undefined;
  constructor(
    private router: Router,
    private authService: AuthService,
    private notify: NotificationService) { }

  /** Attempts to add the id token from current google session on ALL outgoing HTTP Requests
   *  This should be disabled is this UI makes any outbound HTTP calls that should NOT have the id token attached.
   *  You should wrap any http calls in authservice.init.subscribe to make sure you
   *  have a valid id token to send with the request
   * */

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.retryRequest = request;
    const authRequest = request;
    let tokenUser;
    let master;

    try {
      tokenUser = this.authService.getToken();
      master = this.authService.getSelectedMaster();
    } catch (err) {
    }

    const masterId = this.authService.masterId?.toString()

    if (this.authService.isAdminContext()) {
      const selectedMasterId = this.authService.getCurrentMasterId();
      const selectedWarehouseId = this.authService.getCurrentWarehouseId();

      const headers: Record<string, string> = {
        token_admin_cloud: tokenUser as string,
      };

      if (master?.id) {
        headers['x-phonecheck-master-id'] = master.id;
      }

      if (master?.v1MasterId) {
        headers['v1masterid'] = String(master.v1MasterId);
      }

      if (selectedWarehouseId) {
        headers['x-phonecheck-warehouse-id'] = selectedWarehouseId;
      }

      const newRequest = authRequest.clone({
        setHeaders: headers,
      });

      return next.handle(newRequest).pipe(catchError(error => {
        return this.handleResponseError(error);
      })) as Observable<HttpEvent<any>>;
    } else if (this.authService.isMasterContext()) {
      const newRequest = authRequest.clone({
        setHeaders: {
          token_master: tokenUser as string,
        }
      });

      return next.handle(newRequest).pipe(catchError(error => {
        return this.handleResponseError(error);
      })) as Observable<HttpEvent<any>>;
    } else if (this.authService.isUserContext()) {
      const warehouseId = this.authService.getSelectedWarehouse()?.id;

      const headers: Record<string, string> = {
        token_user: tokenUser as string,
      };

      if (warehouseId) {
        headers['x-phonecheck-warehouse-id'] = String(warehouseId);
      }

      const newRequest = authRequest.clone({
        setHeaders: headers,

      });

      return next.handle(newRequest).pipe(catchError(error => {
        return this.handleResponseError(error);
      })) as Observable<HttpEvent<any>>;
    } else {
      return next.handle(authRequest).pipe(catchError(error => {
        return this.handleResponseError(error);
      })) as Observable<HttpEvent<any>>;
    }
  }

  handleResponseError = (err: HttpErrorResponse) => {

    if (err.status === 403) {
      // this.notify.error('Error status 403');
    }

    if (err.status === 404) {
      this.notify.error('Error status 404: Not found');
    }

    if (err.status === 500 && err.error.msg === 'failed to validate token' && this.router.url !== '/login') {
      this.router.navigate(['/login']);
    }

    if (err.status === 401 && err.error.msg === 'token expired' && this.router.url !== '/login') {
      localStorage.setItem('route', this.router.url);
      this.authService.logout();
      this.router.navigate(['/login']);
    }

    throw err;
  };
}

export const interceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
];

