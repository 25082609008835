import { takeUntil, debounceTime, Subject, from, filter, startWith } from 'rxjs';
import { Component, ViewChild, OnInit, Input, OnDestroy } from '@angular/core';
import { DeviceInfo } from '../interfaces/deviceInfo.interface';
import { ColumnConfiguration } from '../interfaces/columnSettings.interface';
import { PaginatorData } from '../interfaces/paginatorData.interface';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { JSONViewerComponent } from '../dialogs/json-viewer/json-viewer.component';
import { DeviceLookupService } from '../services/deviceLookup.service';
import { Page } from '../interfaces/page.interface';
import { DeviceLookupContext, BulkDeviceLookupContext } from '../interfaces/deviceLookup.interface';
import { LookupOption } from '../common/lookup-options.enum';
import { DownloadFormat } from '../common/download';
import { MatSort } from '@angular/material/sort';
import { DEVICE_INFO_COLUMNS } from "../device-info/columns";
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, Scroll } from '@angular/router';

@Component({
  selector: 'app-device-info-datatable',
  templateUrl: './device-info-datatable.component.html',
  styleUrls: ['./device-info-datatable.component.css']
})
export class DeviceInfoDatatableComponent implements OnInit, OnDestroy {
  @Input() mode: 'single' | 'bulk' = 'single';
  @Input() allColumns: ColumnConfiguration[] = DEVICE_INFO_COLUMNS;
  @Input() columns: ColumnConfiguration[] = this.allColumns;

  @ViewChild(MatSort) sort!: MatSort;
  currentBulkLookupContext: BulkDeviceLookupContext;
  currentSingleLookupContext: DeviceLookupContext;

  deviceInfoList: DeviceInfo[] = [];
  loading: boolean = false;
  displayedColumns: string[] = [];
  // allColumns: ColumnConfiguration[] = [];
  paginatorData: PaginatorData = {
    pageIndex: 0,
    length: 0,
    pageSize: 10,
    currentPage: 0,
    pageOptions: [10,20,50],
  };
  columnFilterFormControls!: Record<string, FormControl<string | null>>;
  DISABLED_COLUMN_FILTERS = [
    'links',
  ];
  private readonly INPUT_FIELD_DEBOUNCE_TIME = 300;
  private ngUnsubscribe: Subject<void> = new Subject();

  constructor(
    private deviceLookupService: DeviceLookupService,
    private dialog: MatDialog,
    private router: Router,
  ) {
    // Subscribe to router events to update header and sidenav based on the current route
    router.events
      .pipe(
        filter((event: any) => event instanceof NavigationEnd || event instanceof Scroll),
        startWith(this.router)
      )
      .subscribe((val: any) => {
      });
    this.currentSingleLookupContext = this.getDefaultContext();
    this.currentBulkLookupContext = this.getDefaultBulkContext();
    this.columnFilterFormControls = {};
    this.allColumns = [];
  }


  public ngOnInit() {

  }

  public ngOnDestroy() {
    this.ngUnsubscribe.next();
  }

  public disabledColumnFilter(field: string): boolean {
    return this.DISABLED_COLUMN_FILTERS.includes(field);
  }

  public getColumnFilterFormControl(field: string): FormControl {
    let control = this.columnFilterFormControls[field];

    if (!control) {
      control = new FormControl({
        value: null,
        disabled: this.disabledColumnFilter(field),
      });

      control.valueChanges.pipe(
        debounceTime(this.INPUT_FIELD_DEBOUNCE_TIME),
        takeUntil(this.ngUnsubscribe)
      ).subscribe(value => {
        this.refreshBulkDeviceInfo();
      });

      this.columnFilterFormControls[field] = control;

      if (this.disabledColumnFilter(field))
        control.disable();
    }

    return control;
  }

  public onPaginate(event: any): void {
    this.paginatorData.pageIndex = event.pageIndex;
    this.paginatorData.pageSize = event.pageSize;
    this.getDeviceInfo();
  }

  public isLinksValue(value: any): boolean {
    return !!(value?.dhrLink);
  }

  public openJSONViewer(field: string, data: object) {
    const jsonViewer = this.dialog.open(JSONViewerComponent, {
      data: {
        json: data,
        field,
      },
      width: '33vw',
      maxHeight: '90vh',
    });
  }

  openLink(url: string): void {
    window.open(url, '_blank');
  }

  hasDHRLink(value: any) {
    return !!(value?.dhrLink);
  }

  hasA4ReportLink(value: any) {
    return !!(value?.a4ReportLink);
  }

  hasErasureReportLink(value: any) {
    return !!(value?.erasureReportLink);
  }

  hasSCRReportLink(value: any) {
    return !!(value?.scrReportLink);
  }

  public async refreshBulkDeviceInfo() {
    if (this.currentBulkLookupContext) {
      this.currentBulkLookupContext.columns = this.getColumnFilters();
      this.getBulkDeviceInfo(
        this.currentBulkLookupContext.option,
        this.currentBulkLookupContext.codes,
        this.currentBulkLookupContext.latest,
      );
    }
  }

  public onSortChange(event: any) {
    this.currentBulkLookupContext.sort = event.active;
    this.currentBulkLookupContext.direction = event.direction;
  }

  public async getBulkDeviceInfo(option: LookupOption, codes: string[], latest: boolean) {
    const context: BulkDeviceLookupContext = {
      codes,
      option,
      paginatorData: this.paginatorData,
      columns: this.getColumnFilters(),
      latest,
      columnSettingsId: this.currentBulkLookupContext.columnSettingsId,
    };

    this.currentBulkLookupContext = context;

    if (codes.length === 0) {
      return;
    }

    if (this.loading) {
      this.ngUnsubscribe.next();
    } else {
      this.loading = true;
    }

    try {
      const promise = from(this.deviceLookupService.bulkLookup(
        context,
      )).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
        next: (devicesPage) => {
          if (devicesPage) {
            const { data, totalItems } = devicesPage;

            this.paginatorData.length = totalItems;
            this.deviceInfoList = this.splitTests(data);
          }

          this.loading = false;
        }
      });
    } catch (err) {
      console.error(err);
      this.loading = false;
    }
  }

  private getBulkDeviceInfoContext(option: LookupOption, codes: string[]) {
    const columns = this.getColumnFilters();
    const paginatorData = this.paginatorData;
    const latest = true;

    return {
      option,
      codes,
      columns,
      paginatorData,
      latest,
    }
  }

  private async getSingleDeviceInfo() {
    const context = this.currentSingleLookupContext;

    if (!context.keyword) {
      return;
    }

    this.loading = true;

    try {
      const devicesPage = await this.deviceLookupService.lookup(
        context,
      ) as Page<any>;
      this.paginatorData.length = devicesPage.totalItems;

      if (devicesPage) {
        let { data } = devicesPage;

        this.deviceInfoList = data;
      }
    } catch (err) {
      // search attempt with no search term
    }

    this.loading = false;
  }

  private async getDeviceInfo() {
    let promise;

    if (this.loading) {
      this.ngUnsubscribe.next();
    }

    if (this.mode === 'single') {
      promise = this.getSingleDeviceInfo();
    } else {
      promise = this.refreshBulkDeviceInfo();
    }

    from(promise).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: () => {}
    });
  }

  public isObject(o: any) {
    return o !== null && typeof o === 'object';
  }

  public setContext(latest: boolean, keyword: string, option: LookupOption) {
    const columns: Record<string, string> = {};

    Object.keys(this.columnFilterFormControls).forEach(field => {
      const control = this.columnFilterFormControls[field];
      const value = control.getRawValue();

      if (value) {
        columns[field] = value;
      }
    });

    this.currentSingleLookupContext = {
      latest,
      keyword,
      option,
      paginatorData: this.paginatorData,
      columns,
    };

    if (!keyword) {
      return;
    }
    this.getDeviceInfo();
  }

  public getDefaultBulkContext(): BulkDeviceLookupContext {
    return {
      latest: true,
      option: LookupOption.IMEI,
      paginatorData: this.paginatorData,
      codes: [],
      columns: {},
      columnSettingsId: 'default',
    }
  }

  public getDefaultContext(): DeviceLookupContext {
    return {
      columns: {},
      keyword: '',
      latest: true,
      option: LookupOption.IMEI,
      paginatorData: this.paginatorData,
    }
  }

  public setAllColumns(filterId: string, columns: ColumnConfiguration[]) {
    const uniqueColumns: ColumnConfiguration[] = [];
    const seen: Record<string, boolean> = {};

    // Some user's saved filters have duplicated columns.
    // This break the datatable. We add this workaround here.
    columns.forEach((column: ColumnConfiguration) => {
      if (!seen[column.field]) {
        uniqueColumns.push(column);
        seen[column.field] = true;
      }
    });

    this.currentBulkLookupContext.columnSettingsId = filterId;
    this.allColumns = uniqueColumns;
    this.columns = uniqueColumns;
    this.setDisplayedColumns();
  }

  private setDisplayedColumns() {
    const inputs: Record<string, FormControl<string | null>> = {};
    const newDisplayedColumns: string[] = [];

    this.columns.forEach((column, index) => {
      let control = this.columnFilterFormControls[column.field];

      column.index = index;
      this.displayedColumns[index] = column.field;
      newDisplayedColumns.push(column.field);

      if (control) {
        inputs[column.field] = control;
      } else {
        control = new FormControl<string | null>(null);

        control.valueChanges.pipe(
          debounceTime(this.INPUT_FIELD_DEBOUNCE_TIME),
          takeUntil(this.ngUnsubscribe)
        ).subscribe(() => {
          this.refreshBulkDeviceInfo();
        });
      }
    });

    this.displayedColumns = newDisplayedColumns;
    this.columnFilterFormControls = inputs;
  }

  public clear() {
    this.deviceInfoList = [];
  }

  private getColumnFilters(): Record<string, string> {
    const columns: Record<string, string> = {};

    Object.keys(this.columnFilterFormControls).forEach(field => {
      const control = this.columnFilterFormControls[field];
      const value = control.getRawValue();

      if (value) {
        columns[field] = value;
      }
    });

    return columns;
  }

  public getContext(): BulkDeviceLookupContext {
    const columns = this.getColumnFilters();

    return { ...this.currentBulkLookupContext };
  }

  public clearContext() {
    this.currentBulkLookupContext = this.getDefaultBulkContext();
    this.currentSingleLookupContext = this.getDefaultContext();
  }

  public downloadBulk(format: DownloadFormat) {
    return this.deviceLookupService.downloadBulkLookup(
      this.currentBulkLookupContext,
      format,
    );
  }

  public getPublicValue(o: Record<string, any> , k: string) {
    const v = o[k];

    return v === null || v === undefined ? '' : v;
  }

  private splitTests(devices: any[]) {
    return devices.map(device => {
      if (device.passed && typeof device.passed === 'string') {
        device.passed = device.passed.split(',');
      }
      if (device.failed && typeof device.failed === 'string') {
        device.failed = device.failed.split(',');
      }
      if (device.pending && typeof device.pending === 'string') {
        device.pending = device.pending.split(',');
      }

      return device;
    });
  }
}

