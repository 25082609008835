import { ColumnConfiguration } from '../interfaces/columnSettings.interface';

export const DEVICE_INFO_COLUMNS: ColumnConfiguration[] = [
  { field: 'companyName', displayName: 'Company Name' },
  { field: 'warehouseName', displayName: 'Warehouse' },
  { field: 'stationName', displayName: 'Station\'s Name' },
  { field: 'stationLocalId', displayName: 'Station Local ID' },
  { field: 'invoiceId', displayName: 'Invoice No' },
  { field: 'vendorName', displayName: 'Vendor Name' },
  { field: 'boxNo', displayName: 'Box No' },
  { field: 'qty', displayName: 'QTY' },
  { field: 'testerName', displayName: 'Tester Name' },
  { field: 'serial', displayName: 'Serial' },
  { field: 'imei', displayName: 'IMEI' },
  { field: 'imei2', displayName: 'IMEI2' },
  { field: 'meid', displayName: 'MEID' },
  { field: 'meid2', displayName: 'MEID2' },
  { field: 'decimalMeid', displayName: 'Decimal MEID' },
  { field: 'decimalMeid2', displayName: 'Decimal MEID2' },
  { field: 'pesn', displayName: 'PESN' },
  { field: 'pesn2', displayName: 'PESN2' },
  { field: 'make', displayName: 'Make' },
  { field: 'modelName', displayName: 'Model Name' },
  { field: 'modelNumber', displayName: 'Model Number' },
  { field: 'regulatoryModelNumber', displayName: 'Regulatory Model Number' },
  { field: 'modelIdentifier', displayName: 'Model Identifier'},
  { field: 'deviceType', displayName: 'Device Type'},
  { field: 'os', displayName: 'OS' },
  { field: 'memory', displayName: 'RAM' },
  { field: 'storage', displayName: 'Storage' },
  { field: 'simSerial', displayName: 'SIM Serial' },
  { field: 'simSerial2', displayName: 'SIM Serial 2' },
  { field: 'deviceCarrier', displayName: 'Device Carrier' },
  { field: 'simCarrier', displayName: 'Sim Carrier' },
  { field: 'simCarrier2', displayName: 'Sim Carrier2' },
  { field: 'eid', displayName: 'EID' },
  { field: 'iftCodes', displayName: 'IFT Codes' },
  { field: 'udid', displayName: 'UDID' },
  { field: 'color', displayName: 'Color' },
  { field: 'grade', displayName: 'Grade' },
  { field: 'countryOfOrigin', displayName: 'Country Of Origin' },
  { field: 'simTechnology', displayName: 'SIM Technology' },
  { field: 'networkType', displayName: 'Network Type' },
  { field: 'wifiMacAddress', displayName: 'WiFi MAC Address' },
  { field: 'deviceLock', displayName: 'Device Lock' },
  { field: 'unlockStatus', displayName: 'Unlock Status' },
  { field: 'rooted', displayName: 'Rooted' },
  { field: 'osVersion', displayName: 'OS Version' },
  { field: 'appleID', displayName: 'Apple ID' },
  { field: 'deviceState', displayName: 'Device State' },
  { field: 'screenTime', displayName: 'Screen Time' },
  { field: 'dataVerification', displayName: 'Data Verification' },
  { field: 'firmware', displayName: 'Firmware' },
  { field: 'notes', displayName: 'Notes' },
  { field: 'desktopBuildNumber', displayName: 'Desktop Build No' },
  { field: 'desktopOS', displayName: 'Desktop OS' },
  { field: 'mobileBuildNumber', displayName: 'Mobile Build Number' },
  { field: 'manualEntry', displayName: 'Manual Entry' },
  { field: 'lpn', displayName: 'LPN' },
  { field: 'customField', displayName: 'Custom Field' },
  { field: 'skuCode', displayName: 'SKU Code' },
  { field: 'cloudTransaction', displayName: 'Cloud Transaction' },
  { field: 'eraseStatus', displayName: 'Erase Status' },
  { field: 'eraseType', displayName: 'Erase Type' },
  { field: 'eraseNotes', displayName: 'Erase Notes' },
  { field: 'erasedDate', displayName: 'Erase Date' },
  { field: 'eraseTotalTime', displayName: 'Erase Total Time' },
  { field: 'restoreErrorCode', displayName: 'Restore Error Code' },
  { field: 'sdEraseStatus', displayName: 'SD Erase Status' },
  { field: 'simEraseStatus', displayName: 'Sim Erase Status' },
  { field: 'esimEraseStatus', displayName: 'eSim Erase Status' },
  { field: 'esimDetected', displayName: 'esim Detected' },
  { field: 'batteryInfo', displayName: 'Battery Info' },
  { field: 'working', displayName: 'Working' },
  { field: 'testInfo', displayName: 'Test Results' },
  { field: 'imeiResponse', displayName: 'IMEI Response' },
  { field: 'simLockResponse', displayName: 'SIM Lock Response' },
  { field: 'carrierLockResponse', displayName: 'Carrier Lock Response' },
  { field: 'iCloudResponse', displayName: 'iCloud Response' },
  { field: 'oemParts', displayName: 'OEM Parts' },
  { field: 'mdmResponse', displayName: 'MDM Response/Knox' },
  { field: 'apiResponse', displayName: 'API Response' },
  { field: 'gradingQuestionAnswer', displayName: 'Shopfloor Route Info' },
  { field: 'warranty', displayName: 'Warranty' },
  { field: 'portNumber', displayName: 'Port Number' },
  { field: 'bottomMicReading', displayName: 'Bottom Mic Reading' },
  { field: 'videoMicReading', displayName: 'Video Mic Reading' },
  { field: 'frontMicReading', displayName: 'Front Mic Reading' },
  { field: 'ebayRefurbished', displayName: 'eBay Refurbished' },
  { field: 'ebayRejection', displayName: 'eBay Rejection' },
  { field: 'swappaQualified', displayName: 'Swappa Qualified' },
  { field: 'swappaRejection', displayName: 'Swappa Rejection' },
  { field: 'backMarketQualified', displayName: 'Back Market Qualified' },
  { field: 'backMarketRejection', displayName: 'Back Market Rejection' },
  { field: 'amazonRenewed', displayName: 'Amazon Renewed' },
  { field: 'amazonRenewedRejection', displayName: 'Amazon Renewed Rejection' },
  { field: 'finalPrice', displayName: 'Final Price' },
  { field: 'labelPrinted', displayName: 'Label Printed' },
  { field: 'addedDate', displayName: 'Added Date' },
  { field: 'updatedDate', displayName: 'Updated Date' },
];
