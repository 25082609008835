import { Component, Inject } from "@angular/core";
import { Validators, FormGroup, FormBuilder } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { BuildService } from 'src/app/services/build.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Build } from "src/app/interfaces/build.interface";
import { catchError, forkJoin, from, Observable, of, switchMap } from "rxjs";
@Component({
  selector: 'app-upload-build',
  templateUrl: './upload-build.component.html',
  styleUrls: ['./upload-build.component.css']
})

export class UploadBuildComponent {
  SelectedFile: File | null = null;
  BuildFile: File | null = null;
  public buildFor: string = 'MacCheck';
  selectedFiles: { dmg?: File; zip?: File } = {};
  uploadBuildForm!: FormGroup;
  isEditMode: boolean = false;
  buildUploading = false;
  uploadProgress: { [key: string]: { uploadedChunks: number; totalChunks: number; fileName: string } } = {};
  private abortController: AbortController | null = null; // Track active upload requests
  uploadInProgress = false;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<UploadBuildComponent>,
    private buildService: BuildService,
    private notify: NotificationService,
    @Inject(MAT_DIALOG_DATA) public selectedBuild: Build,
  ) { }

  onFileSelected(event: Event, fileType: 'dmg' | 'zip'): void {
    event.preventDefault(); // Prevent any default behavior
    const input = event.target as HTMLInputElement;

    if (input.files && input.files.length > 0) {
      this.selectedFiles[fileType] = input.files[0]; // Dynamically assign the file based on fileType
    } else {
      console.error(`No ${fileType} file selected.`);
    }
  }

  ngOnInit(): void {
    // Initialize the form group with buildFile required by default
    if (this.selectedBuild) {
      // If editing an existing build, make buildFile optional
      this.isEditMode = true;
      this.uploadBuildForm = this.fb.group({
        platform: ['', Validators.required],
        buildNumber: ['', [Validators.required, Validators.pattern(/^\d+(\.\d+)*$/)]],
        type: ['', Validators.required],
        jiraTickets: [''],
        releaseNotes: [''],
        buildFile: [null],
      });

      // Patch values for editing
      this.uploadBuildForm.patchValue({
        platform: this.selectedBuild.platform,
        buildNumber: this.selectedBuild.buildNumber,
        type: this.selectedBuild.type,
        jiraTickets: this.selectedBuild.jiraTickets,
        releaseNotes: this.selectedBuild.releaseNotes,
        buildFile: this.selectedBuild.buildKey,
        otaFile: this.selectedBuild.otaKey,
      });
    } else {
      // If adding a new build, make buildFile required
      this.isEditMode = false;
      this.uploadBuildForm = this.fb.group({
        platform: ['', Validators.required],
        buildNumber: ['', [Validators.required, Validators.pattern(/^\d+(\.\d+)*$/)]],
        type: ['', Validators.required],
        jiraTickets: [''],
        releaseNotes: [''],
        buildFile: [null, Validators.required],  // Make buildFile required in create mode
      });
    }
  }

  cancelBuild(): void {
    if (this.uploadInProgress) {
      const confirmCancel = confirm("Upload is in progress. Do you want to cancel it?");
      if (!confirmCancel) return;
      this.abortUpload();
    }

    this.resetUploadState();
    this.dialogRef.close();
  }

  private abortUpload(): void {
    if (this.abortController) {
      this.abortController.abort(); // Cancel the upload request
      this.notify.warn("File upload has been cancelled.");
    }
    this.uploadInProgress = false;
    this.buildUploading = false;
  }

  private resetUploadState(): void {
    this.selectedFiles = {};
    this.uploadBuildForm.reset();
    this.uploadProgress = {};
    this.uploadInProgress = false;
  }

  submitBuild(): void {
    if (this.uploadBuildForm.valid) {
      this.buildUploading = true;
      this.uploadInProgress = true;
      this.abortController = new AbortController(); // Create a new abort controller

      const buildFile = this.selectedFiles.dmg ?? null;
      const otaFile = this.selectedFiles.zip ?? null;

      if (this.isEditMode) {
        this.handleEditMode(buildFile, otaFile);
      } else {
        this.handleCreateMode(buildFile, otaFile);
      }
    }
  }

  /**
   * Handles build submission in edit mode.
   */
  private handleEditMode(buildFile: File | null, otaFile: File | null): void {
    const isNewBuildFile = buildFile;
    const isNewOtaFile = otaFile;

    if (isNewBuildFile || isNewOtaFile) {
      this.buildUploading = false;
      this.handleFileUploadsInEditMode(isNewBuildFile, isNewOtaFile);
    } else {
      this.updateBuildMetadataOnly();
    }
  }

  /**
   * Handles file uploads in edit mode.
   */
  private handleFileUploadsInEditMode(isNewBuildFile: File | null, isNewOtaFile: File | null): void {
    const buildFormData = this.createFormData('buildFile', isNewBuildFile);
    const otaFormData = this.createFormData('otaFile', isNewOtaFile);

    const buildUploadObservable = isNewBuildFile
      ? this.initiateFileUpload(buildFormData, isNewBuildFile, 'buildFile')
      : of({
        buildUrl: this.selectedBuild.buildUrl,
        buildUploadId: null,
        buildKey: this.selectedBuild.buildKey,
      });

    const otaUploadObservable = isNewOtaFile
      ? this.initiateFileUpload(otaFormData, isNewOtaFile, 'otaFile')
      : of({
        otaUrl: this.selectedBuild.otaUrl,
        otaUploadId: null,
        otaKey: this.selectedBuild.otaKey,
      });

    forkJoin({ buildData: buildUploadObservable, otaData: otaUploadObservable }).subscribe({
      next: ({ buildData, otaData }) => {
        const updatedBuildData = this.createBuildData(buildData, otaData);
        this.updateBuild(this.selectedBuild.id, updatedBuildData);
      },
      error: (error) => this.handleUploadError(error),
    });
  }

  /**
   * Updates build metadata without uploading new files.
   */
  private updateBuildMetadataOnly(): void {
    const buildFormData = this.createFormData('buildFile', null);
    this.buildService.updateBuild(this.selectedBuild.id, buildFormData).subscribe({
      next: () => this.handleSuccess('Build updated successfully.'),
      error: (error) => this.handleError('Error updating build.', error),
    });
  }

  /**
   * Handles build submission in create mode.
   */
  private handleCreateMode(buildFile: File | null, otaFile: File | null): void {
    if (!buildFile || !(/\.dmg$/i.test(buildFile.name))) {
      this.notify.error('Build file must be a .dmg file.');
      this.buildUploading = false;
      this.uploadInProgress = false;
      return;
    }

    const buildFormData = this.createFormData('buildFile', buildFile);
    const otaFormData = this.createFormData('otaFile', otaFile);

    this.uploadInProgress = true;
    this.buildUploading = false;

    const buildFileUploadRequest = this.initiateFileUploadRequest(buildFormData);
    const otaFileUploadRequest = otaFile
      ? this.initiateFileUploadRequest(otaFormData)
      : of(null);

    forkJoin([buildFileUploadRequest, otaFileUploadRequest]).subscribe({
      next: ([buildResponse, otaResponse]) => {
        if (!buildResponse) {
          this.uploadInProgress = false;
          return;
        }

        const buildFileUploadChunks = this.uploadChunks(
          this.buildFor,
          this.uploadBuildForm.get('platform')?.value,
          this.uploadBuildForm.get('type')?.value,
          buildResponse.uploadId,
          buildFile,
          'buildFile'
        ).then((response: any) => ({
          buildUrl: response.url,
          buildUploadId: response.uploadId,
          buildKey: response.key,
        }));

        const otaFileUploadChunks = otaFile && otaResponse
          ? this.uploadChunks(
            this.buildFor,
            this.uploadBuildForm.get('platform')?.value,
            this.uploadBuildForm.get('type')?.value,
            otaResponse.uploadId,
            otaFile,
            'otaFile'
          ).then((response: any) => ({
            otaUrl: response.url,
            otaUploadId: response.uploadId,
            otaKey: response.key,
          }))
          : Promise.resolve({ otaUrl: null, otaUploadId: null, otaKey: null });

        Promise.all([buildFileUploadChunks, otaFileUploadChunks])
          .then(([buildFileData, otaFileData]) => {
            const buildData = this.createBuildData(buildFileData, otaFileData);
            this.createBuild(buildData);
          })
          .catch((error) => this.handleUploadError(error));
      },
      error: (error) => this.handleUploadError(error),
    });
  }

  /**
   * Creates FormData for file upload.
   */
  private createFormData(fileType: string, file: File | null, includeFile: boolean = false): FormData {
    const formData = new FormData();
    formData.append('platform', this.uploadBuildForm.get('platform')?.value ?? '');
    formData.append('buildNumber', this.uploadBuildForm.get('buildNumber')?.value ?? '');
    formData.append('type', this.uploadBuildForm.get('type')?.value ?? '');
    formData.append('jiraTickets', this.uploadBuildForm.get('jiraTickets')?.value ?? '');
    formData.append('releaseNotes', this.uploadBuildForm.get('releaseNotes')?.value ?? '');
    formData.append('buildFor', this.buildFor ?? 'MacCheck');
    if (file && includeFile) {
      formData.append(fileType, file);
    } else if (file) {
      formData.append('fileName', file.name);
      formData.append('fileType', fileType);
    }
    return formData;
  }

  /**
   * Initiates a file upload request.
   */
  private initiateFileUploadRequest(formData: FormData): Observable<any> {
    return this.buildService.intiateUploadRequest(formData).pipe(
      catchError((error) => {
        console.error('Error initiating file upload:', error);
        this.notify.error('Error initiating file upload. Please try again later.');
        return of(null);
      })
    );
  }

  /**
   * Initiates file upload and handles chunk uploads.
   */
  private initiateFileUpload(formData: FormData, file: File, fileType: string): Observable<any> {
    return this.buildService.intiateUploadRequest(formData).pipe(
      switchMap((response) => {
        const uploadId = response.uploadId;
        return from(
          this.uploadChunks(
            this.buildFor,
            this.uploadBuildForm.get('platform')?.value,
            this.uploadBuildForm.get('type')?.value,
            uploadId,
            file,
            fileType
          ).then((result: any) => {
            // Return the appropriate keys based on the file type
            if (fileType === 'otaFile') {
              return {
                otaUrl: result.url,
                otaUploadId: result.uploadId,
                otaKey: result.key,
              };
            } else if (fileType === 'buildFile') {
              return {
                buildUrl: result.url,
                buildUploadId: result.uploadId,
                buildKey: result.key,
              };
            } else {
              throw new Error('Invalid file type');
            }
          })
        );
      })
    );
  }

  /**
   * Creates build data object for API submission.
   */
  private createBuildData(buildData: any, otaData: any): any {
    return {
      platform: this.uploadBuildForm.get('platform')?.value ?? '',
      buildNumber: this.uploadBuildForm.get('buildNumber')?.value ?? '',
      type: this.uploadBuildForm.get('type')?.value ?? '',
      jiraTickets: this.uploadBuildForm.get('jiraTickets')?.value ?? '',
      releaseNotes: this.uploadBuildForm.get('releaseNotes')?.value ?? '',
      buildFor: this.buildFor ?? 'MacCheck',
      buildUrl: buildData.buildUrl ?? '',
      buildUploadId: buildData.buildUploadId ?? '',
      buildKey: buildData.buildKey ?? '',
      otaUrl: otaData.otaUrl ?? '',
      otaUploadId: otaData.otaUploadId ?? '',
      otaKey: otaData.otaKey ?? '',
    };
  }

  /**
   * Updates build data in the backend.
   */
  private updateBuild(buildId: number, buildData: any): void {
    this.buildService.updateBuild(buildId, buildData).subscribe({
      next: () => this.handleSuccess('Build updated successfully.'),
      error: (error) => this.handleError('Error updating build.', error),
    });
  }

  /**
   * Creates a new build in the backend.
   */
  private createBuild(buildData: any): void {
    this.buildService.createBuild(buildData).subscribe({
      next: () => this.handleSuccess('Build uploaded and saved successfully.'),
      error: (error) => this.handleError('Error saving build data.', error),
    });
  }

  /**
   * Handles successful operations.
   */
  private handleSuccess(message: string): void {
    this.buildUploading = false;
    this.uploadInProgress = false;
    this.dialogRef.close(true);
    this.notify.success(message);
  }

  /**
   * Handles errors during operations.
   */
  private handleError(message: string, error: any): void {
    this.buildUploading = false;
    this.uploadInProgress = false;
    this.dialogRef.close();
    console.error(`${message}:`, error);
    this.notify.error(`${message} Please try again later.`);
  }

  /**
   * Handles file upload errors.
   */
  private handleUploadError(error: any): void {
    this.buildUploading = false;
    this.uploadInProgress = false;
    console.error('Error uploading files:', error);
    this.notify.error('Error uploading files. Please try again later.');
  }

  private async uploadChunks(buildFor: string, platform: string, type: string, uploadId: string, file: File, fileType: string): Promise<{ buildUrl: string; buildUploadId: string; buildKey: string }> {
    const chunkSize = 20 * 1024 * 1024; // 20 MB
    const totalChunks = Math.ceil(file.size / chunkSize);
    if (!this.uploadProgress[fileType]) {
      this.uploadProgress[fileType] = { uploadedChunks: 0, totalChunks: 0, fileName: '' }; // Initialize as an empty object
    }
    this.uploadProgress[fileType].totalChunks = totalChunks;
    this.uploadProgress[fileType].uploadedChunks = 0;
    this.uploadProgress[fileType].fileName = file.name;
    for (let index = 0; index < totalChunks; index++) {
      if (this.abortController?.signal.aborted) {
        throw new Error("Upload cancelled by user.");
      }
      const start = index * chunkSize;
      const end = Math.min(start + chunkSize, file.size);
      const chunk = file.slice(start, end);
      const isLastIndex = index === totalChunks - 1;

      const chunkFormData = new FormData();
      chunkFormData.append('uploadId', uploadId);
      chunkFormData.append('index', index.toString());
      chunkFormData.append('isLastIndex', isLastIndex.toString());
      chunkFormData.append('fileName', file.name);
      chunkFormData.append('buildFor', buildFor);
      chunkFormData.append('platform', platform);
      chunkFormData.append('type', type);
      chunkFormData.append(fileType, chunk);
  
      let attempts = 0;
      while (attempts < 3) {
        try {
          const response = await this.buildService.uploadChunk(chunkFormData).toPromise();
          this.uploadProgress[fileType].uploadedChunks = index + 1;
          if (isLastIndex) {
            return response;
          }
          break; // Success, exit retry loop
        } catch (error) {
          attempts++;
          if (this.abortController?.signal.aborted) {
            this.notify.error(`Upload cancelled for ${fileType}`);
            throw new Error(`Upload cancelled for ${fileType}`);
          }
          if (attempts < 3) {
            console.warn(`Retrying chunk ${index + 1}/${totalChunks}, attempt ${attempts}...`);
            await new Promise(resolve => setTimeout(resolve, 5000)); // Wait for 5 seconds
          } else {
            console.error(`Error uploading chunk ${index + 1}/${totalChunks}:`, error);
            this.notify.error(`Error uploading ${fileType} chunk ${index + 1}. Please try again later.`);
            throw error;
          }
        }
      }
    }
    throw new Error(`${fileType} upload failed.`);
  }  
}
