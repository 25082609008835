<section>
  <form [formGroup]="advancedSettingsFormGroup">
    <mat-checkbox formControlName="apiDataSharingEnabled"> <b>Enable API and data sharing</b></mat-checkbox>

    <div *ngIf="advancedSettingsFormGroup.value.apiDataSharingEnabled"
      style="display: grid; grid-template-columns: 10em repeat(2, 2fr); gap: 1em; width: min(60em, 90%);">
      <mat-checkbox formControlName="sourceEnabled"> <b>Source API</b> </mat-checkbox>

      <mat-form-field>
        <mat-label> URL</mat-label>
        <input matInput formControlName="sourceURL" />
      </mat-form-field>

      <mat-form-field>
        <mat-label> API Key</mat-label>
        <input matInput formControlName="sourceAPIKey" />
      </mat-form-field>

      <mat-checkbox formControlName="resultsEnabled"> <b> Results API </b></mat-checkbox>

      <mat-form-field>
        <mat-label> URL</mat-label>
        <input matInput formControlName="resultsURL" />
      </mat-form-field>

      <mat-form-field>
        <mat-label> API Key</mat-label>
        <input matInput formControlName="resultsAPIKey" />
      </mat-form-field>
      <mat-checkbox formControlName="labelEnabled"><b>Label API</b> </mat-checkbox>

      <mat-form-field>
        <mat-label> URL</mat-label>
        <input matInput formControlName="labelURL" />
      </mat-form-field>

      <mat-form-field>
        <mat-label> API Key</mat-label>
        <input matInput formControlName="labelAPIKey" />
      </mat-form-field>
    </div>

    <mat-divider style="margin-block: 1em;"></mat-divider>
    <div>
      <mat-checkbox formControlName="shopfloorEnabled"> <b>Enable Shopfloor</b> </mat-checkbox>
      <p style="max-width: 30em; margin-left: 1em;"> Shopfloor is a system that routes devices based on grading
        questions and answers. Enabling these settings
        allow the computer to be managed by the routes assigned to the station</p>
    </div>
  </form>
</section>
