import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dataformat'
})
export class DataformatPipe implements PipeTransform {
  transform(value: any, column: any): string {
    const TEST_COLUMNS = ['passed', 'failed', 'pending'];
    const GRADING_QUESTIONS_COLUMNS = ['questionAnswer', 'gradingQuestionAnswer'];

    if (this.isEmptyString(value) || this.isEmptyObject(value) || this.isEmptyArray(value)) {
      return '';
    }

    if (value?.knox && value.knox !== '') {
      return value.knox;
    } else if (value?.status && value.status !== '') {
      return value.status;
    } else if (this.isObject(value) && !value.status) {
      return 'View Response';
    } else if (Array.isArray(value)) {
      if (TEST_COLUMNS.includes(column.field)) {
        return 'View Response';
      }
      if (GRADING_QUESTIONS_COLUMNS.includes(column.field)) {
        return 'View Questions';
      }
      return this.capitalizeWords(value.join(', '));
    } else if (typeof value === 'string') {
      if (this.isLikelyJsonArray(value)) {
        try {
          const arrayValue = JSON.parse(value);
          if (Array.isArray(arrayValue)) {
            if (column.field === 'questionAnswer') {
              return 'View Response';
            }
            return this.capitalizeWords(arrayValue.join(', '));
          }
        } catch (error) {
          console.error('DataformatPipe: Error parsing string as JSON array:', value);
        }
      }

      const trimmedValue = value.trim();
      if (trimmedValue.startsWith('[') && trimmedValue.endsWith(']')) {
        return this.capitalizeWords(trimmedValue.slice(1, -1));
      }

      return this.capitalizeWords(trimmedValue.replace(/"/g, ''));
    }

    return JSON.stringify(value);
  }

  private capitalizeWords(value: string): string {
    return value.replace(/\b\w/g, match => match.toUpperCase());
  }

  private isEmptyObject(obj: any): boolean {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  private isEmptyArray(arr: any): boolean {
    return Array.isArray(arr) && arr.length === 0;
  }

  private isEmptyString(str: any): boolean {
    return typeof str === 'string' && str.trim() === '';
  }

  private isObject(obj: any): boolean {
    return obj && typeof obj === 'object' && !Array.isArray(obj);
  }

  private isLikelyJsonArray(str: string): boolean {
    return str.trim().startsWith('[') && str.trim().endsWith(']');
  }
}
