import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NotificationService } from './notification.service';
import { DeviceTestCategory, SelectedDeviceTest, DeviceTest, WatchTestPlan, AirpodsTestPlan, DeviceTestPlan } from '../interfaces/deviceTest.interfaces';
import { Observable } from 'rxjs';
import { RawWarehouse } from './master.service';
import { AssignmentIdsPayload } from "../interfaces/assignment-ids-payload.interface";
import { Device } from '../interfaces/device.interface';

interface SaveCustomizationProfileResponse {
  success: boolean,
  id?: string,
  error?: any,
}

interface Station {
  id: string,
  name: string,
  assigned: boolean,
  warehouse: RawWarehouse,
  v1LicenseId: number;
}

interface Warehouse {
  id: string;
  name: string;
  assigned: boolean;
  v1WarehouseId: number;
}

@Injectable({
  providedIn: 'root'
})
export class CustomizationService {

  constructor(private http: HttpClient, private notify: NotificationService) { }
  private certifiedTestSuite!: SelectedDeviceTest[];
  private watchCertifiedTestSuite!: SelectedDeviceTest[];
  private airpodsCertifiedTestSuite!: SelectedDeviceTest[];
  public deviceTestCategories: DeviceTestCategory[] = []

  async save(customization: any): Promise<SaveCustomizationProfileResponse | undefined> {
    try {
      const url = `${environment.apiUrl}/customization`;

      const result = await this.http.put(url, customization).toPromise() as SaveCustomizationProfileResponse;
      // @ts-ignore
      if (result && result.success) {
        const message = customization.profileId ? 'Saved existing profile' : 'Saved new profile';
        this.notify.success(message);

        return result;

      } else if (result && !result.success) {
        this.notify.success(result.error);
        return undefined;
      } else {
        console.error(result);
        this.notify.success('Failed to save new profile');
        return undefined;
      }
    } catch (e) {

      console.error(e);
      this.notify.warn(`Failed to save new profile`);
      return undefined;
    }
  }

  public getProfiles(
    sort: string = 'createdAt',
    direction: string = 'desc',
  ): Observable<any> | undefined {
    let params = new HttpParams();

    params = params.append('sort', sort);
    params = params.append('direction', direction);

    try {
      const url = `${environment.apiUrl}/customization`;
      return this.http.get(url, { params });
    } catch (e) {
      console.error(e);
      this.notify.warn(`Failed to get customization profiles`);

      return undefined;
    }
  }

  public getProfile(profileId: string): Promise<any> {
    const url = `${environment.apiUrl}/customization/${profileId}`;

    return this.http.get(url).toPromise();
  }

  public getAssignedProfile(stationId: string): Promise<any> {
    const url = `${environment.apiUrl}/customization/stations/${stationId}/assignedProfile/`;
    return this.http.get(url).toPromise();
  }

  public getWatchCertifiedTestSuite(testSections: DeviceTestCategory[], testFilter?: (platform: string) => boolean): SelectedDeviceTest[] {
    const filter = (platform: string) => {
      platform = platform.toLowerCase();

      return platform === 'watch';
    };

    return this.getCertifiedTestSuiteHelper(testSections, filter);
  }
  public getAirpodsCertifiedTestSuite(testSections: DeviceTestCategory[], testFilter?: (platform: string) => boolean): SelectedDeviceTest[] {
    const filter = (platform: string) => {
      platform = platform.toLowerCase();

      return platform === 'airpods';
    };

    return this.getCertifiedTestSuiteHelper(testSections, filter);
  }

  private getCertifiedTestSuite(testSections: DeviceTestCategory[]): SelectedDeviceTest[] {
    const testFilter = (platform: string) => {
      platform = platform.toLowerCase();

      return platform === 'both' || platform === 'android' || platform === 'ios';
    }

    return this.getCertifiedTestSuiteHelper(testSections, testFilter);
  }

  private getCertifiedTestSuiteHelper(
    testSections: DeviceTestCategory[],
    testFilter: (platform: string) => boolean): SelectedDeviceTest[]
  {
    const certifiedTestSuite: SelectedDeviceTest[] = [];
    const getDefaultValue = (test: DeviceTest): string | undefined => {
      const defaultValue = test.values.find(v => {
        return v.isDefault;
      });

      if (defaultValue) {
        return defaultValue.value;
      }

      if (test.values.length > 0) {
        return test.values[0].value;
      }

      return undefined;
    }

    testSections.forEach(section => {
      section.masterTests.forEach(masterTest => {
        if (masterTest.isDefault) {
          masterTest.tests.filter(t => testFilter(t.platform)).forEach(t => {
            const selectedTest = {
              id: t.id,
              key: t.key,
              displayName: t.displayName,
              requiresParameter: t.requiresParameter,
              description: t.description,
              platform: t.platform,
              inputValue: t.requiresParameter ? getDefaultValue(t) : undefined,
            };

            certifiedTestSuite.push(selectedTest);
          });
        }
      });
    });

    return certifiedTestSuite;
  }

  async getTestPlanSkeleton(): Promise<DeviceTestCategory[] | undefined> {
    try {
      const url = `${environment.apiUrl}/customization/options/tests`;

      if (this.deviceTestCategories.length > 0 ) {
        return this.deviceTestCategories;
      }

      const result = await this.http.get(url).toPromise() as DeviceTestCategory[];

      this.deviceTestCategories = result;

      this.certifiedTestSuite = this.getCertifiedTestSuite(result);
      this.watchCertifiedTestSuite = this.getWatchCertifiedTestSuite(result);
      this.airpodsCertifiedTestSuite = this.getAirpodsCertifiedTestSuite(result);

      return result;
    } catch (e) {
      console.error(e);
      this.notify.warn(`Failed to get tests`);
    }

    return undefined;
  }

  public getCertifiedTestSuiteForBothPlatforms(): DeviceTestPlan {
    return {
      both: this.certifiedTestSuite,
      planType: 'certified-test-suite',
    }
  }

  public getCertifiedTestSuiteWatch(): WatchTestPlan {
    return {
      watchTests: this.watchCertifiedTestSuite,
      watchPlanType: 'certified-test-suite',
    }
  }

  public getCertifiedTestSuiteAirpods(): AirpodsTestPlan {
    return {
      airpodsTests: this.airpodsCertifiedTestSuite,
      airpodsPlanType: 'certified-test-suite',
    }
  }



  async getStations(profileId: string): Promise<Station[]> {
    try {
      const url = `${environment.apiUrl}/customization/${profileId}/stations`;

      const result = await this.http.get(url).toPromise();

      return result as Station[];
    } catch (e) {
      console.error(e);
      this.notify.warn(`Failed to get stations`);
      throw e;
    }
  }

  async getAllStations(): Promise<Station[]> {
    try {
      const url = `${environment.apiUrl}/customization/stations`;

      const result = await this.http.get(url).toPromise();

      return result as Station[];
    } catch (e) {
      console.error(e);
      this.notify.warn(`Failed to get stations`);
      throw e;
    }
  }

  async getWarehouses(profileId: string): Promise<Warehouse[]> {
    try {
      const url = `${environment.apiUrl}/customization/${profileId}/warehouses`;

      const result = await this.http.get(url).toPromise();

      return result as Warehouse[];
    } catch (e) {
      console.error(e);
      this.notify.warn(`Failed to get warehouses`);
      throw e;
    }
  }

  async updateAssignments(
    profileId: string,
    payload: AssignmentIdsPayload | undefined,
    removePreviousAssignment?: boolean
  ): Promise<Object | undefined> {
    const url = `${environment.apiUrl}/customization/${profileId}/assignments`;

    if (!payload) {
      return;
    }
    if (removePreviousAssignment) // if assignment is done by popup
      payload.removePreviousAssignment = removePreviousAssignment;

    try {
      await this.http.post(url, payload).toPromise();

      return;
    } catch (err) {
      console.error(err);
      this.notify.warn(`Failed to update assignments`);

      return;
    }
  }

  async getWarehousesWithStations(): Promise<Array<any> | undefined> {
    const url = `${environment.apiUrl}/customization/warehouses`;
    let result;

    try {
      result = await this.http.get(url).toPromise() as Array<any>;
      return result;
    } catch (err) {
      this.notify.warn(`Failed to get warehouses`);

      return;
    }
  }

  async bulkAssign(payload: any): Promise<void> {
    // const url = `${environment.apiUrl}/customization/assignments`;
    // let result;
    const { assignments } = payload;

    if (!assignments || assignments.length === 0) {

      this.notify.success(`There are no changes to current profile assignments.`);

      return;
    }

    try {
      const profiles: { [key: string]: string[]; } = {};

      assignments.forEach((assignment: any) => {
        if (!profiles[assignment.profileId]) {
          profiles[assignment.profileId] = [assignment.stationId];
        } else {
          profiles[assignment.profileId].push(assignment.stationId);
        }
      });

      for (const profile of Object.keys(profiles)) {
        await this.updateAssignments(profile, { stationIds: profiles[profile] });
      }
    } catch (err) {
      this.notify.warn(`Failed to bulk assign profiles`);

      return;
    }
  }

  async deleteProfile(id: number) {
    try {
      const url = `${environment.apiUrl}/customization/${id}`;

      const result = await this.http.delete(url).toPromise();

      // @ts-ignore
      if (result && result.success) {
        this.notify.success('Deleted customization profile');
        return true;
      } else {
        console.error(result);
        this.notify.success('Failed to delete customization profile');
        return false;
      }
    } catch (e) {
      console.error(e);
      this.notify.warn(`Failed to delete customization profile`);
      return false;
    }
  }

  async getBuiltInBatteryDevices(modelName: string): Promise<Device[] | undefined> {
    const url = `${environment.apiUrl}/customization/options/builtInBatteryDevices`;
    let params = new HttpParams();

    if (modelName)
      params = params.append('name', modelName);

    try {
      const result = await this.http.get(url, { params }).toPromise() as Device[];

      return result;
    } catch (e) {
      console.error(e);
      this.notify.warn(`Failed to get devices`);
    }

    return undefined;
  }
}

