<div *ngIf="isLoading" class="full-screen-loader">
  <img src="assets/loader.gif" alt="Loading" class="loader-image" />
</div>
<div *ngIf="!isLoading">
  <div class="container">
    <div class="action-container">
      <form [formGroup]="updateAllCustomers" (ngSubmit)="onSubmit()">
        <!-- Step 1: Form view -->
        <div *ngIf="step === 1">
          <h3 class="update_customers_heading">Update all customers</h3>
          <mat-divider></mat-divider>
          <h4>Update all customer stations (company wide)</h4>

          <!-- M-Series Selection -->
          <div class="build-select-container">
            <div class="build-select">
              <mat-form-field hideRequiredMarker>
                <mat-label>
                  <h3>M-Series</h3>
                </mat-label>
                <mat-select formControlName="assignedMSeriesBuild">
                  <mat-option [value]="0">None</mat-option>
                  <mat-option *ngFor="let build of getMSeriesBuilds(); let isFirst = first" [value]="build.id">
                    {{ build.buildNumber }} <span *ngIf="isFirst">(Latest)</span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="build-select">
              <mat-form-field hideRequiredMarker>
                <mat-label>
                  <h3>Intel</h3>
                </mat-label>
                <mat-select formControlName="assignedIntelBuild">
                  <mat-option [value]="0">None</mat-option>
                  <mat-option *ngFor="let build of getIntelBuilds(); let isFirst = first" [value]="build.id">
                    {{ build.buildNumber }} <span *ngIf="isFirst">(Latest)</span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="build-select">
              <mat-form-field hideRequiredMarker>
                <mat-label>
                  <h3>USB Utility</h3>
                </mat-label>
                <mat-select formControlName="assignedUsbUtilityBuild">
                  <mat-option [value]="0">None</mat-option>
                  <mat-option *ngFor="let build of getUsbUtilityBuilds(); let isFirst = first" [value]="build.id">
                    {{ build.buildNumber }} <span *ngIf="isFirst">(Latest)</span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <br>
          <!-- Exclude Masters Checkbox -->
          <mat-checkbox formControlName="excludeMasters"> <b>Exclude masters with automatic updates disabled</b>
          </mat-checkbox>

          <!-- Buttons -->
          <div class="action-buttons">
            <button mat-raised-button color="primary" (click)="fetchConfirmation()"
              [disabled]="updateAllCustomers.invalid" type="button">Update</button>
            <button mat-button color="accent" (click)="onCancel()" type="button">Cancel</button>
          </div>
        </div>

        <!-- Step 2: Confirmation view -->
        <div *ngIf="step === 2">
          <h3 class="update_customers_heading">Update all customers</h3>
          <mat-divider></mat-divider>
          <h5 class="confirmation_heading">Confirmation</h5>
          <span class="confirmation_message">{{ confirmationMessage }}</span>
          <br>
          <br>
          <!-- Buttons -->
          <div class="action-buttons">
            <button mat-raised-button color="primary" [disabled]="updateAllCustomers.invalid"
              type="submit">Update</button>
            <button mat-button color="accent" (click)="goBack()" type="button">Cancel</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>