<h1>Device info column configuration</h1>
<p id="description">
  Drag and drop columns to the desired setting and arrange them in the
  desired order.
</p>
<div fxLayout="column" id="configuration-container" mat-dialog-content>
  <mat-form-field>
    <mat-label>Filter's name</mat-label>
    <input matInput type="text" [formControl]="nameFormControl">
  </mat-form-field>
<div fxLayout="row" mat-dialog-content>
  <div class="column-list-container">
    <h3>Visible columns</h3>
    <div
      cdkDropList
      #visibleColumns="cdkDropList"
      [cdkDropListData]="visible"
      [cdkDropListConnectedTo]="[hiddenColumns]"
      class="column-list"
      (cdkDropListDropped)="drop($event)">
      <div *ngFor="let item of visible" class="column-box" cdkDrag>{{item.displayName}}</div>
    </div>
  </div>
  <div class="column-list-container">
    <h3>Hidden columns</h3>
    <div
      cdkDropList
      #hiddenColumns="cdkDropList"
      [cdkDropListData]="hidden"
      [cdkDropListConnectedTo]="[visibleColumns]"
      class="column-list"
      (cdkDropListDropped)="drop($event)">
      <div *ngFor="let item of hidden" class="column-box" cdkDrag>{{item.displayName}}</div>
    </div>
  </div>
  </div>
</div>
<div mat-dialog-actions align="center">
  <button mat-button class="delete-button" (click)="onDelete()" *ngIf="canBeDeleted()">Delete</button>
  <button mat-button class="safe-button" (click)="onCancel()">Cancel</button>
  <button mat-button class="safe-button" (click)="onSave()">Save</button>
</div>

