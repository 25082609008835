import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-saving-without-assignments-dialog',
  templateUrl: './saving-without-assignments-dialog.component.html',
  styleUrls: ['./saving-without-assignments-dialog.component.css']
})
export class SavingWithoutAssignmentsDialogComponent {
  constructor(
  ) { }
}
