import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, map, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import {AuthService} from "../../services/auth.service";

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(
    private http: HttpClient,
    public authService: AuthService,
  ) { }

  async updatePassword(password: Partial<{ currentPassword: string; newPassword: string; confirmNewPasswowrd: string }>): Promise<{ success: boolean }> {
    const url = `${environment.apiUrl}/master/password`;
    try {
      const result = await firstValueFrom(this.http.put<{ success: boolean }>(url, password));
      return result;
    } catch (err) {
      console.error(err);
      return await firstValueFrom(of({ success: false, msg: err }));
    }
  }

  async getAccountSettings(): Promise<any> {
    const url = `${environment.apiUrl}/customization/account-settings`;

    if (!this.authService.isMasterSelected() && !this.authService.isMasterContext()) {
      throw new Error(`Failed to getAccountSettings() - No master selected!`);
    }

    try {
      return await firstValueFrom(
        this.http.get<{
          timezone: string
          name: string
          masterContacts: { contact: string; id: string }[]
        }>(url)
          .pipe(
            map(accountSetting => {
              return {
                ...accountSetting,
                masterContacts: accountSetting.masterContacts[0],
              };
            })
          )
      );
    } catch (err) {
      console.error(err);
      return await firstValueFrom(of({ success: false, msg: err }));
    }
  }

  async updateEmail(emailInfo: { email: string; id?: number; }) {
    const url = `${environment.apiUrl}/master/email`;
    try {
      const result = await firstValueFrom(this.http.put<{ success: boolean }>(url, emailInfo));
      return result;
    } catch (err) {
      console.error(err);
      return await firstValueFrom(of({ success: false, msg: err }));
    }
  }

  async updateTimezone(timezone: string) {
    const url = `${environment.apiUrl}/master/timezone`;
    try {
      const result = await firstValueFrom(this.http.put<{ success: boolean }>(url, { tzCode: timezone }));
      return result;
    } catch (err) {
      console.error(err);

      return await firstValueFrom(of({ success: false, msg: err }));
    }
  }

  async updateGradingDataExport(enabled: boolean) {
    const url = `${environment.apiUrl}/master/settings/grading-data-export`;
    try {
      return firstValueFrom(this.http.put<{ gradingDataExport: boolean }>(url, { gradingDataExport: enabled }));
    } catch (err) {
      console.error(err);
      return undefined;
    }
  }

  async getMasterSettings(): Promise<any> {
    const url = `${environment.apiUrl}/master/settings`;
    try {
      return firstValueFrom(this.http.get(url));
    } catch (err) {
      console.error(err);
      return undefined;
    }
  }

  getLogo(): Observable<any>  {
    let id;

    const userContext = localStorage.getItem(`userContext`);
    if (!userContext) {
      throw new Error(`no userContext found`);
    }
    const userContextParsed = JSON.parse(userContext);

    if (this.authService.isAdminContext() && this.authService.isMasterSelected()) {
      id = userContextParsed.targetMaster.id;
    } else {
      const userContextParsed = JSON.parse(userContext);
      id = userContextParsed.id;
    }

    const url = `https://master-logos.s3.us-west-1.amazonaws.com/${id}.png?random=` + (new Date()).getTime();

    return this.http.get(url, { responseType: 'blob' });
  }

  getBanner(): Observable<any>  {
    let id;

    const userContext = localStorage.getItem(`userContext`);
    if (!userContext) {
      throw new Error(`no userContext found`);
    }
    const userContextParsed = JSON.parse(userContext);

    if (this.authService.isAdminContext() && this.authService.isMasterSelected()) {
      id = userContextParsed.targetMaster.id;
    } else {
      const userContextParsed = JSON.parse(userContext);
      id = userContextParsed.id;
    }

    const url = `https://master-banners.s3.us-west-1.amazonaws.com/${id}.png?random=` + (new Date()).getTime();

    return this.http.get(url, { responseType: 'blob' });
  }

  uploadLogo(formData: any): Observable<any>  {
    const url = `${environment.apiUrl}/master/logo`;

    return this.http.post<{ msg: boolean }>(url, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  uploadBanner(formData: any): Observable<any>  {
    const url = `${environment.apiUrl}/master/banner`;

    return this.http.post<{ msg: boolean }>(url, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }
}
