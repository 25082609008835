<div class="filter-container" fxLayout="column">
  <div>
    <mat-form-field >
      <mat-label>Date</mat-label>
      <mat-date-range-input [formGroup]="dateRangeFormGroup" [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="Start">
        <input matEndDate formControlName="end" placeholder="End">
      </mat-date-range-input>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Filter</mat-label>
      <mat-select [(ngModel)]="filterId">
        <mat-option *ngFor="let filter of filters" [value]="filter.id">{{ filter.name }}</mat-option>
      </mat-select>
    </mat-form-field>
    <button
      mat-raised-button
      class="phonecheck-button"
      (click)="onDownload()"
      color="primary"
      title="Download">Download</button>
    <!-- <button mat-icon-button (click)="onClearFilter()" title="Clear filter"><mat-icon>refresh</mat-icon></button> -->
  </div>
  <div class="latest-or-all-container" fxLayout="column">
    <mat-radio-group [(ngModel)]="recordsToShow">
      <mat-radio-button value="all"><b>Show all records</b></mat-radio-button>
      <mat-radio-button value="latest"><b>Show latest record only</b></mat-radio-button>
    </mat-radio-group>
    <mat-checkbox
      *isGranted="['Custom Reports', 'Email Report Settings']"
      [disabled]="emailStatusCheckboxDisabled"
      [(ngModel)]="emailsEnabled"
      (ngModelChange)="updateEmailStatus()"
    >Email reports automatically</mat-checkbox>
    <a
      *isGranted="['Custom Reports', 'Email Report Settings']"
      class="settings-link" (click)="onOpenAutomaticEmailSettings()">Settings</a>
  </div>

  <h2>Monthly History</h2>

  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <mat-table [dataSource]="reports">
    <ng-container  [matColumnDef]="'filename'">
      <mat-header-cell *matHeaderCellDef>
        Filename
      </mat-header-cell>
      <mat-cell *matCellDef="let report">
          {{ report.filename }}
      </mat-cell>
    </ng-container>
    <ng-container  [matColumnDef]="'createdAt'">
      <mat-header-cell *matHeaderCellDef>
        Added On
      </mat-header-cell>
      <mat-cell *matCellDef="let report">
        {{ report.createdAt |  date: 'mediumDate' }}
      </mat-cell>
    </ng-container>
    <ng-container  [matColumnDef]="'fileURL'">
      <mat-header-cell *matHeaderCellDef>
        Download Link
      </mat-header-cell>
      <mat-cell *matCellDef="let report">
        <a target="_blank" [href]="report.fileURL">Download Link</a>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let report; columns: displayedColumns"></mat-row>
    <tr *matNoDataRow>
      <td id="empty-table-td">
        You don't have any monthly reports yet. 
      </td>
    </tr>
  </mat-table>
  <mat-paginator
    showFirstLastButtons
    (page)="onPaginate($event)"
    [pageSize]="paginatorData.pageSize"
    [pageSizeOptions]="paginatorData.pageOptions"
    [length]="paginatorData.length"
    ></mat-paginator>
</div>
