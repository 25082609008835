import { Injectable } from '@angular/core';
import { HttpResponse, HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Page } from '../interfaces/page.interface';
import { NotificationService } from "./notification.service";
import { environment } from '../../environments/environment';
import exceljs from 'exceljs';
import * as fs from 'file-saver';
import { AutomaticEmailSettingsCreationPayload } from "../automatic-email-settings/creation-payload";
import { EmailHistory } from "../interfaces/email-history.interface";
import { AutomaticEmailSettings } from '../interfaces/automatic-email-settings.interface';
import { ServiceResult } from "../common/service-result.enum";
import { SendTestEmailPayload } from "../automatic-email-settings/test-email-payload";

@Injectable({
  providedIn: 'root'
})
export class CustomReportService {
  constructor(
    private http: HttpClient,
    private notify: NotificationService,
  ) { }

  async getAutomaticEmailSettingsList(page: number, size: number): Promise<Page<any> | undefined> {
    const url = `${environment.apiUrl}/master/reports/automatic-email-settings`;
    const params = new HttpParams()
      .append('page', page)
      .append('size', size);

    try {
      const result = await this.http.get(url, { params }).toPromise()

      return result as Page<any>;
    } catch(err) {
      console.error(err);
      this.notify.error(`Failed to get Automatic Email Settings list`);

      return;
    }
  }

  async getAutomaticEmailSettings(id: string): Promise<AutomaticEmailSettings | undefined> {
    const url = `${environment.apiUrl}/master/reports/automatic-email-settings/${id}`;
    try {
      const result = await this.http.get(url).toPromise()

      return result as AutomaticEmailSettings;
    } catch(err) {
      console.error(err);
      this.notify.error(`Failed to get Automatic Email Settings`);

      return;
    }
  }

  async getEmailHistory(page: number, size: number): Promise<Page<EmailHistory> | undefined> {
    const url = `${environment.apiUrl}/master/reports/email-history`;
    const params = new HttpParams()
      .append('page', page)
      .append('size', size);

    try {
      const result = await this.http.get(url, { params }).toPromise()

      return result as Page<any>;
    } catch(err) {
      console.error(err);
      this.notify.error(`Failed to get Email History`);

      return;
    }
  }

  async createAutomaticEmailSettingsList(
    data: AutomaticEmailSettingsCreationPayload,
  ): Promise<ServiceResult> {
    const url = `${environment.apiUrl}/master/reports/automatic-email-settings`;

    try {
      const result = await this.http.post(url, data).toPromise()

      return ServiceResult.SAVED;
    } catch(err) {
      console.error(err);
      this.notify.error(`Failed to create Automatic Email Settings`);

      return ServiceResult.ERROR;
    }
  }

  async sendTestEmail(
    data: SendTestEmailPayload,
  ): Promise<ServiceResult> {
    const url = `${environment.apiUrl}/master/reports/test-email`;

    try {
      const result = await this.http.post(url, data).toPromise()

      this.notify.error(`The test Email was sent.`);

      return ServiceResult.SAVED;
    } catch(err) {
      console.error(err);
      this.notify.error(`Failed to send test email`);

      return ServiceResult.ERROR;
    }
  }



  async updateAutomaticEmailSettings(
    id: string,
    data: AutomaticEmailSettingsCreationPayload,
  ): Promise<ServiceResult> {
    const url = `${environment.apiUrl}/master/reports/automatic-email-settings/${id}`;

    try {
      const result = await this.http.put(url, data).toPromise()

      return ServiceResult.SAVED;
    } catch(err) {
      console.error(err);

      this.notify.error(`Failed to update Automatic Email Settings`);

      return ServiceResult.ERROR;
    }
  }

  async deleteAutomaticEmailSettings(
    id: string,
  ): Promise<ServiceResult> {
    const url = `${environment.apiUrl}/master/reports/automatic-email-settings/${id}`;

    try {
      const result = await this.http.delete(url).toPromise()

      return ServiceResult.SAVED;
    } catch(err) {
      console.error(err);

      this.notify.error(`Failed to update Automatic Email Settings`);

      return ServiceResult.ERROR;
    }
  }

  async updateAutomaticEmailSettingsStatus(enabled: boolean): Promise<void> {
    const url = `${environment.apiUrl}/master/settings/automatic-email-settings`;
    const data = {
      enabled,
    };

    try {
      await this.http.put(url, data).toPromise()
    } catch(err) {
      console.error(err);

      this.notify.error(`Failed to update automatic email settings status`);

      return;
    }
  }

  async getAutomaticEmailSettingsStatus(): Promise<boolean | undefined> {
    const url = `${environment.apiUrl}/master/settings/automatic-email-settings`;

    try {
      const r = await this.http.get(url).toPromise() as { enabled: boolean };

      return r.enabled;
    } catch(err) {
      console.error(err);
      this.notify.error(`Failed to get automatic email settings status`);

      return;
    }
  }
}

