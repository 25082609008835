import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';
import { PasswordProtectionService } from 'src/app/services/password-protection.service';

@Component({
  selector: 'app-password-protect-profile',
  templateUrl: './password-protect-profile.component.html',
  styleUrls: ['./password-protect-profile.component.css']
})
export class PasswordProtectProfileComponent implements OnInit {
  hideConfirmPassword: boolean = true;
  hidePassword = true;
  protectProfile = new FormControl(false);
  passwordProtection = this.fb.group({
    password: ['', [Validators.required]],
    confirmPassword: ['', [Validators.required]]
  });

  hasPasswordProtection = false;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder, private service: PasswordProtectionService,
    private notify: NotificationService
  ) {
    this.passwordProtection.disable();
  }


  async ngOnInit(): Promise<void> {
    this.hasPasswordProtection = await firstValueFrom(this.service.hasPasswordProtection());
  }

  async passwordProtectProfile() {
    if (
      this.passwordProtection.valid &&
      this.passwordProtection.value.password == this.passwordProtection.value.confirmPassword
    ) {
      const saved = await firstValueFrom(this.service.savePassword(this.passwordProtection.value.password as string, this.data.id));

      if (saved) {
        this.notify.success('Password protected');
        this.dialogRef.close(saved);
      } else {
        this.notify.error('Password not saved');
      }
    } else if (
      this.passwordProtection.valid &&
      this.passwordProtection.value.password !== this.passwordProtection.value.confirmPassword
    ) {
      this.notify.warn('Passwords do not match');
    }
  }

  enableFormGroup() {
    if (this.protectProfile.value) {
      this.passwordProtection.enable();
    } else {
      this.passwordProtection.disable();
    }
  }
}
