import { Component, EventEmitter } from '@angular/core';
import { DeviceInfoService } from '../services/deviceInfo.service';
import { MacDeviceInfoService } from '../services/macDeviceInfo.service';

@Component({
  selector: 'app-custom-reports',
  templateUrl: './custom-reports.component.html',
  styleUrls: ['./custom-reports.component.css']
})
export class CustomReportsComponent {
  automaticEmailSettingsOpen: boolean = false;
  openSettingsEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public deviceInfoService: DeviceInfoService,
    public macInfoService: MacDeviceInfoService,
  ) {
    this.openSettingsEmitter.subscribe((open: boolean) => {
      this.automaticEmailSettingsOpen = open;
    });
  }
}
