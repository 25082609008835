export enum APPLE_ACTION {
  CHECK_CARRIER_IF_UNKNOWN_AND_CHECK_SIMLOCK = 'CHECK_CARRIER_IF_UNKNOWN_AND_CHECK_SIMLOCK',
  ALWAYS_CHECK_CARRIER_AND_SIMLOCK = 'ALWAYS_CHECK_CARRIER_AND_SIMLOCK',
  ALWAYS_CHECK_SIMLOCK_ONLY = 'ALWAYS_CHECK_SIMLOCK_ONLY',
  DO_NOT_CHECK = 'DO_NOT_CHECK',
};

export enum SAMSUNG_ACTION {
  CHECK_CARRIER_IF_UNKNOWN = 'CHECK_CARRIER_IF_UNKNOWN',
  DO_NOT_CHECK = 'DO_NOT_CHECK',
};

export enum LG_ACTION {
  CHECK_CARRIER_IF_UNKNOWN = 'CHECK_CARRIER_IF_UNKNOWN',
  DO_NOT_CHECK = 'DO_NOT_CHECK',
};

export enum GOOGLE_ACTION {
  CHECK_CARRIER_IF_UNKNOWN = 'CHECK_CARRIER_IF_UNKNOWN',
  DO_NOT_CHECK = 'DO_NOT_CHECK',
};

export enum NOT_SUPPORTED_OR_KNOWN_CARRIER_ACTION {
  CHECK_GLOBAL_BLACKLIST_ONLY = 'CHECK_GLOBAL_BLACKLIST_ONLY',
  CHECK_ALL_POSIBLE_DATABASES = 'CHECK_ALL_POSIBLE_DATABASES',
  PROMPT_OPERATOR_FOR_INPUT = 'PROMPT_OPERATOR_FOR_INPUT',
  TAKE_NO_ACTION = 'TAKE_NO_ACTION',
}

export enum UNLOCKED_ACTION {
  CHECK_GLOBAL_BLACKLIST_ONLY = 'CHECK_GLOBAL_BLACKLIST_ONLY',
  CHECK_ALL_POSIBLE_DATABASES = 'CHECK_ALL_POSIBLE_DATABASES',
  PROMPT_OPERATOR_FOR_INPUT = 'PROMPT_OPERATOR_FOR_INPUT',
  TAKE_NO_ACTION = 'TAKE_NO_ACTION',
}

export enum VERIZON_ACTION {
  CHECK_GLOBAL_BLACKLIST_ONLY = 'CHECK_GLOBAL_BLACKLIST_ONLY',
  CHECK_ALL_POSIBLE_DATABASES = 'CHECK_ALL_POSIBLE_DATABASES',
  CHECK_VERIZON = 'CHECK_VERIZON',
  TAKE_NO_ACTION = 'TAKE_NO_ACTION',
}

export enum TMOBILE_ACTION {
  CHECK_GLOBAL_BLACKLIST_ONLY = 'CHECK_GLOBAL_BLACKLIST_ONLY',
  CHECK_ALL_POSIBLE_DATABASES = 'CHECK_ALL_POSIBLE_DATABASES',
  CHECK_TMOBILE = 'CHECK_TMOBILE',
  TAKE_NO_ACTION = 'TAKE_NO_ACTION',
}

export enum ATT_ACTION {
  CHECK_GLOBAL_BLACKLIST_ONLY = 'CHECK_GLOBAL_BLACKLIST_ONLY',
  CHECK_ALL_POSIBLE_DATABASES = 'CHECK_ALL_POSIBLE_DATABASES',
  CHECK_ATT = 'CHECK_ATT',
  TAKE_NO_ACTION = 'TAKE_NO_ACTION',
}

export enum TRACFONE_ACTION {
  CHECK_GLOBAL_BLACKLIST_ONLY = 'CHECK_GLOBAL_BLACKLIST_ONLY',
  CHECK_ALL_POSIBLE_DATABASES = 'CHECK_ALL_POSIBLE_DATABASES',
  CHECK_TRACFONE = 'CHECK_TRACFONE',
  TAKE_NO_ACTION = 'TAKE_NO_ACTION',
}

export enum IMEI_CHECK_SETTING {
  COST_EFFECTIVE = 'Cost Effective',
  MOST_ACCURATE = 'Most Accurate',
  CUSTOM_WORKFLOW = 'Custom Workflow',
}

export interface IMEISettingConfiguration {
  samsungAction: SAMSUNG_ACTION,
  notSupportedOrUnknownCarrierAction: NOT_SUPPORTED_OR_KNOWN_CARRIER_ACTION,
  unlockedAction: UNLOCKED_ACTION,
  verizonAction: VERIZON_ACTION,
  tmobileAction: TMOBILE_ACTION,
  attAction: ATT_ACTION,
  tracfoneAction: TRACFONE_ACTION,
  appleIPhoneAction: APPLE_ACTION,
  appleIPadAction: APPLE_ACTION,
}

export interface IMEICheckConfiguration {
  automaticIMEICheck: boolean;
  usFinancialCarrierSetting: boolean;
  name: IMEI_CHECK_SETTING,
  configuration: IMEISettingConfiguration,
}

