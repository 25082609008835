import { Component } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { debounceTime, Subject } from 'rxjs';
import { ImeiService } from '../services/imei.service';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';
import { NotificationService } from '../services/notification.service';
import { Router } from '@angular/router';

interface Request {
  imei: string;
  comprehensive: boolean;
  make?: string;
  routines: {
    blacklist: boolean;
    usBlackListInsurance: boolean;
    japanese: boolean;
    tMobile: boolean;
    verizon: boolean;
    appleInfo: boolean;
    iCloud: boolean;
    simlock: boolean;
    samsung: boolean;
    google: boolean;
    LG: boolean;
  }
}
interface Result {
  warn: boolean;
  title: string;
  status: boolean
  service: any;
  mapped: any;
  result: any;
  raw: string;
  viewRaw?: boolean;
}

@Component({
  selector: 'app-imei',
  templateUrl: './imei.component.html',
  styleUrls: ['./imei.component.css']
})
export class ImeiComponent {

  tabIndex = 0;
  modelChanged = new Subject<string>();
  searchType: 'imei' | 'serial' = 'imei';
  tac = {
    make: '',
    model: '',
  };

  settings = {
    imei: '',
    comprehensive: {
      checked: true,
      disabled: false,
    },
    blacklist: {
      checked: true,
      disabled: false,
    },
    usBlackListInsurance: {
      checked: true,
      disabled: false,
    },
    japanese: {
      checked: false,
      disabled: false,
    },
    tMobile: {
      checked: true,
      disabled: false,
    },
    verizon: {
      checked: true,
      disabled: false,
    },
    appleInfo: {
      checked: true,
      disabled: false,
    },
    iCloud: {
      checked: true,
      disabled: true,
    },
    simlock: {
      checked: true,
      disabled: true,
    },
    samsung: {
      checked: true,
      disabled: false,
    },
    google: {
      checked: true,
      disabled: false,
    },
    LG: {
      checked: true,
      disabled: false,
    },
  }

  showResults = false;
  loading = false;
  results: Result[] = [];
  overallBad = false;
  historyReportId: string | null = null;
  error = '';

  constructor(
    private imeiService: ImeiService,
    public authService: AuthService,
    private notify: NotificationService,
    private router: Router,
  ) {
    this.modelChanged
      .pipe(
        debounceTime(800))
      .subscribe(() => {
        if (this.searchType === 'imei') {
          this.getTac();
        }
      })

    if (!this.authService.isMasterContext() && !this.authService.isUserContext() && !this.authService.getSelectedMaster()) {
      this.notify.warn('Select an account to view the customizations profiles');
      this.router.navigate(['/dashboard']);
    }
  }

  tabChange(evnt: MatTabChangeEvent) {
    if (evnt.index === 1) {
      this.goToIMEIPage();
      this.tabIndex = 0;
    }
  }

  imeiFormChanged() {
    this.checkInput();
    this.modelChanged.next('');
    this.error = '';
  }

  imeiClearField(){
    this.settings.imei = '';
    this.tac.make = '';
    this.reset();
  }

  inputDeviceCodeInvalid = false;

  checkInput() {
    const t = /^\w+$/;

    this.inputDeviceCodeInvalid
      = this.settings.imei?.length > 0
      && !t.test(this.settings.imei);
  }

  async getTac() {

    if (!this.settings.imei.length) {
      return;
    }

    if (this.inputDeviceCodeInvalid) {
      return;
    }

    const tac = await this.imeiService.getTac(this.settings.imei);

    const disabled = {
      checked: false,
      disabled: true,
    }

    const enabled = {
      checked: true,
      disabled: false,
    }
    if (tac.make && tac.model) {
      this.tac.make = tac.make;
      this.tac.model = tac.model;

      if (tac.make === 'Apple') {
        this.settings.appleInfo.checked = true;
        this.settings.appleInfo.disabled = false;
        this.settings.iCloud.checked = true;
        this.settings.iCloud.disabled = false;
        this.settings.simlock.checked = true;
        this.settings.simlock.disabled = false;
        this.settings.samsung.checked = false;
        this.settings.samsung.disabled = true;
        this.settings.LG.checked = false;
        this.settings.LG.disabled = true;
        this.settings.google.checked = false;
        this.settings.google.disabled = true;
      }

      if (tac.make === 'Samsung') {
        this.settings.samsung.disabled = false;
        this.settings.samsung.checked = true;
        this.settings.appleInfo.disabled = true;
        this.settings.appleInfo.checked = false;
        this.settings.iCloud.disabled = true;
        this.settings.iCloud.checked = false;
        this.settings.simlock.disabled = true;
        this.settings.simlock.checked = false;
        this.settings.LG.disabled = true;
        this.settings.LG.checked = false;
        this.settings.google.disabled = true;
        this.settings.google.checked = false;
      }

      if (tac.make === 'LG') {
        this.settings.samsung.disabled = true;
        this.settings.samsung.checked = false;
        this.settings.appleInfo.checked = false;
        this.settings.appleInfo.disabled = true;
        this.settings.iCloud.checked = false;
        this.settings.iCloud.disabled = true;
        this.settings.simlock.checked = false;
        this.settings.simlock.disabled = true;
        this.settings.LG.disabled = false;
        this.settings.LG.checked = true;
        this.settings.google.checked = false;
        this.settings.google.disabled = true;
      }

      if (tac.make === 'Google') {
        this.settings.samsung.disabled = true;
        this.settings.samsung.checked = false;
        this.settings.appleInfo.disabled = true;
        this.settings.appleInfo.checked = false;
        this.settings.iCloud.checked = false;
        this.settings.iCloud.disabled = true;
        this.settings.simlock.checked = false;
        this.settings.simlock.disabled = true;
        this.settings.LG.disabled = true;
        this.settings.LG.checked = false;
        this.settings.google.checked = true;
        this.settings.google.disabled = false;
      }
    }
  }

  async begin() {
    if (this.loading) {

      return;
    }

    if (!this.settings.imei) {
      console.error(`Invalid IMEI: ${this.settings.imei}`);

      return;
    }

    if (!this.tac.make && this.searchType === 'imei') {
      console.error(`Invalid TAC: ${this.tac.make}`);

      return;
    }

    this.results.length = 0;
    this.historyReportId = null;
    this.overallBad = false;
    this.loading = true;
    this.error = '';

    const responseSettings: Request = {
      imei: this.settings.imei,
      comprehensive: this.settings.comprehensive.checked,
      routines: {
        blacklist: this.settings.blacklist.checked,
        usBlackListInsurance: this.settings.usBlackListInsurance.checked,
        japanese: this.settings.japanese.checked,
        tMobile: this.settings.tMobile.checked,
        verizon: this.settings.verizon.checked,
        appleInfo: this.settings.appleInfo.checked,
        iCloud: this.settings.iCloud.checked,
        simlock: this.settings.simlock.checked,
        samsung: this.settings.samsung.checked,
        google: this.settings.google.checked,
        LG: this.settings.LG.checked,
      },
    };

    if (this.searchType === 'imei') {
      responseSettings.make = this.tac.make;
    }

    let results: any[];
    try {
      results = await this.imeiService.runService(responseSettings);
    } catch (e) {
      console.error(e);
      this.error = e.error.msg;
      this.loading = false;

      throw e;
    }

    this.loading = false;
    results.forEach(result => {
      if (result.historyReportId) {
        this.historyReportId = result.historyReportId;
      }
      result.warn = false;
      Object.keys(result.mapped).forEach(key => {
        if (result.mapped[key].status && ['warn'].includes(result.mapped[key].status)) {
          result.warn = true;
          this.overallBad = true;
        }
      });
      this.results.push(result);
    });


    this.showResults = true;

  }

  reset() {
    this.showResults = false;
    this.loading = false;
    this.results.length = 0;
    this.historyReportId = null;

    if (this.searchType == 'serial') {
      this.disableAll('');
    } else {
      this.resetSettingsToDefault();
      this.tac = {
        make: '',
        model: '',
      };

      this.modelChanged.next('');
      this.overallBad = false;
    }
  }

  resetSettingsToDefault() {
    this.resetCheckboxSettingsToDefault();
    this.settings.imei = '';
  }

  resetCheckboxSettingsToDefault(){
    this.settings = {
      imei: this.settings.imei,
      comprehensive: {
        checked: true,
        disabled: false,
      },
      usBlackListInsurance: {
        checked: true,
        disabled: false,
      },
      blacklist: {
        checked: true,
        disabled: false,
      },
      japanese: {
        checked: false,
        disabled: false,
      },
      tMobile: {
        checked: true,
        disabled: false,
      },
      verizon: {
        checked: true,
        disabled: false,
      },
      appleInfo: {
        checked: true,
        disabled: false,
      },
      iCloud: {
        checked: true,
        disabled: true,
      },
      simlock: {
        checked: true,
        disabled: true,
      },
      samsung: {
        checked: true,
        disabled: false,
      },
      google: {
        checked: true,
        disabled: false,
      },
      LG: {
        checked: true,
        disabled: false,
      },
    }
  }

  public goToIMEIPage(): void {
    if (this.authService.userType == 'cloudAdmin') {
      window.open(`${environment.imeiUrl}/?token=${this.authService.tokenMasterGenerated}`, '_blank');
    } else {
      window.open(`${environment.imeiUrl}/?token=${this.authService.token}`, '_blank');
    }
  }

  disableMostComprehensive() {
    this.settings.comprehensive = {
      checked: false,
      disabled: false,
    };
  }

  updateDeviceCheckboxes() {
    if (this.settings.appleInfo.checked) {
      this.settings.iCloud.checked = true;
      this.settings.iCloud.disabled = true;
      this.settings.simlock.checked = true;
      this.settings.simlock.disabled = true;
    } else {
      this.settings.iCloud.disabled = false;
      this.settings.simlock.disabled = false;
    }
  }

  openHistoryReport() {
    window.open(`${environment.dhrURL}/report/${this.historyReportId}`, '_blank');
  }

  updateSearchType() {
    const existingIMEI = this.settings.imei;
    this.tac = {
      make: '',
      model: '',
    };
    if (this.searchType === 'imei') {
      this.enableAll(existingIMEI);
    } else {
      this.disableAll(existingIMEI);
    }
  }

  toggleComprehensive() {
    if (this.settings.comprehensive.checked) {
      this.resetCheckboxSettingsToDefault();
    }
  }
  disableAll(imei: string) {
    this.settings = {
      imei: imei,
      comprehensive: {
        checked: false,
        disabled: true,
      },
      usBlackListInsurance: {
        checked: false,
        disabled: true,
      },
      blacklist: {
        checked: false,
        disabled: true,
      },
      japanese: {
        checked: false,
        disabled: true,
      },
      tMobile: {
        checked: false,
        disabled: true,
      },
      verizon: {
        checked: false,
        disabled: true,
      },
      appleInfo: {
        checked: false,
        disabled: false,
      },
      iCloud: {
        checked: false,
        disabled: false,
      },
      simlock: {
        checked: false,
        disabled: false,
      },
      samsung: {
        checked: false,
        disabled: false,
      },
      google: {
        checked: false,
        disabled: true,
      },
      LG: {
        checked: false,
        disabled: true,
      },
    };

  }
  enableAll(imei: string) {
    this.settings = {
      imei: imei,
      comprehensive: {
        checked: true,
        disabled: false,
      },
      usBlackListInsurance: {
        checked: true,
        disabled: false,
      },
      blacklist: {
        checked: true,
        disabled: false,
      },
      japanese: {
        checked: false,
        disabled: false,
      },
      tMobile: {
        checked: true,
        disabled: false,
      },
      verizon: {
        checked: true,
        disabled: false,
      },
      appleInfo: {
        checked: true,
        disabled: false,
      },
      iCloud: {
        checked: true,
        disabled: true,
      },
      simlock: {
        checked: true,
        disabled: true,
      },
      samsung: {
        checked: true,
        disabled: false,
      },
      google: {
        checked: true,
        disabled: false,
      },
      LG: {
        checked: true,
        disabled: false,
      },
    };
  }
  uncheckAll() {
    this.settings.comprehensive.checked = false;
    this.settings.blacklist.checked = false;
    this.settings.japanese.checked = false;
    this.settings.tMobile.checked = false;
    this.settings.verizon.checked = false;
    this.settings.appleInfo.checked = false;
    this.settings.appleInfo.disabled = false;
    this.settings.iCloud.checked = false;
    this.settings.iCloud.disabled = false;
    this.settings.simlock.checked = false;
    this.settings.simlock.disabled = false;
    this.settings.samsung.checked = false;
    this.settings.google.checked = false;
    this.settings.LG.checked = false;
    this.settings.usBlackListInsurance.checked = false;
  }
  checkAll() {
    this.settings.comprehensive.checked = true;
    this.settings.blacklist.checked = true;
    this.settings.japanese.checked = true;
    this.settings.tMobile.checked = true;
    this.settings.verizon.checked = true;
    this.settings.appleInfo.checked = true;
    this.settings.iCloud.checked = true;
    this.settings.simlock.checked = true;
    this.settings.samsung.checked = true;
    this.settings.google.checked = true;
    this.settings.LG.checked = true;
    this.settings.usBlackListInsurance.checked = true;

    this.settings.samsung.disabled = false;
    this.settings.google.disabled = false;
    this.settings.LG.disabled = false;

    this.settings.appleInfo.disabled = false;
    this.settings.iCloud.disabled = true;
    this.settings.simlock.disabled = true;
  }
}
