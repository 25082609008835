import { ElementRef, ViewChild, AfterViewInit, Inject, Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthService, UserType } from "./services/auth.service";
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import packageInfo from '../../package.json';
import { environment } from '../environments/environment';
import { ToggleMenuService } from './services/toggle-menu.service';
import { DomSanitizer } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material/icon";
import { AdminService } from './services/admin.service';
import {
  Subject,
  debounceTime,
  distinctUntilChanged,
  ReplaySubject,
  Observable,
  of,
} from 'rxjs';
import { FormControl } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { NotificationService } from "./services/notification.service";
import { FlatTreeControl } from "@angular/cdk/tree";
import { MatTreeFlattener, MatTreeFlatDataSource } from "@angular/material/tree";
import { MasterService } from "./services/master.service";
import { Warehouse } from "./interfaces/warehouse.interface";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('masterFilter') masterFilter!: ElementRef<HTMLInputElement>;
  @ViewChild('warehouseFilter') warehouseFilter!: ElementRef<HTMLInputElement>;
  private ALL_MASTERS_OPTION_ID = 'all_masters';
  private readonly ALL_MASTERS_OPTION = {
    id: this.ALL_MASTERS_OPTION_ID,
    name: 'View as admin',
  };
  private ALL_WAREHOUSES_OPTION_ID = 'all_warehouses';
  private ALL_WAREHOUSES = { id: this.ALL_WAREHOUSES_OPTION_ID, name: 'All Warehouses', timezone: 'UTC' };
  private readonly MASTER_SELECTION_DEBOUNCE_TIME = 150;
  private readonly ALL_MASTER_DATA_FLAG_DEBOUNCE_TIME = 100;
  private readonly AUTOCOMPLETE_LIST_LIMIT = 100;
  private filterSubject = new Subject<string>();
  private seeAllMasterDataSubject = new Subject<boolean>();

  // Flag to show a spinner while user mode or masters
  // are changed.
  public changingUserModeOrMaster = false;

  // whether the current page displayed allows to see all
  // masters' data
  public allowUseAllMasterData: boolean = false;

  // whether the user has selected to view data as admin
  // (all masters' data)
  public useAllMasterData: boolean = true;

  // Whether an admin user id logged in.
  public adminContext: boolean;

  public accountNameToShow: string | undefined;

  ready = false;
  title = 'Phonecheck Cloud';
  version = packageInfo['version'];
  apiUrl = environment.apiUrl;
  editMode = false;
  queryParamCount = 0;
  showMenu = true;
  showHeaderFooter = false;
  masterAccounts = new ReplaySubject<any[]>(1);
  masterSearchInputControlForm: FormControl<string> = new FormControl();
  userType: UserType = 'master';
  searching: boolean = false;
  masterSelected!: { id: string; v1MasterId: number; name: string; };
  weglot = (this.document.defaultView as any).Weglot;
  showContext = false;
  allMasters: any[] = [];
  filteredMasters: any[] = [];
  warehouses: Warehouse[] = [];
  warehouseSelected: any;
  warehouseFormControl = new FormControl();
  filteredOptions = new Observable<any>;
  filteredWarehouses: { name: string; id: string; }[] = [];
  private filterWarehouseSubject = new Subject<string>();
  public showHeader: boolean = false;

  constructor(
    private router: Router,
    public authService: AuthService,
    private toggleMenu: ToggleMenuService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private adminService: AdminService,
    private notificationService: NotificationService,
    private masterService: MasterService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.dataSource.data = ADVANCED_MENU_OPTION_DATA;
    this.reportsMenu.data = REPORTS_MENU_OPTION_DATA;
    this.adminContext = this.authService.isAdminContext();
    this.useAllMasterData = this.authService.getViewAsAdmin();

    this.weglot?.on('languageChanged', (language: any) => {
      this.document.defaultView?.dispatchEvent(new Event('resize'));
    });
    // Subscribe to router events to update header and sidenav based on the current route
    this.router.events.subscribe((event) => {

      if (event instanceof NavigationStart) {
        this.showHeader = event.url !== '/login';
      }

      if (event instanceof NavigationEnd) {
        this.checkRouteAndToggleHeaderAndSidenav();
      }
    });

    this.matIconRegistry.addSvgIcon(
      'dhr',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/dhr.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'a4',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/a4.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'er',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/er.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'scr',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/scr.svg'),
    );

    this.userType = this.authService.userType

    const url = new URL(window.location.href);
    const token = url.searchParams.get("token_master");
    const embed = url.searchParams.get("embed");
    console.log(`Version: ${this.version}`);
    console.log(`API URL: ${environment.apiUrl}`);


    if (token) {
      this.authService.setFreshAuth(token);
    }


    if (embed) {
      this.editMode = true;
    }

    this.document.defaultView?.addEventListener('storage', (elem) => {
      if (elem.key === 'userContext') {
        const oldValue = elem.oldValue;
        const newValue = elem.newValue;

        if (oldValue && newValue) {
          const oldMaster = JSON.parse(oldValue)?.targetMaster;
          const newMaster = JSON.parse(newValue)?.targetMaster;

          if (newMaster.id !== oldMaster.id) {
            // Here order is important
            // Remove warehouse first and then select master
            this.authService.removeSelectedWarehouse(false);
            this.authService.setSelectedMaster(newMaster);
            this.masterSearchInputControlForm.setValue(newMaster.name, { emitEvent: false });
          } else {
            const oldWarehouse = JSON.parse(oldValue)?.targetWarehouse;
            const newWarehouse = JSON.parse(newValue)?.targetWarehouse;

            if (oldWarehouse.id !== newWarehouse.id) {
              this.authService.setSelectedWarehouse(newWarehouse);
              this.warehouseFormControl.setValue(newWarehouse, { emitEvent: false });
            }
          }
        }
      }
    });

    this.authService.getLoginEventEmitter().subscribe(userType => {
      this.adminContext = this.authService.isAdminContext();
      this.getWarehousesByUser().then();

      if (this.authService.isAdminContext()) {
        this.setupAdminMode();
      }
    });

  }

  private async getWarehousesByUser() {
    this.warehouses = await this.authService.getWarehousesByUser();
    this.filteredOptions = of(this.warehouses);

    const currentWarehosue = this.authService.getSelectedWarehouse();

    if (currentWarehosue) {
      this.warehouseFormControl.setValue(currentWarehosue);
    } else {
      this.warehouseFormControl.setValue(this.warehouses[0] as any);
    }

  }

  private getWarehouseByFilter(filter: string | null) {
    const newFilter = filter?.toLowerCase();
    let result = [];

    if (newFilter) {
      result = this.warehouses.filter(master => {
        const name: string = master.name.toLowerCase();
        return name.includes(newFilter)
          || master?.id !== master.id
      });
    } else {
      result = this.warehouses;
    }

    return result.slice(0, this.AUTOCOMPLETE_LIST_LIMIT);
  }

  private getMasterByFilter(filter: string | null): any[] {
    const newFilter = filter?.toLowerCase();
    const master = this.authService.getSelectedMaster();
    let result = [];

    if (newFilter) {
      result = this.allMasters.filter(master => {
        const name: string = master.name.toLowerCase();
        return name.includes(newFilter)
          || master?.id !== master.id
      });
    } else {
      result = this.allMasters;
    }

    return result.slice(0, this.AUTOCOMPLETE_LIST_LIMIT);
  }

  public ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentRoute = event.urlAfterRedirects; // Get the full current route
        if (currentRoute.includes('/release-notes')) {
          return;
        } 
        // Redirect to login if user is NOT authenticated and trying to access a restricted page
        if (!this.authService.isAuthenticated()) {
          this.router.navigate(['/login']);
        }
      }
    });

    this.toggleMenu.isOpenObservable.subscribe(isOpen => {
      this.showMenu = isOpen;
    });

    this.toggleMenu.allowAllMasterUsageObservable.subscribe(allow => {
      this.allowUseAllMasterData = allow;
    });

    this.authService.getLoginEventEmitter().subscribe(value => {
      if (value === 'cloudAdmin') {
        this.setupAdminMode();
      }

      if (value === 'user') {
        this.getWarehousesByUser();
      }

      this.accountNameToShow = this.authService.getAccountNameToShow();
    });

    if (this.authService.isAdminContext()) {
      this.setupAdminMode();
    } else {
      // this.allowIntercom();
    }

    if (this.authService.isUserContext()) {
      this.getWarehousesByUser().then(_ => { });
    }

    this.accountNameToShow = this.authService.getAccountNameToShow();
  }

  private getMasterWarehouses() {
    return this.masterService.getWarehousesV3().then(warehouses => {
      if (warehouses) {
        this.warehouses = [{ name: 'All Warehouses', id: 'all_warehouses', timezone: 'UTC' }, ...warehouses];
      }

      const currentWarehouse = this.authService.getSelectedWarehouse();

      if (currentWarehouse && this.warehouses.find(w => currentWarehouse.id === w.id)) {
        this.warehouseFormControl.setValue(currentWarehouse);
      } else {
        this.warehouseFormControl.setValue(this.ALL_WAREHOUSES);
      }
    });
  }

  setupAdminMode(): void {
    const selectedMaster = this.authService.getSelectedMaster();
    const viewAsAdmin = this.authService.getViewAsAdmin();

    this.userType = 'cloudAdmin'

    if (viewAsAdmin) {
      this.allowUseAllMasterData = true;
      this.onViewAsAdmin(true);
      this.warehouses = [];
    } else if (selectedMaster) {
      this.masterSelected = selectedMaster;
      this.masterSearchInputControlForm.setValue(selectedMaster.name, { emitEvent: false });
      this.getMasterWarehouses();
      // this.allowIntercom();
    }

    this.adminService.getAllMasters().then((masters: any) => {
      this.filteredMasters = this.getMasterByFilter(null);
      this.allMasters = [this.ALL_MASTERS_OPTION, ...masters];
      this.ready = true;
    }).catch(err=>{
      console.error(err);
    });

    this.masterSearchInputControlForm.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(200),
    ).subscribe((filter) => {
      const master = this.allMasters.find(m => m.name === filter);

      if (master) {
        this.selectMaster(master);
      } else {
        console.error(`No master found for search term: "${filter}"`);
      }
    });

    this.filterSubject
      .pipe(debounceTime(this.MASTER_SELECTION_DEBOUNCE_TIME))
      .subscribe(filterValue => {
        filterValue = filterValue.trim();
        this.filteredMasters = this.getMasterByFilter(filterValue);
      });

    this.seeAllMasterDataSubject
      .pipe(
        debounceTime(this.ALL_MASTER_DATA_FLAG_DEBOUNCE_TIME)
      )
      .subscribe(allData => {
        this.onViewAsAdmin(allData);
      });
  }

  onViewAsAdmin(viewAllMastersData: boolean): void {
    this.viewAsAdmin(viewAllMastersData);
    this.router.navigate(['/dashboard']);
  }

  public ngAfterViewInit(): void {
    this.authService.userType$.subscribe(userType => {
      this.userType = userType;
    });

    this.filterWarehouseSubject.pipe(
      debounceTime(this.ALL_MASTER_DATA_FLAG_DEBOUNCE_TIME),
    ).subscribe(warehouse => {
      this.filteredWarehouses = this.getWarehouseByFilter(warehouse);
    });

    this.warehouseFormControl.valueChanges.subscribe((vc: any) => {
      if (vc && this.ALL_WAREHOUSES_OPTION_ID === vc.id) {
        this.authService.removeSelectedWarehouse();
      } else if (vc) {
        this.authService.setSelectedWarehouse(vc);
      }
    });
  }

  private allowIntercom() {
    const master = this.authService.getSelectedMaster();

    // if (this.userType !== 'cloudAdmin' || !!master) {
    //   (<any>window)['Intercom']('shutdown');
    //   (<any>window)['Intercom']('boot', {
    //     name: this.userType == 'cloudAdmin'
    //       ? (master
    //         ? master.name
    //         : this.authService.user?.userName)
    //       : this.authService.user?.userName,
    //   });
    // }
  }

  allowWeglot() {
    // @ts-ignore
    Weglot.initialize({
      api_key: 'wg_aaa6bc0bf0a5e56beee22d212197fe487',
      switchers: [
        {
          button_style: {
            full_name: true,
            with_name: true,
            is_dropdown: true,
            with_flags: true,
            flag_type: "circle"
          },
          location: {
            target: ".weglot",
            sibling: null
          },

        }
      ]
    });
  }

  public logout(): void {
    this.authService.logout();
    (<any>window)['Intercom']('shutdown');
    this.router.navigate(['/login']);
  }

  public goToToolbox(): void {
    if (this.authService.userType == 'cloudAdmin') {
      window.open(`${environment.toolboxUrl}/?token=${this.authService.tokenMasterGenerated}`, '_blank');
    } else {
      window.open(`${environment.toolboxUrl}/?token=${this.authService.token}`, '_blank');
    }
  }

  public selectMaster(master: any, reload: boolean = true): void {
    const allMasters = master?.id === this.ALL_MASTERS_OPTION_ID;

    this.changingUserModeOrMaster = true;

    if (!allMasters) {
      this.masterSelected = master;

      // Here order is important
      // Remove warehouse first and then select master
      this.authService.removeSelectedWarehouse(false);
      this.authService.setSelectedMaster(master);
      this.getMasterWarehouses();
    } else {
      this.authService.removeSelectedMaster();
      this.warehouses = [];
    }

    this.masterSearchInputControlForm.setValue(master.name, { emitEvent: false });
    this.onViewAsAdmin(allMasters);
    this.accountNameToShow = this.authService.getAccountNameToShow();

    // The user expects a spinner to show when switching masters.
    // Give them some UI signal for them to know the change is handled.
    // Give the spinner some time to show.
    this.wait(500).then(() => {
      this.changingUserModeOrMaster = false;
      this.adminService.adminEmitter.next(true)

      this.router.navigate(['/dashboard']);
    });
  }

  private filterWarehouse(filterTerm: string): void {
    this.filterWarehouseSubject.next(filterTerm);
  }

  private filter(filterTerm: string): void {
    this.filterSubject.next(filterTerm);
  }

  public isAdminContext(): boolean {
    return this.authService.isAdminContext();
  }

  onUseAllMasterChange(value: boolean) {
    this.seeAllMasterDataSubject.next(value);
  }

  private checkRouteAndToggleHeaderAndSidenav(): void {
    this.showHeaderFooter = !this.router.url.includes('mac-erasure-report') && !this.router.url.includes('device-erasure-report');
  }

  public onFocus(): void {
    this.masterSearchInputControlForm.setValue('', { emitEvent: false });
    this.filter('');
  }

  public onFocusWarehouse(): void {
    this.warehouseFormControl.setValue('', { emitEvent: false });
    this.filterWarehouse('');
  }

  public onMasterListOpened(): void {
    const value = this.getFilterTerm();

    if (!value) {
      this.onFocus();
    }
  }

  public onWarehouseListOpened(): void {
    const value = this.getFilterTermWarehouse();

    if (!value) {
      this.onFocus();
    }
  }

  public onMasterListClosed(): void {
    const value = this.getFilterTerm();
    const master = this.authService.getSelectedMaster();

    if (!value && master) {
      this.masterSearchInputControlForm.setValue(master.name);
    }
  }

  public onWarehouseListClosed(): void {
    const warehouse = this.authService.getSelectedWarehouse();


    if (warehouse) {
      this.warehouseFormControl.setValue(warehouse);
    } else {
      this.warehouseFormControl.setValue(this.ALL_WAREHOUSES);
    }
  }

  public onMasterSelected(event: any): void {
  }

  private getFilterTermWarehouse() {
    return this.warehouseFilter.nativeElement.value.toLowerCase();
  }

  private getFilterTerm(): string {
    return this.masterFilter.nativeElement.value.toLowerCase();
  }

  public onFilterWarehouseTermChange(): void {
    const term = this.getFilterTermWarehouse();
    this.filterWarehouse(term);
  }

  public onFilterTermChange(): void {
    const term = this.getFilterTerm();

    this.filter(term);
  }

  public showWarehousesForAdmin() {
    return this.userType === 'cloudAdmin'
      && !this.useAllMasterData;
  }

  private viewAsAdmin(seeAllData: boolean): void {
    this.useAllMasterData = seeAllData;

    if (seeAllData) {
      this.masterSearchInputControlForm.setValue(this.ALL_MASTERS_OPTION.name, { emitEvent: false });
    } else {
      const master = this.authService.getSelectedMaster();

      if (master) {
        this.masterSearchInputControlForm.setValue(master.name, { emitEvent: false });
      } else {
        this.notificationService.warn('To disable admin view, select a master first');
        this.useAllMasterData = true;
      }
    }

    this.authService.setViewAsAdmin(this.useAllMasterData);
    this.authService.setAllMasterContext(
      this.allowUseAllMasterData
      && this.useAllMasterData
    );
  }

  public displayFn(value?: any) {
    if (this.warehouses?.length) {
      return this.warehouses.find(warehouse => warehouse.id == value?.id)?.name || '';
    }
    return '';
  }

  public onWarehouseSelected(selectedWarehouse: any) {
    this.warehouseSelected = selectedWarehouse;
    this.warehouseFormControl.setValue(selectedWarehouse);
  }

  public async onViewAsAdminClicked(): Promise<void> {
    this.changingUserModeOrMaster = true;
    this.viewAsAdmin(!this.useAllMasterData);

    // The user expects a spinner to show when switching modes.
    // Give them some UI signal for them to know the change is handled.
    // Give the spinner some time to show.
    await this.wait(500);
    this.changingUserModeOrMaster = false;
  }

  private wait(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  public initWeglot() {
    this.weglot.initialize({
      api_key: 'wg_aaa6bc0bf0a5e56beee22d212197fe487',
      switchers: [
        {
          button_style: {
            full_name: true,
            with_name: true,
            is_dropdown: true,
            with_flags: true,
          },
          location: {
            target: ".language-switcher",
            sibling: null
          }
        }
      ]
    });
  }

  private _transformer = (node: MenuExpandableOption, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      route: node.route,
      permissions: node.permissions,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<FlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  dataSource = new MatTreeFlatDataSource(
    this.treeControl,
    (this.treeFlattener as any)
  );
  reportsMenu = new MatTreeFlatDataSource(
    this.treeControl,
    (this.treeFlattener as any)
  );

  hasChild = (_: number, node: FlatNode) => node.expandable;
  isRoot = (_: number, node: FlatNode) => node.level === 0 && !this.hasChild(_, node);

}

const ADVANCED_MENU_OPTION_DATA: MenuExpandableOption[] = [
  {
    name: 'Advanced',
    children: [
      {
        name: 'SKU Configuration',
        route: '/sku-configuration',
        permissions: ['SKU Configuration'],
      },
    ],
  },
];

const REPORTS_MENU_OPTION_DATA: MenuExpandableOption[] = [
  {
    name: 'Reports',
    children: [
      {
        name: 'Custom Reports',
        route: '/custom-reports',
        permissions: ['Custom Reports'],
      },
      {
        name: 'Activity Reports',
        route: '/activity-reports',
        permissions: ['Activity Reports'],
      },
    ],
  },
];


interface MenuExpandableOption {
  name: string;
  children?: MenuExpandableOption[];
  route?: string;
  permissions?: string[];
}

interface FlatNode {
  expandable: boolean;
  name: string;
  level: number;
  route: string | undefined;
}
