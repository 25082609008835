import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

interface TAC {
  make: string;
  model: string;
}

@Injectable({
  providedIn: 'root'
})
export class ImeiService {

  constructor(private http: HttpClient) { }
  async getTac(imei: string): Promise<TAC> {
    let result: TAC = {
      make: '',
      model: ''
    };

    try {
      const url = `${environment.apiUrl}/tac/${imei}`;

      result = await this.http.get(url).toPromise() as TAC;
    } catch (e) {
      console.error(`Invalid TAC`);
    }

    return result;
  }

  async runService(settings: any): Promise<any> {
    let result;
    const url = `${environment.apiUrl}/dash/imei`;

    result = await this.http.post(url, settings).toPromise();

    return result;
  }
}
