<div class="warning-container">
  <h1 mat-dialog-title>
    <mat-icon>warning</mat-icon>
    Disabling Default Test is not recommended
  </h1>
  <p mat-dialog-content>
    Disabling the default test will not affect performance. However, it
    will produce limited certificate data for those devices!
  </p>
  <div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="true">Cancel</button>
    <button mat-button [mat-dialog-close]="false">Save</button>
  </div>
</div>
