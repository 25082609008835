<div fxLayout="column">
  <div id="filter-container" fxLayout="row">
    <div id="left">
      <mat-form-field>
        <mat-label>Date</mat-label>
        <mat-select [formControl]="dateOptionFormControl">
          <mat-option value="TODAY" selected>Today</mat-option>
          <mat-option value="YESTERDAY" >Yesterday</mat-option>
          <mat-option value="LAST_7_DAYS">Last 7 days</mat-option>
          <mat-option value="LAST_30_DAYS">Last 30 days</mat-option>
          <mat-option value="THIS_MONTH">This Month</mat-option>
          <mat-option value="LAST_MONTH">Last Month</mat-option>
          <mat-option value="CUSTOM_DATE_RANGE">Custom Date Range</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field >
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [disabled]="!datePickerEnabled()" [rangePicker]="picker">
          <input matStartDate (blur)="onDateRangeChanged()" [(ngModel)]="startDate"placeholder="Start">
          <input matEndDate (blur)="onDateRangeChanged()" [(ngModel)]="endDate" placeholder="End">
        </mat-date-range-input>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker (closed)="onDateRangeChanged()"></mat-date-range-picker>
      </mat-form-field>
      <mat-form-field class="filter-input">
        <mat-label>Search devices</mat-label>
        <input matInput [formControl]="keywordFormControl" type="search">
      </mat-form-field>
      <button mat-icon-button (click)="onClearFilter()" title="Clear filter"><mat-icon>refresh</mat-icon></button>
    </div>
    <div id="right" fxLayout="row" fxLayoutAlign="end">
      <mat-form-field>
        <mat-label>Select view filter</mat-label>
        <mat-select [formControl]="selectedFilterFormControl">
          <mat-option *ngFor="let filter of filters" value="{{ filter.id }}">{{ filter.name }}</mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-icon-button (click)="saveFilter()" title="Save filter"><mat-icon>save</mat-icon></button>
      <button mat-icon-button (click)="configureColumns()" title="Column settings"><mat-icon>settings</mat-icon></button>
    </div>
  </div>
  <div class="button-row" fxLayout="column">
    <mat-progress-bar mode="indeterminate" *ngIf="filesLoading"></mat-progress-bar>
    <div fxLayout="row">
      <button mat-raised-button (click)="onDownloadExcel()" title="Download Excel">Excel</button>
      <button mat-raised-button (click)="onDownloadCSV()" title="Download CSV">CSV</button>
      <button mat-raised-button (click)="onCopy()" [disabled]="copyingToClipboard" title="Copy">Copy</button>
      <button mat-raised-button *ngIf="accountSettings?.gradingDataExport" (click)="onGradingDataExport()" [disabled]="copyingToClipboard" title="Grading Data Export">Grading Data Export</button>
    </div>
  </div>
  <div>
    <app-mac-device-info-datatable [params]="getDeviceInfoParams()" [filters]="filters" [allColumns]="allColumns" [columns]="columns"></app-mac-device-info-datatable>
  </div>
</div>
