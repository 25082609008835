<main>
  <div [ngStyle]="{'display': createOrModify ? 'none' : 'block'}">
    <p style="width:40vw; text-wrap: pretty; margin-bottom: 1em;">
      Custom tests allow you to create a test that will prompt the user while
      testing mobile devices with an instruction along with a pass/ fail option.
    </p>

    <div style="display: flex; justify-content: space-between; align-items: center;">
      <button mat-raised-button color="primary" type="button" (click)="fnCreateOrModify()">
        Create Test
      </button>

      <mat-form-field>
        <mat-label>Search</mat-label>
        <input matInput [formControl]="searchCustomTestsControl" />
      </mat-form-field>
    </div>


    <table mat-table [dataSource]="customTestsDataSource" matSort matSortActive="modifiedDate"
      matSortDisableClear="true" matSortDirection="desc">
      <ng-container matColumnDef="testName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Test Name </th>
        <td mat-cell *matCellDef="let element" style="font-weight: bold;"> {{element.testName}} </td>
      </ng-container>
      <ng-container matColumnDef="instructions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Instruction </th>
        <td mat-cell *matCellDef="let element" style="font-weight: bold;"> {{element.instructions}} </td>
      </ng-container>
      <ng-container matColumnDef="platform">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Platform </th>
        <td mat-cell *matCellDef="let element" style="font-weight: bold;"> {{element.platform}} </td>
      </ng-container>
      <ng-container matColumnDef="modelNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Model No </th>
        <td mat-cell *matCellDef="let element" style="font-weight: bold;"> {{element.modelNumber}} </td>
      </ng-container>
      <ng-container matColumnDef="modifiedDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Modified Date </th>
        <td mat-cell *matCellDef="let element" style="font-weight: bold;"> {{element.modifiedDate | date: 'MM/dd/yyyy'}}
        </td>
      </ng-container>
      <ng-container matColumnDef="buttons">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element; let i = index" style="min-width: 132px;">
          <button mat-icon-button (click)="editCustomTest(element)" matTooltip="Edit"><mat-icon>edit</mat-icon></button>
          <button mat-icon-button (click)="deleteCustomTest(element)"
            matTooltip="Delete"><mat-icon>delete</mat-icon></button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <ng-container *ngIf="createOrModify">
    <form [formGroup]="customTestFormGroup">
      <mat-form-field>
        <mat-label> Test Name </mat-label>
        <input type="text" matInput formControlName="testName" />
      </mat-form-field>
      <mat-form-field>
        <mat-label> Instructions </mat-label>
        <textarea rows="5" matInput formControlName="instructions"> Instructions</textarea>
      </mat-form-field>
      <mat-form-field>
        <mat-label> Platform</mat-label>
        <mat-select formControlName="platform">
          <mat-option *ngFor="let platform of platforms" [value]="platform">
            {{ platform }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label> Model No</mat-label>
        <input type="text" matInput formControlName="modelNumber" />
      </mat-form-field>
    </form>
  </ng-container>
</main>
