import { ColumnConfiguration } from '../../interfaces/columnSettings.interface';

export const MAC_DEVICE_INFO_COLUMNS: ColumnConfiguration[] = [
  { field: 'reports', displayName: 'Reports' },
  { field: 'masterName', displayName: 'Master Name' },
  { field: 'tester', displayName: ' Station User' },
  { field: 'testerName', displayName: 'Tester Name' },
  { field: 'vendorName', displayName: 'Vendor Name' },
  { field: 'invoiceNo', displayName: 'Invoice No' },
  { field: 'serial', displayName: 'Serial' },
  { field: 'udid', displayName: 'UDID' },
  { field: 'make', displayName: 'Make' },
  { field: 'modelName', displayName: 'Model Name' },
  { field: 'model', displayName: 'Model Number' },
  { field: 'year', displayName: 'Year' },
  { field: 'color', displayName: 'Color' },
  { field: 'chip', displayName: 'Chip' },
  { field: 'keyboard', displayName: 'Keyboard' },
  { field: 'storage', displayName: 'Storage' },
  { field: 'memory', displayName: 'Memory' },
  { field: 'os', displayName: 'OS' },
  { field: 'osVersion', displayName: 'OS Version' },
  { field: 'wifiMacAddress', displayName: 'WiFi MAC Address' },
  { field: 'mpn', displayName: 'MPN' },
  { field: 'platform', displayName: 'Platform' },
  { field: 'lpn', displayName: 'LPN' },
  { field: 'firmware', displayName: 'Firmware' },
  { field: 'buildNo', displayName: 'Build Number' },
  { field: 'portNumber', displayName: 'Port Number' },
  { field: 'bios', displayName: 'Bios' },
  { field: 'windowsPcName', displayName: 'windows Pc Name' },
  { field: 'deviceLock', displayName: 'Device Lock' },
  { field: 'rooted', displayName: 'Rooted' },
  { field: 'mdmResponse', displayName: 'MDM Response' },
  { field: 'testingStatus', displayName: 'Working' },
  { field: 'passedTest', displayName: 'Passed' },
  { field: 'failedTest', displayName: 'Failed' },
  { field: 'pendingTest', displayName: 'Pending' },
  { field: 'eraseStatus', displayName: 'Erase Status' },
  { field: 'eraseType', displayName: 'Erase Type' },
  { field: 'eraseDate', displayName: 'Erase Date' },
  { field: 'eraseTotalTime', displayName: 'Erase Total Time' },
  { field: 'batteryHealthPercentage', displayName: 'Battery Health Percentage' },
  { field: 'batteryServiceMessage', displayName: 'Battery Condition' },
  { field: 'batteryChargePercentage', displayName: 'Battery Charge Percentage' },
  { field: 'batteryCycleCharge', displayName: 'Battery Cycle Count' },
  { field: 'batteryModel', displayName: 'Battery Model' },
  { field: 'batterySource', displayName: 'Battery Source' },
  { field: 'batteryTemperature', displayName: 'Battery Temperature' },
  { field: 'diskSerial', displayName: 'Disk Serial' },
  { field: 'diskModel', displayName: 'Disk Model' },
  { field: 'smartStatus', displayName: 'Smart Status' },
  { field: 'diskType', displayName: 'Disk Type' },
  { field: 'totalNumberOfDrives', displayName: 'Total Number Of Drives' },
  { field: 'cpuName', displayName: 'CPU Name' },
  { field: 'cpuSpeed', displayName: 'CPU Speed' },
  // { field: 'cpuModel', displayName: 'CPU Model' },
  { field: 'gpuInfo', displayName: 'GPU Info' },
  { field: 'grade', displayName: 'Grade' },
  { field: 'profileName', displayName: 'Grading Profile' },
  { field: 'questionAnswer', displayName: 'Grading Q/A' },
  { field: 'guid', displayName: 'Device History Report Link' },
  { field: 'cosmeticsInfo', displayName: 'Cosmetics' },
  { field: 'createdAt', displayName: 'Creation Time' },
  { field: 'updatedAt', displayName: 'Update Time' },
];
