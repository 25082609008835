// src/app/release-notes/release-notes.component.ts
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BuildService } from 'src/app/services/build.service';
import { AppComponent } from 'src/app/app.component'; // Import the AppComponent

@Component({
  selector: 'app-release-notes',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.css'],
})
export class ReleaseNotesComponent implements OnInit {

  buildData: any;
  buildId: number | null = null;
  isLoading: boolean = false; // Loader flag

  constructor(
    private appComponent: AppComponent,
    private route: ActivatedRoute,
    private buildService: BuildService
  ) {
  }

  ngOnInit(): void {
    this.appComponent.showHeaderFooter = false;
    this.route.paramMap.subscribe((params) => {
      this.buildId = parseInt(params.get('buildId') || '0', 10);
      if (this.buildId) {
        this.isLoading = true; // Start the loader
        this.buildService.getReleaseNotes(this.buildId).subscribe((data) => {
          if (data) {
            this.buildData = data;
          } else {
            this.buildData = null;
          }
          this.isLoading = false; // Loader flag
        });
      }
    });
  }
  ngOnDestroy(): void {
    // Re-enable header/footer when navigating away
    this.appComponent.showHeaderFooter = true;
  }
}
