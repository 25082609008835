import moment, { Moment } from 'moment-timezone';

import { OnDestroy, ViewChild, Component, OnInit, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CdkDragDrop, CdkDragStart, moveItemInArray } from '@angular/cdk/drag-drop';
import { MacDeviceInfoService } from '../services/macDeviceInfo.service';
import { Page } from '../interfaces/page.interface';
import { DateOption, toDateString, DateRange } from '../common/date';
import { MatDialog } from '@angular/material/dialog';
import { CreateDeviceInfoFilterComponent } from '../dialogs/create-device-info-filter/create-device-info-filter.component';
import { MacDeviceInfoColumnConfigurationComponent } from '../dialogs/mac-device-info-column-configuration/mac-device-info-column-configuration.component';
import { ColumnConfiguration, ColumnSettings, SavedColumnSettings, ColumnSettingsCreationRequest } from '../interfaces/columnSettings.interface';
import { NotificationService } from '../services/notification.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MAC_DEVICE_INFO_COLUMNS } from '../common/mac/columns';
import { MacJSONViewerComponent } from '../dialogs/mac-json-viewer/mac-json-viewer.component';
import { ArrayViewerComponent } from '../dialogs/mac-grading-info/array-viewer.component';
import { MatSort } from "@angular/material/sort";
import { debounceTime, skip } from 'rxjs/operators';
import { Subject, takeUntil } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { ToggleMenuService } from "../services/toggle-menu.service";
import { AuthService } from "../services/auth.service";
import { environment } from '../../environments/environment';
import { MacDeviceInfo } from "../common/mac/mac-device-info.interface";
import { PaginatorData } from "../interfaces/paginatorData.interface";
import { MacDeviceInfoDatatableComponent } from "../mac-device-info-datatable/mac-device-info-datatable.component";
import { DownloadFormat } from "../common/download";
import { MasterService } from "../services/master.service";

@Component({
  selector: 'app-mac-device-info',
  templateUrl: './mac-device-info.component.html',
  styleUrls: ['./mac-device-info.component.css']
})
export class MacDeviceInfoComponent implements AfterViewInit, OnDestroy {
  @ViewChild(MacDeviceInfoDatatableComponent) macTableComponent!: MacDeviceInfoDatatableComponent;

  private readonly INPUT_FIELD_DEBOUNCE_TIME = 300;
  loading: boolean = false;
  filesLoading: boolean = false;
  copyingToClipboard: boolean = false;
  filters: any[] = [];
  paginatorData: PaginatorData = {
    pageIndex: 0,
    length: 0,
    pageSize: 10,
    currentPage: 0,
    pageOptions: [10, 20, 50],
  };
  allColumns: ColumnConfiguration[]
    = this.authService.userType !== 'cloudAdmin'
    ? MAC_DEVICE_INFO_COLUMNS.filter(obj => obj.field !== 'masterName')
    : MAC_DEVICE_INFO_COLUMNS;
  columns: ColumnConfiguration[] = this.allColumns;

  deviceInfoList: MacDeviceInfo[] = [];
  dateOptionFormControl: FormControl<DateOption | null>;
  keywordFormControl: FormControl<string | null>;
  selectedFilterFormControl: FormControl<any>;
  startDate: moment.Moment;
  endDate: moment.Moment;
  columnFilterFormControls!: Record<string, FormControl<string | null>>;

  accountSettings: {
    gradingDataExport?: boolean,
  } = {};

  private ngUnsubscribe: Subject<void> = new Subject();

  private setMasterSettings(settings: any) {
    this.accountSettings = settings || {};
  }

  constructor(
    private deviceInfoService: MacDeviceInfoService,
    private dialog: MatDialog,
    private notify: NotificationService,
    private headerMenuService: ToggleMenuService,
    private authService: AuthService,
    private masterService: MasterService,
  ) {
    this.startDate = moment();
    this.endDate = moment();
    this.dateOptionFormControl = new FormControl<DateOption | null>(DateOption.TODAY);
    this.keywordFormControl = new FormControl<string | null>(null);
    this.selectedFilterFormControl = new FormControl(null);
    this.columnFilterFormControls = {};
    this.dateOptionFormControl.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {

        this.updateDatePickerRange(value);

        if (DateOption.CUSTOM_DATE_RANGE !== value) {
          this.getDeviceInfo();
        }
      });

    this.selectedFilterFormControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
      const filter = this.findFilter(value);

      if (filter) {
        this.applyColumnSettings(filter);
      }
    });

    this.keywordFormControl.valueChanges
      .pipe(debounceTime(this.INPUT_FIELD_DEBOUNCE_TIME))
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
        this.getDeviceInfo();
      });

    this.headerMenuService.allowUseAllMasterData(true);
  }

  handleInputClick(event: MouseEvent): void {
    event.stopPropagation();
  }

  private refresh(): void {
    this.headerMenuService.disableSeeAllMasterDataCheckbox();
    Promise.all([
      this.setDisplayedColumns(),
      this.getDeviceInfo(),
    ]).finally(() => {
      this.headerMenuService.enableSeeAllMasterDataCheckbox();
    });
  }

  private updateDatePickerRange(option: DateOption | null): void {
    if (option) {
      const range = this.getDateRangeFromDateOption(option);

      if (range) {
        const { start, end } = range;

        this.updateDatePickerInputs(start, end)
      }
    }
  }

  private updateDatePickerInputs(start: Moment, end: Moment): void {
    this.startDate = start;
    this.endDate = end;
  }

  public datePickerEnabled(): boolean {
    const dateOption = this.dateOptionFormControl.getRawValue();

    return dateOption === DateOption.CUSTOM_DATE_RANGE;
  }

  public ngAfterViewInit(): void {
    this.headerMenuService.allowUseAllMasterData(true);
    this.authService.allMasterContextEmitter.asObservable().pipe(takeUntil(this.ngUnsubscribe)).subscribe(allMasterContext => {
      this.refresh();
    });
    this.authService.masterSelectedEmitter.asObservable().pipe(takeUntil(this.ngUnsubscribe)).subscribe(master => {
      this.refresh();
    });

    this.authService.warehouseSelectedEmitter.asObservable()
      .pipe(
        skip(1),
        takeUntil(this.ngUnsubscribe)
      ).subscribe(warehouse => {
      this.refresh();
    });

    this.getMacDeviceInfoColumns();
    this.getFilters().then(() => {
      this.setDisplayedColumns();
    });


    if (this.authService.isMasterSelected()) {
      this.masterService.getMasterSettings()
        .then(settings => {
          this.setMasterSettings(settings);
        });
    }

  }

  public setDisplayedColumns() {
    this.macTableComponent?.setDisplayedColumns(this.columns);
  }

  public saveFilter() {
    const currentColumnSettings = this.getColumnSettings();
    const saveFilterDialogRef = this.dialog.open(CreateDeviceInfoFilterComponent, {
      disableClose: false,
    });

    saveFilterDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (chosenName) => {
      if (chosenName) {
        const newColumnSettings: ColumnSettingsCreationRequest = {
          name: chosenName,
          ...currentColumnSettings,
        };

        try {
          const settings = await this.deviceInfoService.saveFilter(newColumnSettings);

          if (settings) {
            this.filters.push(settings);
          }
        } catch (err) {
          console.error(err);
        };
      }
    });
  }

  private getColumnSettings(): ColumnSettings {
    return this.macTableComponent.getColumnSettings();
  }

  async updateColumnOrderPreference(): Promise<void> {
    const settingsId = this.selectedFilterFormControl.getRawValue();
    const settings = this.findFilter(settingsId);
    const newSettings: ColumnSettings = this.getColumnSettings();
    const payload = {
      id: settingsId,
      ...newSettings,
      name: '',
    };

    if (settings) {
      const { name } = settings;
      payload.name = name;

      if (this.canUpdateColumnSettings()) {
        const result = await this.deviceInfoService.updateColumnSettings(settingsId, payload);
        this.updateLocalFilter(payload);
      }
    }
  }

  private updateLocalFilter(filter: SavedColumnSettings): void {
    const index = this.filters.findIndex(f => f.id === filter.id);

    if (index >= 0) {
      this.filters[index] = filter;
    }
  }

  public canUpdateColumnSettings(): boolean {
    const settingsId = this.selectedFilterFormControl.getRawValue();

    return settingsId !== 'default' && settingsId;
  }

  async getDeviceInfo() {
    const params = this.getDeviceInfoParams();

    if (params && params.dateRange) {
      this.macTableComponent?.resetPagination();
      this.macTableComponent?.onParamsChange(params);
    }
  }

  public openDHRreport(id: number) {
    this.deviceInfoList.forEach((item: any) => {
      if (item['id'] === id) {
        if (item['guid']) {

          const reportUrl = `${environment.dhrQRURL}/${item['guid']}`;
          // Open the URL in a new tab
          const newTab = window.open(reportUrl, '_blank');
        }
      }
    });
  }

  public onClearFilter(): void {
    this.keywordFormControl.setValue(null, { emitEvent: false });
    this.dateOptionFormControl.setValue(DateOption.TODAY);
  }

  private getTodayDateRange(): DateRange {
    const start = moment();
    const end = start.clone();

    return { start, end };
  }

  private getYesterdayDateRange(): DateRange {
    const start = moment().add(-1, 'days');
    const end = start.clone();

    return { start, end };
  }

  private getLastSevenDaysDateRange(): DateRange {
    const end = moment();
    const start = end.clone().add(-6, 'days');

    return { start, end };
  }

  private getLastThirtyDaysDateRange(): DateRange {
    const end = moment();
    const start = end.clone().add(-29, 'days');

    return { start, end };
  }

  private getThisMonthDateRange(): DateRange {
    const end = moment();
    const start = end.clone().startOf('month');

    return { start, end };
  }
  private getLastMonthDateRange(): DateRange {
    const now = moment();
    const start = now.clone().add(-1, 'months').startOf('month');
    const end = now.clone().add(-1, 'months').endOf('month');

    return { start, end };
  }

  private getDateRangeFromDateOption(option: DateOption): { start: Moment, end: Moment } | undefined {
    switch (option) {
      case DateOption.TODAY:
        return this.getTodayDateRange();
      case DateOption.YESTERDAY:
        return this.getYesterdayDateRange();
      case DateOption.LAST_7_DAYS:
        return this.getLastSevenDaysDateRange();
      case DateOption.LAST_30_DAYS:
        return this.getLastThirtyDaysDateRange();
      case DateOption.THIS_MONTH:
        return this.getThisMonthDateRange();
      case DateOption.LAST_MONTH:
        return this.getLastMonthDateRange();
    }

    return undefined;
  }

  public configureColumns(): void {
    const settingsId = this.selectedFilterFormControl.getRawValue();
    const settings = this.findFilter(settingsId);

    if (settings) {
      const { name } = settings;
      const configureColumnsDialogRef = this.dialog.open(MacDeviceInfoColumnConfigurationComponent, {
        disableClose: false,
        height: '95vh',
        data: {
          settings: this.getColumnSettings(),
          deviceInfoService: this.deviceInfoService,
          id: settingsId,
          name,
        }
      });

      configureColumnsDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe((preference: SavedColumnSettings) => {
        if (preference) {
          const index = this.filters.findIndex(f => f.id == preference.id);

          if (index >= 0) {
            this.filters[index] = preference;
          } else {
            this.filters.push(preference);
          }
          this.selectedFilterFormControl.setValue(preference.id);
        } else if (typeof preference === 'boolean') {
          const index = this.filters.findIndex(f => f.id === settingsId);

          if (index >= 0) {
            this.filters.splice(index, 1);
            this.setDefaultFilter();
          }
        }
      });
    }
  }

  private setDefaultFilter(): void {
    this.selectedFilterFormControl.setValue('default');
  }

  private clearSelectedFilter(): void {
    this.selectedFilterFormControl.setValue(null, { emitEvent: false });
  }

  private getCurrentColumnSettingsId(): string {
    return this.selectedFilterFormControl.getRawValue() || 'default';
  }

  private async getFilters() {
    const filters = await this.deviceInfoService.getFilters();

    if (filters) {
      this.filters = filters;
      this.setDefaultFilter();
    }
  }

  private findFilter(id: string) {
    return this.filters.find(f => f.id === id);
  }

  public getDeviceInfoParams(gradingDataExportOnly: boolean = false) {
    const start = this.startDate;
    const end = this.endDate;
    const keyword = this.keywordFormControl.getRawValue();
    const columns = this.macTableComponent?.getColumnFilters() || {};
    const columnSettingsId = this.getCurrentColumnSettingsId();

    if (!this.isDateRangeValid()) {
      return undefined;
    }

    if (columnSettingsId) {
      columns['columnSettingsId'] = columnSettingsId;
    }

    return {
      dateRange: {
        start: toDateString(start),
        end: toDateString(end),
      },
      keyword,
      columns,
      gradingDataExportOnly,
    }
  }

  private applyColumnSettings(settings: ColumnSettings): void {
    const { visible } = settings;

    this.columns = visible;
    this.setDisplayedColumns();
  }

  public async onDownloadCSV() {
    return this.onDownload(DownloadFormat.CSV);
  }

  public async onDownloadExcel() {
    return this.onDownload(DownloadFormat.EXCEL);
  }

  private async onDownload(format: DownloadFormat, gradingDataExportOnly: boolean = false) {
    this.filesLoading = true;
    const params = this.getDeviceInfoParams(gradingDataExportOnly);

    if (params) {
      await this.deviceInfoService.downloadDeviceInfo(params, format);
      this.filesLoading = false;
    }
  }

  public async onCopy() {
    this.copyingToClipboard = true;
    const params = this.getDeviceInfoParams();

    if (params) {
      await this.deviceInfoService.copyToClipboard(params);
    }
    this.copyingToClipboard = false;
  }

  ngOnDestroy(): void {
    this.headerMenuService.allowUseAllMasterData(false);
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private async getMacDeviceInfoColumns() {
    let columns = await this.deviceInfoService.getMacDeviceDefaultColumns();
    columns = this.authService.userType !== 'cloudAdmin'
      ? columns.filter(obj => obj.field !== 'masterName')
      : columns;
    this.macTableComponent.setAllColumns('default', columns)
  }

  public onGradingDataExport() {
    this.onDownload(DownloadFormat.EXCEL, true);
  }

  public onDateRangeChanged() {
    if (this.isDateRangeValid()) {
      this.getDeviceInfo();
    }
  }

  private isDateRangeValid(): boolean {
    const start = this.startDate;
    const end   = this.endDate;

    return !!start && !!end && start.isValid() && end.isValid()
      && start.isSameOrBefore(end);
  }
}

